import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  Stack,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Link,
  Grid,
  Container,
  Select,
  FormControl,
  InputLabel,  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Input,
  CardActions,
  Avatar
} from '@mui/material';
import AxiosFunction from '../../axiosCustomInstance';
import jwtDecode from 'jwt-decode';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import LoadingDialog from '../Loading';
import { indigo, blueGrey, red,cyan,blue,orange } from '@mui/material/colors';
import ApplicationBar from '../AppBar/ApplicationBar';
import userAuth from '../ProtectedRoute/userAuth';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance';
import Login_Im from "../Images/Aspetto_Login.png";
import cbpseal from "../Images/cbpseal.png"
import { color, styled, ThemeProvider } from '@mui/system';
import { Twitter, Facebook, Instagram,LinkedIn, Fullscreen  } from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import Logo from "../Images/logo.png"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const theme = createTheme({
  palette: {
      primary: {
          main: blueGrey[900],
      },
      secondary: {
          main: blueGrey[300],
      },
  },
});

// Define styles using the styled utility
const Footer = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '12px', // Adjust the font size as needed
  color: 'gray',   // Adjust the color as needed
  borderTop: '0px solid #ccc', // Add a border at the top for separation
}));
const SocialIcons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));


const DHSCBPBodyArmorInstructionsExternal = () => {

  const [dis, setDis] = useState(false);
  const [dHSMssge, setDHSMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [fileTypeFilter, setFileTypeFilter] = useState('all');
// Function to handle search input change
const handleSearch = (e) => {
  // Get the search query from the input field and convert it to lowercase
  const query = e.target.value.toLowerCase();
  // Update the search query state
  setSearchQuery(query);
};

// Function to handle file type filter change
const handleFilterChange = (e) => {
  // Update the file type filter state based on the selected value
  setFileTypeFilter(e.target.value);
};

// Filter the file list based on search query and file type filter
const filteredFiles = fileList.filter(file => {
  // Check if the file name contains the search query
  const isMatch = file.File_Name.toLowerCase().includes(searchQuery);
  // Check if the file type matches the selected file type filter or if 'all' is selected
  const isFileTypeMatch = fileTypeFilter === 'all' || file.FILE_Type.toLowerCase() === fileTypeFilter;
  // Return true if both conditions are met
  return isMatch && isFileTypeMatch;
});

// Function to handle viewing details of a file
const handleViewDetails = (file) => {
  try {
      // Set the selected file and determine if it's a video file
      setSelectedFile(file);
      setIsVideo(file.FILE_Type === 'MP4'); 
      // Open the details dialog and reset video playing state
      setDetailsDialogOpen(true);
      setVideoPlaying(false);
  } catch (error) {
      // Handle any errors that occur
      setErrorDialog(true);
      setDHSMssge('Error while handling view details:', error);
  }
};

// Function to handle closing dialogs
const handleClose = (e) => {
  // Close all open dialogs and reset relevant state variables
  setOpen(false);
  setDHSMssge('');
  setErrorDialog(false);
  setSuccessDialog(false);
  setErrorDialog(false);
};

// Function to fetch file list from the server
const handleFileList = async () => {
  try {
      // Send a GET request to retrieve the file list
      const response = await AxiosGlobalAPIInstance.get('ProductInstructionsUpload/DHSExternal/FileSearch/FileList');
      // Check if response data is an array and update the file list state
      if (response?.data && Array.isArray(response.data)) {
          setFileList(response.data);
      } else {
          // Display error message if response data is not as expected
          setDHSMssge(response.data);
          setErrorDialog(true);
      }
  } catch (error) {
      // Handle errors that occur during the request
      setDHSMssge('Error fetching file list:', error);
      setErrorDialog(true);
  }
};

// UseEffect hook to fetch file list when the component mounts or 'dHSMssge' changes
React.useEffect(() => {
  handleFileList();
}, [dHSMssge]);


  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setIsVideo(false);
  };

  const handleDownloadFile = async (fileId) => {
    try {
      setLoading(true);
      const originalFileName = fileId;
      const encodedFileName = encodeURIComponent(originalFileName);

// Set the response type to 'blob' for binary data
const response = await AxiosGlobalAPIInstance.get(`ProductInstructionsUpload/DHSExternal/FileSearch/DownloadFile/${encodedFileName}`, {
  responseType: 'blob',
});

// Create a Blob from the binary data
const url = window.URL.createObjectURL(new Blob([response.data]));

// Create a link element for download
const a = document.createElement('a');
a.href = url;

// Use the original (decoded) file name for download
a.download = fileId;

// Append the link to the document, trigger a click, and remove the link
document.body.appendChild(a);
a.click();
document.body.removeChild(a);
  setLoading(false);
  
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Axios error with a response from the server
        setDHSMssge(error.response?.data.message || 'An error occurred while downloading the file.');
      } else if (error.request) {
        // The request was made but no response was received
        setDHSMssge('No response received from the server.');
      } else {
        // Other errors (network, timeout, etc.)
        setDHSMssge('An unexpected error occurred while downloading the file.');
      }
      setErrorDialog(true);
      setSuccessDialog(false);
      console.error('Error downloading file:', error);
    }
  };
  



  return (
    <>
     <ThemeProvider theme={theme}>
 <Footer>
      <LoadingDialog open={loading} />
      {successDialog === true && (
        <Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant="filled" severity="success">SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorDialog === true && (
        <Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant="filled" severity="error">ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
     <Dialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog}  justifyContent="center">
        <DialogTitle>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }} justifyContent="center">
            File History
          </Typography>
        </DialogTitle>
        <Divider sx={{ color: 'red[900]', bgcolor: 'red[900]', width: '100%', height: '1.5px' }} orientation="horizontal" />
        <DialogContent>
          <DialogContentText  justifyContent="center">
            {selectedFile?.FILE_Type==='MP4' && (
  <iframe
  src={selectedFile.File_Path}
  width="640"
  height="564"
  frameborder="0"
  allow="autoplay; fullscreen"
  allowfullscreen
></iframe>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectedFile?.FILE_Type==='PDF'&&(<Button variant="contained" color="primary" onClick={()=>{
            var fileId=selectedFile?.File_Name
            handleDownloadFile(fileId)}}>
            Download
          </Button>)} 
          <Button onClick={handleCloseDetailsDialog}>Close</Button>
        </DialogActions>
      </Dialog>
        <Stack direction="column" spacing={2.5} sx={{margin:"auto"}}  justifyContent="center" >
        <Stack direction="row" spacing={3} sx={{marginTop:"0px" }} justifyContent="center">
 <Box component="img" src={cbpseal} sx={{height:"80px",width:"80px"}}></Box>
 <Stack><Box component="img" src={Login_Im} sx={{height:"60px",width:"180px",marginTop:'20px'}}></Box>
 </Stack>
       </Stack>
        <Stack direction="column" spacing={2.5}  justifyContent="center" >
        <Typography  sx={{fontSize:"25px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',padding:"5px",bgcolor:blueGrey[900],color:"white"}} justifyContent="center">Product Instructions File Repository (PDFs & MP4)</Typography>
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
     
<Stack  direction="row" spacing={2}>      {/* Search bar */}
      <TextField fullWidth
        label="Search files..."
        variant="outlined"
        value={searchQuery}
        onChange={handleSearch}
      />
      
      {/* File type filter */}
      <FormControl fullWidth >
        <InputLabel htmlFor="file-type-filter">Filter by File Type</InputLabel>
        <Select
          value={fileTypeFilter}
          onChange={handleFilterChange}
          label="Filter by File Type"
          inputProps={{
            name: 'file-type-filter',
            id: 'file-type-filter',
          }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="pdf">PDF</MenuItem>
          <MenuItem value="mp4">MP4</MenuItem>
        </Select>
      </FormControl>
      
      </Stack>

      <Grid container spacing={2}>
      {filteredFiles.map((file, index) => (
        <Grid item key={file.FILE_ID} xs={12} sm={6} md={4} lg={3}>
          <Card
            sx={{
              maxWidth: 345,
              marginTop: 2,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              transition: '0.3s',
              '&:hover': {
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontSize: '1.2rem' }}>
                {file.File_Name}
              </Typography>
            </CardContent>
            <CardActions>

                   {file.FILE_Type==='MP4'? <IconButton variant="text"   onClick={() => handleViewDetails(file)}  size="small" sx={{color:theme.palette.primary.main,backgroundColor:"white",height:"35px",borderRadius:1,
                  ':hover': {
                    boxShadow: '10px 10px 20px #ccc',
                    bgcolor: blue[300],
                    color: 'white',
                  }, }}> 
          <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }} variant="rounded"><PlayCircleIcon sx={{width:"30px",height:"30px",bgcolor:indigo[800]}} />
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>Play Video</Button> </IconButton> :<IconButton variant="text"   onClick={() => handleViewDetails(file)} size="small" sx={{color:theme.palette.primary.main,backgroundColor:"white",height:"35px",borderRadius:1,
                  ':hover': {
                    boxShadow: '10px 10px 20px #ccc',
                    bgcolor: red[500],
                    color: 'white',
                  }, }}> 
          <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }} variant="rounded"><PictureAsPdfIcon sx={{width:"30px",height:"30px",bgcolor:"red"}} />
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>Download</Button> </IconButton>}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
      </Stack>
        </Stack>
        <br></br>
<Stack direction="row" spacing={1} sx={{bgcolor:blueGrey[900],color:"white",padding:1,fontSize:"12px",height:"100px"}}>
  <Stack direction="column" spacing={0.1} sx={{ alignItems: 'flex-start' }}>
<IconButton size='large'  edge='start'  color='inherit' >
     <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
    </IconButton>
<p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
<p>Inc. 5000 America's Fastest Growing Private Companies</p>
<p>ISO certified ISO 9001:2015</p>
{/* <p>1691 Emancipation Hwy.</p>
<p>Fredericksburg, VA 22401 USA</p> */}
</Stack>
<Stack direction="column" spacing={0.1} sx={{ marginLeft: '20px', alignItems: 'flex-start' }} >
<p></p>
<p></p>
<p></p>
<p></p>
<p></p>

<p>Let's Talk:</p>
<p>Phone: 540-547-8487</p>
<p>Fax: 540-300-2669</p>
<p>E: DHS_orders@aspettoinc.com</p>
<Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
</Stack>
<Stack sx={{width:"830px"}}></Stack>
<Stack direction="column" spacing={0.1}>
<p>SOCIAL MEDIA</p>
          <Stack direction="row" spacing={.5} justifyContent="center" >
          <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
        < Twitter color="white" sx={{ backgroundColor: "white",color:cyan[400] }} />
      </IconButton>
      <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
        <Facebook color="white" sx={{ backgroundColor: "white",color:blue[800] }} />
      </IconButton>
      <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
        <Instagram color="white" sx={{ backgroundColor: "white" ,color:red[800] }} />
      </IconButton>
      <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
        <LinkedIn sx={{ backgroundColor: "white",color:blueGrey[600]  }} />
      </IconButton>
    </Stack>
</Stack>
          {/* <p>Privacy Policy | Terms of Service</p>
          <p>Last Updated: {new Date().toLocaleDateString()}</p> */}
</Stack>
        </Footer>
        </ThemeProvider>
    </>
  );
};

export default DHSCBPBodyArmorInstructionsExternal;
