import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DatePicker } from "@mui/x-date-pickers";
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DescriptionIcon from '@mui/icons-material/Description';
import { orange,indigo,green,yellow,red, blueGrey,grey,teal,deepOrange,deepPurple, blue} from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import AddTaskIcon from '@mui/icons-material/AddTask';
import userAuth from "../ProtectedRoute/userAuth"
import SaveIcon from '@mui/icons-material/Save';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useSearchParams } from "react-router-dom";
import { Link,createSearchParams} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingDialog from "../Loading";

function RMAResolution()
{
const PD= new Date()
const AxiosAPIInstance=AxiosFunction()
const {
  UserName,
  UserEmail
} = userAuth()
  const [Form_Value,Set_Form_Value]=useState({Agency_Name:'',RMARMAID:'',Steps_Taken:'',Problem_Solved:'',Further_Action_Needed:'',Corrective_Action_Issued:'',
  Corrective_Action_Description:'',Resolution_Stage:'',
  Completed_By_Name:UserName,
  Completed_By_Email:UserEmail,
  Completed_By_Phone:'540-547-8487',
  Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  Updated_By:UserEmail,
  Version_No:'',
  Updating_Comments:''})
  
  

  const [dis,setDis]=useState(false)
  const navigate=useNavigate()
  const[rfiMssge,setRFIMssge]=useState('')
  const [successDialog,setSuccessDialog]=useState(false)
  const [errorDialog,setErrorDialog]=useState(false)
  const [open, setOpen] = useState(false);
  const [edit,setEdit]=useState(true)
  const [allFieldsActivate,setAllFieldsActivate]=useState(false)
  const [problemResolvedYESChecked,setProblemResolvedYESChecked]=useState(false)
  const [problemResolvedNOChecked,setProblemResolvedNOChecked]=useState(false)
  const [furtherActionYESChecked,setFurtherActionYESChecked]=useState(false)
  const [furtherActionNOChecked,setFurtherActionNOChecked]=useState(false)
  const [correctiveActionIssuedYESChecked,setCorrectiveActionIssuedYESChecked]=useState(false)
  const [correctiveActionIssuedNOChecked,setCorrectiveActionIssuedNOChecked]=useState(false)
  const [searchParams]=useSearchParams();
  const [loading, setLoading] = useState(false);

React.useEffect(()=>{
  async function fetchData(){
    setLoading(true)
    await AxiosAPIInstance.get("/RMAs/GetRMAResolutionByID",{params:{RMARMAID:String(searchParams.get("RMAID"))}}).then(async(result)=>{
      Set_Form_Value({...result?.data})
      setLoading(false)
      if(result?.data.Problem_Solved==="NO")
      {
        setProblemResolvedNOChecked(true)
        setProblemResolvedYESChecked(false)
      }
      else
      {
        setProblemResolvedNOChecked(false)
        setProblemResolvedYESChecked(true)
      }

      if(result?.data.Corrective_Action_Issued==="NO")
      {
        setCorrectiveActionIssuedNOChecked(true)
        setCorrectiveActionIssuedYESChecked(false)
      }
      else
      {
        setCorrectiveActionIssuedNOChecked(false)
        setCorrectiveActionIssuedYESChecked(true)
      }

      if(result?.data.Further_Action_Needed==="NO")
      {
        setFurtherActionNOChecked(true)
        setFurtherActionYESChecked(false)
      }
      else
      {
        setFurtherActionNOChecked(false)
        setFurtherActionYESChecked(true)
      }
    })
  }
  fetchData();
  },[searchParams])

  const handleClose = (e) => {
    setOpen(false);
    setErrorDialog(false)
    setSuccessDialog(false)
  };
  const handleEdit=()=>{
    setDis(true)
    setEdit(false)
    setAllFieldsActivate(true)
  }
  
  const handleProblemResolvedChange=(e)=>{
    if(e.target.name==="NO")
    {
      setProblemResolvedNOChecked(e.target.checked)
      setProblemResolvedYESChecked(false)
      Set_Form_Value({...Form_Value,Problem_Solved:"NO"})
    }
    else
    {
      setProblemResolvedNOChecked(false)
      setProblemResolvedYESChecked(e.target.checked)
      Set_Form_Value({...Form_Value,Problem_Solved:"YES"})
    }

  }

  const handleFurtherActionChange=(e)=>{
    if(e.target.name==="NO")
    {
      setFurtherActionNOChecked(e.target.checked)
      setFurtherActionYESChecked(false)
      Set_Form_Value({...Form_Value,Further_Action_Needed:"NO"})
    }
    else
    {
      setFurtherActionNOChecked(false)
      setFurtherActionYESChecked(e.target.checked)
      Set_Form_Value({...Form_Value,Further_Action_Needed:"YES"})
    }

  }

  const handleCorrectiveActionIssuedChange=(e)=>{
    if(e.target.name==="NO")
    {
      setCorrectiveActionIssuedNOChecked(e.target.checked)
      setCorrectiveActionIssuedYESChecked(false)
      Set_Form_Value({...Form_Value,Corrective_Action_Issued:"NO"})
    }
    else
    {
      setCorrectiveActionIssuedNOChecked(false)
      setCorrectiveActionIssuedYESChecked(e.target.checked)
      Set_Form_Value({...Form_Value,Corrective_Action_Issued:"YES"})
    }

  }


  const HandleSubmit = async(event) => {
    setDis(false)
    setEdit(false)
    setLoading(true)
      event.preventDefault()
      if(Form_Value.Corrective_Action_Description!=""&& Form_Value.Problem_Solved!="" &&Form_Value.Steps_Taken!=""&&
   Form_Value.Further_Action_Needed!=''&&Form_Value.Corrective_Action_Issued!='' )
      {
      await AxiosAPIInstance.patch("/RMAs/UpdateRMAResolution",Form_Value).then(
          async(response)=>{
            setLoading(false)
            setSuccessDialog(true)
            setErrorDialog(false)
            setRFIMssge(response?.data)
            setAllFieldsActivate(false)
            setDis(false)
            setEdit(true)
          }
      ).catch((error)=>{
        setLoading(false)
        setSuccessDialog(false)
        setErrorDialog(true)
        setRFIMssge(error.response?.data)
        setAllFieldsActivate(true)
        setDis(true)
        setEdit(false)
      })
    }
    else
    { setLoading(false)
      setAllFieldsActivate(true)
      setDis(true)
      setEdit(false)
      setErrorDialog(true)
      setRFIMssge("All Fields are Required Fields. Enter NA only where it is applicable")
    }
  }
  
      return(
          <>
              <LoadingDialog open={loading} />
           {successDialog===true&&(<Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rfiMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e)=>{
            handleClose();
            e.preventDefault();
            setDis(false)
            setEdit(true)
            setAllFieldsActivate(false)
            }
            } href="/Dashboard/RMAResolution">OK</Button>
          </DialogActions>
        </Dialog>)}
        {errorDialog===true&&(<Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rfiMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes"  onClick={(e)=>{
            handleClose();
            e.preventDefault();
            setEdit(false)
            setAllFieldsActivate(true)
            setDis(true);}} href="/Dashboard/RMAResolution">OK</Button>
          </DialogActions>
        </Dialog>)}
          <ApplicationBar></ApplicationBar>
          <Stack component="form" onSubmit={HandleSubmit} justifyContent="center" >
          <Stack direction="column" spacing={3} sx={{marginTop:"100px"}} justifyContent="center">
          <Stack direction="row" justifyContent="center">
          <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold"}} > Resolution:</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center">
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                  <FormLabel sx={{width:"300px",fontSize:"16px",fontWeight:"bold"}} disabled={!allFieldsActivate}  required >Problem Solved? </FormLabel>
                 <FormGroup>
               <FormControlLabel sx={{color:'red'}}  control={<Checkbox size="small" name="YES" disabled={!allFieldsActivate}  sx={{width:'30px',height:'10px'}} checked={problemResolvedYESChecked} onChange={(e)=>{handleProblemResolvedChange(e)}}/>} label="YES" />
                 </FormGroup>
                 <FormGroup>
                <FormControlLabel sx={{color:'red'}}  control={<Checkbox size="small" name="NO"  disabled={!allFieldsActivate}  sx={{width:'30px',height:'10px'}} checked={problemResolvedNOChecked} onChange={(e)=>{handleProblemResolvedChange(e)}}/>} label="NO" />
                 </FormGroup>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"800px"}} label="What were the steps taken to resolve the problem:" multiline={20} value={Form_Value.Steps_Taken} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Steps_Taken:e.target.value})}
                 InputProps={{startAdornment:(<InputAdornment position="start">
                  <DescriptionIcon />
                 </InputAdornment>)}}>
                 </TextField>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <FormLabel sx={{width:"300px",fontSize:"16px",fontWeight:"bold"}} disabled={!allFieldsActivate}  required >Further Action Needed? </FormLabel>
                 <FormGroup>
               <FormControlLabel sx={{color:'red'}}  control={<Checkbox size="small" disabled={!allFieldsActivate}  name="YES" sx={{width:'30px',height:'10px'}} checked={furtherActionYESChecked} onChange={(e)=>{handleFurtherActionChange(e)}}/>} label="YES" />
                 </FormGroup>
                 <FormGroup>
                <FormControlLabel sx={{color:'red'}}  control={<Checkbox size="small" disabled={!allFieldsActivate}  name="NO"  sx={{width:'30px',height:'10px'}} checked={furtherActionNOChecked} onChange={(e)=>{handleFurtherActionChange(e)}}/>} label="NO" />
                 </FormGroup>
                 
                 </Stack>
                 <Stack direction="row" justifyContent="center">
                  <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"24px", fontWeight:"bold"}} > Completed By:</Typography>
                 </Stack>
                 <Stack direction="row" justifyContent="center">
                <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
                </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField size="small" variant="standard" sx={{width:"300px"}} label="Name" value={UserName} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Name:UserName})}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddTaskIcon/>
                    </InputAdornment>
                  ),readOnly:"true"
                }}></TextField>
                 <TextField size="small" variant="standard" sx={{width:"300px"}} label="Email" value={UserEmail} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Email:UserEmail})}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddTaskIcon/>
                    </InputAdornment>
                  ),readOnly:"true"
                }}></TextField>
                 <DatePicker readOnly label="Completed Date" sx={{width:"300px"}} value={Form_Value.Created_Date} minDate={new Date()} renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
          <DatePicker readOnly label="Updated Date" sx={{width:"300px"}} value={Form_Value.Last_Updated_Date}  renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <FormLabel sx={{width:"300px",fontSize:"16px",fontWeight:"bold"}} disabled={!allFieldsActivate}  required>Corrective Action Issued? </FormLabel>
                 <FormGroup>
               <FormControlLabel sx={{color:'red'}}  control={<Checkbox  disabled={!allFieldsActivate} required size="small" name="YES" sx={{width:'30px',height:'10px'}} checked={correctiveActionIssuedYESChecked} onChange={(e)=>{handleCorrectiveActionIssuedChange(e)}}/>} label="YES" />
                 </FormGroup>
                 <FormGroup>
                <FormControlLabel sx={{color:'red'}}  control={<Checkbox disabled={!allFieldsActivate}  size="small" name="NO"  sx={{width:'30px',height:'10px'}} checked={correctiveActionIssuedNOChecked} onChange={(e)=>{handleCorrectiveActionIssuedChange(e)}}/>} label="NO" />
                 </FormGroup>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <FormLabel sx={{width:"300px",fontSize:"17px",}} disabled={!allFieldsActivate}>If Yes, Corrective Action Description #</FormLabel>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"800px"}}  multiline={20} value={Form_Value.Corrective_Action_Description} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Corrective_Action_Description:e.target.value})}
                 InputProps={{startAdornment:(<InputAdornment position="start">
                  <DescriptionIcon />
                 </InputAdornment>)}}>
                 </TextField>
                 </Stack>
                 <Stack direction="row" spacing={2} justifyContent='center'>
                 <Tooltip title="Go Back">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/RMAInvestigation",
                      search:createSearchParams({RMAID:Form_Value.RMARMAID}).toString()
                    })}}
                    sx={{ height:"40px",bgcolor:blue[700],width:"100px"}}
                    startIcon={<ArrowBackIcon/>}>
                   BACK
                  </Button>
                  </Tooltip>
                  <Tooltip title="Edit">
               <Button variant='contained' color="warning" size='medium' sx={{width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[orange[500]]}}} disabled={!edit} onClick={handleEdit} startIcon={<ModeEditIcon/>}>Edit</Button>
              </Tooltip>
              <Tooltip title="Save">
              <Button variant='contained' color="success" size='medium' sx={{height:"40px",width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[green[500]]}}} disabled={!dis} onClick={HandleSubmit}  startIcon={<SaveIcon/>}> Save</Button>
              </Tooltip>
                <Tooltip title="Next Step">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/RMACloseOut",
                      search:createSearchParams({RMAID:Form_Value.RMARMAID}).toString()
                    })}}
                    sx={{height:"40px",bgcolor:red[700],width:"300px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[red[500]]}}}
                    startIcon={<NextPlanIcon/>}>
                   FOLLOW UP & CLOSE OUT
                  </Button>
                  </Tooltip>
                 </Stack>
                 </Stack >
    
                 </Stack> 
                 
         </>
  );
}

export default RMAResolution