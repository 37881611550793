import React from "react";
import {
  Button,
  Stack,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  Alert,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Tooltip,
  Avatar,
  Badge,
  Divider,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  useState,
  useEffect,
} from "react";
import {
  blueGrey,
  orange,
  red,
  indigo,
  green,
} from "@mui/material/colors";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate, useLocation } from "react-router-dom";
import UserAuth from "../ProtectedRoute/userAuth";
import SettingsIcon from "@mui/icons-material/Settings";
import Logo from "../Images/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Logout from "@mui/icons-material/Logout";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { styled } from "@mui/material/styles";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin, selectUserRole } from "../Login_Registration/authSlice";
import { selectCurrentToken } from "../Login_Registration/authSlice";
import { AxiosGlobalAPIInstance } from "../../axiosGlobalInstance";
import { logOut } from "../Login_Registration/authSlice";
import CategoryIcon from "@mui/icons-material/Category";
import MenuIcon from "@mui/icons-material/Menu";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LoadingDialog from "../Loading";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BugReportIcon from "@mui/icons-material/BugReport";
import ReportIcon from "@mui/icons-material/Report";
import SearchIcon from "@mui/icons-material/Search";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
  typography: {
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function ApplicationBar() {
  const { UserName, UserEmail } = UserAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errorSuccessDialog, setErrorSuccessDialog] = useState(false);
  const dispatch = useDispatch();
  const User_FirstName = UserName.split(' ')[0];
  const token = useSelector(selectCurrentToken);
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isRFQsHovered, setIsRFQsHovered] = useState(false);
  const [isRFIsHovered, setIsRFIsHovered] = useState(false);
  const [isManageHovered, setIsManageHovered] = useState(false);
  const [isRMAsHovered, setIsRMAsHovered] = useState(false);
  const [isDraftsHovered, setIsDraftsHovered] = useState(false);
  const [isDHSsHovered, setIsDHSsHovered] = useState(false);
  const [isLifeCycleHovered, setIsLifeCycleHovered] = useState(false);
  const [isSKUsHovered, setIsSKUsHovered] = useState(false);
  const [isProductManualsHovered, setIsProductManualsHovered] = useState(false);
  const [isUserProfileHovered, setIsUserProfileHovered] = useState(false);
  const [isBugsHovered, setIsBugsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInitials = (name) => {
    const nameArray = name.split(' ');
    return nameArray
      .map((word) => word.charAt(0).toUpperCase())
      .join('');
  };

  const initials = getInitials(UserName);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClose = (e) => {
    setErrorSuccessDialog(false);
    navigate('/', { replace: false });
    setSuccess("");
    setError("");
  };

  const handleLogOut = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await AxiosGlobalAPIInstance.post('/UserAuth/logout');
      dispatch(logOut());
      navigate('/', { replace: false });
    } catch (error) {
      setLoading(false);
      setErrorSuccessDialog(true);
      setError(error?.response?.data || 'An error occurred during logout');
    }
  };

  const renderDrawer = () => (
    <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
      <List>
        <ListItem button onClick={() => navigate("/Dashboard")}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/CreateRFQ")}>
          <ListItemText primary="New Quote" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/RFQsSearch")}>
          <ListItemText primary="Retrieve/Update Quote" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/CreateRFI")}>
          <ListItemText primary="New RFI" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/RFIsSearch")}>
          <ListItemText primary="Retrieve/Update RFI" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/NewRMA")}>
          <ListItemText primary="New RMA" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/RMAsSearch")}>
          <ListItemText primary="Retrieve/Update RMA" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/RFQDrafts")}>
          <ListItemText primary="RFQ Drafts" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/LifeCycle/Master")}>
          <ListItemText primary="LifeCycle Master" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/LifeCycle/FBOP")}>
          <ListItemText primary="LifeCycle FBOP" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/LifeCycle/GSA")}>
          <ListItemText primary="LifeCycle GSA" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/LifeCycle/DHS-CBP")}>
          <ListItemText primary="LifeCycle DHS-CBP" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/DHSCBPCustomerInfoRequestForm/InternalSales")}>
          <ListItemText primary="New DHS-CBP Request" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/DHSReqsSearch")}>
          <ListItemText primary="Retrieve DHS Requests" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/DHSRFQsOutSidePortalsSearch")}>
          <ListItemText primary="Retrieve DHS RFQs" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/POsSearch")}>
          <ListItemText primary="POs" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/SKUs/SlateSolutions")}>
          <ListItemText primary="SKUs Slate Solutions" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/SKUs/DHS")}>
          <ListItemText primary="SKUs DHS Body Armor IV Pricing" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/DHS-CBP-BodyArmor-Instructions")}>
          <ListItemText primary="Product Instructions File Handling" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/GenerateQR")}>
          <ListItemText primary="Generate QR" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/SubmitBug")}>
          <ListItemText primary="Report Bug" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/BugList")}>
          <ListItemText primary="Bug Search" />
        </ListItem>
        <ListItem button onClick={() => navigate("/Dashboard/Profile")}>
          <ListItemText primary="My Profile" />
        </ListItem>
        {isAdmin && (<>
          <ListItem button onClick={() => navigate("/Dashboard/Admin/ManageRoles")}>
            <ListItemText primary="Manage Roles" />
          </ListItem>
          <ListItem button onClick={() => navigate("/Dashboard/AdminPage")}>
             <ListItemText primary="Manage Users" />
            </ListItem></>
        )}
        <ListItem button onClick={handleLogOut}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <ThemeProvider theme={theme}>
      {errorSuccessDialog === true && (
        <Dialog
          open={errorSuccessDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={error ? 'error' : 'success'}>
              {error ? error : success}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error ? error : success}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => {
              if (success !== '') {
                navigate('/', { replace: false });
              }
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <AppBar
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: isMobile ? 'static' : 'fixed',
        }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {isMobile && (
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start">
              <MenuIcon />
            </IconButton>
          )}
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            sx={{ marginLeft: isMobile ? "10px" : "20px" }}
            onClick={(e) => { e.preventDefault(); navigate("/Dashboard") }}>
            <img src={Logo} alt="ASPETTO" height="35px" width="140px"></img>
          </IconButton>
          <Hidden smDown >
            <Stack direction='row' edge='end' sx={{ padding: isMobile ? '5px' : '3px' }} alignItems="center">
              <Tooltip title="Go To Home Page">
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    padding: isMobile ? '8px' : '10px',
                    borderRadius: 1,
                    transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                    "&:hover": {
                      backgroundColor: orange[600],
                      transform: 'scale(1.1)', // Zoom in effect on hover
                    },
                  }} // Adjust padding for mobile devices
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/Dashboard");
                  }}>
                  <Avatar
                    sx={{ bgcolor: theme.palette.primary.main, width: "30px", height: "30px", }}
                    variant='rounded'>
                    <HomeIcon sx={{ color: orange[600], backgroundColor: "white", width: "30px", height: "30px" }} />
                  </Avatar>
                </IconButton>
              </Tooltip>
              {/* RFQs Menu */}
              <div
                onMouseEnter={() => setIsRFQsHovered(true)}
                onMouseLeave={() => setIsRFQsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isRFQsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  RFQs{' '}
                  {isRFQsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isRFQsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsRFQsHovered(true)}
                    onMouseLeave={() => setIsRFQsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/CreateRFQ") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: "white",
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant="rounded"><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>New Quote</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px', width: '205px', }}>
                      <IconButton variant="text" onClick={(e) => { ; navigate("/Dashboard/PageUnderDev") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: "white",
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant="rounded"><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Multi-Variant Quote</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/RFQsSearch") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><ModeEditIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Retrieve/Update</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px', width: "180px" }}>
                      <Button variant="text" onClick={(e) => {
                        navigate("/Dashboard/ExtractData/PDFs")
                        e.preventDefault();
                      }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><PictureAsPdfIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Upload PDF(GSA)</Button>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {/* RFIs Menu */}
              <div
                onMouseEnter={() => setIsRFIsHovered(true)}
                onMouseLeave={() => setIsRFIsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isRFIsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  RFIs{' '}
                  {isRFIsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isRFIsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsRFIsHovered(true)}
                    onMouseLeave={() => setIsRFIsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/CreateRFI") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>New RFI</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/RFIsSearch") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><ModeEditIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Retrieve/Update</Button>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              {/* RMAs Menu */}
              <div
                onMouseEnter={() => setIsRMAsHovered(true)}
                onMouseLeave={() => setIsRMAsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isRMAsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  RMAs{' '}
                  {isRMAsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isRMAsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsRMAsHovered(true)}
                    onMouseLeave={() => setIsRMAsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/NewRMA") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>New RMA</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/RMAsSearch") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><ModeEditIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Retrieve/Update</Button>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              {/* Drafts Menu */}
              <div
                onMouseEnter={() => setIsDraftsHovered(true)}
                onMouseLeave={() => setIsDraftsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isDraftsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  Drafts{' '}
                  {isDraftsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isDraftsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsDraftsHovered(true)}
                    onMouseLeave={() => setIsDraftsHovered(false)}>
                    <div style={{ padding: '10px', width: "150px", }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/RFQDrafts") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: "white",
                        '&:hover': {
                          bgcolor: orange[600],
                        }, height: "35px", borderRadius: 1,
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant="rounded"><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>RFQ Drafts</Button>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              {/* LifeCycle Menu */}
              <div
                onMouseEnter={() => setIsLifeCycleHovered(true)}
                onMouseLeave={() => setIsLifeCycleHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isLifeCycleHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  LifeCycle{' '}
                  {isLifeCycleHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isLifeCycleHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsLifeCycleHovered(true)}
                    onMouseLeave={() => setIsLifeCycleHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/LifeCycle/Master") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><SearchIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Master</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/LifeCycle/FBOP") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><SearchIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>FBOP</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/LifeCycle/GSA") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><SearchIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>GSA</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px', width: '100%' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/LifeCycle/DHS-CBP") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><SearchIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>DHS-CBP</Button>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              {/* DHS-CBP Menu */}
              <div
                onMouseEnter={() => setIsDHSsHovered(true)}
                onMouseLeave={() => setIsDHSsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isDHSsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  DHS-CBP{' '}
                  {isDHSsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isDHSsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsDHSsHovered(true)}
                    onMouseLeave={() => setIsDHSsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/DHSCBPCustomerInfoRequestForm/InternalSales") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        backgroundColor: "white",
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant="rounded"><AssignmentIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>New Request</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/DHSReqsSearch") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><ModeEditIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Retrieve(Files)</Button>
                      </IconButton>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <IconButton variant="text" onClick={(e) => { navigate("/Dashboard/DHSRFQsOutSidePortalsSearch") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><ModeEditIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Retrieve(RFQs)</Button>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
              {/* POs Menu */}
              <div style={{ padding: '10px' }}>
                <Tooltip title="Purchase Orders">
                  <IconButton variant="text" sx={{
                    color: 'white',
                    fontSize: "14px",
                    padding: isMobile ? '8px' : '10px',
                    borderRadius: 1,
                    transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                    "&:hover": {
                      backgroundColor: orange[600],
                      transform: 'scale(1.1)', // Zoom in effect on hover
                    },
                  }} onClick={(e) => { e.preventDefault(e); navigate("/Dashboard/POsSearch") }}>POs</IconButton>
                </Tooltip>
              </div>
              {/* SKUs Menu */}
              <div
                onMouseEnter={() => setIsSKUsHovered(true)}
                onMouseLeave={() => setIsSKUsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isSKUsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  SKUs{' '}
                  {isSKUsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isSKUsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsSKUsHovered(true)}
                    onMouseLeave={() => setIsSKUsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <Button variant="text" onClick={(e) => { navigate("/Dashboard/SKUs/SlateSolutions") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        width: "180px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><CategoryIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>SLATE SOLUTIONS</Button>
                      </Button>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <Button variant="text" onClick={(e) => { navigate("/Dashboard/SKUs/DHS") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><CategoryIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>DHS Body Armor IV Pricing</Button>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {/* Product Manuals Menu */}
              <div
                onMouseEnter={() => setIsProductManualsHovered(true)}
                onMouseLeave={() => setIsProductManualsHovered(false)}
                style={{ position: 'relative' }}>
                <IconButton
                  variant="text"
                  sx={{
                    color: 'white',
                    fontSize: '14px',
                    padding: '10px',
                    transition: 'transform 0.2s ease-in-out',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: orange[600],
                    },
                    transform: isProductManualsHovered ? 'scale(1.1)' : 'scale(1)',
                  }}>
                  <Tooltip title="Product Instructions File Handling(Videos/PDFs)">
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                      <NewspaperIcon sx={{ color: red[700], backgroundColor: "white", width: "25px", height: "25px" }} /></Avatar>
                  </Tooltip>{' '}
                  {isProductManualsHovered ? (
                    <ArrowDropUpIcon fontSize="inherit" />
                  ) : (
                    <ArrowDropDownIcon fontSize="inherit" />
                  )}
                </IconButton>
                {isProductManualsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsProductManualsHovered(true)}
                    onMouseLeave={() => setIsProductManualsHovered(false)}>
                    <div style={{ padding: '10px', width: "180px" }}>
                      <Button variant="text" onClick={(e) => { navigate("/Dashboard/DHS-CBP-BodyArmor-Instructions") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><UploadFileIcon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>New File Upload</Button>
                      </Button>
                    </div>
                    <div style={{ padding: '10px', width: "180px" }}>
                      <Button variant="text" onClick={(e) => { navigate("/Dashboard/GenerateQR") }} size="small" sx={{
                        color: theme.palette.primary.main,
                        height: "35px",
                        borderRadius: 1,
                        '&:hover': {
                          bgcolor: orange[600],
                        }
                      }}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'><QrCode2Icon sx={{ width: "25px", height: "25px" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Generate QR</Button>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {/* Notifications */}
              <Tooltip title="Notifications">
                <IconButton variant="text" sx={{
                  color: 'white',
                  padding: isMobile ? '8px' : '10px',
                  borderRadius: 1,
                  transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                  "&:hover": {
                    backgroundColor: orange[600],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  },
                }} onClick={(e) => { e.preventDefault(); navigate("/Dashboard/PageUnderDev") }}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                    <NotificationsIcon sx={{ color: indigo[700], backgroundColor: "white", width: "25px", height: "25px" }} />
                  </Avatar>
                </IconButton>
              </Tooltip>
              {/* Metrics */}
              <Tooltip title="My Metrics">
                <IconButton variant="text" sx={{
                  color: 'white',
                  padding: isMobile ? '8px' : '10px',
                  borderRadius: 1,
                  transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                  "&:hover": {
                    backgroundColor: orange[600],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  },
                }} onClick={(e) => { e.preventDefault(); navigate("/Dashboard/MyPerformanceDashboard") }}>
                  <Avatar sx={{
                    bgcolor: theme.palette.primary.main, width: "25px", height: "25px", transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                    "&:hover": {
                      backgroundColor: blueGrey[600],
                      transform: 'scale(1.1)', // Zoom in effect on hover
                    },
                  }} variant='rounded'><InsertChartIcon sx={{ color: green[700], backgroundColor: "white", width: "25px", height: "25px" }} /></Avatar>
                </IconButton>
              </Tooltip>
              {/* Revenue Goals */}
              <Tooltip title="Revenue Goals">
                <IconButton variant="text" sx={{
                  color: 'white',
                  padding: isMobile ? '8px' : '10px',
                  borderRadius: 1,
                  transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                  "&:hover": {
                    backgroundColor: orange[600],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  },
                }} onClick={(e) => { e.preventDefault(); navigate("/Dashboard/PageUnderDev") }}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                    <EmojiFlagsIcon sx={{ color: '#00e5ff', backgroundColor: "white", width: "25px", height: "25px" }} />
                  </Avatar>
                </IconButton>
              </Tooltip>
              {/* Bugs Menu */}
              <div
                onMouseEnter={() => setIsBugsHovered(true)}
                onMouseLeave={() => setIsBugsHovered(false)}
                style={{ position: 'relative' }}>
                <Tooltip title="Bugs">
                  <IconButton
                    variant="text"
                    sx={{
                      color: 'white',
                      fontSize: '14px',
                      padding: '10px',
                      transition: 'transform 0.2s ease-in-out',
                      borderRadius: 1,
                      '&:hover': {
                        bgcolor: orange[600],
                      },
                      transform: isBugsHovered ? 'scale(1.1)' : 'scale(1)',
                    }}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                      <BugReportIcon sx={{ color: red[600], backgroundColor: "white", width: "25px", height: "25px" }} />{' '}
                    </Avatar>
                    {isBugsHovered ? (
                      <ArrowDropUpIcon fontSize="inherit" />
                    ) : (
                      <ArrowDropDownIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Tooltip>
                {isBugsHovered && (
                  <div
                    style={{
                      display: 'block',
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                      mt: '2px',
                    }}
                    onMouseEnter={() => setIsBugsHovered(true)}
                    onMouseLeave={() => setIsBugsHovered(false)}>
                    <div style={{ padding: '10px' }}>
                      <Tooltip title="Report a Bug">
                        <IconButton variant="text" sx={{
                          color: blueGrey[900],
                          fontSize: "14px",
                          padding: isMobile ? '8px' : '10px',
                          borderRadius: 1,
                          transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                          width: '140px',
                          "&:hover": {
                            backgroundColor: orange[600],
                            transform: 'scale(1.1)', // Zoom in effect on hover
                          },
                        }} onClick={(e) => { e.preventDefault(e); navigate("/Dashboard/SubmitBug") }}>
                          <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                            <ReportIcon></ReportIcon>
                          </Avatar>
                          Report Bug
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <Tooltip title="Bug Search">
                        <IconButton variant="text" sx={{
                          color: blueGrey[900],
                          fontSize: "14px",
                          padding: isMobile ? '8px' : '10px',
                          borderRadius: 1,
                          transition: 'transform 0.2s ease-in-out', // Add transition for smooth effect
                          width: '140px',
                          "&:hover": {
                            backgroundColor: orange[600],
                            transform: 'scale(1.1)', // Zoom in effect on hover
                          },
                        }} onClick={(e) => { e.preventDefault(e); navigate("/Dashboard/BugList") }}>
                          <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant='rounded'>
                            <SearchIcon></SearchIcon>
                          </Avatar>
                          Bug Search
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
{/* User Profile Menu */}
<div
  onMouseEnter={() => setIsUserProfileHovered(true)}
  onMouseLeave={() => setIsUserProfileHovered(false)}
  style={{ position: 'relative' }}
>
  <IconButton
    variant="text"
    sx={{
      color: 'white',
      fontSize: '14px',
      padding: '10px',
      transition: 'transform 0.2s ease-in-out',
      borderRadius: 1,
      '&:hover': {
        bgcolor: orange[600],
      },
      transform: isUserProfileHovered ? 'scale(1.1)' : 'scale(1)',
    }}
  >
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
    >
      <Avatar alt={UserName} sx={{ width: "25px", height: "25px", fontSize: "14px" }}>{initials}</Avatar>
    </StyledBadge>
    <Button variant="text" sx={{ color: 'white' }}>{String(User_FirstName).toUpperCase()}</Button>{' '}
    {isUserProfileHovered ? (
      <ArrowDropUpIcon fontSize="inherit" />
    ) : (
      <ArrowDropDownIcon fontSize="inherit" />
    )}
  </IconButton>

  {isUserProfileHovered && (
    <div
      style={{
        display: 'block',
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 1,
        backgroundColor: 'white',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '10px',
        minWidth: '200px',
      }}
      onMouseEnter={() => setIsUserProfileHovered(true)}
      onMouseLeave={() => setIsUserProfileHovered(false)}
    >
      <div style={{ padding: '5px 0' }}>
        <IconButton
          sx={{
            fontSize: "14px",
            color: "black",
            borderRadius: 1,
            justifyContent: 'flex-start',
            width: '100%',
            '&:hover': {
              bgcolor: orange[600],
            }
          }}
          onClick={() => { navigate("/Dashboard/Profile") }}
        >
          <Avatar fontSize="small" sx={{ width: "25px", height: "25px", bgcolor: theme.palette.primary.main }} variant="rounded" />
          My Profile
        </IconButton>
      </div>
{/* Manage (Visible only to Admins) */}
{isAdmin === true && (
  <div
    onMouseEnter={() => setIsManageHovered(true)}
    onMouseLeave={() => setIsManageHovered(false)}
    style={{ position: 'relative', padding: '5px 0' }}
  >
    <IconButton
      sx={{
        fontSize: "14px",
        color: "black",
        borderRadius: 1,
        justifyContent: 'flex-start',
        width: '100%',
        '&:hover': {
          bgcolor: orange[600],
        }
      }}
    >
      <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "25px", height: "25px" }} variant="rounded">
        <AdminPanelSettingsIcon fontSize="small" />
      </Avatar>
      Manage{' '}
      {isManageHovered ? (
        <ArrowDropUpIcon fontSize="inherit" />
      ) : (
        <ArrowDropDownIcon fontSize="inherit" />
      )}
    </IconButton>

    {isManageHovered && (
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          zIndex: 1,
          backgroundColor: 'white',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: '10px',
          minWidth: '180px',
        }}
        onMouseEnter={() => setIsManageHovered(true)}
        onMouseLeave={() => setIsManageHovered(false)}
      >
        <div style={{ padding: '5px 0' }}>
          <IconButton
            sx={{
              fontSize: "14px",
              color: "black",
              borderRadius: 1,
              justifyContent: 'flex-start',
              width: '100%',
              '&:hover': {
                bgcolor: orange[600],
              }
            }}
            onClick={() => { navigate("/Dashboard/Admin/ManageRoles") }}
          >
            Roles
          </IconButton>
        </div>
        <div style={{ padding: '5px 0' }}>
          <IconButton
            sx={{
              fontSize: "14px",
              color: "black",
              borderRadius: 1,
              justifyContent: 'flex-start',
              width: '100%',
              '&:hover': {
                bgcolor: orange[600],
              }
            }}
            onClick={() => { navigate("/Dashboard/AdminPage") }}
          >
            Users
          </IconButton>
        </div>
      </div>
    )}
  </div>
)}


      <div style={{ padding: '5px 0' }}>
        <IconButton
          sx={{
            fontSize: "14px",
            color: "black",
            borderRadius: 1,
            justifyContent: 'flex-start',
            width: '100%',
            '&:hover': {
              bgcolor: orange[600],
            }
          }}
        >
          <Avatar fontSize="small" sx={{ width: "25px", height: "25px", bgcolor: theme.palette.primary.main }} variant="rounded">
            <SettingsIcon fontSize="small" />
          </Avatar>
          Settings
        </IconButton>
      </div>

      <div style={{ padding: '5px 0', borderTop: '1px solid #ddd' }}>
        <IconButton
          sx={{
            fontSize: "14px",
            color: "black",
            borderRadius: 1,
            justifyContent: 'flex-start',
            width: '100%',
            '&:hover': {
              bgcolor: orange[600],
            }
          }}
          onClick={(e) => { handleLogOut(e) }}
        >
          <Avatar fontSize="small" sx={{ width: "25px", height: "25px", bgcolor: theme.palette.primary.main }} variant="rounded">
            <Logout fontSize="small" />
          </Avatar>
          Logout
        </IconButton>
      </div>
    </div>
  )}
</div>


            </Stack>
          </Hidden>
        </Toolbar>
      </AppBar>
      {renderDrawer()}
    </ThemeProvider>
  );
}

export default ApplicationBar;
