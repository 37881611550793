// Importing necessary components from Material-UI
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Paper,
  Stack,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Link,
  Grid,
  Container,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Input,
  CardActions,
  Avatar
} from '@mui/material';

// Importing Axios instance
import AxiosFunction from '../../axiosCustomInstance';

// Importing jwt-decode for decoding JWT tokens
import jwtDecode from 'jwt-decode';

// Importing necessary hooks from react-router-dom
import { useNavigate, useSearchParams } from 'react-router-dom';

// Importing Dialog component from Material-UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Importing Alert component from Material-UI
import { Alert } from '@mui/material';

// Importing LoadingDialog component
import LoadingDialog from '../Loading';

// Importing colors from Material-UI
import { indigo, blueGrey, red, blue, orange } from '@mui/material/colors';

// Importing Barcode component
import Barcode from 'react-barcode';

// Importing axios library
import axios from 'axios';

// Importing ApplicationBar component
import ApplicationBar from '../AppBar/ApplicationBar';

// Importing userAuth component for authentication
import userAuth from '../ProtectedRoute/userAuth';

// Importing icons from Material-UI
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// Importing createTheme function from Material-UI
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
      primary: {
          main: blueGrey[900],
      },
      secondary: {
          main: blueGrey[300],
      },
  },
});


const DHSCBPBodyArmorInstructions = () => {
  const [file, setFile] = useState(null);
  const [uploadedFilename, setUploadedFilename] = useState(null);
  const [searchParams] = useSearchParams();
  const { UserName, UserEmail } = userAuth();
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dHSMssge, setDHSMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const [statusCode, setStatusCode] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [selectedFileType, setSelectedFileType] = useState('pdf');
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const AxiosAPIInstance = AxiosFunction();
  const [isVideo, setIsVideo] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [fileTypeFilter, setFileTypeFilter] = useState('all');
  const allowedFileTypes = ['pdf', 'mp4'];


// Function to handle search input change
const handleSearch = (e) => {
    // Get the search query from the input field and convert it to lowercase
    const query = e.target.value.toLowerCase();
    // Update the search query state
    setSearchQuery(query);
};

// Function to handle file type filter change
const handleFilterChange = (e) => {
    // Update the file type filter state based on the selected value
    setFileTypeFilter(e.target.value);
};

// Filter the file list based on search query and file type filter
const filteredFiles = fileList.filter(file => {
    // Check if the file name contains the search query
    const isMatch = file.File_Name.toLowerCase().includes(searchQuery);
    // Check if the file type matches the selected file type filter or if 'all' is selected
    const isFileTypeMatch = fileTypeFilter === 'all' || file.FILE_Type.toLowerCase() === fileTypeFilter;
    // Return true if both conditions are met
    return isMatch && isFileTypeMatch;
});

// Function to handle viewing details of a file
const handleViewDetails = (file) => {
    try {
        // Set the selected file and determine if it's a video file
        setSelectedFile(file);
        setIsVideo(file.FILE_Type === 'MP4'); 
        // Open the details dialog and reset video playing state
        setDetailsDialogOpen(true);
        setVideoPlaying(false);
    } catch (error) {
        // Handle any errors that occur
        setErrorDialog(true);
        setDHSMssge('Error while handling view details:', error);
    }
};

// Function to handle closing dialogs
const handleClose = (e) => {
    // Close all open dialogs and reset relevant state variables
    setOpen(false);
    setDHSMssge('');
    setErrorDialog(false);
    setSuccessDialog(false);
    setErrorDialog(false);
};

// Function to fetch file list from the server
const handleFileList = async () => {
    try {
        // Send a GET request to retrieve the file list
        const response = await AxiosAPIInstance.get('ProductInstructionsUpload/FileSearch/FileList');
        // Check if response data is an array and update the file list state
        if (response?.data && Array.isArray(response.data)) {
            setFileList(response.data);
        } else {
            // Display error message if response data is not as expected
            setDHSMssge(response.data);
            setErrorDialog(true);
        }
    } catch (error) {
        // Handle errors that occur during the request
        setDHSMssge('Error fetching file list:', error);
        setErrorDialog(true);
    }
};

  React.useEffect(() => {
  
    handleFileList();

  }, [dHSMssge]); // 

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setIsVideo(false);
  };




  const handleDownloadFile = async (fileId) => {
    try {
      setLoading(true);
      const originalFileName = fileId;
      const encodedFileName = encodeURIComponent(originalFileName);

// Set the response type to 'blob' for binary data
const response = await AxiosAPIInstance.get(`ProductInstructionsUpload/FileSearch/DownloadFile/${encodedFileName}`, {
  responseType: 'blob',
});

// Create a Blob from the binary data
const url = window.URL.createObjectURL(new Blob([response.data]));

// Create a link element for download
const a = document.createElement('a');
a.href = url;

// Use the original (decoded) file name for download
a.download = fileId;

// Append the link to the document, trigger a click, and remove the link
document.body.appendChild(a);
a.click();
document.body.removeChild(a);
  setLoading(false);
  
    } catch (error) {
      setLoading(false);
      if (error.response) {
        // Axios error with a response from the server
        setDHSMssge(error.response?.data.message || 'An error occurred while downloading the file.');
      } else if (error.request) {
        // The request was made but no response was received
        setDHSMssge('No response received from the server.');
      } else {
        // Other errors (network, timeout, etc.)
        setDHSMssge('An unexpected error occurred while downloading the file.');
      }
      setErrorDialog(true);
      setSuccessDialog(false);
      console.error('Error downloading file:', error);
    }
  };
  
  
  //---------------------------------------------------------------------------------
  const handlePlayVideo = (file) => {
    setVideoPlaying(true)
    
  };

// Function to handle file upload
const handleUpload = async () => {
  // Check if a file is selected
  if (file !== null) {
      // Set loading state to indicate file upload is in progress
      setLoading(true);

      // Check if the selected file type is allowed
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedFileTypes.includes(fileExtension)) {
          // Display error message if the file type is not allowed
          setLoading(false);
          setErrorDialog(true);
          setSuccessDialog(false);
          setDHSMssge('Invalid file type. Please upload PDF or MP4 files only.');
          return;
      }

      try {
          // Prepare file details object
          const File_Details = {
              FILE_ID: '',
              FILE_Type: selectedFileType,
              File_Name: '',
              File_Path: '',
              Uploaded_By_Name: UserName,
              Uploaded_By_Email: UserEmail,
              Version_No: 0,
              Updating_Comments: '',
              Created_Date: '',
              Updated_Date: '',
              Updated_By_Name: UserName,
              Updated_By_Email: UserEmail,
          };

          // Create a FormData object and append file and file details to it
          const formData = new FormData();
          formData.append('file', file);
          Object.keys(File_Details).forEach((key) => {
              formData.append(key, File_Details[key]);
          });

          // Send a POST request to upload the file
          await AxiosAPIInstance.post('ProductInstructionsUpload/Files', formData)
              .then(async (result) => {
                  const filename = result?.data.File_Name;
                  // Set the uploaded filename and file path
                  setFilePath(result?.data.File_Path);
                  setUploadedFilename(filename);
                  setLoading(false);
                  setErrorDialog(false);
                  setSuccessDialog(true);
                  setDHSMssge(result?.data);
                  setFile(null);
              })
              .catch((error) => {
                  // Handle errors from the server response
                  if (error?.response?.status === 403) {
                      setStatusCode(true);
                  }
                  setLoading(false);
                  setSuccessDialog(false);
                  setErrorDialog(true);
                  setDHSMssge(error.response?.data);
                  setDis(false);
              });
      } catch (error) {
          // Handle errors that occur during file upload
          setLoading(false);
          setErrorDialog(true);
          setSuccessDialog(false);
          setDHSMssge('Cannot submit the request');
      }
  } else {
      // Display error message if no file is selected
      setLoading(false);
      setErrorDialog(true);
      setSuccessDialog(false);
      setDHSMssge('Please Select File to Upload');
  }
};


  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {successDialog === true && (
        <Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant="filled" severity="success">SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorDialog === true && (
        <Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant="filled" severity="error">ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
     <Dialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog} justifyContent="center">
        <DialogTitle>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }} justifyContent="center">
            File History
          </Typography>
        </DialogTitle>
        <Divider sx={{ color: 'red[900]', bgcolor: 'red[900]', width: '100%', height: '1.5px' }} orientation="horizontal" />
        <DialogContent>
          <DialogContentText  justifyContent="center">
            <Typography variant="subtitle1">
              <b>Uploaded Date:</b>
              <br></br>
              {selectedFile?.Created_Date}
            </Typography>
            {isVideo && !videoPlaying && (
              <Button variant="contained" color="primary" sx={{ bgcolor:"white",color:red[900],":hover":{
                boxShadow:"10px 10px 20px #ccc",bgcolor:red[500],color:"white"
               
            }}} onClick={handlePlayVideo()}>
                Play Video
              </Button>
            )}
            {isVideo && videoPlaying && (
  <iframe
  src={selectedFile.File_Path}
  width="640"
  height="564"
  frameborder="0"
  allow="autoplay; fullscreen"
  allowfullscreen
></iframe>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectedFile?.FILE_Type==='PDF'&&(<Button variant="contained" color="primary" onClick={()=>{
            var fileId=selectedFile?.File_Name
            handleDownloadFile(fileId)}}>
            Download
          </Button>)} 
          <Button onClick={handleCloseDetailsDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Stack direction="column" spacing={2.5} justifyContent="center" alignItems="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>Product Instructions File Uploader (PDFs & MP4)</Typography>
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="file-input"></InputLabel>
              <Input 
                id="file-input"
                type="file"
                accept={selectedFileType === 'pdf' ? 'application/pdf' : 'video/*'}
                onChange={handleFileChange}
              />
            </FormControl>
            <FormControl sx={{ width: "250px" }}>
              <InputLabel htmlFor="file-type">File Type</InputLabel>
              <Select
                labelId="file-type"
                id="file-type-id"
                value={selectedFileType}
                variant='standard'
                label="File Type"
                onChange={(e) => setSelectedFileType(e.target.value)}
                sx={{ width: "250px" }}
              >
                <MenuItem size="small" name="pdf" value="pdf">PDF</MenuItem>
                <MenuItem size="small" name="mp4" value="mp4">MP4</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" size='small' sx={{ width: "250px", bgcolor: red[800], height: "35px", ":hover": {
              boxShadow: "10px 10px 20px #ccc",
              bgcolor: red[500]
            }}} onClick={handleUpload}>
              Upload File
            </Button>
          </Stack>
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>File List</Typography>
          <Stack direction='row' spacing={2} justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
            <TextField
              fullWidth
              label="Search files..."
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="file-type-filter">Filter by File Type</InputLabel>
              <Select
                value={fileTypeFilter}
                onChange={handleFilterChange}
                label="Filter by File Type"
                inputProps={{
                  name: 'file-type-filter',
                  id: 'file-type-filter',
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="mp4">MP4</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Grid container spacing={2} justifyContent="center">
            {filteredFiles.map((file, index) => (
              <Grid item key={file.FILE_ID} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: 2,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: '0.3s',
                    '&:hover': {
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontSize: '1.2rem' }}>
                      {file.File_Name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {file.FILE_Type === 'MP4' ? (
                      <IconButton variant="text" onClick={() => handleViewDetails(file)} size="small" sx={{ color: theme.palette.primary.main, backgroundColor: "white", height: "35px", borderRadius: 1, ':hover': {
                        boxShadow: '10px 10px 20px #ccc',
                        bgcolor: blue[300],
                        color: 'white',
                      }}}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "30px", height: "30px" }} variant="rounded">
                          <PlayCircleIcon sx={{ width: "30px", height: "30px", bgcolor: indigo[800] }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Play Video</Button>
                      </IconButton>
                    ) : (
                      <IconButton variant="text" onClick={() => handleViewDetails(file)} size="small" sx={{ color: theme.palette.primary.main, backgroundColor: "white", height: "35px", borderRadius: 1, ':hover': {
                        boxShadow: '10px 10px 20px #ccc',
                        bgcolor: red[500],
                        color: 'white',
                      }}}>
                        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "30px", height: "30px" }} variant="rounded">
                          <PictureAsPdfIcon sx={{ width: "30px", height: "30px", bgcolor: "red" }} />
                        </Avatar>
                        <Button variant="text" size="small" sx={{ color: theme.palette.primary.main }}>Download</Button>
                      </IconButton>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default DHSCBPBodyArmorInstructions;
