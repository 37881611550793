import React from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState,useEffect} from "react";
import {Button,Avatar,Tooltip,Paper,Divider} from '@mui/material';
import {blue, blueGrey, green, red, teal, yellow } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import PdfDocument from "../../../PDF/Main";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { orange,indigo } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';    
import CloseIcon from '@mui/icons-material/Close'; 
import PhoneIcon from '@mui/icons-material/Phone'; 
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TodayIcon from '@mui/icons-material/Today';
import EmailIcon from '@mui/icons-material/Email';
import LoadingDialog from "../../../Loading";
import { pdf } from '@react-pdf/renderer';
const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
  
  const color1=blueGrey[500]
  const color2=blueGrey[50]
  
function FinanceRow(props) {
    const PD= new Date()
    const today=new Intl.DateTimeFormat('en-US',{dateStyle:'medium'}).format(PD)
    const { row} = props;
    const [open, setOpen] = React.useState(false);
    const [clicked,setClicked]=useState(false);
    const [briefNotesForPODialog,setNotesForPODialog]=useState(false)
    const [briefClick,setBriefClick]=useState(false)
    const [loading, setLoading]=useState(false)

   const  handleBeiefAndPONotesClick=(e,row)=>{
    e.preventDefault();
    if(briefClick===true)
    {
      setNotesForPODialog(true)
      setNotesForPODialog(true)
    }
    else{
    setNotesForPODialog(true)
    }
   }
   const handleNotesForPODialog=(e)=>{
     e.preventDefault();
     setNotesForPODialog(false)
     setBriefClick(false)

   }
   const OP_arr = String(row.Opportunity_Name).split(".");
   for (var i = 0; i < OP_arr.length; i++) {
     OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
   
   }
   var File_Opportunity_Name=OP_arr.join(" ")
  {/*---------------------------------------------Defining variables for PDF data---------------------------------------------------------------------*/}
  const [InvoiceData,setInvoiceData]=useState({ 
    invoice_no:"",
    Contract_Vehicle:"",
    KO_name:"",
    KO_email:"",
    KO_phone: "",
    KO_address:"",
    trans_date:today,
    Print_Date:String(today),
    Valid_For:"30 Days",
    due_date: "",
    Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax:row.Tax,
    Shipping_Cost:row.Shipping_Cost,
    Total_Cost:row.Actual_Total_Cost_After_Margin,
    Shipping_Details:{
      Organization_Name:"",
      KO_name:"",
      Sales_Head_Info:{},
      Customer_Name:"",
      Customer_Email:"",
      Customer_Phone:"",
      Customer_Address:"",
      Shipping_Method:"",
      Shipping_Terms:"",
      Estimated_Delivery:"",
      Payment_Terms:"",
      Notes:""}})
      const HandlePDFClick = async (row) => {
        setLoading(true)
        const arr = String(row.Sales_Head_Name).toLowerCase().split(' ');
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const KO_arr = String(row.KO_name).toLowerCase().split(' ');
        for (var i = 0; i < KO_arr.length; i++) {
          KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
        }
        const CO_arr = String(row.Customer_Name).toLowerCase().split(' ');
        for (var i = 0; i < CO_arr.length; i++) {
          CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
        }
        const OP_arr = String(row.Opportunity_Name).split('.');
        for (var i = 0; i < OP_arr.length; i++) {
          OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
        }
    
        const updatedInvoiceData = {
          ...InvoiceData,
          invoice_num: String(row.Invoice_No),
          Contract_Vehicle: row.Contract_Vehicle,
          KO_name: KO_arr.join(' '),
          KO_email: row.KO_email,
          KO_phone: row.KO_phone,
          KO_address: row.KO_address,
          Print_Date: String(today),
          trans_date: String(row.Last_Updated_Date).split('T')[0],
          due_date: row.Due_date,
          Valid_For: row.Valid_For,
          Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
          items: row.Purchase_Orders,
          Tax: row.Tax,
          Shipping_Cost: row.Shipping_Cost,
          Total_Cost: row.Actual_Total_Cost_After_Margin,
          Other_Cost_1: row.Other_Cost_1,
          Other_Cost_2: row.Other_Cost_2,
          Other_Cost_3: row.Other_Cost_3,
          Other_Cost_Name1: row.Other_Cost_Name1,
          Other_Cost_Name2: row.Other_Cost_Name2,
          Other_Cost_Name3: row.Other_Cost_Name3,
          Shipping_Details: {
            Organization_Name: row.Organization_Name,
            KO_name: row.KO_name,
            Sales_Head_Info: { Name: arr.join(' '), Email: row.Sales_Head_Email, Phone: row.Sales_Head_Ph },
            Customer_Name: CO_arr.join(' '),
            Customer_Email: row.Customer_Email,
            Customer_Phone: row.Customer_Phone,
            Customer_Address: row.Customer_Address,
            Shipping_Method: row.Shipping_Method,
            Shipping_Terms: row.Shipping_Terms,
            Estimated_Delivery: row.Estimated_Delivery,
            Payment_Terms: row.Payment_Terms,
            Notes: row.Notes
          }
        };
    
        setInvoiceData(updatedInvoiceData);
    
        // Format Opportunity Name for file name
        File_Opportunity_Name = OP_arr.join(" ")
        setTimeout(async () => {
          setLoading(true);
          const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
          const url = URL.createObjectURL(pdfBlob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${File_Opportunity_Name} ${String(row.Invoice_No).split('.')[0]}${String(row.Invoice_No).split('.')[1]}.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          setLoading(false);
        }, 0);
      };
  
    return (
      <React.Fragment>
        <LoadingDialog/>
             {briefNotesForPODialog===true&&(<Dialog
        open={briefNotesForPODialog}
        onClose={handleNotesForPODialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='info' sx={{backgroundColor:briefClick?blue[600]:yellow[800]}}>{briefClick?"Brief":"Notes For PO"}</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          {briefClick?row.Brief:row.Notes_For_PO}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="CLOSE" onClick={(e)=>{handleNotesForPODialog(e)}}>CLOSE</Button>
        </DialogActions>
      </Dialog>)}
        <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {e.preventDefault();setOpen(!open)}}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell  align="center" scope="row">
          <b  style={{color:'red'}}>{row.Opportunity_Name}</b> <br></br>
          <b>{row.Contract_Vehicle}</b><br></br>
           {row.Invoice_No}
          </TableCell>
          <TableCell align="center">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2,border:1,borderColor:blueGrey[900]}}>
  {/* Each TableRow will be replaced with a div */}
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px',width:'100%' }}> 
    {/* <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'13px'}}>Name</Typography> */}
    <PersonIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row.KO_name}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    {/* <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'13px' }}>Address</Typography> */}
    <ContactMailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}} />
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{String(row.KO_address).trim()}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
      {/* <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '13px' }}>Email</Typography> */}
      <AlternateEmailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
        <Typography variant="body1" sx={{ fontSize: '12.5px',color:blue[900] }}>{row.KO_email}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    {/* <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'13px' }}>Phone</Typography> */}
    <PhoneIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row.KO_phone}</Typography>
  </div>
</Paper>
          </TableCell>
          <TableCell align="center">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2,border:1 ,borderColor:blueGrey[900]}}>
  {/* Each TableRow will be replaced with a div */}
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
   <PersonIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row.Customer_Name}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center',marginTop: '8px' }}> 
   <ContactMailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{String(row.Customer_Address).trim()}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <AlternateEmailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
        <Typography variant="body1" sx={{ fontSize: '12.5px',color:blue[900]  }}>{row.Customer_Email}</Typography>
    </div>
 
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
    <PhoneIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row.Customer_Phone}</Typography>
  </div>
</Paper>
          </TableCell>
          <TableCell align="Left">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2,border:1,borderColor:blueGrey[900]}}>
  {/* Each TableRow will be replaced with a div */}
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
  <PersonIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{row.Sales_Head_Name}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}> 
  <AlternateEmailIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>
    <div style={{ alignItems: 'center' }}>
      <Typography variant="body1" sx={{ fontSize:'12.5px'}}>{row.Sales_Head_Email}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', marginTop: '8px' }}> 
    <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><TodayIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>Created </Typography>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{String(row.Created_Date).split('T')[0]}</Typography>
  </div>
  <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', marginTop: '8px' }}> 
    <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><TodayIcon sx={{marginRight:1,color:orange[900],height:'20px',width:'20px'}}/>Updated </Typography>
    <Typography variant="body1" sx={{fontSize:'12.5px'}}>{String(row.Last_Updated_Date).split('T')[0]}</Typography>
  </div>        
</Paper> 
          </TableCell>
          <TableCell align="center">
          {row.RFQ_Status==="PENDING"&& <Button color="warning" type="text" size="small" variant="contained" sx={{height:"25px",fontSize:"10px",}}>{row.RFQ_Status}</Button>}
          {row.RFQ_Status==="WON"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small" color="success" type="text" variant="contained">{row.RFQ_Status}</Button>}
          {row.RFQ_Status==="LOST"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small"  color="error" type="text" variant="contained">{row.RFQ_Status}</Button>}
          <br></br>
          <br></br>
          {row.Notes_For_PO!==null && row.Notes_For_PO!=="NA" && row.Notes_For_PO!=="N/A"&& row.Notes_For_PO!==""&&<Tooltip title="Notes For PO" placement="right"><IconButton  onClick={(e,row)=>{handleBeiefAndPONotesClick(e,row)}} sx={{width:"70px",fontSize:"14px",fontWeight:'bold',backgroundColor:yellow[800],color:"black", borderRadius: 0,
           border: "1px solid",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:yellow[400],color:'white'}}} variant="contained" >
          Notes for PO</IconButton></Tooltip>}
          <br></br>
          <br></br>
          {row.Brief!==null && row.Brief!=="NA" && row.Brief!=="N/A"&&row.Brief!==""&&<Tooltip title="Brief" placement="right"><IconButton  onClick={(e,row)=>{setBriefClick(true);handleBeiefAndPONotesClick(e,row)}} sx={{width:"70px",fontSize:"14px",fontWeight:'bold',backgroundColor:blue[400],color:"black", borderRadius: 0,
           border: "1px solid",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:blue[800],color:'white'}}} variant="contained" >
          Brief</IconButton></Tooltip>}
          <br></br>
          <Typography variant='h7' sx={{width:"50px",fontSize:"14px"}}><b>Revision:</b> {row.Version_No}</Typography><br></br>
          <Tooltip title="Generate PDF">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); HandlePDFClick(row) }}>
                <Avatar sx={{ backgroundColor: red[800], width: '30px', height: '30px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1,}}>
                {/* <Typography variant="h6">
                  SKU INFO
                </Typography> */}
                <Table aria-label="purchases" sx={{border:1,borderColor:'text.main'}}>
                  <TableHead sx={{backgroundColor:theme.palette.secondary.main,}}>
                    <TableRow>
                    <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',fontWeight:'bold',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}}>Vendor/Partner Name</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}}>SKU ID</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">SKU Description</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">QTY</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">UM</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">Aspetto's Unit Cost($)</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">Margin(%)</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">Customer's Unit Cost($)</TableCell>
                      <TableCell sx={{color:"black",fontFamily:'Verdana (sans-serif)',bgcolor:orange[200],fontWeight:'bold',fontSize:'12.5px'}} align="center">Total SKU Cost($)</TableCell>
                  
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row["Purchase_Orders"]?.map((Purchase_OrderROW,index) => (
                      <TableRow key={index}>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Purchase_OrderROW.Vendor_Partner_name}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}}align="center">{Purchase_OrderROW.SKU_ID}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Purchase_OrderROW.SKU_Description}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}}align="center">{Purchase_OrderROW.QTY}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}}align="center">{Purchase_OrderROW.Unit_Of_Measure}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}}align="center">{Number(Purchase_OrderROW.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}}align="center">{Purchase_OrderROW.Margin_Percent}</TableCell>
                        <TableCell  sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(Purchase_OrderROW.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        <TableCell  sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(Purchase_OrderROW.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">Shipping Cost</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Shipping_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                   
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                  
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">Tax</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Tax).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{row.Other_Cost_Name1}</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Other_Cost_1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{row.Other_Cost_Name2}</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Other_Cost_2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{row.Other_Cost_Name3}</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Other_Cost_3).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">TOTAL</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary',fontSize:'12.5px'}} align="center">{Number(row.Actual_Total_Cost_After_Margin).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  export default FinanceRow