import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: blueGrey[900],
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
    },
    description: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor:blueGrey[100]
    },
    total: {
        width: '15%',
        textAlign: 'center',
        borderRightWidth: 1,
        backgroundColor:blueGrey[100]
    },
    Realtotal: {
        width: '15%',
        textAlign: 'center',
        borderRightWidth: 1,
        backgroundColor:theme.palette.primary.main,
        color:"white"
    },
    RealtotalLabel: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor:theme.palette.primary.main,
        color:"white"
    },
});
const InvoiceTableFooter = ({ invoice }) => {
    let cal = 0;
    const total = invoice.items?.map((item) => {
        cal = cal + item.QTY * item.Customer_Unit_Cost;
    });
    const Shipping_Cost = parseFloat(invoice.Shipping_Cost);
    const Tax = parseFloat(invoice.Tax);
    const OtherCostName1 = invoice.Other_Cost_Name1;
    const OtherCost1 = parseFloat(invoice.Other_Cost_1);
    const OtherCostName2 = invoice.Other_Cost_Name2;
    const OtherCost2 = parseFloat(invoice.Other_Cost_2);
    const OtherCostName3 = invoice.Other_Cost_Name3;
    const OtherCost3 = parseFloat(invoice.Other_Cost_3);

    return (
        <>
            {Shipping_Cost !== 0 && (
                <View style={styles.row}>
                    <Text style={styles.description}>Shipping Cost</Text>
                    <Text style={styles.total}>{'$' + Shipping_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </View>
            )}
            {Tax !== 0 && (
                <View style={styles.row}>
                    <Text style={styles.description}>Tax</Text>
                    <Text style={styles.total}>{'$' + Tax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </View>
            )}
            {OtherCost1 !== 0 && (
                <View style={styles.row}>
                    <Text style={styles.description}>{OtherCostName1}</Text>
                    <Text style={styles.total}>{'$' + OtherCost1.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </View>
            )}
            {OtherCost2 !== 0 && (
                <View style={styles.row}>
                    <Text style={styles.description}>{OtherCostName2}</Text>
                    <Text style={styles.total}>{'$' + OtherCost2.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </View>
            )}
            {OtherCost3 !== 0 && (
                <View style={styles.row}>
                    <Text style={styles.description}>{OtherCostName3}</Text>
                    <Text style={styles.total}>{'$' + OtherCost3.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                </View>
            )}
            <View style={styles.row}>
                <Text style={styles.RealtotalLabel}>TOTAL</Text>
                <Text style={styles.Realtotal}>{'$' + Number.parseFloat(invoice.Total_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
        </>
    );
};

export default InvoiceTableFooter;