import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Avatar,
  Alert,Autocomplete
} from '@mui/material';
import {
  Add as AddIcon,
  AlternateEmail as AlternateEmailIcon,
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon,
  DisabledByDefault as DisabledByDefaultIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  History as HistoryIcon,
  HistoryToggleOff as HistoryToggleOffIcon,
  Merge as MergeIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Send as SendIcon,
  Subscriptions as SubscriptionsIcon,
  Today as TodayIcon,
  Unpublished as UnpublishedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import {
  blue,
  blueGrey,
  cyan,
  deepOrange,
  green,
  indigo,
  lightGreen,
  orange,
  red,
  teal,
  yellow
} from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import PdfDocument from '../../../PDF/Main';
import RFQs_Merge_Search from './rfqsMergeSearch';
import AxiosFunction from '../../../../axiosCustomInstance';
import LoadingDialog from '../../../Loading';
import UserAuth from '../../../ProtectedRoute/userAuth';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ConversationDialog from '../../../Comments/Conversation';
import CommentIcon from '@mui/icons-material/Comment';
// Create a custom theme for the MUI components
const theme = createTheme({
  palette: {
    primary: { main: blueGrey[900] },
    secondary: { main: blueGrey[500] }
  }
});

// SalesRow component handles rendering and managing each sales row
const SalesRow = (props) => {
  const { row, usersData } = props; // Destructure row and usersData from props
  const PD = new Date(); // Get current date
  const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD); // Format date in 'medium' format
  const [briefNotesForPODialog, setNotesForPODialog] = useState(false); // State to manage PO dialog visibility
  const [briefClick, setBriefClick] = useState(false); // State to manage brief click
  const [skuDetailsOnlyChecked, setSkuDetailsOnlyChecked] = useState(false); // State to manage SKU details checkbox
  const [fullQuoteDetailsOnlyChecked, setFullQuoteDetailsOnlyChecked] = useState(false); // State to manage full quote checkbox
  const [quoteDetailsOnlyChecked, setQuoteDetailsOnlyChecked] = useState(false); // State to manage quote details checkbox
  const [copyQuoteDialog, setCopyQuoteDialog] = useState(false); // State to manage copy quote dialog visibility
  const [CopyError, setCopyError] = useState(false); // State to manage copy error visibility
  const [CopyErrorMsge, setCopyErrorMsge] = useState(false); // State to manage copy error message
  const [rfqID, setRFQID] = useState(''); // State to store RFQ ID
  const [getMoreInfo, setGetMoreInfo] = useState(false); // State to manage more info retrieval
  const [allVersions, setAllVersions] = useState([{}]); // State to store all versions of RFQ
  const [recentVersion, setShowRecentVersion] = useState(false); // State to manage recent version visibility
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [errorAndSuccessDialog, setErrorAndSuccessDialog] = useState(false); // State to manage error and success dialog visibility
  const [successDialogMsge, setSuccessDialogMsge] = useState(''); // State to manage success message
  const [errorDialogMsge, setErrorDialogMsge] = useState(''); // State to manage error message
  const [errorStatus, setErrorStatus] = useState(false); // State to manage error status
  const [visibility, setVisibility] = useState(false); // State to manage visibility
  const [emailQuoteDialog, setEmailQuoteDialog] = useState(false); // State to manage email quote dialog visibility
  const [sendQuotesToEmail, setSendQuotesToEmail] = useState(''); // State to store email address to send quotes
  const [emailComments, setEmailComments] = useState(''); // State to store email comments
  const [mergeQuoteDialog, setMergeQuoteDialog] = useState(false); // State to manage merge quote dialog visibility
  const [customerOrKOEmailDialogOpen, setCustomerOrKOEmailDialogOpen] = useState(false); // State to manage customer/KO email dialog visibility
  const [attachments, setAttachments] = useState([]); // State to store attachments
  const [getKOAndCustomerEmailHistory, setGetKOAndCustomerEmailHistory] = useState(false); // State to manage KO and customer email history retrieval
  const [koCustomerEMailHistory, setKOCustomerEMailHistory] = useState([{}]); // State to store KO and customer email history
  const [attachFilesChecked, setAttachFilesChecked] = useState(false); // State to manage attach files checkbox
  const [attachSKUDetailsInTheBody, setAttachSKUDetailsInTheBody] = useState(false); // State to manage attach SKU details checkbox
  const [allSKUsCost, setAllSKUsCost] = useState(''); // State to store total SKU cost
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to manage dialog open state
  const [commentsOpen, setCommentsOpen] = useState(false);
  const conversationId = row.RFQ_ID; // Replace with the actual conversation ID
  const title = row.Opportunity_Name; // Replace with the actual title

  const handleCommentsOpen = () => {
    setCommentsOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsOpen(false);
  };
  const [InvoiceData, setInvoiceData] = useState({
    invoice_no: '',
    Contract_Vehicle: '',
    KO_name: '',
    KO_email: '',
    KO_phone: '',
    KO_address: '',
    Print_Date: String(today),
    Valid_For: '30 Days',
    trans_date: '',
    due_date: '',
    Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax: 0,
    Shipping_Cost: 0,
    Total_Cost: 0,
    Shipping_Details: {
      Organization_Name: '',
      KO_name: '',
      Sales_Head_Info: {},
      Customer_Name: '',
      Customer_Email: '',
      Customer_Phone: '',
      Customer_Address: '',
      Shipping_Method: '',
      Shipping_Terms: '',
      Estimated_Delivery: '',
      Payment_Terms: '',
      Notes: ''
    }
  }); // State to store invoice data

  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Hook to check if the device is mobile
  const { UserName, UserEmail } = UserAuth(); // Get user name and email from authentication context
  const [searchBySalesEmailInput, setSearchBySalesEmailInput] = useState(UserEmail); // State to store search input by sales email
  let File_Opportunity_Name = ''; // Variable to store file opportunity name
  const defaultContent = `Hope you're doing great!
Just wanted to drop a quick note to let you know that we've got those quotes you asked for. Check 'em out in the attachment!
We've made sure they're exactly what you need based on our recent chats. Any questions or tweaks you want to make, just hit me up. Always here to help!
Thanks for considering Aspetto. Can't wait to work with you!
Cheers!`; // Default email content

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(); // Function to capitalize the first letter of a string

  const [firstName, lastName] = UserName.split(' ').map(capitalize); // Split and capitalize user's first and last name

  const [emailData, setEmailData] = useState({
    Email_REQ_ID: '',
    RFQRFQID: row?.RFQ_ID,
    Quote_No: '',
    From: 'platform_admin@aspettoinc.com',
    To_Email: '',
    To_Name: '',
    CC: 'quotesandsales@aspettoinc.com',
    Subject: 'Request For Quotation: ' + row?.Invoice_No,
    Body: defaultContent,
    Email_SentBy_Name: UserName,
    Email_SentBy_Email: UserEmail,
    Attachments: attachFilesChecked,
    AttachSKUDataInTheEmailBody: attachSKUDetailsInTheBody,
    AttachmentNames: '',
    Signature: `
      Thank You
     ${firstName} ${lastName}
      Aspetto Quotes & Sales Team
      A: 1691 Emancipation Hwy. Fredericksburg, VA 22401
      E: ${UserEmail}
      P: 540-547-8487
      DISCLAIMER: Please do not reply to this email. This is an automatically triggered email and nobody checks its inbox.
        
      CONFIDENTIALITY NOTICE: This e-mail and/or any files transmitted are proprietary and intended solely for the use of the individual or entity to whom they are addressed. 
      Dissemination of this email to anyone else is strictly prohibited.If you have reason to believe that you have received this e-mail in error,contact us immediately by returning it to the sender and destroy this email as well as any attached files. 
      Please note that any views or opinions presented in this e-mail are solely those of the author and do not necessarily represent those of Aspetto, Inc. or any of its subsidiaries or affiliates. 
      The recipient should check this e-mail and any attachments for the presence of viruses.
      Aspetto, Inc. and its subsidiaries accept no liability for any damage caused by any virus transmitted by this e-mail.`,
    Latest_Email_Date: '',
    SKU_DATA: []
  }); // State to store email data

  const navigate = useNavigate(); // Hook to navigate programmatically
  const AxiosAPIInstance = AxiosFunction(); // Axios instance for making API requests

  // Function to search RFQ by ID and navigate to UpdateRFQ page
  const RFQID_Search = () => {
    navigate({
      pathname: '/Dashboard/UpdateRFQ',
      search: createSearchParams({
        RID: row.RFQ_ID,
        RStatus: row.RFQ_Status,
        VNo: row.Version_No
      }).toString()
    });
  };

  // Handler to manage changes in the email editor
  const handleEditorChange = (newEditorState) => {
    setEmailData({
      ...emailData,
      Body: newEditorState
    });
  };

  // Handler to toggle visibility
  const handleVisibility = (e) => {
    e.preventDefault();
    setVisibility(!visibility);
    setLoading(true);
    var rowData = {
      Opportunity_Name: row.Opportunity_Name,
      Tracking: visibility === true ? true : false,
      Tracking_By_Email: UserEmail,
      Tracking_By_Name: UserName,
      RFQRFQID: row.RFQ_ID,
      Invoice_No: row.Invoice_No,
      Contract_Vehicle: row.Contract_Vehicle,
      Sales_Head_Name: row.Sales_Head_Name,
      Sales_Head_Email: row.Sales_Head_Email,
      Sales_Head_Ph: row.Sales_Head_Ph,
      RFQ_Status: row.RFQ_Status
    };
    setVisibility(!visibility);
  };

  // Handler for brief and PO notes click
  const handleBeiefAndPONotesClick = (e, row) => {
    e.preventDefault();
    if (briefClick === true) {
      setNotesForPODialog(true);
      setNotesForPODialog(true);
    } else {
      setNotesForPODialog(true);
    }
  };

  // Handler to close notes for PO dialog
  const handleNotesForPODialog = (e) => {
    e.preventDefault();
    setNotesForPODialog(false);
    setBriefClick(false);
  };

  // Handler to close various dialogs and reset states
  const handleClose = async () => {
    setCopyError(false);
    setCopyErrorMsge('');
    setShowRecentVersion(false);
    setGetMoreInfo(false);
    setEmailQuoteDialog(false);
    setErrorAndSuccessDialog(false);
    setErrorDialogMsge('');
    setSendQuotesToEmail('');
    setKOCustomerEMailHistory('');
    setRFQID('');
    setGetKOAndCustomerEmailHistory(false);
  };

  // Handler to fetch KO and customer email history
  const handleKOCustomerEmailHistoryDialogClickOpen = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setRFQID(row?.RFQ_ID);
      await AxiosAPIInstance.get('RFQsSearch/KOOrCustomerEmailHistory', {
        params: { RFQRFQID: row?.RFQ_ID }
      }).then(async (response) => {
        await setKOCustomerEMailHistory(response?.data);
        setLoading(false);
        setGetKOAndCustomerEmailHistory(true);
      }).catch(async (error) => {
        if (error?.response.data === 'No Data Found') {
          setLoading(false);
          setCopyError(true);
          setCopyErrorMsge('No Data Found');
          setGetKOAndCustomerEmailHistory(false);
        } else {
          setLoading(false);
          setGetKOAndCustomerEmailHistory(false);
          setCopyError(true);
          setCopyErrorMsge(error?.response.data);
        }
      });
    } catch {
      setLoading(false);
      setCopyError(true);
      setGetKOAndCustomerEmailHistory(false);
      setCopyErrorMsge('Some Error Occurred While Publishing Data');
    }
  };

  // Handler to open customer email dialog and set email data
  const handleCustomerDialogClickOpen = async (e, row) => {
    e.preventDefault();
    setEmailData({
      ...emailData,
      RFQRFQID: row?.RFQ_ID,
      From: 'platform_admin@aspettoinc.com',
      Subject: 'Request for Quotation: ' + row?.Invoice_No,
      Quote_No: row?.Invoice_No,
      To_Email: String(row?.Customer_Email).trim(),
      CC: 'quotesandsales@aspettoinc.com',
      To_Name: String(row?.Customer_Name).trim(),
      Body: defaultContent,
      Email_SentBy_Name: UserName,
      Email_SentBy_Email: UserEmail,
      Signature: `
      Thank You
      ${String(UserName)}
      Aspetto Quotes & Sales Team
      A: 1691 Emancipation Hwy. Fredericksburg, VA 22401
      E: ${UserEmail}
      P: 540-547-8487
        
      DISCLAIMER: Please do not reply to this email. This is an automatically triggered email and nobody checks its inbox.
        
      CONFIDENTIALITY NOTICE: This e-mail and/or any files transmitted are proprietary and intended solely for the use of the individual or entity to whom they are addressed. 
      Dissemination of this email to anyone else is strictly prohibited.If you have reason to believe that you have received this e-mail in error,contact us immediately by returning it to the sender and destroy this email as well as any attached files. 
      Please note that any views or opinions presented in this e-mail are solely those of the author and do not necessarily represent those of Aspetto, Inc. or any of its subsidiaries or affiliates. 
      The recipient should check this e-mail and any attachments for the presence of viruses.
      Aspetto, Inc. and its subsidiaries accept no liability for any damage caused by any virus transmitted by this e-mail.`,
      Latest_Email_Date: '',
      SKU_DATA: row?.Purchase_Orders
    });
    setCustomerOrKOEmailDialogOpen(true);
  };

  // Handler to open KO email dialog and set email data
  const handleKOEmailDialogClickOpen = async (e) => {
    e.preventDefault();
    setEmailData({
      ...emailData,
      From: 'platform_admin@aspettoinc.com',
      Email_REQ_ID: '',
      RFQRFQID: row?.RFQ_ID,
      Quote_No: row?.Invoice_No,
      To_Email: String(row?.KO_email).trim(),
      Subject: 'Request for Quotation: ' + row?.Invoice_No,
      CC: 'quotesandsales@aspettoinc.com',
      To_Name: String(row?.KO_name).trim(),
      Body: defaultContent,
      Email_SentBy_Name: UserName,
      Email_SentBy_Email: UserEmail,
      Signature: `
      Thank You
      ${String(UserName)}
      Aspetto Quotes & Sales Team
      A: 1691 Emancipation Hwy. Fredericksburg, VA 22401
      E: ${UserEmail}
      P: 540-547-8487
        
      DISCLAIMER: Please do not reply to this email. This is an automatically triggered email and nobody checks its inbox.
        
      CONFIDENTIALITY NOTICE: This e-mail and/or any files transmitted are proprietary and intended solely for the use of the individual or entity to whom they are addressed. 
      Dissemination of this email to anyone else is strictly prohibited.If you have reason to believe that you have received this e-mail in error,contact us immediately by returning it to the sender and destroy this email as well as any attached files. 
      Please note that any views or opinions presented in this e-mail are solely those of the author and do not necessarily represent those of Aspetto, Inc. or any of its subsidiaries or affiliates. 
      The recipient should check this e-mail and any attachments for the presence of viruses.
      Aspetto, Inc. and its subsidiaries accept no liability for any damage caused by any virus transmitted by this e-mail.`,
      Latest_Email_Date: '',
      SKU_DATA: row?.Purchase_Orders
    });
    setCustomerOrKOEmailDialogOpen(true);
  };

  // Handler to close customer/KO email dialog and reset states
  const handleCustomerKOEmailDialogClickClose = (e) => {
    e.preventDefault();
    setRFQID('');
    setCustomerOrKOEmailDialogOpen(true);
    setGetKOAndCustomerEmailHistory(false);
    setKOCustomerEMailHistory([{}]);
    setCustomerOrKOEmailDialogOpen(false);
    setAttachments(null);
    setAllSKUsCost(0);
    setAttachSKUDetailsInTheBody(false);
    setAttachFilesChecked(false);
    setEmailData({ ...emailData, Attachments: false, AttachSKUDataInTheEmailBody: false });
  };

  // Handler for input change in email form
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailData({ ...emailData, [name]: value });
  };

  // Handler for attachment change in email form
  const handleAttachmentChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);
      setAttachments((prevAttachments) => (prevAttachments ? [...prevAttachments, ...newFiles] : [...newFiles]));

      const newFileNames = newFiles.map((file) => file.name).join(' ');
      setEmailData((prevEmailData) => ({
        ...prevEmailData,
        AttachmentNames: prevEmailData.AttachmentNames ? `${prevEmailData.AttachmentNames} ${newFileNames}` : newFileNames
      }));
    }
  };

  // Handler for SKU details checkbox change in email form
  const handleAttachSKUDetailsCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setAttachSKUDetailsInTheBody(isChecked);
  
    // Update the emailData state with the new value
    setEmailData((prevData) => ({
      ...prevData,
      AttachSKUDataInTheEmailBody: isChecked
    }));
  };
  
  // Handler to attach SKU details in the email body
  const handleAttachSKUDetailsInTheEMailBody = async (e, row) => {
    if (e.target.checked === true) {
      if (Array.isArray(emailData.SKU_DATA)) {
        const totalSKU = emailData.SKU_DATA.reduce((total, current) => total + Number(current.Total_SKU_Cost), 0);
  
        setAllSKUsCost(totalSKU.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
      } else {
        console.error('SKU_DATA is not an array:', emailData.SKU_DATA);
        setAllSKUsCost('0.00');
      }
    }
  };
  
  // Function to remove an attachment from the email form
  const removeAttachment = (indexToRemove) => {
    const updatedAttachments = attachments.filter((_, index) => index !== indexToRemove);
    setAttachments(updatedAttachments);
  };

  // Handler to send email to customer or KO
  const handleSendCustometOrKOEmail = async (e) => {
    e.preventDefault();
  
    if (loading) return;
  
    setLoading(true);
    const formData = new FormData();
  
    if (attachments.length > 0) {
      attachments.forEach((attachment) => {
        formData.append('attachments', attachment, attachment.name);
      });
    } else {
      console.warn('No attachments to send.');
    }
  
    if (Object.keys(emailData).length > 0) {
      Object.entries(emailData).forEach(([key, value]) => {
        const data = typeof value === 'object' ? JSON.stringify(value) : value;
        formData.append(key, data);
      });
    } else {
      console.warn('No email data to send.');
    }
  
    try {
      const response = await AxiosAPIInstance.post('RFQsSearch/SendCustomerKOEmail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      setSuccessDialogMsge('Email sent successfully: ' + response.data.message);
      setCustomerOrKOEmailDialogOpen(false);
      setErrorStatus(false);
      setAttachFilesChecked(false);
      setAttachSKUDetailsInTheBody(false);
      setAttachments([]);
      setErrorAndSuccessDialog(true);
    } catch (error) {
      const errorMessage = error.response ? JSON.stringify(error.response.data) : 'Network error';
      setErrorDialogMsge('Cannot submit the request: ' + errorMessage);
      setErrorStatus(true);
      setErrorAndSuccessDialog(true);
    } finally {
      setLoading(false);
    }
  };
  
  // Function to parse serialized JSON data
  const parseSerializedJSON = (serializedJSON) => {
    try {
      return JSON.parse(serializedJSON);
    } catch (error) {
      console.error('Error parsing serialized JSON:', error);
      return [];
    }
  };

  // Handler to open email quotes dialog and set RFQ ID
  const handleEmailQuotes = async (e, row) => {
    e.preventDefault();
    setRFQID(row?.RFQ_ID);
    setEmailQuoteDialog(true);
    setSendQuotesToEmail('');
    setEmailComments('');
  };

  // Handler to send quotes to email
  const handleEmailQuotesTo = async (e, row) => {
    e.preventDefault();
    const RFQ_ID = row?.RFQ_ID;
    try {
      await AxiosAPIInstance.post('RFQsSearch/sendQuoteToEmails', {
        RFQID: String(row?.RFQ_ID),
        SendQuotesToEmail: String(sendQuotesToEmail).toLowerCase(),
        Email_Comments: emailComments,
        UserName: UserName
      }).then((response) => {
        setLoading(false);
        setEmailQuoteDialog(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(false);
        setSuccessDialogMsge(response?.data);
      }).catch((error) => {
        setLoading(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(true);
        setErrorDialogMsge(error?.response.data);
      });
    } catch (error) {
      setLoading(false);
      setErrorAndSuccessDialog(true);
      setErrorStatus(true);
      setErrorDialogMsge(error?.response.data);
    }
  };

  // Handler to fetch full history of RFQ
  const handleFullHistory = async (e) => {
    setLoading(true);

    try {
      e.preventDefault();

      await AxiosAPIInstance.get('RFQsSearch/AllVersions', {
        params: { RFQRFQID: row.RFQ_ID }
      }).then(async (response) => {
        await setAllVersions(response?.data);
        setLoading(false);
        setGetMoreInfo(true);
      }).catch(async (error) => {
        if (error?.response.data === 'No Data Found') {
          setLoading(false);
          setShowRecentVersion(true);
          setGetMoreInfo(true);
        } else {
          setLoading(false);
          setGetMoreInfo(false);
          setCopyError(true);
          setCopyErrorMsge(error?.response.data);
        }
      });
    } catch {
      setLoading(false);
      setCopyError(true);
      setCopyErrorMsge('Some Error Occurred While Publishing Data');
    }
  };

  // Handler to close copy quote dialog
  const handleCopyQuoteDialogClose = (e) => {
    e.preventDefault();
    setFullQuoteDetailsOnlyChecked(false);
    setCopyQuoteDialog(false);
    setQuoteDetailsOnlyChecked(false);
    setSkuDetailsOnlyChecked(false);
  };

  // Handler to close merge quote dialog
  const handleMergeQuoteDialogClose = (e) => {
    e.preventDefault();
    setMergeQuoteDialog(false);
  };

  // Handler to generate PDF from invoice data and download it
  const HandlePDFClick = async (row) => {
    const arr = String(row.Sales_Head_Name).toLowerCase().split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const KO_arr = String(row.KO_name).toLowerCase().split(' ');
    for (var i = 0; i < KO_arr.length; i++) {
      KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
    }
    const CO_arr = String(row.Customer_Name).toLowerCase().split(' ');
    for (var i = 0; i < CO_arr.length; i++) {
      CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
    }
    const OP_arr = String(row.Opportunity_Name).split('.');
    for (var i = 0; i < OP_arr.length; i++) {
      OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
    }

    const updatedInvoiceData = {
      ...InvoiceData,
      invoice_num: String(row.Invoice_No),
      Contract_Vehicle: row.Contract_Vehicle,
      KO_name: KO_arr.join(' '),
      KO_email: row.KO_email,
      KO_phone: row.KO_phone,
      KO_address: row.KO_address,
      Print_Date: String(today),
      trans_date: String(row.Last_Updated_Date).split('T')[0],
      due_date: row.Due_date,
      Valid_For: row.Valid_For,
      Address: 'ASPETTO INC, 1691 EMANCipation HWY, FREDERICKSBURG, VA-22401',
      items: row.Purchase_Orders,
      Tax: row.Tax,
      Shipping_Cost: row.Shipping_Cost,
      Total_Cost: row.Actual_Total_Cost_After_Margin,
      Other_Cost_1: row.Other_Cost_1,
      Other_Cost_2: row.Other_Cost_2,
      Other_Cost_3: row.Other_Cost_3,
      Other_Cost_Name1: row.Other_Cost_Name1,
      Other_Cost_Name2: row.Other_Cost_Name2,
      Other_Cost_Name3: row.Other_Cost_Name3,
      Shipping_Details: {
        Organization_Name: row.Organization_Name,
        KO_name: row.KO_name,
        Sales_Head_Info: { Name: arr.join(' '), Email: row.Sales_Head_Email, Phone: row.Sales_Head_Ph },
        Customer_Name: CO_arr.join(' '),
        Customer_Email: row.Customer_Email,
        Customer_Phone: row.Customer_Phone,
        Customer_Address: row.Customer_Address,
        Shipping_Method: row.Shipping_Method,
        Shipping_Terms: row.Shipping_Terms,
        Estimated_Delivery: row.Estimated_Delivery,
        Payment_Terms: row.Payment_Terms,
        Notes: row.Notes
      }
    };

    setInvoiceData(updatedInvoiceData);

    // Format Opportunity Name for file name
    File_Opportunity_Name = OP_arr.join(" ")
    setTimeout(async () => {
      setLoading(true);
      const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${File_Opportunity_Name} ${String(row.Invoice_No).split('.')[0]}${String(row.Invoice_No).split('.')[1]}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setLoading(false);
    }, 0);
  };


  return (
    <React.Fragment>
      {loading && <LoadingDialog />}
      {CopyError && (
        <Dialog open={CopyError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled" severity="error">ERROR</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center', color: 'black' }}>
              {CopyErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorAndSuccessDialog && (
        <Dialog open={errorAndSuccessDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
            {!errorStatus ? <Alert variant="filled" severity="success">Success</Alert> : <Alert variant="filled" severity="error">Error</Alert>}
          </DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-description">
              {!errorStatus ? successDialogMsge : errorDialogMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => handleClose(e)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {emailQuoteDialog && (
        <Dialog key={row} maxWidth="lg" open={emailQuoteDialog} onClose={handleClose} sx={{ justifyContent: 'stretch' }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled" sx={{ backgroundColor: orange[200],color:'black'}}>Please Enter Receipent Details:</Alert>
          </DialogTitle>
          <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
            <DialogContentText id="alert-dialog-description">
              <Stack sx={{ textAlign: 'center', alignItems: 'center' }}>
                <b>Quote Number:</b>
                <TextField size="small" value={row?.Invoice_No} disabled sx={{ width: '300px' }} />
                <b>RFQ ID:</b>
                <TextField size="small" value={row?.RFQ_ID} disabled sx={{ width: '300px' }} />
                <br />
                <FormControl>
                  <InputLabel id="Transfer-to-email-id">Select User Email ID</InputLabel>
                  <Select labelId="Transfer-to-email-id" value={sendQuotesToEmail} variant="standard" style={{ width: 300 }} onChange={(e) => { setSendQuotesToEmail(e.target.value) }}>
                    {usersData?.map((row1, index) => (
                      <MenuItem value={row1?.User_email} key={index}>{row1?.User_email}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <b>Comments: </b>
                <TextField multiline maxRows={10} minRows={3} size="small" value={emailComments} onChange={(e) => { setEmailComments(e.target.value) }} sx={{ width: '300px' }} />
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size="small" type="submit" variant="contained" sx={{":hover": { boxShadow: '10px 10px 20px #ccc' }}} onClick={(e) => { setLoading(true); handleEmailQuotesTo(e, row) }}>
              Send
            </Button>
            <Button name="Cancel" size='small' variant="outlined" color="warning" onClick={(e) => { setEmailQuoteDialog(false); setSendQuotesToEmail(''); setEmailComments('') }}>Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
{customerOrKOEmailDialogOpen && (
  <Dialog
    key={row}
    maxWidth="lg"
    fullWidth
    open={customerOrKOEmailDialogOpen}
    onClose={handleClose}
    sx={{ justifyContent: 'stretch' }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title"  >
      <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px',  fontFamily: 'arial' }}>
        Quote Number: <strong>{row?.Invoice_No}</strong>
      </Alert>
    </DialogTitle>
    <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
      <DialogContentText id="alert-dialog-description">
        <Stack sx={{ textAlign: 'center', alignItems: 'center', width: '100%' }}>
          <Stack direction="column" spacing={2} sx={{ width: '100%', marginTop: '20px' }}>
            <TextField
              autoFocus
              margin="dense"
              label="From"
              type="email"
              name="From"
              sx={{ marginTop: '20px', width: '100%' }}
              value={emailData.From}
              disabled
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="To"
              type="email"
              name="To_Email"
              sx={{ width: '100%' }}
              value={emailData.To_Email}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Cc"
              type="text"
              name="CC"
              multiline
              rows={3}
              sx={{ width: '100%' }}
              disabled
              value={emailData.CC || 'quotesandsales@aspettoinc.com'}
              onChange={handleInputChange}
            />
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                freeSolo
                options={usersData?.map((user) => user.User_email)} // Map to user emails
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Add Other Emails to CC"
                    helperText="Start entering user name or email"
                  />
                )}
                onInputChange={(e, value) => {
                  setSendQuotesToEmail(value);
                }}
                renderOption={(props, option) => {
                  const emailIncluded = emailData.CC && emailData.CC.includes(option);
                  return (
                    <MenuItem {...props} key={option}>
                      {option}
                      {emailIncluded ? (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedCC = emailData.CC.split(';')
                              .filter((email) => email !== option)
                              .join(';')
                              .replace(/^;|;$/g, '');
                            setEmailData({ ...emailData, CC: updatedCC });
                            setSendQuotesToEmail('');
                          }}
                        >
                          <Avatar variant='rounded' sx={{ bgcolor: red[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                            <Tooltip title='Remove'>
                              <CloseIcon sx={{ color: 'whitesmoke' }} />
                            </Tooltip>
                          </Avatar>
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            const updatedCC = emailData.CC
                              ? `${emailData.CC};${option}`
                              : `quotesandsales@aspettoinc.com;${option}`;
                            setEmailData({ ...emailData, CC: updatedCC });
                            setSendQuotesToEmail('');
                          }}
                        >
                          <Avatar variant='rounded' sx={{ bgcolor: green[800], marginLeft: "10px", width: "20px", height: "20px" }}>
                            <Tooltip title='Add'>
                              <AddIcon sx={{ color: 'whitesmoke' }} />
                            </Tooltip>
                          </Avatar>
                        </IconButton>
                      )}
                    </MenuItem>
                  );
                }}
              />
            </FormControl>
            <TextField
              margin="dense"
              label="Subject"
              name="Subject"
              sx={{ width: '100%' }}
              value={emailData.Subject}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Body"
              name="Body"
              multiline
              rows={12}
              sx={{ width: '100%' }}
              value={emailData.Body}
              onChange={handleInputChange}
            />
            <FormGroup>
              <FormControlLabel
                size="small"
                control={
                  <Checkbox
                    size="small"
                    checked={attachFilesChecked}
                    onChange={(e) => {
                      setAttachFilesChecked(e.target.checked);
                      setEmailData({ ...emailData, Attachments: e.target.checked });
                    }}
                  />
                }
                label="Attach Files"
              />
            </FormGroup>
            {attachFilesChecked && (
              <>
                <input
                  accept="image/*, .pdf, .doc, .docx"
                  style={{ display: 'none' }}
                  id="attachment-input"
                  multiple
                  type="file"
                  onChange={handleAttachmentChange}
                />
                <label
                  htmlFor="attachment-input"
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}
                >
                  <AttachFileIcon />
                  Attach Files
                </label>
                <ul>
                  {attachments?.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <IconButton onClick={() => removeAttachment(index)} style={{ marginLeft: '10px' }}>
                        <CloseIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <FormGroup>
              <FormControlLabel
                size="small"
                control={
                  <Checkbox
                    size="small"
                    checked={attachSKUDetailsInTheBody}
                    onChange={async (e) => {
                      handleAttachSKUDetailsCheckboxChange(e);
                      handleAttachSKUDetailsInTheEMailBody(e, row);
                    }}
                  />
                }
                label="Attach SKU Details in the Email Body"
              />
            </FormGroup>
            {attachSKUDetailsInTheBody && (
              <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      Vendor/Partner
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      SKU ID
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      SKU Desc
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      QTY
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      Unit Cost($)
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      Total Cost($)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emailData.SKU_DATA.map((P, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {P.Vendor_Partner_name}
                      </TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {P.SKU_ID}
                      </TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {P.SKU_Description}
                      </TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {P.QTY}
                      </TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {Number(P.Customer_Unit_Cost).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                        {Number(P.Total_SKU_Cost).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        border: 1,
                        borderColor: 'text.primary',
                        color: 'black',
                        backgroundColor: orange[200],
                        fontFamily: 'Verdana (sans-serif)',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                      align="center"
                    >
                      SUB TOTAL
                    </TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                      ${allSKUsCost}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            <Stack sx={{ width: '100%' }}>
              <TextField
                label="Signature"
                name="Signature"
                multiline
                rows={12}
                sx={{ width: '100%' }}
                value={emailData.Signature}
                onChange={handleInputChange}
              />
            </Stack>
          </Stack>
        </Stack>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{padding:2}}>
      <Button size="small" variant="contained" sx={{bgcolor:blueGrey[900],color:'whitesmoke',":hover": { boxShadow: '10px 10px 20px #ccc' }}} 
      onClick={async (e) => { handleSendCustometOrKOEmail(e, row); }}>
        Send
        {/* <Avatar sx={{ backgroundColor: green[500], ":hover": { boxShadow: '10px 10px 20px #ccc' } }} variant="rounded">
          <SendIcon />
        </Avatar> */}
      </Button>
      <Button
        name="Cancel"
        variant="outlined"
        size='small'
        color="warning"
        sx={{":hover": { boxShadow: '10px 10px 20px #ccc' }}}
        onClick={(e) => {
          setEmailQuoteDialog(false);
          setSendQuotesToEmail('');
          setEmailComments('');
          handleCustomerKOEmailDialogClickClose(e);
        }}
      >Cancel
        {/* <Avatar variant="rounded" sx={{ backgroundColor: orange[900], ":hover": { boxShadow: '10px 10px 20px #ccc' } }}>
          <DisabledByDefaultIcon />
        </Avatar> */}
      </Button>
    </DialogActions>
  </Dialog>
)}


      { mergeQuoteDialog && (
        <Dialog open={mergeQuoteDialog} maxWidth="xx-lg" sx={{ justifyContent: 'stretch', marginTop: '60px'}} onClose={handleCopyQuoteDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title"  >
                <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontFamily:'arial'}}>
                Please Select Details You want To Merge
                </Alert>
  
          </DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black'}}>
              <Stack>
                <Table sx={{ flexGrow: 'inherit' }}>
                    <TableRow>
                      <TableCell colSpan={16}>
                        <RFQs_Merge_Search />
                      </TableCell>
                    </TableRow>
                </Table>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <IconButton name="Cancel" variant="contained" color="warning" onClick={(e) => { handleMergeQuoteDialogClose(e) }}>
              <Avatar variant="rounded" sx={{ backgroundColor: [orange[500]], border: 1, width: '100px', fontSize: '13px', height: '35px', ":hover": { boxShadow: '10px 10px 20px #ccc' } }}>CLOSE</Avatar>
            </IconButton>
          </DialogActions>
        </Dialog>
       )}

      { copyQuoteDialog && (
        <Dialog open={copyQuoteDialog} onClose={handleCopyQuoteDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontFamily:'arial'}}>
            Please Select Details You want to Copy
            </Alert>
          </DialogTitle>
          <DialogContent sx={{ width: '550px', height: '120px' }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
              <Stack>
                <FormGroup>
                  <FormControlLabel size="small" control={<Checkbox size="small" checked={skuDetailsOnlyChecked} onChange={(e) => {
                    setSkuDetailsOnlyChecked(e.target.checked);
                    if (e.target.checked === true) {
                      setQuoteDetailsOnlyChecked(false);
                      setFullQuoteDetailsOnlyChecked(false);
                    }
                  }} />} label="Copy Only SKU Details, Shipping Cost, Tax and Other Costs" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel size="small" control={<Checkbox size="small" checked={quoteDetailsOnlyChecked} onChange={(e) => {
                    setQuoteDetailsOnlyChecked(e.target.checked);
                    if (e.target.checked === true) {
                      setSkuDetailsOnlyChecked(false);
                      setFullQuoteDetailsOnlyChecked(false);
                    }
                  }} />} label="Copy Only Customer, Contracting Officer & Organization Details" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel size="small" control={<Checkbox size="small" checked={fullQuoteDetailsOnlyChecked} onChange={(e) => {
                    setFullQuoteDetailsOnlyChecked(e.target.checked);
                    if (e.target.checked === true) {
                      setSkuDetailsOnlyChecked(false);
                      setQuoteDetailsOnlyChecked(false);
                    }
                  }} />} label="Copy Complete Quote Details" />
                </FormGroup>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Tooltip title='Copy'>
            <Button  type="submit" variant="contained" size='small' sx={{":hover": { boxShadow: '10px 10px 20px #ccc' }}} onClick={(e) => {
              e.preventDefault();
              var CheckedDetails = '';
              if (skuDetailsOnlyChecked === false && quoteDetailsOnlyChecked === false && fullQuoteDetailsOnlyChecked === false) {
                setCopyError(true);
                setCopyErrorMsge('Please Select any one checkbox');
              } else {
                setCopyError(false);
                setCopyErrorMsge('');
                if (skuDetailsOnlyChecked === true) {
                  CheckedDetails = 'SKU';
                } else if (quoteDetailsOnlyChecked === true) {
                  CheckedDetails = 'QUOTE';
                } else if (fullQuoteDetailsOnlyChecked === true) {
                  CheckedDetails = 'FULL QUOTE';
                }
                navigate({
                  pathname: '/Dashboard/RFQCopy',
                  search: createSearchParams({ RID: row.RFQ_ID, RStatus: row.RFQ_Status, VNo: row.Version_No, CheckedDetails: CheckedDetails, QuoteType: 'Internal' }).toString()
                });
              }
            }}>Copy
            </Button>
            </Tooltip>
            <Tooltip title='Cancel'>
            <Button name="Cancel" variant="outlined" color="warning" sx={{":hover": { boxShadow: '10px 10px 20px #ccc' }}} onClick={(e) => { handleCopyQuoteDialogClose(e) }} size='small'>
              Cancel
            </Button>
            </Tooltip>
          </DialogActions>
        </Dialog>
      )}
      {briefNotesForPODialog && (
        <Dialog maxWidth="lg" open={briefNotesForPODialog} onClose={handleNotesForPODialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
            <Alert variant="filled" severity="info" sx={{ backgroundColor: briefClick ? blue[600] : yellow[800] }}>{briefClick ? 'Brief' : 'Notes For PO'}</Alert>
          </DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
              {briefClick ? row.Brief : row.Notes_For_PO}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="CLOSE" onClick={(e) => { handleNotesForPODialog(e) }}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {getMoreInfo && (
        <Dialog maxWidth="x-lg" key={row.RFQQuoteNumber} open={getMoreInfo} sx={{ justifyContent: 'stretch' }} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled" sx={{ bgcolor: blueGrey[400], fontSize: '11px' }}>SKU REVISION HISTORY: {row.Opportunity_Name}</Alert>
          </DialogTitle>
          <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
            <DialogContentText id="alert-dialog-description">
              <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', width: '10%', fontWeight: 'bold' }} align="center">Version No</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Created Date</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Updated Date</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Vendor/Partner</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SKU ID</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SKU Desc</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">QTY</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Aspetto's Cost($)</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Customer's Cost($)</TableCell>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Total Cost($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'red', fontSize: '11px', width: '250px' }} align="center">CURRENT REVISION</TableCell>
                  </TableRow>
                  {row.Purchase_Orders.map((P, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px', width: '10%' }} align="center">{P.Version_No}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{String(P.SKU_Created_Date).split('T')[0]}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{String(P.SKU_Last_Updated_Date).split('T')[0]}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.Vendor_Partner_name}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.SKU_ID}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.SKU_Description}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.QTY}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    {recentVersion !== true && <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'red', fontSize: '11px' }} align="center">OLDER REVISION</TableCell>}
                  </TableRow>
                  {recentVersion !== true && (
                    allVersions.map((P, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px', width: '10%' }} align="center">{P.Version_No}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{String(P.SKU_Created_Date).split('T')[0]}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{String(P.SKU_Last_Updated_Date).split('T')[0]}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.Vendor_Partner_name}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.SKU_ID}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.SKU_Description}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{P.QTY}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Aspetto_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(P.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} variant='outlined' size='small' color="warning" sx={{":hover": { boxShadow: '10px 10px 20px #ccc' } }}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {getKOAndCustomerEmailHistory && (
        <Dialog maxWidth="x-lg" key={row.RFQQuoteNumber} open={getKOAndCustomerEmailHistory} sx={{ justifyContent: 'stretch' }} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontWeight:'bold',fontFamily:'arial'}}>
              EMAIL HISTORY: {row.Opportunity_Name}</Alert>
          </DialogTitle>
          <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
            <DialogContentText id="alert-dialog-description">
              {koCustomerEMailHistory.length !== 0 && koCustomerEMailHistory?.map((P, index) => (
                <Paper elevation={3} sx={{ borderRadius: 1, padding: 1, border: 1 }} key={index}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>Date</Typography>
                      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(P.Latest_Email_Date).split('T')[0]}</Typography>
                    </div>
                    <div>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>Quote No:</Typography>
                      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(P.Quote_No)}</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>Sent To</Typography>
                      <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{P.To_Name}<br />{P.To_Email}</Typography>
                    </div>
                    <div>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>Sent By</Typography>
                      <Typography variant="body1" sx={{ fontSize: '13px' }}>{P.Email_SentBy_Name}<br />{P.Email_SentBy_Email}</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>Body</Typography>
                      <Typography variant="body1" sx={{ fontSize: '12.5px' }}><span style={{ whiteSpace: 'pre-wrap' }}>{P.Body}</span></Typography>
                    </div>
                  </div>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>ORDER DETAILS:</Typography>
                  {P?.AttachSKUDataInTheEmailBody && (
                    <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Vendor/Partner</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SKU ID</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SKU Desc</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">QTY</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Unit Cost($)</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Total Cost($)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {JSON.parse(P?.SKU_DATA).map((skurow, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.Vendor_Partner_name}</TableCell>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.SKU_ID}</TableCell>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.SKU_Description}</TableCell>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.QTY}</TableCell>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(skurow.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                            <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(skurow.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={5} sx={{ border: 1, borderColor: 'text.primary', color: 'black', backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SUB TOTAL</TableCell>
                          <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">${JSON.parse(P?.SKU_DATA).reduce((total, current) => Number(total) + Number(current.Total_SKU_Cost), 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Paper>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined' size='small' color="warning" sx={{":hover": { boxShadow: '10px 10px 20px #ccc' } }}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <TableRow sx={{ padding: 0.5 }}>
        <TableCell sx={{ padding: 1 }} align="center" scope="row">
          <b style={{ color: 'red' }}>{row.Opportunity_Name}</b> <br />
          <b style={{ color: 'black' }}>{row.Contract_Vehicle}</b><br />
          <span style={{ color: 'black' }}>{row.Invoice_No}</span>
          <br /><br />
          {row.RFQ_Status === 'PENDING' && <Button sx={{ width: '50px', fontSize: '10px' }} color="warning" type="text" variant="contained">{row.RFQ_Status}</Button>}
          {row.RFQ_Status === 'WON' && <Button sx={{ width: '50px', fontSize: '10px' }} color="success" type="text" variant="contained">{row.RFQ_Status}</Button>}
          {row.RFQ_Status === 'LOST' && <Button sx={{ width: '50px', fontSize: '10px' }} color="error" type="text" variant="contained">{row.RFQ_Status}</Button>}
          {row.RFQ_Status === 'CANCELLED' && <Button sx={{ width: '70px', fontSize: '10px' }} color="info" type="text" variant="contained">{row.RFQ_Status}</Button>}
        </TableCell>
        <TableCell sx={{ padding: 0.5 }} align="left">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1, borderColor: blueGrey[900] }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', width: '100%' }}>
              <PersonIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.KO_name}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <ContactMailIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.KO_address}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <AlternateEmailIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px', color: blue[900] }}>{row.KO_email}</Typography>
              <Tooltip title="Email KO">
                <IconButton variant="text" sx={{ color: 'white', borderRadius: 1, transition: 'transform 0.2s ease-in-out', "&:hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleKOEmailDialogClickOpen(e, row) }}>
                  <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', "&:hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                    <EmailIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <PhoneIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.KO_phone}</Typography>
            </div>
          </Paper>
        </TableCell>
        <TableCell align="left">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1, borderColor: blueGrey[900] }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <PersonIcon sx={{ marginRight: 1, color: orange[900] ,height:'20px',width:'20px'}} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.Customer_Name}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <ContactMailIcon sx={{ marginRight: 1, color: orange[900] ,height:'20px',width:'20px'}} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.Customer_Address}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <AlternateEmailIcon sx={{ marginRight: 1, color: orange[900] ,height:'20px',width:'20px'}} />
              <Typography variant="body1" sx={{ fontSize: '12.5px', color: blue[900] }}>{row.Customer_Email}</Typography>
              <Tooltip title="Email Customer">
                <IconButton variant="text" sx={{ color: 'white', borderRadius: 1, transition: 'transform 0.2s ease-in-out', "&:hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleCustomerDialogClickOpen(e, row) }}>
                  <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', "&:hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                    <EmailIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <PhoneIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.Customer_Phone}</Typography>
            </div>
          </Paper>
        </TableCell>
        <TableCell sx={{ padding: 0.5 }} align="left">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1, borderColor: blueGrey[900] }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <PersonIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.Sales_Head_Name}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
              <AlternateEmailIcon sx={{ marginRight: 1, color: orange[900] ,height:'20px',width:'20px'}} />
              <div style={{ alignItems: 'center' }}>
                <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{row.Sales_Head_Email}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>
                <TodayIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />Created
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row.Created_Date).split('T')[0]}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>
                <TodayIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />Updated
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row.Last_Updated_Date).split('T')[0]}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12.5px' }}>
                <AlternateEmailIcon sx={{ marginRight: 1, color: orange[900],height:'20px',width:'20px' }} />Updated
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '12.5px' }}>{String(row.Updated_By)}</Typography>
            </div>
          </Paper>
        </TableCell>
        <TableCell sx={{ padding: 0.5 }} align="center">{Number(row.Actual_Total_Cost_After_Margin).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
        <TableCell align="center" sx={{ width: '150px', padding: 0.5 }}>
          <Stack direction="row" sx={{ padding: '5px' }} spacing={0.5}>
            {row.Notes_For_PO !== null && row.Notes_For_PO !== 'NA' && row.Notes_For_PO !== 'N/A' && row.Notes_For_PO !== '' && (
              <Tooltip title="Notes For PO" placement="bottom">
                <IconButton onClick={(e, row) => { handleBeiefAndPONotesClick(e, row); }} sx={{ width: '70px', fontSize: '10px', fontWeight: 'bold', backgroundColor: yellow[800], color: 'black', borderRadius: 0, border: '1px solid', ":hover": { boxShadow: '10px 10px 20px #ccc', backgroundColor: yellow[400], color: 'white' } }} variant="contained">
                  Notes for PO
                </IconButton>
              </Tooltip>
            )}
            {row.Brief !== null && row.Brief !== 'NA' && row.Brief !== 'N/A' && row.Brief !== '' && (
              <Tooltip title="Brief" placement="bottom">
                <IconButton onClick={(e, row) => { setBriefClick(true); handleBeiefAndPONotesClick(e, row); }} sx={{ width: '70px', fontSize: '10px', fontWeight: 'bold', backgroundColor: blue[400], color: 'black', borderRadius: 0, border: '1px solid', ":hover": { boxShadow: '10px 10px 20px #ccc', backgroundColor: blue[800], color: 'white' } }} variant="contained">
                  Brief
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Tooltip title="Add Comments">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1,fontSize:'12px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={handleCommentsOpen}>
                
                <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', fontSize:'12px',":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                {/* Add Comments */}
                <CommentIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} /> 
                
                </Avatar>
              </IconButton>
            </Tooltip>
            <br />
          <Typography variant="h7" sx={{ width: '50px', fontSize: '14px' }}>Revision: {row.Version_No}</Typography>
          <br />
          <Stack direction="row" spacing={0.5}>

            <Tooltip title="Get SKU Revisions" placement="bottom">
              <IconButton name="Get Full History" variant="contained" color="info" onClick={(e) => { handleFullHistory(e) }}>
                <Avatar sx={{ backgroundColor: cyan[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: cyan[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <HistoryIcon sx={{ color: 'white', backgroundColor: cyan[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Quote">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: teal[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); setCopyQuoteDialog(true); }}>
                <Avatar sx={{ backgroundColor: teal[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: teal[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <ContentCopyIcon sx={{ color: 'white', backgroundColor: teal[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="UNDER DEVELOPMENT: Merge This Quote With Other Quotes to Create a New Quote">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: blue[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); setMergeQuoteDialog(true); }}>
                <Avatar sx={{ backgroundColor: blue[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: blue[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <MergeIcon sx={{ color: 'white', backgroundColor: blue[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title={!visibility ? 'UNDER DEVELOPMENT: Remove from your Tracking List' : 'UNDER DEVELOPMENT: Add to your Tracking List'}>
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleVisibility(e) }}>
                <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                  {visibility ? <VisibilityIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} /> : <VisibilityOffIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '30px', height: '30px' }} />}
                </Avatar>
              </IconButton>
            </Tooltip>
   
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Edit Quote">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); RFQID_Search() }}>
                <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <EditIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Generate PDF">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); HandlePDFClick(row) }}>
                <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Email this Quote">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleEmailQuotes(e, row) }}>
                <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <EmailIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="KO/Customer Email History">
              <IconButton variant="text" sx={{ color: 'white', padding: isMobile ? '8px' : '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleKOCustomerEmailHistoryDialogClickOpen(e) }}>
                <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <HistoryToggleOffIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>

           <ConversationDialog
           open={commentsOpen}
           onClose={handleCommentsClose}
           conversationId={conversationId}
           title={title}
           />
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
    <Collapse in={isDialogOpen} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        <Table aria-label="purchases" sx={{ border: 1, borderColor: 'text.main' }}></Table>
      </Box>
    </Collapse>
  </TableCell>
</TableRow>
    </React.Fragment>
  );
}

export default SalesRow;
