import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel,Alert
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';

const UpdateBugDialog = ({ open, onClose, bug, onUpdate }) => {
  const [status, setStatus] = useState(bug.Resolution_Stage || 'open');
  const [closingComments, setClosingComments] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClosingCommentsChange = (event) => {
    setClosingComments(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(bug.Bug_REQ_ID, status, closingComments);
    onClose(); // Close the dialog after update
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{}}>
      <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px',  fontFamily: 'arial' }}>
        Update Bug Status
      </Alert></DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            variant="standard"
            labelId="status-label"
            value={status}
            onChange={handleStatusChange}
          >
            <MenuItem value="Open">Open</MenuItem>
            <MenuItem value="InProgress">InProgress</MenuItem>
            <MenuItem value="Resolved">Resolved</MenuItem>
            <MenuItem value="Closed">Closed</MenuItem>
          </Select>
        </FormControl>

        {status === 'Closed' && (
          <TextField
            label="Closing Comments"
            value={closingComments}
            onChange={handleClosingCommentsChange}
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBugDialog;
