import * as React from 'react';
import {Button, Stack,Typography,Link,Divider} from '@mui/material';
import Login_Bck from "../Images/Login.png"
import {blueGrey } from '@mui/material/colors'
import { createTheme} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance} from '../../axiosGlobalInstance'
import {useState,} from "react"
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom'

const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
function EmailVerificationSuccess()
{
    const [errMsge,setErrMsge]=useState("");
    const [errorDialog,setErrorDialog]=useState(false)
    const [success,setSuccess]=useState("");
    const [successMsge,setSuccessMsge]=useState("");
    const [successDialog,setSuccessDialog]=useState(false)
    const [emailToken,setEmailToken]=useState("")
    const navigate=useNavigate();
    const [serachParams]=useSearchParams()


    const {token}  = useParams()
    const [verificationStatus, setVerificationStatus] = useState('');
    React.useEffect(() => {
      const verifyEmail = async () => {
        try {
          await AxiosGlobalAPIInstance.get(`/UserAuth/verify/${token}`).then(async(response)=>{
            setVerificationStatus(response?.data);
            setSuccessDialog(true)
            setSuccessMsge(response?.data)
            setErrorDialog(false)
            setErrMsge("")
        }).catch((error)=>{
          setVerificationStatus(error?.response.data);
          setErrorDialog(true)
          setErrMsge(error?.response.data)
          setSuccessDialog(false)
          setSuccessMsge("")
        })
        } catch (error) {
          setVerificationStatus('Email verification failed.');
          setErrorDialog(true)
          setErrMsge('Email verification failed.')
          setSuccessDialog(false)
          setSuccessMsge("")
        }
      };
  
      verifyEmail();
    }, [token]);

 const handleClose = (e) => {
      e.preventDefault();
      setErrorDialog(false)
      setSuccessMsge("")
      setSuccessDialog(false)
      setErrMsge("")
      navigate("/",{ replace: true })
    }

    return(
        <> 
        {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        <Alert variant='filled' severity='error'>error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle  id="alert-dialog-title">
        <Alert variant='filled' severity='success'>success</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {successMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <Stack sx={{ backgroundImage: `url(${Login_Bck})`,
      backgroundSize:'cover',
        height: "97vh",
        width: "203.5vh",alignItems:'center'}}>
          </Stack>
        </>
    )
}
export default EmailVerificationSuccess