import * as React from 'react'
import {useLocation,useNavigate} from 'react-router-dom'
import { logOut, selectCurrentToken} from '../Login_Registration/authSlice'
import { useSelector } from 'react-redux'
import  jwtDecode  from 'jwt-decode';
import { useDispatch } from "react-redux";

const UserAuth =()=>
{ 
   const token=useSelector(selectCurrentToken)
   const navigate=useNavigate()
   const location=useLocation()
   const dispatch=useDispatch()
   if(token)
   {
      try {
    const decoded=jwtDecode(token)
   //  console.log(decoded?.userInfo)
    const {UserName,UserEmail}=decoded?.userInfo
    return { UserName , UserEmail, token}
   }
   catch (error) {
       dispatch(logOut())
       navigate('/',{replace:false})
      // Handle the error (e.g., redirect to login page)
    }
   }
   else
   {
   return { UserName:'',UserEmail:'',token:''}
   }

   
}
export default UserAuth