import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useState} from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { orange,indigo,green,yellow,red, blueGrey,grey,teal,deepOrange,deepPurple, blue} from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import AddTaskIcon from '@mui/icons-material/AddTask';
import userAuth from "../ProtectedRoute/userAuth"
import SaveIcon from '@mui/icons-material/Save';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useSearchParams } from "react-router-dom";
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { Link,createSearchParams} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingDialog from "../Loading";

function RMAInvestigation()
{

  const PD= new Date()
  const AxiosAPIInstance=AxiosFunction()
  const {
    UserName,
    UserEmail
  } = userAuth()

const[Form_Value,Set_Form_Value]=  useState({Agency_Name:'',RMARMAID:'',Investigation_Finding:'',Resolution_Stage:'Investigation',Completed_By_Name:UserName,Completed_By_Email:UserEmail,Completed_By_Phone:'540-547-8487',
Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
Updated_By:UserEmail,Version_No:'',Updating_Comments:''})

const [dis,setDis]=useState(false)
const navigate=useNavigate()
const[invMsge,setRFIMssge]=useState('')
const [successDialog,setSuccessDialog]=useState(false)
const [errorDialog,setErrorDialog]=useState(false)
const [open, setOpen] = useState(false);
const [edit,setEdit]=useState(true)
const [allFieldsActivate,setAllFieldsActivate]=useState(false)
const [searchParams]=useSearchParams();
const [loading, setLoading] = useState(false);

React.useEffect(()=>{
  setLoading(true)
  async function fetchData(){
    await AxiosAPIInstance.get("/RMAs/GetRMAInvestigationByID",{params:{RMARMAID:String(searchParams.get("RMAID"))}}).then(async(result)=>{
      setLoading(false)
      Set_Form_Value({...result?.data})
    })
  }
  fetchData();
  },[searchParams])

const handleClose = (e) => {
  setOpen(false);
  setErrorDialog(false)
  setSuccessDialog(false)
};
const handleEdit=()=>{
  setDis(true)
  setEdit(false)
  setAllFieldsActivate(true)
}
const HandleSubmit = async(event) => {
  setDis(false)
  setEdit(false)
  setLoading(true)
    event.preventDefault()
    if(Form_Value.Investigation_Finding!=="")
    {
    await AxiosAPIInstance.patch("/RMAs/RMAIvestigation",Form_Value).then(
        async(response)=>{
          setLoading(false)
          setSuccessDialog(true)
          setErrorDialog(false)
          setRFIMssge(response?.data)
          setDis(false)
          setEdit(true)
        }
    ).catch((error)=>{
      setLoading(false)
      setSuccessDialog(false)
      setErrorDialog(true)
      setRFIMssge(error.response?.data)
      setDis(true)
      setEdit(false)
      setAllFieldsActivate(true)
    })
    }
    else
    {  setLoading(false)
      setAllFieldsActivate(true)
      setDis(true)
      setEdit(false)
      setErrorDialog(true)
      setRFIMssge("All Fields are Required Fields. Enter NA only where it is applicable")
    }
}

    return(
        <>
         <LoadingDialog open={loading} />
         {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {invMsge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {invMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          setEdit(true)
          setAllFieldsActivate(false)
          }
          } href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {invMsge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {invMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setAllFieldsActivate(true)
          setEdit(false)
          setDis(true);}} href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
        <ApplicationBar></ApplicationBar>
        <Stack component="form" onSubmit={HandleSubmit}  >
        <Stack direction="column" spacing={3} sx={{marginTop:"100px"}}>
        <Stack direction="row" justifyContent="center">
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold"}} > Investigation:</Typography>
          </Stack>
        
        <Stack direction="row" justifyContent="center">
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
               <Stack direction="row" spacing={3} justifyContent="center">
               <TextField size="small"  variant="standard" disabled={!allFieldsActivate} fullWidth sx={{width:"60%"}} multiline={true}  required label="Investigation Finding" value={Form_Value.Investigation_Finding} onChange={(e)=>Set_Form_Value({...Form_Value,Investigation_Finding:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PlagiarismIcon />
            </InputAdornment>
          ),
        }}></TextField>
               </Stack>
             
               <Stack>
               <Stack direction="row" justifyContent="center">
               <Typography  sx={{alignContent:"center",borderColor:'divider',fontSize:"18px", fontWeight:"bold"}}>Completed By:</Typography>
          </Stack>
               <Stack direction="row" justifyContent="center">
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
               </Stack>
               <Stack direction="row" spacing={3} justifyContent="center">
               <TextField size="small" variant="standard" sx={{width:"300px"}} label="Name" value={UserName} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Name:UserName})}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddTaskIcon/>
                  </InputAdornment>
                ),readOnly:"true"
              }}></TextField>
               <TextField size="small" variant="standard" sx={{width:"300px"}} label="Email" value={UserEmail} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Email:UserEmail})}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddTaskIcon/>
                  </InputAdornment>
                ),readOnly:"true"
              }}></TextField>
               <DatePicker readOnly label="Completed Date" sx={{width:"300px"}} value={Form_Value.Created_Date} minDate={new Date()} renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
        </DatePicker>
        <DatePicker readOnly label="Updated Date" sx={{width:"300px"}} value={Form_Value.Last_Updated_Date}  renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
               </Stack>
               <Stack direction="row" spacing={2} justifyContent="center">
               <Tooltip title="Go Back">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/EditRMA",
                      search:createSearchParams({RMAID:Form_Value.RMARMAID}).toString()
                    })}}
                    sx={{ height:"40px",bgcolor:blue[700],width:"100px"}}
                    startIcon={<ArrowBackIcon/>}>
                   BACK
                  </Button>
                  </Tooltip>
               <Tooltip title="Edit">
               <Button variant='contained' color="warning" size='medium' sx={{width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[orange[500]]}}} disabled={!edit} onClick={handleEdit} startIcon={<ModeEditIcon/>}>Edit</Button>
              </Tooltip>
              <Tooltip title="Save">
              <Button variant='contained' color="success" size='medium' sx={{height:"40px",width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[green[500]]}}} disabled={!dis} onClick={HandleSubmit}  startIcon={<SaveIcon/>}> Save</Button>
              </Tooltip>
              <Tooltip title="Next Step">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/RMAResolution",
                      search:createSearchParams({RMAID:Form_Value.RMARMAID}).toString()
                    })}}
                    sx={{height:"40px",bgcolor:red[700],width:"300px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[red[500]]}}}
                    startIcon={<NextPlanIcon/>}>
                   RESOLUTION
                  </Button>
                  </Tooltip>
               </Stack>
               </Stack >
  
               </Stack> 
               
       </>
    );
}

export default RMAInvestigation
