import React from 'react';
import AxiosFunction from '../../axiosCustomInstance';
import axios from 'axios';
import { Button, Input, Paper, Typography,  IconButton,
    Avatar,
    Grid,
    Tooltip, } from '@mui/material';
import jwtDecode from 'jwt-decode'
import { useNavigate,useSearchParams } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import LoadingDialog from '../Loading';
import { green, indigo } from '@mui/material/colors';
import { useState} from "react";
import DownloadIcon from '@mui/icons-material/Download';

const FileDownloadButton = ({ filename }) => {
  const axiosInstance = AxiosFunction();
  const[dHSMssge,setDHSMssge]=useState('')
  const [successDialog,setSuccessDialog]=useState(false)
  const [errorDialog,setErrorDialog]=useState(false)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dis,setDis]=useState(false)

  const handleClose = (e) => {
    setOpen(false);
    setErrorDialog(false)
    setSuccessDialog(false)
   
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/DHSCustomer/files/download/${filename}`, {
        responseType: 'blob',
      });
  
      setLoading(false);
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      setLoading(false);
  
      if (axios.isAxiosError(error)) {
        // Axios error with a response from the server
        setDHSMssge(error.response?.data.message || 'An error occurred while downloading the file.');
      } else {
        // Other errors (network, timeout, etc.)
        setDHSMssge('An unexpected error occurred while downloading the file.');
      }
  
      setErrorDialog(true);
      setSuccessDialog(false);
      console.error('Error downloading file:', error);
    }
  };
  

  return (
    <>
           <LoadingDialog open={loading} />
          {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          } >OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <IconButton color="secondary" type="text" size="small" variant="contained" onClick={(e)=>{
            e.preventDefault();
            handleDownload();}} sx={{marginLeft:"300px",marginTop:"10px",height:"25px",fontSize:"8px", width:"200px"}}><Avatar variant='rounded' sx={{backgroundColor:[green[800]],width:"200px"}}><DownloadIcon  ></DownloadIcon>Download File</Avatar> </IconButton>
      </>

  );
};

export default FileDownloadButton;
