// Importing necessary modules from React and Material-UI
import React, { useState } from 'react';
import { Button, Input, Paper, Typography } from '@mui/material';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance'; // Assuming this is a custom Axios instance
import jwtDecode from 'jwt-decode'; // Library for decoding JWT tokens
import { useNavigate, useSearchParams } from "react-router-dom"; // Hooks for navigation and URL search params
import Dialog from '@mui/material/Dialog'; // Dialog component from Material-UI
import DialogActions from '@mui/material/DialogActions'; // DialogActions component for actions in Dialog
import DialogContent from '@mui/material/DialogContent'; // DialogContent component for content in Dialog
import DialogContentText from '@mui/material/DialogContentText'; // DialogContentText component for text in Dialog
import DialogTitle from '@mui/material/DialogTitle'; // DialogTitle component for title in Dialog
import { Alert } from '@mui/material'; // Alert component from Material-UI
import LoadingDialog from '../Loading'; // Assuming this is a custom Loading component
import { indigo, blueGrey } from '@mui/material/colors'; // Material-UI color utilities
import Barcode from 'react-barcode'; // Library for generating barcodes

const ExcelUploader = () => {
// State variables using useState hook
const [file, setFile] = useState(null); // State for file selection
const [barcodeValue, setBarcodeValue] = useState(''); // State for barcode value

// Function to handle file input change
const handleFileChange = (e) => {
  setFile(e.target.files[0]); // Update file state with the selected file
  // Generating barcode value
  setBarcodeValue(`DHSCBP${Date.now()}`); // Setting barcode value with current timestamp
};

// Hook for accessing URL search params
const [searchParams] = useSearchParams();
// Extracting token from search params and trimming whitespace
const token= String(searchParams.get('Temp')).trim()
// Decoding JWT token to get user information
const decoded= jwtDecode(token);
const {UserName, UserEmail} = decoded?.UserInfo; // Destructuring user information from decoded token
const [dis,setDis]=useState(false) // State for disabling
const navigate=useNavigate() // Hook for navigation
const[dHSMssge,setDHSMssge]=useState('') // State for DHSM message
const [successDialog,setSuccessDialog]=useState(false) // State for success dialog
const [errorDialog,setErrorDialog]=useState(false) // State for error dialog
const [open, setOpen] = useState(false); // State for dialog open/close
const [loading, setLoading] = useState(false); // State for loading state
const [statusCode,setStatusCode]=useState(false) // State for status code
const [expTime,setExpTime]=useState(false) // State for expiration time


// Function to handle closing of dialogs
const handleClose = (e) => {
  // Set all dialog states to false to close them
  setOpen(false); // Close main dialog
  setErrorDialog(false); // Close error dialog
  setSuccessDialog(false); // Close success dialog
  setErrorDialog(false); // Close error dialog again (assuming this is not a typo)
};


 // Function to handle file upload
const handleUpload = async () => {
  // Check if file is selected
  if (file !== null) {
    setLoading(true); // Set loading state to true
    try {
      // Prepare user details for the upload
      const UserDetails = {
        Customer_Name: UserName,
        Customer_Email: UserEmail,
        Excel_Sheet_Upload: true,
        Temp_Token: token,
        Barcode_Value: barcodeValue, // Include barcode value in the request
      };

      const formData = new FormData();
      formData.append('file', file); // Append file to form data

      // Append additional data to the form data
      Object.keys(UserDetails).forEach((key) => {
        formData.append(key, UserDetails[key]);
      });

      // Perform POST request to upload the file
      await AxiosGlobalAPIInstance.post('DHSCustomer/upload', formData)
        .then(async (result) => {
          setLoading(false); // Set loading state to false
          setErrorDialog(false); // Close error dialog
          setSuccessDialog(true); // Open success dialog
          setDHSMssge(result?.data); // Set DHSM message
          setFile(null); // Reset file state
        })
        .catch((error) => {
          // Handle error responses
          if (error?.response?.status === 403) {
            setStatusCode(true); // Set status code to true
          }

          setLoading(false); // Set loading state to false
          setSuccessDialog(false); // Close success dialog
          setErrorDialog(true); // Open error dialog
          setDHSMssge(error.response?.data); // Set DHSM message
          setDis(false); // Reset disable state
        });
    } catch (error) {
      // Handle unexpected errors
      setLoading(false); // Set loading state to false
      setErrorDialog(true); // Open error dialog
      setSuccessDialog(false); // Close success dialog
      setDHSMssge("Cannot submit the request"); // Set DHSM message
    }
  } else {
    // If no file is selected, show error message
    setLoading(false); // Set loading state to false
    setErrorDialog(true); // Open error dialog
    setSuccessDialog(false); // Close success dialog
    setDHSMssge("Please Select File to Upload"); // Set DHSM message
  }
}


  return (
    <>
       <LoadingDialog open={loading} />
          {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          }>OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h5" gutterBottom>
     File Upload
      </Typography>
      <Input type="file" accept=".xlsx, .xls" required onChange={handleFileChange} style={{ marginBottom: '20px',width:"500px"}} />
    {barcodeValue && <Barcode value={barcodeValue} />}
      <Button
        variant="contained"
        sx={{ width: '200px', height: '30px', bgcolor: indigo[800] }}
        color="primary"
        onClick={handleUpload}
      >
        Upload
      </Button>
      <Typography variant="body2" style={{ marginTop: '20px', textAlign: 'center' }}>
          <strong>NOTE: </strong><br></br>Once you upload this file, an email notification containing this file will be sent to our dedicated Aspetto's DHS-CBP Sales team at {' '}
          <text type='email' style={{color:'blue'}}><strong>DHS_orders@aspettoinc.com</strong></text>. We will start working on your order as soon as we receive the email.
        </Typography>
    </Paper>
    </>
  );
};

export default ExcelUploader;
