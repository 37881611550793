import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, sessionID: null, exp: 0, IsLoggedIn: false, isAdmin:'', userRole:''},
  reducers: {
    setCredentials: (state, action) => {
      const accessToken = action.payload;
      state.token = accessToken;
      state.user = jwtDecode(accessToken).userInfo;
      state.isAdmin = jwtDecode(accessToken).userInfo.isAdmin || false;
      state.userRole=jwtDecode(accessToken).userInfo.User_Role ||'USER-SALES'
    },
    logOut: (state, action) => {
      state.token = null;
      state.user = null;
      state.isAdmin = false;
    },
    setSessionStart: (state, action) => {
      const { exp } = jwtDecode(state.token);
      state.exp = exp;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;

export const selectCurrentUser = (state) => state.auth.user;

export const selectCurrentUserSessionExpiration = (state) => state.auth.exp;

export const selectCurrentUserSessionID = (state) => state.auth.sessionID;

export const selectCurrentUserIsLoggedIn = (state) => state.auth.IsLoggedIn;

export const selectIsAdmin = (state) => state.auth.isAdmin;

export const selectUserRole = (state) => state.auth.userRole;

export const selectIsTokenExpired = (state) => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
  return state.auth.exp < currentTimestamp;
};


