import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        fontWeight:"extrabold",
        flexGrow: 1,
    },
    description: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5
    },
    qty: {
        width: '7%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5
    },
    UM: {
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5
    },
    rate: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5
    },
    Sno: {
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:8.5,
        textAlign: 'center',
        borderLeftWidth: 1,
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        fontSize:8.5
    },
    SKU:{
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:8.5
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.Sno}>SNo</Text>
        <Text style={styles.SKU}>SKU#</Text>
        <Text style={styles.description}>Item Description</Text>
        <Text style={styles.qty}>Qty</Text>
        <Text style={styles.UM}>UM</Text>
        <Text style={styles.rate}>Unit Price</Text>
        <Text style={styles.amount}>Line Total</Text>
    </View>
);

export default InvoiceTableHeader;