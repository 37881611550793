import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, Alert, FormGroup, FormControlLabel, Checkbox, IconButton, MenuItem, Select, InputLabel, FormControl, Container, Grid, Divider, ListItemIcon } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import AxiosFunction from '../../axiosCustomInstance';
import LoadingDialog from '../Loading';
import UserAuth from '../ProtectedRoute/userAuth';
import ApplicationBar from '../AppBar/ApplicationBar';
import { blue, blueGrey, orange, red, yellow } from '@mui/material/colors';

const BugReport = () => {
  const { UserName, UserEmail } = UserAuth();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('');
  const [steps, setSteps] = useState('');
  const [expectedBehavior, setExpectedBehavior] = useState('');
  const [actualBehavior, setActualBehavior] = useState('');
  const [browser, setBrowser] = useState('');
  const [files, setFiles] = useState([]);
  const [attachFilesChecked, setAttachFilesChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const AxiosAPIInstance = AxiosFunction();
  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const removeAttachment = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('priority', priority);
    formData.append('steps', steps);
    formData.append('expectedBehavior', expectedBehavior);
    formData.append('actualBehavior', actualBehavior);
    formData.append('browser', browser);
    formData.append('createdBy', UserName);
    formData.append('To_Email',UserEmail)
    if (attachFilesChecked) {
      files.forEach((file) => formData.append('attachments', file));
    }

    // Logging FormData
    for (const pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const response = await AxiosAPIInstance.post('/Bugs/NewBug', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMessage('Bug reported successfully');
      setTitle('');
      setDescription('');
      setPriority('');
      setSteps('');
      setExpectedBehavior('');
      setActualBehavior('');
      setBrowser('');
      setFiles([]);
      setAttachFilesChecked(false);
    } catch (error) {
      setErrorMessage('Error reporting bug');
      console.error('Error reporting bug', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ApplicationBar />
      <Container maxWidth="lg">
        <Box sx={{ mt: 12 }}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: '#f9f9f9' }}>
            <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana, sans-serif' }} justifyContent="center">
              Report a Bug
            </Typography>
            <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
            {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Priority</InputLabel>
                    <Select
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      required
                      label='Priority'
                    >
                      <MenuItem value="Low">
                        <ListItemIcon>
                          <LowPriorityIcon sx={{ color: blue[700] }} />
                        </ListItemIcon>
                        Low
                      </MenuItem>
                      <MenuItem value="Medium">
                        <ListItemIcon>
                          <WarningIcon sx={{ color: yellow[700] }} />
                        </ListItemIcon>
                        Medium
                      </MenuItem>
                      <MenuItem value="High">
                        <ListItemIcon>
                          <PriorityHighIcon sx={{ color: orange[700] }} />
                        </ListItemIcon >
                        High
                      </MenuItem>
                      <MenuItem value="Critical">
                        <ListItemIcon>
                          <ErrorIcon sx={{ color: red[900] }} />
                        </ListItemIcon>
                        Critical
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Steps to Reproduce"
                    value={steps}
                    onChange={(e) => setSteps(e.target.value)}
                    margin="normal"
                    multiline
                    rows={3}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Expected Behavior"
                    value={expectedBehavior}
                    onChange={(e) => setExpectedBehavior(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Actual Behavior"
                    value={actualBehavior}
                    onChange={(e) => setActualBehavior(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Browser/Environment Details"
                    value={browser}
                    onChange={(e) => setBrowser(e.target.value)}
                    margin="normal"
                    multiline
                    rows={2}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormGroup>
                    <FormControlLabel
                      size="small"
                      control={
                        <Checkbox
                          size="small"
                          checked={attachFilesChecked}
                          onChange={(e) => setAttachFilesChecked(e.target.checked)}
                        />
                      }
                      label="Attach Files"
                    />
                  </FormGroup>
                  {attachFilesChecked && (
                    <>
                      <input
                        accept="image/*, .pdf, .doc, .docx"
                        style={{ display: 'none' }}
                        id="attachment-input"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                      />
                      <label
                        htmlFor="attachment-input"
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}
                      >
                        <AttachFileIcon />
                        Attach Files
                      </label>
                      <ul>
                        {files.map((file, index) => (
                          <li key={index}>
                            {file.name}
                            <IconButton onClick={() => removeAttachment(index)} style={{ marginLeft: '10px' }}>
                              <CloseIcon />
                            </IconButton>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="warning"
                disabled={loading}
                sx={{ mt: 2, width: '300px' }}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
      {loading && <LoadingDialog />}
    </>
  );
};

export default BugReport;
