import {React} from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        alignItems: 'center',
        fontStyle: 'bold',
        fontSize:9,
    },
    Contract_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Shipping_Method: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Tracking_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Ship_Date: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },

});

const Packing_Slip_Contract_Info_Row = ({ invoice }) => {
   
    return(
            <>
    <View style={styles.row}>
        <Text style={styles.Contract_Number}>{invoice.Contract_Number}</Text>
        <Text style={styles.Shipping_Method}>{invoice.Shipping_Method}</Text>
        <Text style={styles.Tracking_Number}>{invoice.Tracking_Number}</Text>
        <Text style={styles.Ship_Date}>{invoice.Ship_Date}</Text>
    </View>

     </>

    )
};

export default Packing_Slip_Contract_Info_Row;