// src/Pages/RFQ/RFQsSearch/TablePaginationActions.js

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

/**
 * Component for handling table pagination actions.
 * Provides buttons to navigate between pages in a table.
 *
 * @param {object} props - The props for the component.
 * @param {number} props.count - The total number of rows.
 * @param {number} props.page - The current page number.
 * @param {number} props.rowsPerPage - The number of rows per page.
 * @param {function} props.onPageChange - The function to call when the page is changed.
 */
function TablePaginationActions(props) {
  // Using the theme hook to access the theme's properties
  const theme = useTheme();
  
  // Destructuring props to use in the component
  const { count, page, rowsPerPage, onPageChange } = props;

  /**
   * Handle click event for the first page button.
   *
   * @param {object} event - The event object.
   */
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  /**
   * Handle click event for the back button.
   *
   * @param {object} event - The event object.
   */
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  /**
   * Handle click event for the next button.
   *
   * @param {object} event - The event object.
   */
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  /**
   * Handle click event for the last page button.
   *
   * @param {object} event - The event object.
   */
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {/* Button to go to the first page */}
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      {/* Button to go to the previous page */}
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      {/* Button to go to the next page */}
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {/* Button to go to the last page */}
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

// Prop types to enforce the type of props passed to this component
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired, // Total number of rows
  onPageChange: PropTypes.func.isRequired, // Function to call when the page changes
  page: PropTypes.number.isRequired, // Current page number
  rowsPerPage: PropTypes.number.isRequired, // Number of rows per page
};

export default TablePaginationActions;
