import React, { useState } from "react";
import { Page, Document, StyleSheet, Image, View ,Text} from "@react-pdf/renderer";
import InvoiceTitle from "./Heading";
import InvoiceNo from "./Number";
import InvoiceItemsTable from "./Items-Table";
import Packing_Slip_Contract_Info_Table from "./Packing_Slip_Contract_Info_Table";
import InvoiceThankYouMsg from "./Thank-You";
import Signature from "./Signature";
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 40,
        lineHeight: 1.5,
        flexDirection: 'column',
        
    },
    watermarkContainer: {
        position: 'absolute',
        top: '40%',
        left: '35%',
        width: '100%', 
        height: '100%', 
        transform: 'translate(-50%, -50%) rotate(-30deg)', 
        opacity: 0.3, 
      },
      watermark: {
        fontSize: 80,
        color: 'gray', 
        fontFamily: 'Times-Roman', 
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
      footer: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 8,
        color: 'gray',
      },
  
});
const Watermark = () => (
  <View style={styles.watermarkContainer}>
      <Text style={styles.watermark}>Packing Slip</Text>
  </View>
);

const Footer = () => (
  <View style={styles.footer}>
    <Text>ISO certified ISO 9001:2015</Text>
    <Text>Inc. 5000 America's Fastest Growing Private Companies</Text>
  </View>
);
const PackingSlipPdfDocument = ({ invoicedata}) => {

    const [pageNumber,setPageNumber]=useState(1)
    const [numberOfPages,setNumberOfPages]=useState(null)


    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape" wrap>
                {/* <Watermark/> */}
                <InvoiceTitle invoice={invoicedata} />
                <InvoiceNo invoice={invoicedata}  />
                <Packing_Slip_Contract_Info_Table invoice={invoicedata}></Packing_Slip_Contract_Info_Table>
                <InvoiceItemsTable invoice={invoicedata}  />
                <Signature invoice={invoicedata} />
                <Footer/>
                <InvoiceThankYouMsg/>
            </Page>
        </Document>
    );
}

export default PackingSlipPdfDocument;