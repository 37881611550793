import React, { useState, useEffect } from 'react';
import {
  Container, TextField, Button, Grid, Typography, Paper, IconButton,
  Card, CardContent, CardActions, Stack, Divider, Tooltip
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';
import ApplicationBar from '../AppBar/ApplicationBar';
import { blueGrey, orange, red, blue, green } from '@mui/material/colors';
import AxiosFunction from '../../axiosCustomInstance';
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin, selectUserRole } from "../Login_Registration/authSlice";

const RoleManagement = () => {
  // State variables
  const [roleName, setRoleName] = useState(''); // To store role name input
  const [description, setDescription] = useState(''); // To store role description input
  const [roles, setRoles] = useState([]); // To store the list of roles fetched from the server
  const [editingRole, setEditingRole] = useState(null); // To track if a role is being edited
  const [openDialog, setOpenDialog] = useState(false); // To control the visibility of the dialog
  const AxiosAPIInstance = AxiosFunction(); // Custom Axios instance
  const isAdmin = useSelector(selectIsAdmin); // To check if the user is an admin
  const userRoleDatabase = useSelector(selectUserRole); // To get the user's role from the store

  // Fetch roles and user role on component mount
  useEffect(() => {
    fetchRoles();
  }, []);

  // Function to fetch roles from the server
  const fetchRoles = async () => {
    try {
      const response = await AxiosAPIInstance.get('/Admin/roles'); // API call to fetch roles
      setRoles(response.data); // Update state with fetched roles
    } catch (error) {
      console.error('Error fetching roles:', error); // Log error if fetching fails
    }
  };

  // Function to handle adding or updating a role
  const handleAddOrUpdateRole = async () => {
    try {
      if (editingRole) {
        // Update role if editing
        await AxiosAPIInstance.put(`/Admin/roles/${editingRole.id}`, { RoleName: roleName, Description: description });
      } else {
        // Add new role if not editing
        await AxiosAPIInstance.post('/Admin/roles', { RoleName: roleName, Description: description });
      }
      fetchRoles(); // Refresh roles after update
      setRoleName(''); // Reset role name input
      setDescription(''); // Reset description input
      setEditingRole(null); // Clear editing state
    } catch (error) {
      console.error('Error adding/updating role:', error); // Log error if adding/updating fails
    }
  };

  // Function to handle editing a role
  const handleEdit = (role) => {
    // Prevent editing of "USER-SUPER-ADMIN" role by anyone except the super admin
    if (role.RoleName === 'USER-SUPER-ADMIN' && userRoleDatabase !== 'USER-SUPER-ADMIN') {
      alert('You are not allowed to edit the USER-SUPER-ADMIN role!');
      return;
    }
    setRoleName(role.RoleName); // Set role name input to the selected role's name
    setDescription(role.Description); // Set description input to the selected role's description
    setEditingRole(role); // Set editing state to the selected role
  };

  // Function to handle deleting a role (restricted to SUPER-ADMIN)
  const handleDelete = async (id) => {
    if (userRoleDatabase !== 'USER-SUPER-ADMIN') {
      alert('Only USER-SUPER-ADMIN can delete roles!'); // Alert if user is not SUPER-ADMIN
      return;
    }
    try {
      await AxiosAPIInstance.delete(`/Admin/roles/${id}`); // API call to delete role
      fetchRoles(); // Refresh roles after deletion
    } catch (error) {
      console.error('Error deleting role:', error); // Log error if deleting fails
    }
  };

  // Function to handle dialog closing
  const handleDialogClose = () => {
    setOpenDialog(false); // Close dialog
    setRoleName(''); // Reset role name input
    setDescription(''); // Reset description input
    setEditingRole(null); // Clear editing state
  };

  return (
    <>
      {/* Application Bar */}
      <ApplicationBar />
      
      {/* Main container */}
      <Container sx={{ marginTop: '80px' }}>
        <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{ alignContent: "center", fontSize: "25px", fontWeight: "bold", padding: 1 }}>
            Role Management
          </Typography>
        </Stack>
        
        {/* Divider */}
        <Stack justifyContent="center" direction="row">
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
        </Stack>

        {/* Input form for role name and description */}
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Role Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddOrUpdateRole}
                disabled={!roleName} // Disable button if role name is empty
              >
                {editingRole ? 'Update Role' : 'Add Role'} {/* Button text changes based on editing state */}
              </Button>
              {editingRole && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDialogClose}
                  style={{ marginLeft: '10px' }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>

        {/* List of roles displayed as cards */}
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          {roles.map((role) => (
            <Grid item xs={12} sm={6} md={4} key={role.id}>
              <Card sx={{border:1,borderBlockColor:blueGrey[900]}}>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: blueGrey[900],fontSize:'18px',marginBottom:'10px'}}>
                    {role.RoleName}
                  </Typography>
                  <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
                  <Typography variant="body2" color="textSecondary">
                    {role.Description}
                  </Typography>
                </CardContent>
                <CardActions>
                  {/* Edit button */}
                  <Tooltip title="Edit Role">
                    <span>
                      <IconButton 
                        onClick={() => handleEdit(role)} 
                        sx={{ color: green[600], fontSize: '17px', '&:hover': { color: green[800] } }}
                        disabled={role.RoleName === 'USER-SUPER-ADMIN' && userRoleDatabase !== 'USER-SUPER-ADMIN'} // Disable edit for USER-SUPER-ADMIN if not super admin
                      >
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>
                  
                  {/* Delete button (restricted to SUPER-ADMIN) */}
                  {userRoleDatabase === 'USER-SUPER-ADMIN' && (
                    <Tooltip title="Delete Role">
                      <IconButton 
                        onClick={() => handleDelete(role.id)} 
                        sx={{ color: red[600], fontSize: '17px', '&:hover': { color: red[800] } }}
                        disabled={role.RoleName === 'USER-SUPER-ADMIN'} // Disable delete for USER-SUPER-ADMIN
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default RoleManagement;
