import React, { useState } from 'react';
import {
  Stack,
  TextField,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  Avatar,
  Tooltip,
} from '@mui/material';
import { AddBox as AddBoxIcon, AttachMoney as AttachMoneyIcon, Percent as PercentIcon } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const renderVariants = (variants, setVariants, onDragEnd) => {
  const handleVariantChange = (index, field, value) => {
    const newVariants = [...variants];
    newVariants[index][field] = value;
    setVariants(newVariants);
  };

  const handleSKUChange = (variantIndex, skuIndex, field, value) => {
    const newVariants = [...variants];
    newVariants[variantIndex].skus[skuIndex][field] = value;
    setVariants(newVariants);
  };

  const addVariant = () => {
    const newVariants = [
      ...variants,
      {
        variantName: '',
        skus: [
          {
            Vendor_Partner_name: '',
            SKU_ID: '',
            SKU_Description: '',
            QTY: 0,
            Aspetto_Unit_Cost: 0.0,
            Margin_Percent: 0.0,
            Customer_Unit_Cost: 0.0,
            Total_SKU_Cost: 0.0,
          },
        ],
        shippingCost: 0.0,
        tax: 0.0,
        otherCosts: [{ name: '', amount: 0.0 }],
        totalCostBeforeMargin: 0.0,
        totalCostAfterMargin: 0.0,
      },
    ];
    setVariants(newVariants);
  };

  const addSKU = (variantIndex) => {
    const newVariants = [...variants];
    newVariants[variantIndex].skus.push({
      Vendor_Partner_name: '',
      SKU_ID: '',
      SKU_Description: '',
      QTY: 0,
      Aspetto_Unit_Cost: 0.0,
      Margin_Percent: 0.0,
      Customer_Unit_Cost: 0.0,
      Total_SKU_Cost: 0.0,
    });
    setVariants(newVariants);
  };

  return (
    <>
      {variants.map((variant, variantIndex) => (
        <Stack key={variantIndex} direction="column" spacing={2}>
          <TextField
            label="Variant Name"
            value={variant.variantName}
            onChange={(e) => handleVariantChange(variantIndex, 'variantName', e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: '250px', marginBottom: '20px' }}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`variant-${variantIndex}`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {variant.skus.map((sku, skuIndex) => (
                    <Draggable key={skuIndex} draggableId={`${variantIndex}-${skuIndex}`} index={skuIndex}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <Stack direction="row" spacing={1} justifyContent="center">
                            <TextField
                              size="small"
                              required
                              variant="outlined"
                              name="Vendor_Partner_name"
                              sx={{ width: '150px' }}
                              label="Vendor's Partner Name"
                              value={sku.Vendor_Partner_name}
                              onChange={(e) =>
                                handleSKUChange(variantIndex, skuIndex, 'Vendor_Partner_name', e.target.value)
                              }
                            />
                            <TextField
                              size="small"
                              required
                              variant="outlined"
                              name="SKU_ID"
                              sx={{ width: '150px' }}
                              label="SKU ID"
                              value={sku.SKU_ID}
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'SKU_ID', e.target.value)}
                            />
                            <TextField
                              size="small"
                              required
                              sx={{ width: '250px' }}
                              name="SKU_Description"
                              label="SKU Description"
                              multiline
                              rowsMax={20}
                              value={sku.SKU_Description}
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'SKU_Description', e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PercentIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              size="small"
                              name="QTY"
                              required
                              variant="outlined"
                              sx={{ width: '100px' }}
                              label="QTY"
                              value={String(sku.QTY).trim()}
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'QTY', e.target.value)}
                              inputProps={{ min: '0', step: 'any', inputMode: 'numeric' }}
                            />
                            <TextField
                              size="small"
                              required
                              variant="outlined"
                              name="Unit_Of_Measure"
                              sx={{ width: '70px' }}
                              label="UM"
                              value={String(sku.Unit_Of_Measure).trim()}
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'Unit_Of_Measure', e.target.value)}
                            />
                            <TextField
                              size="small"
                              name="Aspetto_Unit_Cost"
                              required
                              sx={{ width: '130px' }}
                              inputProps={{ min: '0', step: 'any', inputMode: 'numeric' }}
                              label="Aspetto's Cost"
                              value={
                                sku.Aspetto_Unit_Cost === 'NaN' ? parseFloat(0.0) : String(sku.Aspetto_Unit_Cost).trim()
                              }
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'Aspetto_Unit_Cost', e.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              size="small"
                              required
                              variant="outlined"
                              name="Margin_Percent"
                              sx={{ width: '110px' }}
                              label="Margin"
                              value={String(sku.Margin_Percent).trim()}
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'Margin_Percent', e.target.value)}
                              inputProps={{ min: '0', step: 'any', inputMode: 'numeric' }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <PercentIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              size="small"
                              name="Customer_Unit_Cost"
                              required
                              sx={{ width: '130px' }}
                              label="Customer's Cost"
                              value={
                                sku.Customer_Unit_Cost === 'NaN'
                                  ? parseFloat(0.0)
                                  : String(sku.Customer_Unit_Cost).trim()
                              }
                              onChange={(e) => handleSKUChange(variantIndex, skuIndex, 'Customer_Unit_Cost', e.target.value)}
                              inputProps={{ min: '0', step: 'any', inputMode: 'numeric' }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              size="small"
                              name="Total_SKU_Cost"
                              required
                              sx={{ width: '180px' }}
                              label="Total SKU Cost"
                              value={
                                sku.Total_SKU_Cost === 'NaN' ? parseFloat(0.0) : String(sku.Total_SKU_Cost).trim()
                              }
                              inputProps={{ min: '0', step: 'any', inputMode: 'numeric' }}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Stack>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              size="small"
              onClick={() => addSKU(variantIndex)}
              sx={{
                bgcolor: '#FFA500',
                '&:hover': {
                  bgcolor: '#FF8C00',
                },
              }}
            >
              Add SKU
            </Button>
          </Stack>

          {/* Shipping, Tax, Other Costs, and Total Costs */}
          <Stack direction="column" spacing={2}>
            <TextField
              size="small"
              variant="outlined"
              label="Shipping Cost"
              value={variant.shippingCost}
              onChange={(e) => handleVariantChange(variantIndex, 'shippingCost', e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '250px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Tax"
              value={variant.tax}
              onChange={(e) => handleVariantChange(variantIndex, 'tax', e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '250px' }}
            />
            {variant.otherCosts.map((cost, costIndex) => (
              <Stack key={costIndex} direction="row" spacing={1}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={`Other Cost ${costIndex + 1} Name`}
                  value={cost.name}
                  onChange={(e) =>
                    handleVariantChange(variantIndex, 'otherCosts', [
                      ...variant.otherCosts.slice(0, costIndex),
                      { ...cost, name: e.target.value },
                      ...variant.otherCosts.slice(costIndex + 1),
                    ])
                  }
                  sx={{ width: '250px' }}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  label={`Other Cost ${costIndex + 1} Amount`}
                  value={cost.amount}
                  onChange={(e) =>
                    handleVariantChange(variantIndex, 'otherCosts', [
                      ...variant.otherCosts.slice(0, costIndex),
                      { ...cost, amount: e.target.value },
                      ...variant.otherCosts.slice(costIndex + 1),
                    ])
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '250px' }}
                />
              </Stack>
            ))}
            <TextField
              size="small"
              variant="outlined"
              label="Total Cost Before Margin"
              value={variant.totalCostBeforeMargin}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '250px' }}
            />
            <TextField
              size="small"
              variant="outlined"
              label="Total Cost After Margin"
              value={variant.totalCostAfterMargin}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '250px' }}
            />
          </Stack>
        </Stack>
      ))}

      <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          size="small"
          onClick={addVariant}
          sx={{
            bgcolor: '#32CD32',
            '&:hover': {
              bgcolor: '#2E8B57',
            },
          }}
        >
          Add Variant
        </Button>
      </Stack>
    </>
  );
};

export default renderVariants;
