import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { DatePicker } from "@mui/x-date-pickers";
import DescriptionIcon from '@mui/icons-material/Description';
import { orange,indigo,green,yellow,red, blueGrey,grey,teal,deepOrange,deepPurple, blue} from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import AddTaskIcon from '@mui/icons-material/AddTask';
import userAuth from "../ProtectedRoute/userAuth"
import SaveIcon from '@mui/icons-material/Save';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { Link,createSearchParams} from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import LoadingDialog from "../Loading";

function Update_RMA()
{

const PD= new Date()
const AxiosAPIInstance=AxiosFunction()
const {
  UserName,
  UserEmail
} = userAuth()
  const [Form_Value,Set_Form_Value]=useState({Agency_Name:"",RMA_ID:"",Detailed_Description:"",Initial_Cause:"",Purchase_Order:"",
  Customer_Name:"",Completed_By_Name:UserName,Completed_By_Email:UserEmail,Customer_Email:"",Completed_By_Phone:"540-547-8487",Customer_Phone:"",Customer_Address:"",Shipping_To_Address:"",Return_Date:null,Reported_Date:null,
  Tracking_Number:"",
  Product_Service:"",
  Resolution_Stage:"RMA Created",
  Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  Updated_By:UserEmail,
  Version_No:parseInt(0),
  ReOpened_Rev:parseInt(0),
  ReOpened_Flag:"",
  Updating_Comments:"RMA Created"})
  
  const [dis,setDis]=useState(false)
  const navigate=useNavigate()
  const[rfiMssge,setRFIMssge]=useState('')
  const [successDialog,setSuccessDialog]=useState(false)
  const [errorDialog,setErrorDialog]=useState(false)
  const [open, setOpen] = useState(false);
  const [edit,setEdit]=useState(true)
  const [allFieldsActivate,setAllFieldsActivate]=useState(false)
  const [searchParams]=useSearchParams();
  const [loading, setLoading] = useState(false);

  React.useEffect(()=>{
    setLoading(true)
    async function fetchData(){
      await AxiosAPIInstance.get("/RMAs/GetRMAByIDForRMAUpdate",{params:{RMA_ID:String(searchParams.get("RMAID")).trim().toUpperCase()}}).then(async(result)=>{
        setLoading(false)
        Set_Form_Value(result?.data)
      })
    }
    fetchData();
    },[searchParams])
  
  const handleClose = (e) => {
    setOpen(false);
    setErrorDialog(false)
    setSuccessDialog(false)
  };
  const handleEdit=()=>{
    setDis(true)
    setEdit(false)
    setAllFieldsActivate(true)
  }
  const HandleSubmit = async(event) => {
    setDis(false)
    setEdit(false)
    setLoading(true)
      event.preventDefault()
      if(searchParams.get("ResolutionStage")==="Closed")
      {
        Set_Form_Value({...Form_Value,ReOpened_Flag:"RMA Reopened",Resolution_Stage:"RMA Reopened"})
      }
      if(Form_Value.Agency_Name!=="" && Form_Value.Detailed_Description!==""&& Form_Value.Initial_Cause!==""&&Form_Value.Purchase_Order!==""&&
      Form_Value.Customer_Name!==""&& Form_Value.Customer_Phone!=="" &&Form_Value.Customer_Address!==""&&Form_Value.Shipping_To_Address!==""&&
      Form_Value.Return_Date!==""&&Form_Value.Reported_Date!==""&&
      Form_Value.Tracking_Number!==""&&
      Form_Value.Product_Service!=="")
      {
      await AxiosAPIInstance.patch("/RMAs/UpdateRMA",Form_Value).then(
          async(response)=>{
            setLoading(false)
            setSuccessDialog(true)
            setErrorDialog(false)
            setRFIMssge(response?.data)
            setDis(false)
            setEdit(true)
            setAllFieldsActivate(false)
          }
      ).catch((error)=>{
        setLoading(false)
        setSuccessDialog(false)
        setErrorDialog(true)
        setEdit(false)
        setAllFieldsActivate(true)
        setRFIMssge(error.response?.data)
        setDis(true)
      })
    }
      else
      {
        setLoading(false)
        setAllFieldsActivate(true)
        setDis(true)
        setEdit(false)
        setErrorDialog(true)
        setRFIMssge("All Fields are Required Fields. Enter NA only where it is applicable")
      }
      setLoading(false)
  }
  
      return(
          <>
          <LoadingDialog open={loading} />
           {successDialog===true&&(<Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rfiMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e)=>{
            handleClose();
            e.preventDefault();
            setDis(false)
            setEdit(true)
            setAllFieldsActivate(false)
            }
            } href="/Dashboard/NewRMA">OK</Button>
          </DialogActions>
        </Dialog>)}
        {errorDialog===true&&(<Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rfiMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes"  onClick={(e)=>{
            handleClose();
            setEdit(false)
            setAllFieldsActivate(true)
            e.preventDefault();
            setDis(true);}} href="/Dashboard/NewRMA">OK</Button>
          </DialogActions>
        </Dialog>)}
          <ApplicationBar></ApplicationBar>
          <Stack>
          <Stack direction="column" spacing={1} sx={{marginTop:"60px"}} justifyContent='center'>
          <Stack direction="row" justifyContent="center" sx={{}}>
          <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold",padding:1,}} > Return Merchandise Authorization(RMA) Update:</Typography>
        </Stack>
        <Stack justifyContent="center" direction="row">
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField size="small"  variant="standard" disabled={!allFieldsActivate} sx={{width:"300px"}} required label="Organization/Agency's Name" value={Form_Value.Agency_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Agency_Name:e.target.value})} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            ),
          }}></TextField>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"300px"}} label="Customer's Name" value={Form_Value.Customer_Name} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Customer_Name:e.target.value})}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}></TextField>
                 <TextField size="small" required variant="standard" disabled={!allFieldsActivate} type="email" sx={{width:"300px"}} label="Customer's Email" value={Form_Value.Customer_Email} 
           onChange={(e)=>
            {
              Set_Form_Value({...Form_Value,Customer_Email:e.target.value})
            }
            } InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}>
          </TextField>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField   size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"300px"}} multiline={true} rowsMax={20} label="Purchase Order" value={Form_Value.Purchase_Order}  onChange={(e)=>Set_Form_Value({...Form_Value,Purchase_Order:e.target.value})}  InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <DescriptionIcon />
                     </InputAdornment>
                   ),
                 }}></TextField>
                    <TextField size="small" variant="standard" disabled={!allFieldsActivate} required sx={{width:"300px"}} label="Customer'sPhone" value={Form_Value.Customer_Phone} onChange={(e)=>{ Set_Form_Value({...Form_Value,Customer_Phone:e.target.value})
               }
            } InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ContactPhoneIcon />
              </InputAdornment>
            ),
          }}></TextField>
                  <TextField size="small" required variant="standard" disabled={!allFieldsActivate} multiline={true} rowsMax={10} sx={{width:"300px"}} label="Customer's Address" value={Form_Value.Customer_Address} 
                   onChange={(e)=>
                     {
                       Set_Form_Value({...Form_Value,Customer_Address:e.target.value})
                     }
                    } InputProps={{
                   startAdornment: (
                  <InputAdornment position="start">
                <ContactMailIcon />
              </InputAdornment>
                 ),
                }}>
                </TextField>
             
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField size="small" required variant="standard" disabled={!allFieldsActivate} multiline={true} rowsMax={10} sx={{width:"300px"}} label="Shipping Address" value={Form_Value.Shipping_To_Address} 
                   onChange={(e)=>
                     {
                       Set_Form_Value({...Form_Value,Shipping_To_Address:e.target.value})
                     }
                    } InputProps={{
                   startAdornment: (
                  <InputAdornment position="start">
                <ContactMailIcon />
              </InputAdornment>
                 ),
                }}>
                </TextField>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"300px"}} label="Product/Service" value={Form_Value.Product_Service} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Product_Service:e.target.value})}></TextField>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"300px"}} multiline={true} rowsMax={20} label="Initial Cause" value={Form_Value.Initial_Cause} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Initial_Cause:e.target.value})}></TextField>
                 
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField  size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"900px"}} label="Detailed Problem Description" multiline={true} rowsMax={20} value={Form_Value.Detailed_Description} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Detailed_Description:e.target.value})}
                 InputProps={{startAdornment:(<InputAdornment position="start">
                  <DescriptionIcon />
                 </InputAdornment>)}}>
                 </TextField>
                 </Stack>
                 <Stack  direction="row" spacing={3} justifyContent='center'>
                 <TextField  disabled={!allFieldsActivate}  size="small" variant="standard"sx={{width:"300px"}} multiline={true} rowsMax={20} label="Tracking Number"  value={Form_Value.Tracking_Number}  onChange={(e)=>Set_Form_Value({...Form_Value,Tracking_Number:e.target.value})}></TextField>
                 <DatePicker disabled={!allFieldsActivate} label="Return Date" sx={{width:"300px"}} value={Form_Value.Return_Date}  renderInput={(params)=><TextField disabled={!allFieldsActivate} size="small" variant="standard"  sx={{width:"300px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Return_Date:value})}>
          </DatePicker>
          <DatePicker disabled={!allFieldsActivate} label="Reported Date" sx={{width:"300px"}} value={Form_Value.Reported_Date}  renderInput={(params)=><TextField disabled={!allFieldsActivate} size="small" variant="standard" required  sx={{width:"300px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Reported_Date:value})}>
          </DatePicker>
  
                 </Stack>
                 <Stack direction="row" sx={{}} justifyContent="center">
          <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"20px", fontWeight:"bold",padding:1}}> Completed By:</Typography>
        </Stack>
        <Stack justifyContent="center" direction="row">
        <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"60%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
                 <Stack direction="row" spacing={3} justifyContent='center'>
                 <TextField size="small" variant="standard" sx={{width:"300px"}} label="Name" value={UserName} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Name:UserName})}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddTaskIcon/>
                    </InputAdornment>
                  ),readOnly:"true"
                }}></TextField>
                 <TextField size="small" variant="standard" sx={{width:"300px"}} label="Email" value={UserEmail} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Email:UserEmail})}
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddTaskIcon/>
                    </InputAdornment>
                  ),readOnly:"true"
                }}></TextField>
                 <DatePicker readOnly label="Completed Date" sx={{width:"300px"}} value={Form_Value.Created_Date}  renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
          <DatePicker readOnly label="Updated Date" sx={{width:"300px"}} value={Form_Value.Last_Updated_Date}  renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
                 </Stack>
                 <Stack direction="row" spacing={2} justifyContent='center'>
                 <Tooltip title="Edit">
                 <Button variant='contained' color="warning" size='medium' sx={{width:"100px"}} disabled={!edit} onClick={handleEdit} startIcon={<ModeEditIcon/>}>Edit</Button>
                </Tooltip>
                <Tooltip title="Save">
                <Button variant='contained' color="success" size='medium' sx={{width:"100px"}} disabled={!dis} onClick={HandleSubmit}  startIcon={<SaveIcon/>}> Save</Button>
                </Tooltip>
                <Tooltip title="Next Step">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/RMAInvestigation",
                      search:createSearchParams({RMAID:Form_Value.RMA_ID}).toString()
                    })}}
                    sx={{ mt: 1, mr: 1,bgcolor:red[700],width:"300px"}}
                    startIcon={<NextPlanIcon/>}>
                   INVESTIGATION
                  </Button>
                  </Tooltip>
                 </Stack>
                 <Stack direction="row">
 
                </Stack>
                 </Stack >
    
                 </Stack> 
                 
         </>
  );
}

export default Update_RMA