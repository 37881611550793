import {
    Box,
    Button,
    TextField,
    Paper,
    Stack,
    Typography,
    IconButton,
    Avatar,
    Grid,
    Tooltip
  } from '@mui/material';
  import EmailIcon from '@mui/icons-material/Email';
  import InputAdornment from '@mui/material/InputAdornment';
  import ContactMailIcon from '@mui/icons-material/ContactMail';
  import {
    useState
  } from "react";
  import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
  import PersonIcon from '@mui/icons-material/Person';
  import {
    PDFDownloadLink,
    PDFViewer
  } from '@react-pdf/renderer';
  import {
    DatePicker
  } from "@mui/x-date-pickers";
  import PdfDocument from "../PDF/Main";
  import DescriptionIcon from '@mui/icons-material/Description';
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  import PercentIcon from '@mui/icons-material/Percent';
  import * as React from 'react';
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import Select from '@mui/material/Select';
  import LocalShippingIcon from '@mui/icons-material/LocalShipping';
  import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
  import BusinessIcon from '@mui/icons-material/Business';
  import FormGroup from '@mui/material/FormGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import Checkbox from '@mui/material/Checkbox';
  import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogContentText from '@mui/material/DialogContentText';
  import DialogTitle from '@mui/material/DialogTitle';
  import {
    useNavigate
  } from 'react-router-dom';
  import {
    Alert
  } from '@mui/material';
  import ApplicationBar from "../AppBar/ApplicationBar";
  import userAuth from "../ProtectedRoute/userAuth"
  import {
    orange,
    indigo,
    green,
    red
  } from '@mui/material/colors';
  import {
    useSearchParams
  } from 'react-router-dom';
  import AddBoxIcon from '@mui/icons-material/AddBox';
  import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
  import {
    color
  } from '@mui/system';
  import LockIcon from '@mui/icons-material/Lock';
  import AxiosFunction from "../../axiosCustomInstance"

function PageUnderDev()
{


    return(<>
    <ApplicationBar></ApplicationBar>
    <Stack sx={{marginTop:"160px",alignContent:"center",textAlign:"center"}}>
        <Typography variant='h3'> Sorry! This Page is under development.</Typography>

    </Stack>
    
    </>)
}
export default PageUnderDev