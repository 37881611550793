import React from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, Box, Typography,styled} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import Loading from '../Pages/Images/Loading.png'
import { blueGrey, deepOrange, orange } from '@mui/material/colors';


const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Set the background color to transparent black
}));


const LoadingDialog = ({ open }) => {
  return (
    <Dialog open={open} disableBackdropClick disableEscapeKeyDown  maxWidth="xs" maxHeight="xs" sx={{backgroundColor: 'rgba(255, 255, 255, 0.7)',}}>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
          height: '100px', 
          width:"100px",
          borderRadius:'80px'
        }}
      >
        <Box sx={{ position: 'relative', textAlign: 'center' }}>
  
          <img src={Loading} alt="Company Logo" height="80px" width="80px" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',borderRadius:'80px' }} />

          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '50px', 
              height: '50px',
  
            }}
          >
            <CircularProgress
              size={100}
              thickness={3}
              style={{
                position: 'absolute',
                color:orange[700],
                top: -25,
                left: -25,
                transform: `rotate(${open ? '360deg' : '0deg'})`, 
                transition: 'transform 2s linear',
              }}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;