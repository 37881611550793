import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Paper, Grid, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

const RevenueGoalMetrics = ({ totalData }) => {
  if (totalData.length === 0) {
    return (
      <Typography sx={{ textAlign: 'center', color: 'gray', marginTop: '20px' }}>
        No goals set for the selected year and division.
      </Typography>
    );
  }

  const data = totalData[0]; // Assuming only one set of goals is fetched for the selected year and division

  console.log("Data for Charts:", data); // Debugging

  // Bar Chart Data for General Revenue Goals
  const barChartData = {
    labels: ['New Business', 'Renewal', 'Upsell', 'Cross-Sell'],
    datasets: [
      {
        label: 'Revenue Goals ($)',
        data: [
          parseFloat(data.NewBusinessGoal), 
          parseFloat(data.RenewalGoal), 
          parseFloat(data.UpsellGoal), 
          parseFloat(data.CrossSellGoal)
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        borderWidth: 1,
      },
    ],
  };

  // Bar Chart Data for Contract Type-Specific Revenue Goals
  const contractTypeBarChartData = {
    labels: [
      'Federal', 'Military', 'Tactical', 'GSA', 'FBOP', 'DHS-CBP', 
      'SAM/Open Market', 'Unison', 'Direct Inquiry', 'Commercial'
    ],
    datasets: [
      {
        label: 'Revenue Goals ($)',
        data: [
          parseFloat(data.FederalRevenueGoal),
          parseFloat(data.MilitaryRevenueGoal),
          parseFloat(data.TacticalRevenueGoal),
          parseFloat(data.GSARevenueGoal),
          parseFloat(data.FBOPRevenueGoal),
          parseFloat(data.DHS_CBPRevenueGoal),
          parseFloat(data.SAM_OpenMarketRevenueGoal),
          parseFloat(data.UnisonRevenueGoal),
          parseFloat(data.DirectInquiryRevenueGoal),
          parseFloat(data.CommercialRevenueGoal),
        ],
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#FF6384'
        ],
        borderWidth: 1,
      },
    ],
  };

  // Pie Chart Data for General Revenue Goals
  const pieChartData = {
    labels: ['New Business', 'Renewal', 'Upsell', 'Cross-Sell'],
    datasets: [
      {
        label: 'Revenue Distribution',
        data: [
          parseFloat(data.NewBusinessGoal),
          parseFloat(data.RenewalGoal),
          parseFloat(data.UpsellGoal),
          parseFloat(data.CrossSellGoal),
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverOffset: 4,
      },
    ],
  };

  // Pie Chart Data for Contract Type-Specific Revenue Goals
  const contractTypePieChartData = {
    labels: [
      'Federal', 'Military', 'Tactical', 'GSA', 'FBOP', 'DHS-CBP', 
      'SAM/Open Market', 'Unison', 'Direct Inquiry', 'Commercial'
    ],
    datasets: [
      {
        label: 'Contract Type Distribution',
        data: [
          parseFloat(data.FederalRevenueGoal),
          parseFloat(data.MilitaryRevenueGoal),
          parseFloat(data.TacticalRevenueGoal),
          parseFloat(data.GSARevenueGoal),
          parseFloat(data.FBOPRevenueGoal),
          parseFloat(data.DHS_CBPRevenueGoal),
          parseFloat(data.SAM_OpenMarketRevenueGoal),
          parseFloat(data.UnisonRevenueGoal),
          parseFloat(data.DirectInquiryRevenueGoal),
          parseFloat(data.CommercialRevenueGoal),
        ],
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
          '#FF9F40', '#FFCD56', '#4BC0C0', '#36A2EB', '#FF6384'
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Grid container spacing={4} sx={{ marginTop: '20px' }}>
      {/* Bar Chart for General Revenue Goals */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            General Revenue Goals Breakdown
          </Typography>
          <Bar data={barChartData} options={{
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    return `$${value.toLocaleString()}`; // Format Y-axis values
                  }
                }
              }
            }
          }} />
        </Paper>
      </Grid>

      {/* Bar Chart for Contract Type Revenue Goals */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            Contract Type Revenue Goals Breakdown
          </Typography>
          <Bar data={contractTypeBarChartData} options={{
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    return `$${value.toLocaleString()}`; // Format Y-axis values
                  }
                }
              }
            }
          }} />
        </Paper>
      </Grid>

      {/* Pie Chart for General Revenue Distribution */}
      <Grid item xs={12} md={5}>
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            General Revenue Distribution
          </Typography>
          <Pie data={pieChartData} />
        </Paper>
      </Grid>

      {/* Pie Chart for Contract Type Distribution */}
      <Grid item xs={12} md={5}>
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            Contract Type Revenue Distribution
          </Typography>
          <Pie data={contractTypePieChartData} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RevenueGoalMetrics;
