import {React} from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { style } from '@mui/system';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

const borderColor = theme.palette.primary.main
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        fontWeight:"extrabold",
        flexGrow: 1,
    },
    Sales_Head: {
        width: '37.5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 8.5,
    },
    Shipping_Method: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 8.5,
    },
    Shipping_Terms: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 8.5,
    },
    Estimated_Delivery: {
        width: '22.5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 8.5,
    },
    Payment_Terms: {
        width: '15%',
        fontSize: 8.5,
    },
});

const ShippingHeader = () => (
    <View style={styles.container}>
        <Text style={styles.Sales_Head}>Sales Head</Text>
        <Text style={styles.Shipping_Method}>Shipping Method</Text>
        <Text style={styles.Shipping_Terms}>Shipping Terms</Text>
        <Text style={styles.Estimated_Delivery}>Estimated Delivery</Text>
        <Text style={styles.Payment_Terms}>Payment Terms</Text>
    </View>
);

export default ShippingHeader;