import {React} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import ShippingHeader from './Shipping_Terms_Header';
import ShippingTableRow from './Shipping_Terms_Table_Row';
import { blueGrey } from '@mui/material/colors';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderColor: blueGrey[900],
        padding:0,
        borderBottomWidth:1,
        borderRightWidth:1,
        borderLeftWidth:1
    },
});

const ShippingItemsTable = ({ invoice }) => (
    <View style={styles.tableContainer}>
        <ShippingHeader />
        <ShippingTableRow Shipping_Details={invoice.Shipping_Details} />
    </View>
);

export default ShippingItemsTable;