import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[100],
        },
    },
  });
const borderColor = 'black'
const styles = StyleSheet.create({
    row1: {
        flexDirection: 'row',
        width: '50%',
        borderBottomColor: 'black',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
        fontStyle: 'bold',

    },
    row2: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        width: '50%',
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
        fontStyle: 'bold',

    },
    row3: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        width: '50%',
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
        fontStyle: 'bold',

    },
    row4: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        width: '50%',
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
        fontStyle: 'bold',

    },
    Label: {
        width: '25%',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor:blueGrey[100],
        textAlign:'right'
    },
    description: {
        width: '25%',
        borderRightColor: borderColor,
        textAlign:'left'
    },

});
const Signature = ({ invoice }) => {

    return (
        <>
                <View style={styles.row1}>
                    <Text style={styles.Label}>Received By: </Text>
                    {/* <Text style={styles.total}>{invoice.Received_By}</Text> */}
                </View>
                    <View style={styles.row2}>
                    <Text style={styles.Label}>Date: </Text>
                    {/* <Text style={styles.total}>{invoice.Signature_Date}</Text> */}
                </View>
                    <View style={styles.row3}>
                    <Text style={styles.Label}>Signature: </Text>
                    {/* <Text style={styles.total}>{invoice.Signature}</Text> */}
                </View>
                <View style={styles.row4}>
                <Text style={styles.Label}>Print Date: </Text>
                <Text style={styles.description} >{String(invoice.Print_Date)}</Text>
               </View >
        </>
    );
};

export default Signature;