import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  TableBody,Paper,styled 
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState} from "react";
import {Avatar, Button,Tooltip} from '@mui/material'
import { blueGrey, cyan, deepOrange, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { Link,createSearchParams,useNavigate} from 'react-router-dom';
import { orange,indigo,yellow,green,blue,teal } from '@mui/material/colors';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';    
import CloseIcon from '@mui/icons-material/Close'; 
import PhoneIcon from '@mui/icons-material/Phone'; 
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TodayIcon from '@mui/icons-material/Today';
import EmailIcon from '@mui/icons-material/Email';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import { Stack } from "@mui/system";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import FindInPageIcon from '@mui/icons-material/FindInPage';
// const steps = [
//   'RMA Created',
//   'Investigation',
//   'Resolution',
//   'Follow Up',
//   'Closed',
// ];
// const steps2 = [
//   'RMA Re-Opened',
//   'Investigation',
//   'Resolution',
//   'Follow Up',
//   'Closed',
// ];

// const stepIcons = {
//   'RMA Created': <AddCircleOutlineIcon sx={{ color: blueGrey[500] }} />,
//   'Investigation': <SearchIcon sx={{ color: blueGrey[500] }} />,
//   'Resolution': <CheckCircleOutlineIcon sx={{ color: blueGrey[500] }} />,
//   'Follow Up': <UpdateIcon sx={{ color: blueGrey[500] }} />,
//   'Closed': <DoneAllIcon sx={{ color: blueGrey[500] }} />,
// };

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const color1=blueGrey[500]
const color2=blueGrey[50]

function RMARow(props) {
    const PD= new Date()
    const today=new Intl.DateTimeFormat('en-US',{dateStyle:'medium'}).format(PD)
    const { row} = props;
    const [activeStepColor,setActiveStepColor]=useState(row?.Resolution_Stage)
    const [areYouSureDialog,setAreYouSureDialog]=useState(false)

    const navigate=useNavigate();

    const RMAID_Search=async(RMARow)=>{
      if(RMARow?.Resolution_Stage==="RMA Created" || RMARow?.Resolution_Stage==="Closed" || RMARow?.ReOpened_Flag==="RMA Reopened" )
      {
        navigate({
        pathname:"/Dashboard/EditRMA",
        search:createSearchParams({RMAID:RMARow?.RMA_ID}).toString()
      });
      }

      else if(String(RMARow?.Resolution_Stage).trim()==="Investigation"|| String(RMARow?.ReOpened_Flag).trim()==="Investigation")
      {
          navigate({
          pathname:"/Dashboard/RMAInvestigation",
          search:createSearchParams({RMAID:RMARow?.RMA_ID}).toString()
        });
      }

      else if(String(RMARow?.Resolution_Stage).trim()==="Resolution"|| String(RMARow?.ReOpened_Flag).trim()==="Resolution")
      {
        navigate({
        pathname:"/Dashboard/RMAResolution",
        search:createSearchParams({RMAID:RMARow?.RMA_ID}).toString()
      });
      }

      else if(String(RMARow?.Resolution_Stage).trim()==="Follow Up"|| String(RMARow?.ReOpened_Flag).trim()==="Follow Up")
      {
      navigate({
      pathname:"/Dashboard/RMACloseOut",
      search:createSearchParams({RMAID:RMARow?.RMA_ID}).toString()
      });
      }

    };

  const handleAreYouSureDialogClose=(e)=>{
   e.preventDefault();
   setAreYouSureDialog(false)
  }
  const steps = ['RMA Created', 'Investigation', 'Resolution', 'Follow Up', 'Closed'];
  const steps2 = ['RMA Re-Opened', 'Investigation', 'Resolution', 'Follow Up', 'Closed'];
  
  const stages = {
    rmacreated: { label: 'RMA Created', color: blue[700], icon: <DescriptionIcon  /> },
    investigation: { label: 'Investigation', color: orange[700], icon: <FindInPageIcon /> },
    resolution: { label: 'Resolution', color: green[700], icon: <CheckCircleOutlineIcon /> },
    followup: { label: 'Follow Up', color: blue[700], icon: <FollowTheSignsIcon /> },
    closed: { label: 'Closed', color: red[700], icon: <CheckCircleIcon /> },
    rmareopened: { label: 'RMA Re-Opened', color: orange[700], icon: <AddCircleOutlineIcon /> },
  };
  
  const CustomStepIcon = styled('div')(({ theme, active, completed }) => ({
    backgroundColor: active || completed ? orange[300] : 'rgba(0, 0, 0, 0.26)',
    color: blueGrey[900],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 24,
    height: 24,
    fontSize: 12,
    padding: 6,
  }));
  
  const renderStepper = (steps, activeStep) => (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => {
        const stageKey = label.toLowerCase().replace(' ', '');
        const stage = stages[stageKey];
  
        // Debugging output
        console.log('Label:', label, 'StageKey:', stageKey, 'Stage:', stage);
  
        return (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon {...props}>
                  {stage ? stage.icon : <div>?</div>} {/* Render the icon or fallback */}
                </CustomStepIcon>
              )}
              sx={{
                color: 'white',
                padding: '10px',
                height: "30px",
                borderRadius: '5px',
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
    return (
      <React.Fragment>
            {areYouSureDialog===true&&(<Dialog
        open={areYouSureDialog}
        onClose={handleAreYouSureDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='warning'> Are You Sure, You want to Re-Open?</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          <b>Things to Remember:</b><br/>1. Your Revision Number will be updated<br/>
          2.Once Submited You Can only Access it from the Retrieve/Update RMAs Tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="YES" type='submit' onClick={async(e)=>{ RMAID_Search(row)}} >YES</Button>
          <Button name="NO" onClick={(e)=>{e.preventDefault();setAreYouSureDialog(false)}}>
           NO
          </Button>
        </DialogActions>
      </Dialog>)}
        <TableRow>
 
          <TableCell sx={{}}align="center">{row?.RMA_ID}</TableCell>
          <TableCell sx={{}}align="left">

  <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1,fontSize:'12.5px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', ffontSize:'12.5px',alignContent:'center'}}><ApartmentIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Agency Name</Typography>

      <Typography variant="body1" sx={{ fontSize:'12.5px',fontWeight:'bold',marginLeft:3.5,color:blue[900]}}>{row?.Agency_Name}</Typography>
    </div>
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px'}}><CategoryIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Product/Service</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Product_Service}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
    <div style={{ width: '100%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><DescriptionIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Detailed Description</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Detailed_Description}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Name</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Name}</Typography>
    </div>
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px'}}><AlternateEmailIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Email</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Email}</Typography>
    </div>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PhoneIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Phone</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Customer_Phone}</Typography>
    </div>
    <div style={{ width: '55%' }}> {/* Adjusted width */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><ContactMailIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Address</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Address}</Typography>
    </div>
  </div>
</Paper>
            </TableCell>
          <TableCell sx={{}}align="Left">
          <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1 }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Created By</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{row?.Completed_By_Email}</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><TodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Created On</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{String(row?.Created_Date).split('T')[0]}</Typography>
    </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Updated By</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2 }}>{row?.Updated_By}</Typography>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><TodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Updated On</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{String(row?.Last_Updated_Date).split('T')[0]}</Typography>
    </div>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><HourglassTopIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Re-Opened</Typography>
      <Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:2}}>{row?.ReOpened_Rev===null?0:row?.ReOpened_Rev+" times"}</Typography>
  </div>
</Paper>
         </TableCell>
         {row?.Resolution_Stage !== "RMA Reopened" && (
      <TableCell align="center" sx={{ padding: "8px" }}>
        {row?.Resolution_Stage === "RMA Created" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps, 0)}</Box>
        )}
        {row?.Resolution_Stage === "Investigation" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps, 1)}</Box>
        )}
        {row?.Resolution_Stage === "Resolution" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps, 2)}</Box>
        )}
        {row?.Resolution_Stage === "Follow Up" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps, 3)}</Box>
        )}
        {row?.Resolution_Stage === "Closed" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps, 4)}</Box>
        )}
        <br />
        <br />
        <Stack direction='row' spacing={2} sx={{marginTop:'10px'}}>
          <Tooltip title={row?.Resolution_Stage === "Closed" ? "Re-Open" : "Edit"}>
            <IconButton
              sx={{ width: "80px" }}
              variant="contained"
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                if (row?.Resolution_Stage === "Closed") {
                  setAreYouSureDialog(true);
                } else {
                  const RMARow = { ...row };
                  setAreYouSureDialog(false);
                  RMAID_Search(RMARow);
                }
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: row?.Resolution_Stage === "Closed" ? red[800] : indigo[800],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: row?.Resolution_Stage === "Closed" ? red[400] : indigo[400],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }}
                variant='rounded'
              >
                <ModeEditIcon sx={{ width: "20px", height: "20px" }} />
                {row?.Resolution_Stage === "Closed" ? "Re-Open" : "Update"}
              </Avatar>
            </IconButton>
          </Tooltip>
          {row?.Resolution_Stage !== "RMA Reopened" && row?.Resolution_Stage !== "Closed" && (
            <Tooltip title="UNDER DEVELOPMENT: Cancel">
              <IconButton sx={{ width: "100px" }} variant="contained" type='submit'>
                <Avatar
                  sx={{
                    backgroundColor: blue[700],
                    ":hover": {
                      boxShadow: "10px 10px 20px #ccc",
                      backgroundColor: blue[500],
                    },
                    width: "80px",
                    fontSize: '12.5px',
                    height: '25px'
                  }}
                  variant='rounded'
                >
                  <CancelIcon sx={{ width: "20px", height: "20px" }} />
                  Cancel
                </Avatar>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="UNDER DEVELOPMENT: Clone">
            <IconButton
              sx={{ width: "80px" }}
              variant="contained"
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                RMAID_Search(row);
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: cyan[900],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: cyan[700],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }}
                variant='rounded'
              >
                <ContentCopyIcon sx={{ width: "20px", height: "20px" }} />
                Clone
              </Avatar>
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    )}
    {row?.Resolution_Stage === "RMA Reopened" && (
      <TableCell align="center" sx={{ padding: "8px" }}>
        {row?.ReOpened_Flag === "RMA Reopened" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps2, 0)}</Box>
        )}
        {row?.ReOpened_Flag === "Investigation" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps2, 1)}</Box>
        )}
        {row?.ReOpened_Flag === "Resolution" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps2, 2)}</Box>
        )}
        {row?.ReOpened_Flag === "Follow Up" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps2, 3)}</Box>
        )}
        {row?.ReOpened_Flag === "Closed" && (
          <Box sx={{ width: '100%' }}>{renderStepper(steps2, 4)}</Box>
        )}
        <br />
        <br />
        <Tooltip title={row?.ReOpened_Flag === "Closed" ? "Re-Open" : "Edit"}>
          <IconButton
            sx={{ width: "100px" }}
            variant="contained"
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              if (row?.Resolution_Stage === "Closed") {
                setAreYouSureDialog(true);
              } else {
                const RMARow = { ...row };
                setAreYouSureDialog(false);
                RMAID_Search(RMARow);
              }
            }}
          >
            <Avatar
              sx={{
                backgroundColor: row?.Resolution_Stage === "Closed" ? red[800] : indigo[800],
                ":hover": {
                  boxShadow: "10px 10px 20px #ccc",
                  backgroundColor: row?.Resolution_Stage === "Closed" ? red[400] : indigo[400],
                },
                width: "100px",
                fontSize: '13px',
                height: '30px'
              }}
              variant='rounded'
            >
              <ModeEditIcon sx={{ width: "30px", height: "25px" }} />
              {row?.Resolution_Stage === "Closed" ? "Re-Open" : ""}
            </Avatar>
          </IconButton>
        </Tooltip>
      </TableCell>
    )}
        </TableRow>
            
      </React.Fragment>
    );
  }
  
  export default RMARow