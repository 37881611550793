import React from 'react';
import { Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { orange } from '@mui/material/colors';

const formatNameFromEmail = (email) => {
  if (!email) return '';
  const parts = email.split('@')[0].split('.');
  const formattedName = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  return formattedName;
};



export default formatNameFromEmail;