import React,{Fragment} from 'react';
import { Text, View, StyleSheet,Font} from '@react-pdf/renderer';
// Register the Roboto font
Font.register({
    family: 'Roboto',
    fonts: [
      { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf' },
      { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' }
    ]
  });
const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop:'15px',
        fontFamily:'Roboto',
        fontWeight:'bold'
    },
    label: {
        width: 200,
        fontSize:8.5,
    }
});

const InvoiceThankYouMsg = () => (
    <Fragment>
    <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>
            -----------------   Thank You   -------------------
        </Text>
    </View >
</Fragment>
        	
);

export default InvoiceThankYouMsg;