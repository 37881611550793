import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useState} from "react";
import {Button, Stack,TextField,InputAdornment,MenuItem, CircularProgress,Divider} from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey,red,orange } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate} from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar"
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SalesRow from "./RMATableRowHelper";
import AxiosFunction from "../../axiosCustomInstance"
import LoadingDialog from "../Loading";
import TableSortLabel from '@mui/material/TableSortLabel';

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function RMAsSearch() {
  const [GetClick,SetGetClick]=useState(false);
  const [totalData,setTotalData]=useState([{}])
  const [getRMANum,setgetRMANum]=useState("")
  const [statusError,setStatusError]=useState(false)
  const [statusErrorMsge,setStatusErrorMsge]=useState("")
  const [getRMANumChecked,setGetRMANumChecked]=useState(false)
  const [fieldchecked,setFieldChecked]=useState("");
  const [statusFieldChecked, setStatusFieldChecked] = useState(false);
  const [stus,setStus]=useState("RMA Created")
  const [dis,setDis]=useState(false)
  const [rMANumError,setRMANumError]=useState(false)
  const [searchFilterError,setSearchFilterError]=useState(false)
  const [rMANumErrorMsge,setRMANumErrorMsge]=useState("")
  const [searchFilterErrorMsge,setSearchFilterErrorMsge]=useState("")
  const navigate=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const AxiosAPIInstance=AxiosFunction()
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  //-----------------------------------------------------------------------------------------------------------------

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'Actual_Total_Cost_After_Margin') {
      // Convert to Number type before comparison
      const decimalA = new Number(a[orderBy]);
      const decimalB = new Number(b[orderBy]);
  
      if (decimalB < decimalA) {
        return -1;
      }
      if (decimalB > decimalA) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClose = (e) => {
    setRMANumError(false);
    setRMANumErrorMsge("")
    setDis(false)
    setStatusError(false)
    setStatusErrorMsge("")
    setSearchFilterError(false)
    setSearchFilterErrorMsge("")

  };
  useEffect(()=>{
    setTotalData([{}]);
    SetGetClick(false);
  },[stus])
  
  const handleRMANumChange=(e)=>{
    setPage(0)
    setGetRMANumChecked(e.target.checked);
    SetGetClick(false);
    if(e.target.checked===true)
    { 
      setFieldChecked("RMAID");
      setStatusFieldChecked(false)
      setStus("")
    }
    else
    {
      setFieldChecked("");
      setgetRMANum("");   
    }

  }
  const handleStatusChange=(e)=>{
    setPage(0)
    setStatusFieldChecked(e.target.checked);
    SetGetClick(false);
    if(e.target.checked===true)
    { 
      setFieldChecked("Status");
      setGetRMANumChecked(false);
      setgetRMANum("");
    }
    else
    {
      setFieldChecked("");
      setStus("")
      setgetRMANum("");   
    }

  }

  const Get_Data_Click=async (e)=>{
    e.preventDefault();
    setDis(true)
    setLoading(true)
   if(fieldchecked!=='')
   {
    if(getRMANumChecked===true)
        {  
          if(getRMANum!=='')
        {
          await AxiosAPIInstance.get("/RMAs/GetRMAByID", { params: { RMA_ID: getRMANum }})
          .then((response) => {
            setLoading(false);
            if (response?.data && response?.data.length > 0) {
              setTotalData(response?.data);
              setDis(false);
              SetGetClick(true);
            } else {
              setRMANumError(true);
              setRMANumErrorMsge("No Records Found");
              SetGetClick(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setRMANumError(true);
            setRMANumErrorMsge(err.response?.data || "An error occurred");
            setTotalData([{}]);
            setDis(false);
          });
        }
        else {
          setLoading(false)
          setRMANumError(true)
          setRMANumErrorMsge("RMA Number is Required")
          setTotalData([{}]);
          setDis(false)
            }
        }
   
      else if(statusFieldChecked===true)
      {  
        await AxiosAPIInstance.get("/RMAs/GetAllRMAByStatus",{params:{Resolution_Stage:stus}}).then(
          (response)=>{
            setLoading(false)
          
            if(response?.data.length>0)
            {
            setTotalData(response?.data)
            setDis(false)
            SetGetClick(true);
            }
            else{
              SetGetClick(false);
              setStatusError(true)
              setStatusErrorMsge("No Records Found")
              
            }
          }
        ).catch((err)=>{
          setLoading(false)
          SetGetClick(false)
          setStatusError(true)
          setStatusErrorMsge(err.response?.data)
          setTotalData([{}]);
          setDis(false)
        })
      }
      setDis(false)
      setLoading(false)
    }
    else
    {
      await AxiosAPIInstance.get("/RMAs/GetAllRMAs").then(
        (response)=>{
          setLoading(false)
          if(response?.data.length>0)
          {
          setTotalData(response?.data)
          setDis(false)
          SetGetClick(true);
          }
          else{
            SetGetClick(false);
            setStatusError(true)
            setStatusErrorMsge("No Records Found")
            
          }
        }
      ).catch((err)=>{
        setLoading(false)
        SetGetClick(false)
        setStatusError(true)
        setStatusErrorMsge(err.response?.data)
        setTotalData([{}]);
        setDis(false)
      })
      setDis(false)
      setLoading(false)
    }
  }
  return (
    <>
    {<ApplicationBar></ApplicationBar>}
    <LoadingDialog open={loading} />
    {rMANumError===true&&(<Dialog
      open={rMANumError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert  variant='filled' severity={rMANumErrorMsge==="No Records Found"?"warning":"error"}>{rMANumErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {rMANumErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {statusError===true&&(<Dialog
      open={statusError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert  variant='filled' severity={rMANumErrorMsge==="No Records Found"?"warning":"error"}>{statusErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {statusErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}

    {searchFilterError===true&&(<Dialog
      open={searchFilterError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' severity="error">ERROR</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
      {searchFilterErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    <Stack direction='column' spacing={0}>
      <Stack sx={{marginTop:"60px"}} direction="row" justifyContent="center">
        <Typography  sx={{fontSize:"25px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)'}}>Return Merchandise Authorization(RMAs)</Typography>
        </Stack>
        
        <Stack sx={{marginTop:'10px'}} direction='row' justifyContent="center">
        <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="RMANumSearch"  checked={getRMANumChecked} onChange={(e)=>{handleRMANumChange(e)}}/>} label="RMA Number" />
       </FormGroup>
        <TextField required  size="small" disabled={!getRMANumChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
         
            </InputAdornment>
          ),}} value={getRMANum} onChange={(e)=>{setgetRMANum(e.target.value);SetGetClick(false)}}></TextField>
          <Stack sx={{width:"100px"}}></Stack>
          <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Status Search"  checked={statusFieldChecked} onChange={(e)=>{handleStatusChange(e)}}/>} label="Status" />
       </FormGroup>
          <FormControl size="small" >
        <InputLabel id="RMA_Status">Status</InputLabel>
        <Select size="small"
          labelId="RMA_Status"
          id="RMA_Status-id"
          value={stus}
          label="RMA Status"
          sx={{width:"150px"}}
          onChange={(e)=>{            
            setStus(e.target.value)
          }}
          disabled={!statusFieldChecked}
          >
          <MenuItem size="small" value={"RMA Created"}>RMA Created</MenuItem>
          <MenuItem size="small" value={"Investigation"}>Investigation</MenuItem>
          <MenuItem size="small" value={"Resolution"}>Resolution</MenuItem>
          <MenuItem size="small" value={"Follow UP"}>Follow Up</MenuItem>
          <MenuItem size="small" value={"Closed"}>Closed</MenuItem>   
          <MenuItem size="small" value={'RMA Reopened'}>RMA Re-Opened</MenuItem>
        </Select>
      </FormControl>
          </Stack>
        <Stack direction="row" justifyContent="center" sx={{marginTop:"10px",marginBottom:"10px"}}>
          <Button sx={{width:'200px'}} variant="contained" color='success' disabled={dis} onClick={(e)=>Get_Data_Click(e)}>Get</Button>
          </Stack>
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
        <Stack></Stack>
        <br></br>
        {GetClick ===true &&(getRMANumChecked===true||statusFieldChecked===true||totalData.length>0)&& 
        (<TableContainer >
        <Table  aria-label="POs_DateRange">
          <TableHead sx={{backgroundColor:theme.palette.primary.main,padding:0}}>
            <TableRow>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',padding:1,width:'10%'}} align="center">RMA ID</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'45%',padding:1}} align="center">Customer's Info & Problem Description</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white", backgroundColor: orange[600],fontFamily:'Verdana (sans-serif)',cursor: 'pointer',width:'35%',
                    color: 'white',
                    "&:hover": {
                      backgroundColor: orange[300],
                      color: orange[800],
                    },padding:1}} align="center"><TableSortLabel
                    active
                    direction={orderBy === 'Created_Date' ? order : 'asc'}
                    onClick={() => handleRequestSort('Created_Date')}>
                  <b>Sort by Created Date</b>
                  </TableSortLabel></TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',padding:1,width:'25%'}} align="center">Status & Actions</TableCell>
            </TableRow>

          </TableHead>
          <TableBody>
          {(rowsPerPage > 0
        ? stableSort(totalData, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : totalData
      )?.map((row, index) => (
        <SalesRow key={index} row={row} />
      ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
      </Stack>
      </>
    );
}
  export default RMAsSearch