// Importing necessary modules from Material-UI and React
import React, { useEffect } from "react";
import { useState} from "react";
import {Button, Stack,TextField,InputAdornment,MenuItem, CircularProgress, Grid,Divider} from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey, red, yellow,orange } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

// Importing custom components and utilities
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ApplicationBar from "../../AppBar/ApplicationBar";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import DHSCBPsalesTableRowHelper from './TableHelpers/DHSCBPsalesTableRowHelper'
import AxiosFunction from "../../../axiosCustomInstance"
import LoadingDialog from '../../Loading'
import { SaveAlt, Today } from '@mui/icons-material';
import * as XLSX from 'xlsx';
const ExcelJS = require('exceljs');

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function DHSCBP_RFQs_Search_Update() {
// State variable to track whether the search button is clicked or not
const [GetClick, SetGetClick] = useState(false);

// State variable to store total data fetched from API
const [totalData, setTotalData] = useState([]);

// State variables to manage input values and checkboxes for different search filters
const [salesHeadName, setSalesHeadName] = useState("");
const [salesHeadNameChecked, setSalesHeadNameChecked] = useState(false);
const [opportunityName, setOpportunityName] = useState("");
const [opportunityNameChecked, setOpportunityNameChecked] = useState(false);
const [getInvoiceNum, setgetInvoiceNum] = useState("");
const [getInvoiceNumChecked, setGetInvoiceNumChecked] = useState(false);
const [DateValue, SetDateValue] = useState({From_Date: new Date(), To_Date: new Date()});
const [getDateRangeChecked, setGetDateRangeChecked] = useState(false);
const [fieldchecked, setFieldChecked] = useState("");

// State variables to manage dialog states
const [open1, setOpen1] = useState(false);
const [stus, setStus] = useState("PENDING");
const [dis, setDis] = useState(false);

// State variables to manage error states and messages for different search filters
const [oppError, setOppError] = useState(false);
const [dateError, setDateError] = useState(false);
const [salesHeadError, setSalesHeadError] = useState(false);
const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState("");
const [invoiceNumError, setInvoiceNumError] = useState(false);
const [searchFilterError, setSearchFilterError] = useState(false);
const [oppErrorMsge, setOppErrorMsge] = useState("");
const [dateErrorMsge, setDateErrorMsge] = useState("");
const [invoiceNumErrorMsge, setInvoiceNumErrorMsge] = useState("");
const [searchFilterErrorMsge, setSearchFilterErrorMsge] = useState("");


// Creating a new Date object to represent the current date
const PD = new Date()

// Formatting the current date into a string representation using the en-US locale and 'medium' date style
const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
}).format(PD)

// Importing the `useNavigate` hook from 'react-router-dom' for navigation within the application
// const navigate = useNavigate()

// State variables to manage pagination
const [page, setPage] = React.useState(0); // State for current page
const [rowsPerPage, setRowsPerPage] = React.useState(5); // State for number of rows per page

// State variable to store user data fetched from API
let [usersData, setUsersData] = useState([{User_email:""}]);

// Axios instance for making API requests
const AxiosAPIInstance = AxiosFunction()

// State variable to manage loading state
const [loading, setLoading] = useState(false);



    const [data, setData] = useState([{OpportunityName:'',QuoteNo:'',ContractVehicle:'',KOname:'',KOemail:'',
    KOphone:'',KOaddress:'',PublishedDate:null,DueDate:null,Brief:'',OrganizationName:'',NotesFo_PO:"NA",
    CustomerName:'',CustomerEmail:'',CustomerPhone:'',CustomerAddress:'',ShippingMethod:"N/A",SalesHeadName:'',SalesHeadEmail:"sales@aspettoinc.com",SalesHeadPh:"540-547-8487",
    ShippingTerms:'FOB Origin',EstimatedDelivery:'45 Days ARO',PaymentTerms:'NET30',Notes:"N/A",ShippingCost:parseFloat(0).toFixed(2),
    Tax:parseFloat(0).toFixed(2),OtherCost1:parseFloat(0).toFixed(2),OtherCost2:parseFloat(0).toFixed(2),OtherCost3:parseFloat(0).toFixed(2),OtherCostName1:"NA",OtherCostName2:"NA",OtherCostName3:"NA",
    TotalCostBeforeMargin:parseFloat(0.00).toFixed(2),
    TotalCostAfterMargin:parseFloat(0.00 ).toFixed(2),CreatedDate:String(today),
    LastUpdatedDate:String(today),Status:'PENDING',
    UpdatedBy:'',VersionNo:parseInt(0),UpdatingComments:'RFQ Created under PENDING Status',
    }]);
    //-----------------------------------------------------------------------------------------------------------------

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

// useEffect hook to fetch user data from the API when the component mounts
React.useEffect(() => {
    // Set loading state to true while fetching data
    setLoading(true);

    // Function to fetch users data from the API
    const fetchUsersData = async () => {
        // Making GET request to fetch users data
        await AxiosAPIInstance.get("/UserAuth/users")
            .then((result) => {
                // Update loading state to false after successful data fetching
                setLoading(false);
                // Extracting users data from the response and updating the state
                const users = [...result?.data];
                setUsersData(users);
                // Resetting error states and messages related to sales head
                setSalesHeadError(false);
                setSalesHeadErrorMsge("");
            })
            .catch((error) => {
                // Update loading state to false on error
                setLoading(false);
                // Setting error states and messages related to sales head
                setSalesHeadError(true);
                setSalesHeadErrorMsge(error?.response.data);
            });
    };

    // Calling the fetchUsersData function
    fetchUsersData();
}, []);

// Function to handle closing of dialog and resetting error states and messages
const handleClose = (e) => {
    setOpen1(false);
    // Resetting error states and messages related to invoice number, opportunity, sales head, search filter, and date
    setInvoiceNumError(false);
    setOppError(false);
    setSalesHeadError(false);
    setSearchFilterError(false);
    setDateError(false);
    setOppErrorMsge("");
    setSalesHeadError("");
    setInvoiceNumErrorMsge("");
    setDateErrorMsge("");
};

// useEffect hook to reset total data and search button click state when the status (stus) changes
useEffect(() => {
    setTotalData([]);
    SetGetClick(false);
}, [stus]);

    
    //-------------------------------Excel Generation---------------------------------
    const handleGenerateExcel = async (e) => {
      e.preventDefault();
      setLoading(true);
    
      try {
        // Fetch data from the API using Axios
        const response = await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/StatusOnly", {
          params: { RFQ_Status: stus },
        });
    
        if (response.data.length > 0) {
          // If data is available, set it to the totalData state
          setTotalData(response.data);
          //---------------------------------------------------------
          const formattedData = response.data.map((row) => ({
            OpportunityName: row.Opportunity_Name,
            QuoteNo: row.Invoice_No,
            ContractVehicle: row.Contract_Vehicle,
            KOname:row.KO_name,
            KOemail:row.KO_email,
            KOphone:row.KO_phone,
            KOaddress:row.KO_address,
            PublishedDate:String(row.Published_date).split('T')[0],
            DueDate:String(row.Due_date).split('T')[0],
            Brief:row.Brief,
            OrganizationName:row.Organization_Name,
            NotesForPO:row.Notes_For_PO,
            CustomerName:row.Customer_Name,
            CustomerEmail:row.Customer_Email,
            CustomerPhone:row.Customer_Phone,
            CustomerAddress:row.Customer_Address,
            ShippingMethod:row.Shipping_Method,
            SalesHeadName:row.Sales_Head_Name,
            SalesHeadEmail:row.Sales_Head_Email,
            SalesHeadPh:row.Sales_Head_Ph,
            ShippingTerms:row.Shipping_Terms,
            EstimatedDelivery:row.Estimated_Delivery,
            PaymentTerms:row.Payment_Terms,
            Notes:row.Notes,
            ShippingCost:row.Shipping_Cost,
            Tax:row.Tax,
            OtherCost1:row.Other_Cost_1,
            OtherCost2:row.Other_Cost_2,
            OtherCost3:row.Other_Cost_3,
            OtherCostName1:row.Other_Cost_Name1,
            OtherCostName2:row.Other_Cost_Name2,
            OtherCostName3:row.Other_Cost_Name3,
            TotalCostBeforeMargin:row.Actual_Total_Cost_Before_Margin,
            TotalCostAfterMargin:row.Actual_Total_Cost_After_Margin,
            CreatedDate:String(row.Created_Date).split('T')[0],
            LastUpdatedDate:String(row.Last_Updated_Date).split('T')[0],
            Status:row.RFQ_Status,
            UpdatedBy:row.Updated_By,
            UpdatingComments:row.Updating_Comments,
            // Add more properties as needed
          }));
          setDis(false);
          setLoading(false);
        
      // Create an Excel workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(formattedData); // Use totalData here
 // Add column headers
 const headers = [
'Opportunity Name',
 'Quote No',
 'Contract Vehicle',
'KO name',
 'KO email',
 'KO phone',
'KO address',
 'Published Date',
 'Due Date', 
'Brief',
 'Organization Name',
 'Notes For PO',
 'Customer Name', 
'Customer Email', 
 'Customer Phone',
'Customer Address',
'Shipping Method',
'Sales Head Name',
 'Sales Head Email',
'Sales Head Ph',
'Shipping Terms',
 'Estimated Delivery',
 'Payment Terms',
 'Notes',
 'Shipping Cost($)',
 'Tax($)',
'Other Cost1($)',
'Other Cost2($)',
'Other Cost3($)',
 'Other Cost Name1',
 'Other Cost Name2',
 'Other Cost Name3',
 'Total Cost Before Margin($)',
'Total Cost After Margin($)', 
 'Created Date',
 'Last Updated Date',
 'Status',
 'Updated By',
 'Updating Comments',

  // Add more headers and styles as needed
];
 headers.forEach((header, index) => {
  const cellAddress = { r: 0, c: index };
  ws[XLSX.utils.encode_cell(cellAddress)] = { v: header, s: { font: { bold: true } } };
});

// Define the Excel file name (customize as needed)
XLSX.utils.book_append_sheet(wb, ws, 'ASPETTO SALES');

// Define the Excel file name (replace with your variable)
const today = new Date();
const excelFileName = 'ASPETTO SALES-' + String(today).split('T')[0] + '(' + 'STATUS:' + stus + ')' + '.xlsx';

// Trigger the download of the Excel file
XLSX.writeFile(wb, excelFileName);
} else {
// Handle the case when no records are found
SetGetClick(false);
setDateError(true);
setDateErrorMsge("No Records Found");
}
  } catch (err) {
    // Handle Axios request error
    setLoading(false);
    SetGetClick(false);

    // Handle the error here
    console.error(err);

    setTotalData([]);
    setDis(false);
    setSearchFilterError(true);
    setSearchFilterErrorMsge("Something went wrong");
  }
};
  //-----------------------------------------------------------------------
// Function to handle changes in the invoice number checkbox
const handleInvoiceNumberChange = (e) => {
  // Update state based on checkbox status
  setGetInvoiceNumChecked(e.target.checked);
  // Reset search button click state
  SetGetClick(false);
  if (e.target.checked === true) {
      // If checkbox is checked, reset other checkboxes and fields
      setOpportunityNameChecked(false);
      setSalesHeadNameChecked(false);
      setGetDateRangeChecked(false);
      // Set field for search to "RFQID"
      setFieldChecked("RFQID");
      // Reset date range and other input values
      SetDateValue({ From_Date: new Date(), To_Date: new Date() });
      setSalesHeadName("");
      setOpportunityName("");
  } else {
      // If checkbox is unchecked, reset field and input value
      setFieldChecked("");
      setgetInvoiceNum("");
  }
}

// Function to handle changes in the opportunity name checkbox
const handleOpporNameChange = (e) => {
  // Reset search button click state
  SetGetClick(false);
  // Update state based on checkbox status
  setOpportunityNameChecked(e.target.checked);
  if (e.target.checked === true) {
      // If checkbox is checked, reset other checkboxes and fields
      setGetInvoiceNumChecked(false);
      setSalesHeadNameChecked(false);
      setGetDateRangeChecked(false);
      // Set field for search to "OpportunityName"
      setFieldChecked("OpportunityName");
      // Reset date range and other input values
      setgetInvoiceNum("");
      setSalesHeadName("");
      SetDateValue({ From_Date: new Date(), To_Date: new Date() });
  } else {
      // If checkbox is unchecked, reset field and input value
      setFieldChecked("");
      setOpportunityName("");
  }
}

// Function to handle changes in the sales head email checkbox
const handleSalesHeadEmailChange = (e) => {
  // Update state based on checkbox status
  setSalesHeadNameChecked(e.target.checked);
  // Reset search button click state
  SetGetClick(false);
  if (e.target.checked === true) {
      // If checkbox is checked, reset other checkboxes and fields
      setGetInvoiceNumChecked(false);
      setOpportunityNameChecked(false);
      setGetDateRangeChecked(false);
      // Set field for search to "SalesHead"
      setFieldChecked("SalesHead");
      // Reset date range and other input values
      setgetInvoiceNum("");
      setOpportunityName("");
      SetDateValue({ From_Date: new Date(), To_Date: new Date() });
  } else {
      // If checkbox is unchecked, reset field and input value
      setFieldChecked("");
      setSalesHeadName("");
  }
}

// Function to handle changes in the date range checkbox
const handleDateRangeChange = (e) => {
  // Update state based on checkbox status
  setGetDateRangeChecked(e.target.checked);
  // Reset search button click state
  SetGetClick(false);
  if (e.target.checked === true) {
      // If checkbox is checked, reset other checkboxes and fields
      setGetInvoiceNumChecked(false);
      setSalesHeadNameChecked(false);
      setOpportunityNameChecked(false);
      // Set field for search to "DateSearch"
      setFieldChecked("DateSearch");
      // Reset input values
      setgetInvoiceNum("");
      setOpportunityName("");
      setSalesHeadName("");
  } else {
      // If checkbox is unchecked, reset field
      setFieldChecked("");
  }
}


  const Get_Data_Click=async (e)=>{
    e.preventDefault();
    setDis(true)
    setLoading(true)
   if(fieldchecked!=='')
   {
    if(getInvoiceNumChecked===true)
        {  
          if(getInvoiceNum!=='')
        {
          await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/QuoteNumber",{params:{Invoice_No:getInvoiceNum,RFQ_Status:stus}}).then(
            async(response)=>{
              setLoading(false)
              if(response?.data.length>0)
              {
              await setTotalData(response?.data)
              setDis(false)
              SetGetClick(true)
              }
              else
              { 
                SetGetClick(false)
                setInvoiceNumError(true)
                setInvoiceNumErrorMsge("No Records Found")
              }
            }
          ).catch((err)=>{
            setLoading(false)
            setInvoiceNumError(true)
            setInvoiceNumErrorMsge(err.response?.data)
            setTotalData([]);
            setDis(false)
          })
        }
        else {
          setInvoiceNumError(true)
          setInvoiceNumErrorMsge("Quote Number is Required")
          setTotalData([]);
          setDis(false)
            }
        }
    else if(fieldchecked==='OpportunityName')
        {
          if(String(opportunityName).trim().length>=3)
          {
          await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/OpportunityName",{params:{Opportunity_Name:String(opportunityName).trim(),RFQ_Status:stus}}).then(
            async(response)=>{
              setLoading(false)
              if(response?.data.length>0)
              {
                SetGetClick(true);
                await setTotalData(response?.data)
                setDis(false)
              }
              else{
                SetGetClick(false)
                setOppError(true)
                setOppErrorMsge("No Records Found")
              }
             
            }
          ).catch((err)=>{
            setLoading(false)
            SetGetClick(false)
            setOppError(true);
            setOppErrorMsge(err.response?.data);
            setTotalData([]);
            setDis(false)
          
          })
        }
        else
        { 
          setLoading(false)
          SetGetClick(false);
          setTotalData([]);
          setDis(false);
          setOppError(true);
          setOppErrorMsge("Opportunity Name should contain atleast 3 letters");;
         
        }
        }
    else if(salesHeadNameChecked===true)
        {
          if(salesHeadName!=='')
          {
          await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/SalesHead",{params:{Sales_Head_Email:String(salesHeadName).trim().toLowerCase(),RFQ_Status:stus}}).then(
            async(response)=>{
              setLoading(false)
              if(response?.data.length>0){
              SetGetClick(true);
              await setTotalData(response?.data)
              setDis(false)
              }
              else
              {
                SetGetClick(false)
                setSalesHeadError(true)
                setSalesHeadErrorMsge("No Records Found")
              
              }
            }
          ).catch((err)=>{
            setLoading(false)
            SetGetClick(false)
            setSalesHeadError(true)
            setSalesHeadErrorMsge(err.response?.data)
            setTotalData([]);
            setDis(false)
          })
        }
        else {
              setLoading(false)
              SetGetClick(false)
              setSalesHeadError(true)
              setSalesHeadErrorMsge("Sales Head Email is Required")
              setDis(false)
              setTotalData([]);
              }
        }
        else if(fieldchecked=='DateSearch')
        {
          await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/DateSearch",{params:{From:DateValue.From_Date,To:DateValue.To_Date,RFQ_Status:stus}}).then(
            async (response)=>{
              setLoading(false)
              if(response?.data.length>0)
              {
              await setTotalData(response?.data)
              setDis(false)
              SetGetClick(true);
              }
              else{
                SetGetClick(false);
                setDateError(true)
                setDateErrorMsge("No Records Found")
                
              }
            }
          ).catch((err)=>{
            setLoading(false)
            SetGetClick(false)
            alert(err.response?.data)
            setTotalData([]);
            setDis(false)
          })
        }
      }
      else
      {

        await AxiosAPIInstance.get("/DHSCustomer/DHSOutSideSearch/StatusOnly",{params:{RFQ_Status:stus}}).then(
          async(response)=>{
            setLoading(false)
            if(response?.data.length>0)
            {
            await setTotalData(response?.data)
            setDis(false)
            SetGetClick(true);
            }
            else{
              SetGetClick(false);
              setDateError(true)
              setDateErrorMsge("No Records Found")
              
            }
          }
        ).catch((err)=>{
          setLoading(false)
          SetGetClick(false)
          alert(err.response?.data)
          setTotalData([]);
          setDis(false)
        })
      }
      setLoading(false)
      setDis(false)
    }
  return (
    <>
    {<ApplicationBar></ApplicationBar>}
    <LoadingDialog open={loading} />
    {invoiceNumError===true&&(<Dialog
      open={invoiceNumError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{}} variant='filled' severity={invoiceNumErrorMsge==="No Records Found"?"warning":"error"}>{invoiceNumErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
       {invoiceNumErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {oppError===true&&(<Dialog
      open={oppError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{}} variant='filled' severity={oppErrorMsge==="No Records Found"?"warning":"error"}>{oppErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
          {oppErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {salesHeadError===true&&(<Dialog
      open={salesHeadError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{}} variant='filled' severity={salesHeadErrorMsge==="No Records Found"?"warning":"error"}>{salesHeadErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
        {salesHeadErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {dateError===true&&(<Dialog
      open={dateError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{}} variant='filled' severity={dateErrorMsge==="No Records Found"?"warning":"error"}>{dateErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
      {dateErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {searchFilterError===true&&(<Dialog
      open={searchFilterError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' severity="error">ERROR</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center",color:'black'}}>
      {searchFilterErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    <Stack direction='column' spacing={0} justifyContent="center">
      <Stack sx={{marginTop:"60px",}}  direction="row"  justifyContent="center">
        <Stack></Stack>
        <Stack></Stack>
        <Typography  sx={{fontSize:"25px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)'}} justifyContent="center">Request For Quotes(DHS-CBP-External)</Typography>
        </Stack>

        <Stack direction='row'justifyContent="center" >
        <Stack direction='column' spacing={0}>
        <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="RFQIDSearch"  checked={getInvoiceNumChecked} onChange={(e)=>{handleInvoiceNumberChange(e)}}/>} label="Quote Number" />
       </FormGroup>
        <TextField required size="small" disabled={!getInvoiceNumChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
         
            </InputAdornment>
          ),}} label="Quote Number" value={getInvoiceNum} onChange={(e)=>{setgetInvoiceNum(e.target.value);SetGetClick(false)}}></TextField></Stack><Stack sx={{width:"50px"}}></Stack>
           <Stack direction='column' spacing={0}>
          <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Opportunity_Name_Search" checked={opportunityNameChecked} onChange={(e)=>{handleOpporNameChange(e)}}/>} label="Opportunity Name" />
       </FormGroup>
        <TextField  required  size="small" disabled={!opportunityNameChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
             
            </InputAdornment>
          ),}} label="Opportunity Name" value={opportunityName} onChange={(e)=>{setOpportunityName(e.target.value);setTotalData([])}} ></TextField></Stack><Stack sx={{width:"50px"}}></Stack>
          <Stack direction='column' spacing={0}>
          {/* <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Sales_Head_Search" checked={salesHeadNameChecked} onChange={(e)=>{handleSalesHeadEmailChange(e);SetGetClick(false)}}/>} label="Sales Head Email" />
       </FormGroup>
       <FormControl>
                <InputLabel id="Sales-Head-id">
                   Sales Head Email ID
                </InputLabel>
                <Select
                label="Sales Head Email ID"
                  disabled={!salesHeadNameChecked}
                    labelId="Sales-Head-id"
                    value={String(salesHeadName).toLowerCase()}
                    size='small'
                    onChange={(e)=>setSalesHeadName(e.target.value)}>
                  {usersData?.map((row,index)=>{return (<MenuItem value={String(row?.User_email).toLowerCase()}
                    >{String(row?.User_email).toLowerCase()}</MenuItem>)})}
                </Select>
            </FormControl> */}
          </Stack><Stack sx={{width:"50px"}}></Stack>
           <Stack direction='column' spacing={0}>
           <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="DateSearch" checked={getDateRangeChecked} onChange={(e)=>{handleDateRangeChange(e)}}/>} label="Created Date" />
       </FormGroup>
           <Stack direction='row' spacing={1}>
        <DatePicker disabled={!getDateRangeChecked} size="small" label="From"  maxDate={new Date()} required  value={DateValue.From_Date} onChange={(value)=>{SetDateValue({...DateValue,From_Date:value});SetGetClick(false)}}  renderInput={(params)=><TextField size="small"  disabled={!getDateRangeChecked} {...params} />}>
        </DatePicker>
        <DatePicker  disabled={!getDateRangeChecked}  label="To" minDate={DateValue.From_Date} required  value={DateValue.To_Date} onChange={(value)=>{SetDateValue({...DateValue,To_Date:value});SetGetClick(false)}}renderInput={(params)=><TextField disabled={!getDateRangeChecked}  variant="outlined" size="small" {...params} />}>
        </DatePicker></Stack></Stack>
        </Stack>
      
        <Stack spacing={1} direction="row" justifyContent="center">    
        {/* <FormControl size="small" >
        <InputLabel id="Quote_Status">Status</InputLabel>
        <Select size="small"
          labelId="Quote_Status"
          id="Quote_Status-id"
          value={stus}
          label="Quote Status"
          onChange={(e)=>{            
            setStus(e.target.value)
          }}
          sx={{width:"150px"}}
          >
          <MenuItem size="small" value={"PENDING"}>PENDING</MenuItem>
          <MenuItem size="small" value={"WON"}>WON</MenuItem>
          <MenuItem size="small" value={"LOST"}>LOST</MenuItem>
        </Select>
      </FormControl> */}
      </Stack>
      <Stack sx={{marginTop:"10px",marginBottom:"10px"}} spacing={1} direction="row" justifyContent="center">
       <Button sx={{width:"200px"}} size="small" variant="contained" color='success' disabled={dis} onClick={(e)=>Get_Data_Click(e)}>Get</Button> 
       <Button
            disabled={!GetClick}
            variant="contained"
            sx={{backgroundColor:yellow[900],fontSize:"10px",":hover":{backgroundColor:yellow[800]}}}
            startIcon={<SaveAlt />}
            onClick={handleGenerateExcel}
            size="small">
            Generate Excel
          </Button>
       </Stack>
       <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
        <Stack></Stack>
        <br></br>
        {GetClick ===true &&(getDateRangeChecked===true ||opportunityNameChecked===true||salesHeadNameChecked===true||getInvoiceNumChecked===true||totalData.length>0)&& 
        (<TableContainer >
        <Table margin="2" aria-label="POs_DateRange">
          <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Contract Info</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'22%'}} align="center">Contracting Officer's Info</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'22%'}} align="center">Customer's Info</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Sales Head</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Total Sale($)</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Actions</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <DHSCBPsalesTableRowHelper key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
      </Stack>
      </>
    );
  }
  export default DHSCBP_RFQs_Search_Update