import {React} from 'react';
import { Text, View, StyleSheet,Image} from '@react-pdf/renderer';
import logo from "../Images/Aspetto_Login.png";
import HubZone from '../Images/HUBZone.png';
import Address from '../Images/Address.png'
import Hub1 from '../Images/Hub1.png'
import Hub2 from '../Images/Hub2.png'
import NonGSAHub1 from "../Images/NonGSAHub1.png"
import HubFBOP from "../Images/HubFBOP.png"
const styles = StyleSheet.create({
    label:{
        marginTop: 24,
        flexDirection:'column'
    },
    titleContainer: {
        marginTop: 20,
    },
    reportTitle: {
        color: 'black',
        fontSize: 10,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'extrabold'
    },
    Quote_Title:{
        color: 'black',
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'bold',
    },
    logo: {
        width: 150,
        height: 50,
        marginLeft: 'auto',
        marginRight: "auto",
        alignContent:"center"
    },
    Hub1:{
        width: 130,
        height: 42,
    },
    Hub3:{
        width: 87,
        height: 59,
    },
    Hub2:{
        width: 75,
        height: 35,
    },
    NonGSAHub1:{
        width: 91,
        height: 32,
    },
    Image_con:{
        flexDirection:'row',
        backgroundColor:'#fff'
    },
    Address:{
        width: 190,
        height: 40,
    }
});

const InvoiceTitle = ({ invoice }) => {
    var Contract_Vehicle_GSA_Type=false
    var Contract_Vehicle_FBOP_Type=false

    if(invoice.Contract_Vehicle==="GSA")
    {
        Contract_Vehicle_GSA_Type=true
    }
    else if(invoice.Contract_Vehicle==="FBOP")
    {
        Contract_Vehicle_FBOP_Type=true
    }
    return(
    <>
     <View style={styles.Image_con}>
     <Image style={styles.Address} src={Address}></Image>
    <Image style={styles.logo} src={logo} />
    {Contract_Vehicle_GSA_Type?(<Image style={styles.Hub1} src={Hub1}></Image>):Contract_Vehicle_FBOP_Type?(<Image style={styles.Hub3} src={HubFBOP}></Image>):(<Image style={styles.NonGSAHub1} src={NonGSAHub1}></Image>)}
    {/* {Contract_Vehicle_FBOP_Type?(<Image style={styles.Hub1} src={HubFBOP}></Image>):(<Image style={styles.NonGSAHub1} src={NonGSAHub1}></Image>)} */}
     <Image style={styles.Hub2} src={Hub2}></Image>
     </View>
    </>
);
}

export default InvoiceTitle;