import React, { Fragment } from 'react';
import { Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import { textAlign } from '@mui/system';
import { Font } from '@react-pdf/renderer'
import ADDRES from '../Images/ADDRES.png'
import Agency_Name from '../Images/Agency_Name.png'
import Email from '../Images/Email.png'
import Phone from '../Images/Phone.png'
import POC from '../Images/POC.png'

import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[100],
        },
    },
  });

const styles = StyleSheet.create({
    boldLabel: {
        fontWeight: 'bold',
    },

    label: {
        width: 60,
        fontSize: 9,
    },
    invoiceDate: {
        fontSize: 9,
        fontStyle: 'bold',
        textAlign:'right',
    },
    ATTNContainer: {
        flexDirection: 'row',
        fontSize: 9,
    },
    AddressContainer: {
        flexDirection: 'row',
        fontSize: 9,
    },
    Date_Inv_Con:{
        flexDirection: 'column',
        borderColor:'black',
        width: '20%',
        borderColor:'black',
        padding:'3px',
        textAlign:'right'
    },
    Quote_label: {
        width: 70,
        fontSize: 9,
        fontWeight:'bold'
    },
    headerRow:{
        flexDirection: 'row',
        backgroundColor: theme.palette.primary.main,
        fontWeight:"bold",
        color:'white',
        fontSize: 9,
        marginTop:"10px",
     
    },
    From_Header: {
        fontSize: 9,
        width: '35%',
        padding:'3px',
        textAlign:'left',
        fontWeight:'bold',
        borderRightWidth:1,
    },
    Ship_To_Header: {
        fontSize: 9,
        width: '35%',
        padding:'3px',
        textAlign:'left',
        fontWeight:'bold',
        borderLeftWidth:1,
    },
    Total_Con:{
        flexDirection: 'row',
        // borderTopWidth:1,
        // borderBottomWidth: 1,
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:'black',


    },
    FromHeaderContainer: {
        justifyContent: 'flex-start',
        width: '35%',
        borderColor:'black',
        fontSize: 9,
        borderRightWidth:1,
        padding:'3px',
        borderBottomWidth: 1,

    },
    ToContainer: {
        justifyContent: 'flex-start',
        width: '35%',
        borderColor:'black',
        fontSize: 9,
        borderLeftWidth:1,
        padding:'3px',
        borderBottomWidth: 1,
       
    },
    FromLabels:
    {
        width:'70px',
        fontSize: 9,
        fontWeight:'bold'
    },
    FromLabelsFlow:
    {
        flexDirection:'row',
    },
    Gap1:{
        borderTopWidth:0,
        borderBottomWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        width: '30%',
        backgroundColor:'white',
        color:'black'

        
    },
    Gap2:{
        borderTopWidth:0,
        borderBottomWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        width: '30%',
        backgroundColor: 'white',

        
    },
    PackingSlip:
    {
        textAlign:'center',
        fontSize: 12,
        fontWeight:'bold',
        color:'black'
   
        
    }
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        
        <View style={styles.headerRow}>
            <Text style={styles.From_Header}>From</Text>
            <View style={styles.Gap1}>
            <Text style={styles.PackingSlip}>PACKING SLIP</Text>
            </View>
            <Text style={styles.Ship_To_Header}>Ship To</Text>
        </View>

        <View style={styles.Total_Con}>
        <View style={styles.FromHeaderContainer}>
                 <View style={styles.FromLabelsFlow}>
                    <Text>{invoice.From_Address}</Text>
                </View>
        </View>
        <View style={styles.Gap2}>
        {/* <Text style={styles.PackingSlip}>PACKING SLIP</Text> */}
        </View>
        <View style={styles.ToContainer}>
        <View style={styles.FromLabelsFlow}>
            <Text>{invoice.To_Address}</Text>
        </View>
        </View>
        </View>

        {/* <View style={styles.Total_Con} >
        <View style={styles.FromHeaderContainer}>
                <View style={styles.FromLabelsFlow}>
                    <Text style={[styles.FromLabels, { fontWeight: 'bold' }]}>FROM:</Text>
                    <Text>Aspetto Inc.</Text>
                </View>
                <View style={styles.FromLabelsFlow}>
                    <Text style={[styles.FromLabels, { fontWeight: 'bold' }]}>POC: </Text>
                    <Text>{invoice.From_Name}</Text>
                </View>
                <View style={styles.FromLabelsFlow}>
                    <Text style={[styles.FromLabels, { fontWeight: 'bold' }]}>ADDRESS:</Text>
                    <Text>{invoice.From_Address}</Text>
                </View>
                <View style={styles.FromLabelsFlow}>
                    <Text style={[styles.FromLabels, { fontWeight: 'bold' }]}>EMAIL:</Text>
                    <Text>{invoice.From_Email}</Text>
                </View>
                <View style={styles.FromLabelsFlow}>
                    <Text style={[styles.FromLabels, { fontWeight: 'bold' }]}>PH:</Text>
                    <Text>{String(invoice.From_Phone)}</Text>
                </View>
            </View>
    <View style={styles.Gap}>
    </View>

     <View style={styles.Gap}>
    </View>
    <View style={styles.Date_Inv_Con}>
        <View style={styles.ToContainer}>
            <Text style={[styles.label,{ fontWeight: 'bold' }]}>TO: </Text>
            <Text>{invoice.Shipping_Details.Organization_Name}</Text>
        </View >
        <View style={styles.ATTNContainer}>
            <Text style={[styles.label,{ fontWeight: 'bold' }]}>ATTN: </Text>
            <Text>{invoice.KO_name}</Text>
        </View >
        <View style={styles.AddressContainer}>
            <Text style={[styles.label,{ fontWeight: 'bold' }]}>ADDRESS: </Text>
            <Text>{invoice.KO_address}</Text>
        </View >

        <View style={styles.PrintDateContainer}>
            <Text style={[styles.label,{ fontWeight: 'bold' }]}>PRINT DATE: </Text>
            <Text style={styles.invoiceDate}>{String(invoice.Print_Date)}</Text>
        </View >
    </View>
        </View> */}
    </Fragment>
);

export default InvoiceNo;