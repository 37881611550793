import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: { padding: 30 },
    section: { marginBottom: 10, padding: 10, flexGrow: 1 },
    heading: { fontSize: 18, marginBottom: 10, textAlign: 'center', color: '#003366' },
    text: { fontSize: 12, color: '#333333' },
});

const InvoicePDF = ({ invoiceData }) => (
    <Document>
        <Page style={styles.page}>
            <Text style={styles.heading}>Invoice</Text>

            <View style={styles.section}>
                <Text style={styles.text}>Invoice Number: {invoiceData.invoice_no}</Text>
                <Text style={styles.text}>Contract Vehicle: {invoiceData.Contract_Vehicle}</Text>
                <Text style={styles.text}>KO Name: {invoiceData.KO_name}</Text>
                <Text style={styles.text}>KO Email: {invoiceData.KO_email}</Text>
                <Text style={styles.text}>KO Phone: {invoiceData.KO_phone}</Text>
                <Text style={styles.text}>KO Address: {invoiceData.KO_address}</Text>
                <Text style={styles.text}>Print Date: {invoiceData.Print_Date}</Text>
                <Text style={styles.text}>Valid For: {invoiceData.Valid_For}</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.text}>Shipping Details:</Text>
                <Text style={styles.text}>Organization Name: {invoiceData.Shipping_Details.Organization_Name}</Text>
                <Text style={styles.text}>Customer Name: {invoiceData.Shipping_Details.Customer_Name}</Text>
                <Text style={styles.text}>Customer Email: {invoiceData.Shipping_Details.Customer_Email}</Text>
                <Text style={styles.text}>Customer Phone: {invoiceData.Shipping_Details.Customer_Phone}</Text>
                <Text style={styles.text}>Customer Address: {invoiceData.Shipping_Details.Customer_Address}</Text>
                <Text style={styles.text}>Shipping Method: {invoiceData.Shipping_Details.Shipping_Method}</Text>
                <Text style={styles.text}>Shipping Terms: {invoiceData.Shipping_Details.Shipping_Terms}</Text>
                <Text style={styles.text}>Estimated Delivery: {invoiceData.Shipping_Details.Estimated_Delivery}</Text>
                <Text style={styles.text}>Payment Terms: {invoiceData.Shipping_Details.Payment_Terms}</Text>
            </View>

            <View style={styles.section}>
                <Text style={styles.text}>Items:</Text>
                {invoiceData.items.map((item, idx) => (
                    <Text key={idx} style={styles.text}>{`${item.name || 'Item'} - ${item.qty || 0} @ $${item.price || 0} each`}</Text>
                ))}
            </View>

            <View style={styles.section}>
                <Text style={styles.text}>Total Cost: ${invoiceData.items.reduce((acc, item) => acc + (item.qty || 0) * (item.price || 0), 0).toFixed(2)}</Text>
            </View>
        </Page>
    </Document>
);

const GenerateInvoicePDF = ({ invoiceData }) => (
    <div>
        <PDFDownloadLink document={<InvoicePDF invoiceData={invoiceData} />} fileName="invoice.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
        </PDFDownloadLink>
    </div>
);

export default GenerateInvoicePDF;
