import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider,Container} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import { green, blueGrey,red,blue } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import axios from "../../axiosCustomInstance";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import { useNavigate,useSearchParams } from "react-router-dom";
import AxiosFunction from "../../axiosCustomInstance";
import { orange,indigo } from '@mui/material/colors';
import LoadingDialog from '../Loading'
import Login_Bck from "../Images/Login.png"
import Login_Im from "../Images/Aspetto_Login.png";
import cbpseal from "../Images/cbpseal.png"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PlaceIcon from '@mui/icons-material/Place';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import  jwtDecode  from 'jwt-decode';
import UserAuth from "../ProtectedRoute/userAuth";
import { selectCurrentToken, selectCurrentUser} from '../Login_Registration/authSlice'
import { useSelector } from 'react-redux'
import ApplicationBar from '../AppBar/ApplicationBar'
import ExcelUploaderInternalSales from "./ExcelUploaderInternalSales";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

function DHSCustomerPortalInternalSales() {
  // Get current date
  const PD = new Date();

  // Create Axios instance using custom Axios function
  const AxiosAPIInstance = AxiosFunction();

  // Extract search parameters using useSearchParams hook
  const [searchParams] = useSearchParams();

  // Get token from Redux store using useSelector
  const token = useSelector(selectCurrentToken);

  // Decode JWT token to extract user information
  const decoded = jwtDecode(token);
  const { UserName, UserEmail } = decoded.userInfo;

  // State variables to track user actions
  const [newOrderClick, setNewOrderClick] = useState(false);
  const [uploadExcelClick, setUploadExcelClick] = useState(false);

  const [Form_Value, Set_Form_Value] = useState({
    REQ_ID: "",
    Customer_Name: UserName,
    Customer_Email: UserEmail,
    User_ID: "",
    Location: "",
    Contract_Number: "70B06C23D00000002",
    Delivery_Order_Number: "",
    Refresh_Kit: false,
    CLIN: "",
    Order_Number: "",
    Armor_Gender: "",
    Order_Type: "",
    Height_ft_in: "",
    Weight_lbs: "",
    Chest_in: "",
    Waist_in: "",
    Front_Stand_in: "",
    Front_Sit_in: "",
    Back_in: "",
    Bra_Size: "",
    Cup_Size: "",
    Tip_Apex_to_Tip_Apex_in: "",
    Tip_Apex_to_Belt_in: "",
    Tip_Apex_to_Clavicle_Shoulder_in: "",
    Tip_Apex_to_Side_in: "",
    Side_Panel_Size: "",
    Cumberbund_Size: "",
    Front_Panel_Size: "",
    Back_Panel_Size: "",
    Base_Carrier_Size: "",
    Color: "",
    Placard: "",
    Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
    Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
    Created_By: "TBD",
    Version_No: parseInt(0),
    Temp_Token: token,
    Excel_Sheet_Upload: false,
    File_Name: 'NA',
    Barcode_Value: 'NA',
    Updating_Comments: "Requested"
  });

  // State variable to control the disabled state of components
  const [dis, setDis] = useState(false);

  // Hook to navigate between routes
  const navigate = useNavigate();

  // State variable to hold error messages
  const [dHSMssge, setDHSMssge] = useState('');

  // State variables to control visibility of success and error dialogs
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  // State variable to control the visibility of a dialog
  const [open, setOpen] = useState(false);

  // State variable to indicate loading state
  const [loading, setLoading] = useState(false);

  // State variable to hold status code for error handling
  const [statusCode, setStatusCode] = useState(false);

  // State variable to hold expiration time
  const [expTime, setExpTime] = useState(false);

  // Function to handle closing dialogs
  const handleClose = (e) => {
    // Reset state variables when closing dialog
    setOpen(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setErrorDialog(false);
  };

  // Function to handle form submission
  const HandleSubmit = async (event) => {
    // Set loading state to true and disable form
    setLoading(true);
    setDis(true);
    event.preventDefault();

    try {
      // Send a POST request to create a new order
      await AxiosAPIInstance.post("/DHSCustomer/CreateNewOrder", Form_Value).then(
        async (response) => {
          // If successful response, update state variables and reset form values
          setLoading(false);
          setSuccessDialog(true);
          setErrorDialog(false);
          setDHSMssge(response?.data);
          setDis(false);
          // Reset form values
          Set_Form_Value({
            User_ID: "",
            REQ_ID: "",
            Location: "",
            Contract_Number: "70B06C23D00000002",
            Delivery_Order_Number: "",
            Refresh_Kit: false,
            CLIN: "",
            Order_Number: "",
            Armor_Gender: "",
            Order_Type: "",
            Height_ft_in: "",
            Weight_lbs: "",
            Chest_in: "",
            Waist_in: "",
            Front_Stand_in: "",
            Front_Sit_in: "",
            Back_in: "",
            Bra_Size: "",
            Cup_Size: "",
            Tip_Apex_to_Tip_Apex_in: "",
            Tip_Apex_to_Belt_in: "",
            Tip_Apex_to_Clavicle_Shoulder_in: "",
            Tip_Apex_to_Side_in: "",
            Color: "",
            Placard: "",
            Created_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
            Last_Updated_Date: `${Number(PD.getUTCMonth()) + 1}-${PD.getUTCDate()}-${PD.getUTCFullYear()}`,
            Created_By: "TBD",
            Version_No: parseInt(0),
            File_Name: 'NA',
            Barcode_Value: "NA",
            Updating_Comments: "Requested"
          });
        }
      ).catch((error) => {
        // If error response, handle the error and update state variables
        setLoading(false);
        if (error?.response.status === 403) {
          setStatusCode(true);
        }
        setLoading(false);
        setSuccessDialog(false);
        setErrorDialog(true);
        setDHSMssge(error.response?.data);
        setDis(false);
      });
    } catch (error) {
      // Handle unexpected errors
      setLoading(false);
      setSuccessDialog(false);
      setErrorDialog(true);
      setDHSMssge("Cannot submit the request");
      setDis(false);
    }
  };

  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {open === true && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to exit this page?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All the changes will be lost if you decide to exit this page
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={handleClose} href="/Dashboard">Yes</Button>
            <Button name="No" onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {successDialog === true && (
        <Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false)
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorDialog === true && (
        <Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {dHSMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Stack component="form" direction="column" onSubmit={HandleSubmit} justifyContent="center" alignItems="center" spacing={4} sx={{ width: '100%' }}>
          <Stack direction="row" spacing={3} justifyContent="center" alignItems="center">
            <Box component="img" src={cbpseal} sx={{ height: "80px", width: "80px" }} />
            <Box component="img" src={Login_Im} sx={{ height: "60px", width: "180px", ml: 1, mt: 1 }} />
          </Stack>
          <Typography component="p" sx={{ alignContent: "center", borderColor: 'divider', fontSize: "22px", color: 'black', fontWeight: "bold" }}>
            DHS-CBP Customer Information Request Form
          </Typography>
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
          <Stack direction="column" alignItems="center" spacing={1}>
            <FormGroup>
              <FormControlLabel size="small" control={<Checkbox size="small" checked={newOrderClick} onChange={(e) => {
                setNewOrderClick(e.target.checked);
                setUploadExcelClick(!e.target.checked);
                Set_Form_Value({ ...Form_Value, Excel_Sheet_Upload: false });
              }} />} label="Create New Order" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel size="small" control={<Checkbox size="small" checked={uploadExcelClick} onChange={(e) => {
                setUploadExcelClick(e.target.checked);
                setNewOrderClick(!e.target.checked);
                Set_Form_Value({ ...Form_Value, Excel_Sheet_Upload: true });
              }} />} label="Upload File" />
            </FormGroup>
            {uploadExcelClick && <ExcelUploaderInternalSales />}
          </Stack>
          {newOrderClick && (
            <Stack direction="column" alignItems="center" spacing={1} sx={{ width: '100%' }}>
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <TextField size="small" variant="standard" sx={{ width: "300px" }} label="Contract Number" value={Form_Value.Contract_Number} required onChange={(e) => Set_Form_Value({ ...Form_Value, Contract_Number: e.target.value })} />
                <TextField size="small" variant="standard" sx={{ width: "300px" }} label="Delivery Order Number" value={Form_Value.Delivery_Order_Number} required onChange={(e) => Set_Form_Value({ ...Form_Value, Delivery_Order_Number: e.target.value.trim().toUpperCase() })} />
                <TextField size="small" variant="standard" sx={{ width: "300px" }} label="CLIN" value={Form_Value.CLIN} required onChange={(e) => Set_Form_Value({ ...Form_Value, CLIN: e.target.value })} />
                <TextField size="small" variant="standard" sx={{ width: "300px" }} label="Order Number" value={Form_Value.Order_Number} required onChange={(e) => Set_Form_Value({ ...Form_Value, Order_Number: e.target.value.trim().toUpperCase() })} />
              </Stack>
              <FormGroup>
                <FormControlLabel size="small" control={<Checkbox size="small" checked={Form_Value.Refresh_Kit} onChange={(e) => Set_Form_Value({ ...Form_Value, Refresh_Kit: e.target.checked })} />} label="Refresh Kit" />
              </FormGroup>
              <Divider sx={{ color: red[900], bgcolor: red[900], height: "2px", width: "100%" }} />
              <FormControl size="small" sx={{ width: "300px" }}>
                <InputLabel id="Order-Type">Order Type</InputLabel>
                <Select
                  size="small"
                  labelId="Order-Type"
                  variant="standard"
                  id="Order-Type-id"
                  value={Form_Value.Order_Type}
                  required
                  label="Order Type"
                  onChange={(e) => Set_Form_Value({ ...Form_Value, Order_Type: e.target.value })}
                >
                  <MenuItem size="small" name="SSO" value={"Scalable System Order"}>Scalable System Order</MenuItem>
                  <MenuItem size="small" name="TSO" value={"Traditional System Order"}>Traditional System Order</MenuItem>
                </Select>
              </FormControl>
              {Form_Value.Order_Type === 'Traditional System Order' && (
                <>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField size="small" variant="standard" sx={{ width: "300px" }} label="User ID" value={Form_Value.User_ID} required onChange={(e) => Set_Form_Value({ ...Form_Value, User_ID: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField size="small" variant="standard" sx={{ width: "300px" }} label="Location" value={Form_Value.Location} required onChange={(e) => Set_Form_Value({ ...Form_Value, Location: e.target.value.trim().toUpperCase() })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonPinCircleIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <FormControl size="small" sx={{ width: "300px" }}>
                      <InputLabel id="Armor-Gender">Armor Gender</InputLabel>
                      <Select
                        size="small"
                        labelId="Armor-Gender"
                        variant="standard"
                        id="Armor-Gender-id"
                        value={Form_Value.Armor_Gender}
                        required
                        label="Armor Gender"
                        onChange={(e) => Set_Form_Value({ ...Form_Value, Armor_Gender: e.target.value })}
                      >
                        <MenuItem size="small" name="Ma" value={"Male"}>Male</MenuItem>
                        <MenuItem size="small" name="Fe" value={"Female"}>Female</MenuItem>
                        <MenuItem size="small" name="Ot" value={"Others"}>Others</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Height(ft-in)" value={Form_Value.Height_ft_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Height_ft_in: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                      }} />
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Weight(lbs)" value={Form_Value.Weight_lbs} required onChange={(e) => Set_Form_Value({ ...Form_Value, Weight_lbs: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ScaleIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Chest(inches)" value={Form_Value.Chest_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Chest_in: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FitnessCenterIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Waist(inches)" value={Form_Value.Waist_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Waist_in: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ContactMailIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Front Stand(inches)" value={Form_Value.Front_Stand_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Front_Stand_in: e.target.value })} />
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Front Sit(inches)" value={Form_Value.Front_Sit_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Front_Sit_in: e.target.value })} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Back(inches)" value={Form_Value.Back_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Back_in: e.target.value })} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Color" value={Form_Value.Color} onChange={(e) => Set_Form_Value({ ...Form_Value, Color: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PaletteIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Placard" value={Form_Value.Placard} required onChange={(e) => Set_Form_Value({ ...Form_Value, Placard: e.target.value })} />
                  </Stack>
                  {Form_Value.Armor_Gender === "Female" && (
                    <>
                      <Divider sx={{ color: red[900], bgcolor: red[900], height: "2px", width: "100%" }} />
                      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Bra Size" value={Form_Value.Bra_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Bra_Size: e.target.value })} />
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Cup Size" value={Form_Value.Cup_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Cup_Size: e.target.value })} />
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Tip(Apex) to Tip(Apex) (inches)" value={Form_Value.Tip_Apex_to_Tip_Apex_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Tip_Apex_to_Tip_Apex_in: e.target.value })} />
                      </Stack>
                      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Tip(Apex) to Belt (inches)" value={Form_Value.Tip_Apex_to_Belt_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Tip_Apex_to_Belt_in: e.target.value })} />
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Tip(Apex) to Clavicle(Shoulder)(inches)" value={Form_Value.Tip_Apex_to_Clavicle_Shoulder_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Tip_Apex_to_Clavicle_Shoulder_in: e.target.value })} />
                        <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Tip(Apex) to Side(inches)" value={Form_Value.Tip_Apex_to_Side_in} required onChange={(e) => Set_Form_Value({ ...Form_Value, Tip_Apex_to_Side_in: e.target.value })} />
                      </Stack>
                    </>
                  )}
                </>
              )}
              {Form_Value.Order_Type === 'Scalable System Order' && (
                <>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField size="small" variant="standard" sx={{ width: "300px" }} label="User ID" value={Form_Value.User_ID} required onChange={(e) => Set_Form_Value({ ...Form_Value, User_ID: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField size="small" variant="standard" sx={{ width: "300px" }} label="Location" value={Form_Value.Location} required onChange={(e) => Set_Form_Value({ ...Form_Value, Location: e.target.value.trim().toUpperCase() })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonPinCircleIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Base Carrier Size" value={Form_Value.Base_Carrier_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Base_Carrier_Size: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Front Panel Size(inches)" value={Form_Value.Front_Panel_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Front_Panel_Size: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                      }} />
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Back Panel Size(inches)" value={Form_Value.Back_Panel_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Back_Panel_Size: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Cumberbund Size" value={Form_Value.Cumberbund_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Cumberbund_Size: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HeightIcon />
                          </InputAdornment>
                        ),
                      }} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Side Panel Size" value={Form_Value.Side_Panel_Size} required onChange={(e) => Set_Form_Value({ ...Form_Value, Side_Panel_Size: e.target.value })} />
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Color" value={Form_Value.Color} onChange={(e) => Set_Form_Value({ ...Form_Value, Color: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PaletteIcon />
                          </InputAdornment>
                        ),
                      }} />
                  </Stack>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Placard" value={Form_Value.Placard} required onChange={(e) => Set_Form_Value({ ...Form_Value, Placard: e.target.value })} />
                  </Stack>
                </>
              )}
              <Button sx={{ width: "300px", bgcolor: indigo[500] }} variant="contained" type="submit" disabled={dis}>Submit</Button>
            </Stack>
          )}
          <Typography component="p" sx={{ alignContent: "center", borderColor: 'divider', fontSize: "19px", color: 'black', fontWeight: "bold" }}>Sales Head Details:</Typography>
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
            <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Customer Name" value={Form_Value.Customer_Name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
                readOnly: true
              }} />
            <TextField variant="standard" size="small" sx={{ width: "300px" }} label="Customer Email" value={Form_Value.Customer_Email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  </InputAdornment>
                ),
                readOnly: true
              }} />
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default DHSCustomerPortalInternalSales;
