// Importing necessary components and styles from Material-UI
// along with other dependencies
import React, { useState } from 'react';
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Stack,
  Paper,
  Divider,
  Box,
  Chip,
  IconButton,
  Link
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance'; // Assuming this is your Axios instance
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Importing images
import Login_Bck from "../Images/Login.png";
import Login_Im from "../Images/Aspetto_Login.png";
import cbpseal from "../Images/cbpseal.png";
import Logo from "../Images/logo.png";
import DHSCBP from "../Images/DHS-CBP.png";

// Importing icons from Material-UI
import { Twitter, Facebook, Instagram, LinkedIn, Fullscreen } from '@mui/icons-material';

// Importing styles and themes from Material-UI
import { bgcolor, color, styled, ThemeProvider } from '@mui/system';
import { green, blueGrey, red, blue, grey, cyan } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Importing Loading component
import LoadingDialog from '../Loading';

// Importing EmailIcon from Material-UI
import EmailIcon from '@mui/icons-material/Email';

const DHSCustomerEmailPage = () => {

// State variables to manage error message and error dialog visibility
const [errMsge, setErrMsge] = useState("");
const [errorDialog, setErrorDialog] = useState(false);

// State variables to manage success message and success dialog visibility
const [successMsge, setSuccessMsge] = useState("");
const [successDialog, setSuccessDialog] = useState(false);

// State variable to manage form data (first name, last name, email)
const [formData, setFormData] = useState({
  firstName: '',
  lastName: '',
  email: '',
});

// State variable to manage form disable state
const [dis, setDis] = useState(true);

// Hook to access navigation functions
const navigate = useNavigate();

// State variable to manage loading state
const [loading, setLoading] = useState(false);


  const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });
// Define styles using the styled utility
const Footer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '12px', // Adjust the font size as needed
  color: 'gray',   // Adjust the color as needed
}));
const SocialIcons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));


  const {token}  = useParams()
  const [verificationStatus, setVerificationStatus] = useState('');

// Function to handle form submission
const handleSubmit = async (e) => {
  // Set loading state to true to indicate form submission in progress
  setLoading(true);
  // Prevent default form submission behavior
  e.preventDefault();
  // Enable form input fields
  setDis(false);
  
  // Extract form data from state and prepare customer details object
  var Customer_Details = {
    Customer_Email: String(formData.email).toLowerCase().trim(),
    Customer_FirstName: String(formData.firstName),
    Customer_LastName: String(formData.lastName)
  };

  try {
    // Send a POST request to server with customer details
    await AxiosGlobalAPIInstance.post('/DHSCustomer/Email', Customer_Details)
      .then(async (response) => {
        // If request is successful
        // Set loading state to false
        setLoading(false);
        // Show success dialog
        setSuccessDialog(true);
        // Set success message received from server
        setSuccessMsge(response?.data);
        // Hide error dialog
        setErrorDialog(false);
        // Clear error message
        setErrMsge("");
        // Disable form input fields
        setDis(true);
      })
      .catch((error) => {
        // If request fails
        // Set loading state to false
        setLoading(false);
        // Show error dialog
        setErrorDialog(true);
        // Set error message received from server
        setErrMsge(error?.response.data);
        // Hide success dialog
        setSuccessDialog(false);
        // Clear success message
        setSuccessMsge("");
        // Disable form input fields
        setDis(true);
      });
  } catch (error) {
    // If an error occurs during request
    // Set loading state to false
    setLoading(false);
    // Set verification status message
    setVerificationStatus('Email verification failed.');
    // Show error dialog
    setErrorDialog(true);
    // Set error message
    setErrMsge('Email verification failed.');
    // Hide success dialog
    setSuccessDialog(false);
    // Clear success message
    setSuccessMsge("");
    // Disable form input fields
    setDis(true);
  }
};

// Function to handle closing of dialogs
const handleClose = (e) => {
  // If success dialog is open, reset form data
  if (successDialog === true) {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
    });
  }

  // Prevent default behavior
  e.preventDefault();
  // Hide error dialog
  setErrorDialog(false);
  // Clear success message
  setSuccessMsge("");
  // Hide success dialog
  setSuccessDialog(false);
  // Clear error message
  setErrMsge("");
  // Disable form input fields
  setDis(true);
};



  return (
    <>

      <LoadingDialog open={loading} />
   {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        <Alert variant='filled' severity='error'>error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle  id="alert-dialog-title">
        <Alert variant='filled' severity='success'>success</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {successMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}


 <Stack direction="column" sx={{ backgroundImage: `url(${Login_Bck})`,
      backgroundSize:'cover',
        height: "81vh",
        ":fullscreen":true,alignItems:'center'}} justifyContent="center">
          
    <Paper  component='form'  spacing={3} sx={{backgroundColor:'white',borderRadius:"16px",}}>
<Stack  direction='row' spacing={4} sx={{backgroundColor:'white',borderRadius:"15px",}} justifyContent="center">
<Stack direction="column" justifyContent="center">
<Box component="img" src={Login_Im} sx={{height:"75px",width:"220px",marginLeft:"65px"}}></Box>
<Chip label="DHS-CBP Email Request Page" sx={{fontSize:"18px",fontWeight:'bold',color:blueGrey[700],fontFamily:'Verdana (sans-serif)',marginLeft:"35px"}}></Chip>
</Stack>
{/* <Divider sx={{color:blueGrey[900],bgcolor:blueGrey[900],height:"310px"}} orientation='vertical'></Divider> */}
<Stack direction='column' spacing={2}  justifyContent="center">
<Stack></Stack>
<Stack></Stack>
<TextField type="text"  
value={formData.firstName} 
onChange={(e)=>{setFormData({...formData,firstName:e.target.value})
}}
variant='outlined' 
size="small"  
required 
name='firstName'
id="firstName" 
label="First Name" 

></TextField>

<TextField type="text"  
value={formData.lastName} 
onChange={(e)=>{setFormData({...formData,lastName:e.target.value})
}}
variant='outlined'  
size="small"  
required 
name='lastName'
id="lastName" 
label="Last Name" 
></TextField>

<TextField type="text"   
value={formData.email} 
onChange={(e)=>{setFormData({...formData,email:e.target.value})
}}
variant='outlined' 
size="small"  
required 
name='email'
id="Email" 
label="Workplace Email" 
InputProps={{ startAdornment: (
  <InputAdornment position="start">
    <EmailIcon/>
  </InputAdornment>
),}}
></TextField>
<Button type="submit" size='small' variant="contained" color="warning" disabled={!dis} fullWidth onClick={(e)=>{handleSubmit(e)}} >Request Link (Email) </Button>
<Button  size='small' variant="contained" sx={{backgroundColor:blueGrey[600]}} disabled={!dis}  onClick={(e)=>{e.preventDefault();navigate("/",{replace:true})}}>Go Back to Login Page </Button>
<Stack></Stack>
<Stack></Stack>
</Stack>
<Stack></Stack>
<Stack></Stack>
</Stack>
<Stack direction="row" justifyContent="center" sx={{borderBottomLeftRadius:"16px",borderBottomRightRadius:"16px",backgroundColor:blueGrey[900]}}>
<Typography variant='body2' sx={{backgroundColor:blueGrey[900],color:'whitesmoke',borderBottomLeftRadius:"16px",borderBottomRightRadius:"16px",padding:"10px",alignContent:"center"}}>This Webpage is reserved for DHS-CBP customers. Kindly utilize your agency's workplace email</Typography>        
</Stack>

</Paper>
</Stack>
<Stack direction="row" spacing={4} sx={{ bgcolor: blueGrey[900], color: "white", padding: 1, fontSize: "11px", height: "100px" }}>
            <Stack direction="column" spacing={0.1} sx={{ margin:'50px', }}>
              <IconButton size='large' edge='start' color='inherit' >
                <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
              </IconButton>
              <p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
              <p>Inc. 5000 America's Fastest Growing Private Companies</p>
              <p>ISO certified ISO 9001:2015</p>
            </Stack>
            <Stack></Stack>
            <Stack direction="column" spacing={0.1} sx={{ marginLeft: "20px",padding:'20px'  }} justifyContent="right">

              <p>Let's talk:</p>
              <p>Phone: 540-547-8487</p>
              <p>Fax: 540-300-2669</p>
              <Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}></Stack>
            <Stack direction="column" spacing={0.1} sx={{ alignItems: 'center', }}>
              <p></p>
              <p>SOCIAL MEDIA</p>
              <Stack direction="row" spacing={.5} justifyContent="center" >
                <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
                  <Twitter color="white" sx={{ backgroundColor: "white", color: cyan[400] }} />
                </IconButton>
                <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <Facebook color="white" sx={{ backgroundColor: "white", color: blue[800] }} />
                </IconButton>
                <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
                  <Instagram color="white" sx={{ backgroundColor: "white", color: red[800] }} />
                </IconButton>
                <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <LinkedIn sx={{ backgroundColor: "white", color: blueGrey[600] }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
  </>
  );
};

export default DHSCustomerEmailPage;

