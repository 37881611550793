import {React} from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';
import {
    blue,
    blueGrey
  } from '@mui/material/colors'
import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });


const borderColor = theme.palette.primary.main

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: blueGrey[900],
        alignItems: 'center',
        fontStyle: 'bold',
        fontSize: 8.5,
    },
    Sales_Head: {
        width: '37.5%',
        textAlign: 'center',
        fontSize: 8.5,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color:blue[900],
        fontWeight:'bold',
    },
    Shipping_Method: {
        width: '15%',
        borderRightColor: borderColor,
        borderLeftColor: borderColor,
        textAlign: 'center',
        fontSize: 8.5,
    },
    Shipping_Terms:{
        width: '10%',
        textAlign: 'center',
        fontSize: 8.5,
        borderRightColor: borderColor,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        borderRightWidth: 1,
    },
    Estimated_Delivery:{
        width: '22.5%',
        borderRightColor: borderColor,
        borderLeftColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize: 8.5,
    },
    Payment_Terms: {
        width: '15%',
        textAlign: 'center',
        fontSize: 8.5,
    },
});

const ShippingTableRow = ({ Shipping_Details }) => {
   
    return(
        <>
            <View style={styles.Sales_Head}><Text>{Shipping_Details.Sales_Head_Info.Name+", "+
             String(Shipping_Details.Sales_Head_Info.Email).toLowerCase()+", "}</Text><Text>{Shipping_Details.Sales_Head_Info.Phone}</Text></View>
            <View style={styles.Shipping_Method}><Text >{Shipping_Details.Shipping_Method}</Text></View>
            <View style={styles.Shipping_Terms}><Text >{Shipping_Details.Shipping_Terms}</Text></View>
            <View style={styles.Estimated_Delivery}><Text>{Shipping_Details.Estimated_Delivery}</Text></View>
            <View style={styles.Payment_Terms}><Text>{Shipping_Details.Payment_Terms}</Text></View>
        </>
    )
};

export default ShippingTableRow;