import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { textAlign } from '@mui/system';
import { Font } from '@react-pdf/renderer'
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

  Font.register({
    family: 'Montserrat',
    src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2',
  });

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        fontSize: 9,
       
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        fontSize: 9,
    },
    headerRow:{
        flexDirection: 'row',
        backgroundColor: theme.palette.primary.main,
        fontWeight:"extrabold",
        color:'white',
        fontSize: 9,
        marginTop:"30px",
    },
    KOsHeader: {
        fontSize: 9,
        width: '45%',
        padding:'3px',
        textAlign:'left',
        fontWeight:'extrabold',
        fontFamily: 'Montserrat',
    },
    COsHeader: {
        fontSize: 9,
        width: '37.5%',
        padding:'3px',
        textAlign:'left',
        fontFamily: 'Montserrat',
        fontWeight:'extrabold'

    },
    QuoteInfoHeader: {
        fontSize: 9,
        width: '17.5%',
        padding:'3px',
        textAlign:'left',
        fontFamily: 'Montserrat',
        fontWeight:'ultrabold'

    },
    invoiceDate: {
        fontSize: 9,
        fontStyle: 'bold',
        textAlign:'right',
    },
    label: {
        width: 60,
        fontSize: 9,
    },
    Total_Con:{
        flexDirection: 'row',
        borderTopWidth:1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:'black',
        backgroundColor:blueGrey[75]

    },
    KosHeaderContainer: {
        justifyContent: 'flex-start',
        width: '45%',
        borderColor:'black',
        fontSize: 9,
        borderRightWidth:1,
        padding:'3px',

    },
    Date_Inv_Con:{
        flexDirection: 'column',
        borderColor:'black',
        width: '20%',
        borderColor:'black',
        padding:'3px',
    },
    QuotedTo: {
        width: '35%',
        borderColor:'black',
        fontSize: 9,
        borderColor:'black',
        padding:'3px',
        borderRightWidth:1,
        
    },
    Quote_label: {
        width: 70,
        fontSize: 9,
        fontWeight:'ultrabold'
    },
    KOsLabels:
    {
        width:'70px',
        fontSize: 9,
        fontWeight:'ultrabold'
    },
    KOsLabelsFlow:
    {
        flexDirection:'row',
    },
    Gap:{
        borderTopWidth:0,
        borderBottomWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        width: '2%',

    }
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
     <View style={styles.headerRow}>
      <Text style={styles.KOsHeader}>Customer Details</Text>
      <Text style={styles.KOsHeader}>Ship To:</Text>
      <Text style={styles.QuoteInfoHeader}>Quote Info</Text>
    </View>
        <View style={styles.Total_Con} >
    <View style={styles.KosHeaderContainer}>
        <View style={styles.KOsLabelsFlow}><Text  style={styles.KOsLabels}>AGENCY NAME:</Text><Text>{invoice.Shipping_Details.Organization_Name}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text  style={styles.KOsLabels}>POC: </Text><Text>{invoice.KO_name}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text  style={styles.KOsLabels}>ADDRESS:</Text><Text>{invoice.KO_address}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text  style={styles.KOsLabels}>EMAIL:</Text><Text>{invoice.KO_email}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text  style={styles.KOsLabels}>PH:</Text><Text>{String(invoice.KO_phone)}</Text></View>
    </View>
    <View style={styles.Gap}>

    </View>
    <View style={styles.KosHeaderContainer}>
    <View style={styles.KOsLabelsFlow}><Text>{invoice.Shipping_Details.Organization_Name}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text>{invoice.KO_name}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text>{invoice.KO_address}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text>{invoice.KO_email}</Text></View>
        <View style={styles.KOsLabelsFlow}><Text>{String(invoice.KO_phone)}</Text></View>
</View>
     <View style={styles.Gap}>

    </View>
    <View style={styles.Date_Inv_Con}>
       <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>QUOTE#: </Text>
            <Text style={styles.invoiceDate}>{String(invoice.invoice_num)}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>DATE: </Text>
            <Text style={styles.invoiceDate}>{String(invoice.trans_date)}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>PRINT DATE: </Text>
            <Text style={styles.invoiceDate}>{String(invoice.Print_Date)}</Text>
        </View >
    </View>
        </View>
    </Fragment>
);

export default InvoiceNo;