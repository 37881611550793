import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { store } from '../src/app/store';
import { Provider } from 'react-redux';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}
const root = ReactDOM.createRoot(document.getElementById('root'));


const UnderMaintenance = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: '#f2f2f2',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
    },
    heading: {
      fontSize: '3rem',
      marginBottom: '1rem',
    },
    paragraph: {
      fontSize: '1.2rem',
      marginBottom: '0.5rem',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>We'll be back soon!</h1>
      <p style={styles.paragraph}>Sorry for the inconvenience but we're performing some maintenance at the moment. We'll be back online shortly!</p>
    </div>
  );
};

root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
    <Routes>
      <Route path="/*" element={<App    />} />
    </Routes>
  </BrowserRouter>
  </Provider>
  // <UnderMaintenance />
  // </React.StrictMode>

);

