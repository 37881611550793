import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Stack,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  useMediaQuery,
  Paper,
  Grid,
  Box,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import AxiosFunction from '../../axiosCustomInstance';
import { useTheme } from '@mui/material/styles';
import UserAuth from "../ProtectedRoute/userAuth";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingDialog from '../Loading';
import ApplicationBar from '../AppBar/ApplicationBar';
import RevenueGoalMetrics from './RevenueGoalMetrics';
import MetricsDashboard from './OtherGoalMetrics';

const RevenueGoalForm = () => {
  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(PD);

  const [loading, setLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [dis, setDis] = useState(false);
  const [errorSuccessMessage, setErrorSuccessMessage] = useState('');
  const AxiosAPIInstance = AxiosFunction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { UserName, UserEmail } = UserAuth();

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDivision, setSelectedDivision] = useState('Tactical');

  const [revenueGoal, setRevenueGoal] = useState({
    Goal_Year: selectedYear,
    Goal_ID: '',
    Set_By_Name: String(UserName),
    Set_By_Email: String(UserEmail),
    Division: selectedDivision.toUpperCase(),
    TotalRevenueGoal: 0,
    AverageRequiredMarginPercentGoal: 0,
    NewBusinessGoal: 0,
    RenewalGoal: 0,
    UpsellGoal: 0,
    CrossSellGoal: 0,
    FederalRevenueGoal: 0,
    MilitaryRevenueGoal: 0,
    TacticalRevenueGoal: 0,
    GSARevenueGoal: 0,
    FBOPRevenueGoal: 0,
    DHS_CBPRevenueGoal: 0,
    SAM_OpenMarketRevenueGoal: 0,
    UnisonRevenueGoal: 0,
    DirectInquiryRevenueGoal: 0,
    CommercialRevenueGoal: 0,
    ActualRevenueGoalMet: 0,
    RevenueDeficit: 0,
    PercentOfRevenueGoalMet: 0,
    Created_Date: String(today),
    Last_Updated_Date: String(today),
    Updated_By: String(UserEmail),
    Version_No: 0,
    Updating_Comments: '',
    History: '',
  });

  const [totalData, setTotalData] = useState([]);

  // Update year handler
  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setRevenueGoal((prevState) => ({
      ...prevState,
      Goal_Year: year,
    }));
  };

  // Update division handler
  const handleDivisionChange = (e) => {
    const division = e.target.value;
    setSelectedDivision(division);
    setRevenueGoal((prevState) => ({
      ...prevState,
      Division: division.toUpperCase(),
    }));
  };

  const handleRevenueGoalChange = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''));
    setRevenueGoal({ ...revenueGoal, [e.target.name]: isNaN(value) ? 0 : value });
  };

  const fetchRevenueGoalsData = async () => {
    setLoading(true);
    try {
      const response = await AxiosAPIInstance.get("/RevenueGoals/Year", {
        params: { Goal_Year: String(selectedYear), Division: String(selectedDivision) },
      });
      if (response?.data) {
        setTotalData(response.data);
        if (response.data.length > 0) {
          const goalData = response.data[0];
          setRevenueGoal({
            ...revenueGoal,
            TotalRevenueGoal: goalData.TotalRevenueGoal || 0,
            AverageRequiredMarginPercentGoal: goalData.AverageRequiredMarginPercentGoal || 0,
            NewBusinessGoal: goalData.NewBusinessGoal || 0,
            RenewalGoal: goalData.RenewalGoal || 0,
            UpsellGoal: goalData.UpsellGoal || 0,
            CrossSellGoal: goalData.CrossSellGoal || 0,
            FederalRevenueGoal: goalData.FederalRevenueGoal || 0,
            MilitaryRevenueGoal: goalData.MilitaryRevenueGoal || 0,
            TacticalRevenueGoal: goalData.TacticalRevenueGoal || 0,
            GSARevenueGoal: goalData.GSARevenueGoal || 0,
            FBOPRevenueGoal: goalData.FBOPRevenueGoal || 0,
            DHS_CBPRevenueGoal: goalData.DHS_CBPRevenueGoal || 0,
            SAM_OpenMarketRevenueGoal: goalData.SAM_OpenMarketRevenueGoal || 0,
            UnisonRevenueGoal: goalData.UnisonRevenueGoal || 0,
            DirectInquiryRevenueGoal: goalData.DirectInquiryRevenueGoal || 0,
            CommercialRevenueGoal: goalData.CommercialRevenueGoal || 0,
          });
        }
        resetDialogs();
      } else {
        showErrorDialog('Failed to fetch revenue goals. Please try again.');
      }
    } catch (error) {
      showErrorDialog('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDis(true);
    try {
      if (revenueGoal.TotalRevenueGoal > 0 && revenueGoal.AverageRequiredMarginPercentGoal > 0) {
        const response = await AxiosAPIInstance.post('/RevenueGoals/SetOrUpdate', revenueGoal);
        if (response?.data) {
          showSuccessDialog(response.data.message);
          resetFormFields();
          fetchRevenueGoalsData();
        } else {
          showErrorDialog('Failed to save revenue goal. Please try again.');
        }
      } else {
        showErrorDialog('Revenue Goals cannot be set to empty or zero values.');
      }
    } catch (error) {
      showErrorDialog('Error saving revenue goal.');
    } finally {
      setLoading(false);
    }
  };

  const resetFormFields = () => {
    setRevenueGoal({
      ...revenueGoal,
      TotalRevenueGoal: 0,
      AverageRequiredMarginPercentGoal: 0,
      NewBusinessGoal: 0,
      RenewalGoal: 0,
      UpsellGoal: 0,
      CrossSellGoal: 0,
      FederalRevenueGoal: 0,
      MilitaryRevenueGoal: 0,
      TacticalRevenueGoal: 0,
      GSARevenueGoal: 0,
      FBOPRevenueGoal: 0,
      DHS_CBPRevenueGoal: 0,
      SAM_OpenMarketRevenueGoal: 0,
      UnisonRevenueGoal: 0,
      DirectInquiryRevenueGoal: 0,
      CommercialRevenueGoal: 0,
    });
  };

  const resetDialogs = () => {
    setErrorDialog(false);
    setSuccessDialog(false);
    setErrorSuccessMessage('');
  };

  const showErrorDialog = (message) => {
    setErrorDialog(true);
    setErrorSuccessMessage(message);
    setDis(false);
  };

  const showSuccessDialog = (message) => {
    setSuccessDialog(true);
    setErrorSuccessMessage(message);
    setDis(false);
  };

  const formatNumberWithCommas = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  useEffect(() => {
    fetchRevenueGoalsData();
  }, [selectedYear, selectedDivision]);

  const handleClose = () => {
    setErrorSuccessMessage(''); // Clear the success or error message
    setErrorDialog(false);      // Close the error dialog
    setSuccessDialog(false);    // Close the success dialog
    setDis(false);              // Re-enable the submit button if it was disabled
  };
  
  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />

      {successDialog && (
        <Dialog open={successDialog} onClose={handleClose}>
          <DialogTitle>
            {errorSuccessMessage && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: 'black' }}>
              {errorSuccessMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}

      {errorDialog && (
        <Dialog open={errorDialog} onClose={handleClose}>
          <DialogTitle>
            {errorSuccessMessage && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: 'black' }}>
              {errorSuccessMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}

      <Box sx={{ marginTop: '70px', padding: '20px' }}>
        <Paper elevation={3} sx={{ padding: '20px', maxWidth: '900px', margin: 'auto' }}>
          <Typography gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '25px' }}>
            Revenue Goals
          </Typography>

          <Divider sx={{ bgcolor: orange[800], height: "2px", marginBottom: '20px' }} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='standard'>
                <InputLabel>Select Year</InputLabel>
                <Select
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {[...Array(5)].map((_, index) => {
                    const year = new Date().getFullYear() - 2 + index;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant='standard'>
                <InputLabel>Division</InputLabel>
                <Select
                  value={selectedDivision}
                  onChange={handleDivisionChange}
                >
                  <MenuItem value={"Tactical"}>Tactical</MenuItem>
                  <MenuItem value={"Engineering"}>Engineering</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Total Revenue Goal ($)"
                variant='standard'
                type="text"
                name="TotalRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.TotalRevenueGoal)}
                onChange={handleRevenueGoalChange}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Average Required Margin (%)"
                variant='standard'
                type="number"
                name="AverageRequiredMarginPercentGoal"
                value={revenueGoal.AverageRequiredMarginPercentGoal}
                onChange={handleRevenueGoalChange}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="New Business Goal ($)"
                variant='standard'
                type="text"
                name="NewBusinessGoal"
                helperText="The total revenue expected from acquiring new customers or clients. 
                This goal focuses on expanding the customer base through new contracts or sales."
                value={formatNumberWithCommas(revenueGoal.NewBusinessGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Renewal Goal ($)"
                variant='standard'
                type="text"
                name="RenewalGoal"
                helperText="The total revenue expected from renewing existing customer contracts or subscriptions. 
                This goal emphasizes customer retention and ensuring ongoing relationships."
                value={formatNumberWithCommas(revenueGoal.RenewalGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Upsell Goal ($)"
                variant='standard'
                type="text"
                name="UpsellGoal"
                helperText="The total revenue expected from upgrading existing customers to higher-value products or services. 
                This goal is aimed at increasing the value of existing customer relationships."
                value={formatNumberWithCommas(revenueGoal.UpsellGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Cross-Sell Goal ($)"
                variant='standard'
                type="text"
                helperText="The total revenue expected from selling additional products or services to existing customers. 
                This goal focuses on broadening the range of products or services purchased by current clients."
                name="CrossSellGoal"
                value={formatNumberWithCommas(revenueGoal.CrossSellGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>

            {/* Add fields for each contract type */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Federal Revenue Goal ($)"
                variant='standard'
                type="text"
                name="FederalRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.FederalRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Military Revenue Goal ($)"
                variant='standard'
                type="text"
                name="MilitaryRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.MilitaryRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Tactical Revenue Goal ($)"
                variant='standard'
                type="text"
                name="TacticalRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.TacticalRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="GSA Revenue Goal ($)"
                variant='standard'
                type="text"
                name="GSARevenueGoal"
                value={formatNumberWithCommas(revenueGoal.GSARevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="FBOP Revenue Goal ($)"
                variant='standard'
                type="text"
                name="FBOPRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.FBOPRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="DHS-CBP Revenue Goal ($)"
                variant='standard'
                type="text"
                name="DHS_CBPRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.DHS_CBPRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="SAM/Open Market Revenue Goal ($)"
                variant='standard'
                type="text"
                name="SAM_OpenMarketRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.SAM_OpenMarketRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Unison Revenue Goal ($)"
                variant='standard'
                type="text"
                name="UnisonRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.UnisonRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Direct Inquiry Revenue Goal ($)"
                variant='standard'
                type="text"
                name="DirectInquiryRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.DirectInquiryRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Commercial Revenue Goal ($)"
                variant='standard'
                type="text"
                name="CommercialRevenueGoal"
                value={formatNumberWithCommas(revenueGoal.CommercialRevenueGoal)}
                onChange={handleRevenueGoalChange}
              />
            </Grid>

          </Grid>

          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              variant="contained"
              color="warning"
              disabled={dis}
              size='small'
              onClick={handleSubmit}
              sx={{ width: isMobile ? "100%" : '200px' }}
            >
              Set Goals
            </Button>
          </Box>

          {/* Display Set Goals with Metrics */}
          <MetricsDashboard />
          {/* <RevenueGoalMetrics totalData={totalData} /> */}
  
        </Paper>
      </Box>
    </>
  );
};

export default RevenueGoalForm;
