import React from "react";

function DHS_SKUs_ARRAY()
{
  const DHS_SKUs_ARRAY=[{ID:'ASP-DHS-TRAUMAPAD',Desc:'Trauma Pad'},
  {ID:'ASP-DHS-TRADITIONALPKG-OCP',Desc:`TRADITIONAL PACKAGE
  Non-Structured and Structured, All Sizes, To Include:
  •Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
  •Concealable Carrier 
  •Front Ballistic Panel 
  •Back Ballistic Panel 
  •Trauma Pad 
  •Placards/Badge 
  •Accessory Platform 
  •Handgun Magazine Pouch 
  •Carry Bag 
  Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-TRADITIONALPKG`,Desc:	`TRADITIONAL PACKAGE 
   Non-Structured and Structured, All Sizes, To Include: 
   •Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Carrier 
   •Front Ballistic Panel 
   •Back Ballistic Panel 
   •Trauma Pad 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-SCALABLEPKG-OCP`	,Desc:`SCALABLE PACKAGE 
   All Sizes, To include: 
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Ballistic Panel 
   •Back Ballistic Panel 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Ballistic Panels (2) 
   •Side Panel Covers (2) 
   •Trauma Pad 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-SCALABLEPKG`,Desc:`SCALABLE PACKAGE 
   All Sizes, To include:
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Ballistic Panel 
   •Back Ballistic Panel 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Ballistic Panels (2) 
   •Side Panel Covers (2) 
   •Trauma Pad 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-REFRESHKIT-OCP` ,Desc:`REFRESH KIT (SCALABLE) 
   All Sizes; To include:
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Panel Covers (2) 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-REFRESHKIT`,Desc: `REFRESH KIT (SCALABLE) 
   All Sizes; To include: 
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Panel Covers (2) 
   •Placards/Badge•Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-TRADITIONALPANELS`,Desc:	`Traditional Ballistic Panels (Set of Front and Back)
   All Sizes`},
   {ID:`ASP-DHS-TRADITIONALOUTER-OCP`,Desc:	`Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   All Sizes
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-TRADITIONALOUTER`,Desc:`Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   All Sizes
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-SIDEPLATEPCH-OCP`	,Desc:`Side Plate Pouch (Set of 2)
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-SIDEPLATEPCH`	,Desc:`Side Plate Pouch (Set of 2)
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-SIDEPANELCOVERS-OCP`	,Desc:`Side Panel Covers (set of 2)
   All Sizes
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-SIDEPANELCOVERS`	,Desc:`Side Panel Covers (set of 2)
   All Sizes
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-SIDEBAL`,Desc:	`Side Ballistic Panels (Set of 2)
   All Sizes`},
   {ID:`ASP-DHS-SCALABLEFRONTPANEL`,Desc:	`Scalable Front Ballistic Panel (Single)
   All Sizes`},
   {ID:`ASP-DHS-SCALABLEBACKPANEL`,Desc:	`Scalable Back Ballistic Panel (Single)
   All Sizes`},
   {ID:`ASP-DHS-PLACARDS`,Desc:`Placards (Set of Front and Back)`},
   {ID:`ASP-DHS-MAGPCH-OCP`,Desc:	`Handgun Magazine Pouch Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-MAGPCH`,Desc:	`Handgun Magazine Pouch Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-INDREMOVABLE-OCP`,Desc:	`Independent Removeable MOLLE Platform Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-INDREMOVABLE`,Desc:	`Independent Removeable MOLLE Platform Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-HANDCUFFPCH-OCP`,Desc:	`Handcuff Pouch Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-HANDCUFFPCH`,Desc:	`Handcuff Pouch Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-FRONTPANELCOVER-OCP`,Desc:	`Front Panel Cover All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-FRONTPANELCOVER`,Desc:	`Front Panel Cover All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-FITKIT`,Desc:	`Fit Kit`},
   {ID:`ASP-DHS-CUMMERBUND-OCP`,Desc:	`Cummerbund  All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-CUMMERBUND`	,Desc:`Cummerbund All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-CONCEALABLECONKIT-OCP`,Desc:	`Concealable Conversion Kit All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-CONCEALABLECONKIT`,Desc:`Concealable Conversion Kit All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-CONCEALABLECARRIER-OCP`	,Desc:`Concealable Carrier Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-CONCEALABLECARRIER`,Desc:	`Concealable Carrier Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-BOX`,Desc:	`"Pizza Box"`},
   {ID:`ASP-DHS-BASECARRIER-OCP`,Desc:`Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-BASECARRIER`,Desc:`Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-BADGE-PVC` ,Desc:`Badge (PVC)`},
   {ID:`ASP-DHS-BADGE-EM`	,Desc:`Badge (Embroidered)`},
   {ID:`ASP-DHS-BACKPANELCOVER-OCP`,Desc:	`Back Panel Cover All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-BACKPANELCOVER`,Desc:	`Back Panel Cover All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`ASP-DHS-ACCPL-OCP`,Desc:	`Accessory Platform
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`ASP-DHS-ACCPL`,Desc:	`Accessory Platform
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`,
   ID:'BASP-DHS-TRAUMAPAD',Desc:'Trauma Pad'},
  {ID:'BASP-DHS-TRADITIONALPKG-OCP',Desc:`TRADITIONAL PACKAGE
  Non-Structured and Structured, All Sizes, To Include:
  •Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
  •Concealable Carrier 
  •Front Ballistic Panel 
  •Back Ballistic Panel 
  •Trauma Pad 
  •Placards/Badge 
  •Accessory Platform 
  •Handgun Magazine Pouch 
  •Carry Bag 
  Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-TRADITIONALPKG`,Desc:	`TRADITIONAL PACKAGE 
   Non-Structured and Structured, All Sizes, To Include: 
   •Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Carrier 
   •Front Ballistic Panel 
   •Back Ballistic Panel 
   •Trauma Pad 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-SCALABLEPKG-OCP`	,Desc:`Scalable Package
   All Sizes, To include:
   • Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   • Concealable Conversion Kit
   • Front Ballistic Panel
   • Back Ballistic Panel
   • Front Panel Cover
   • Back Panel Cover
   • Side Ballistic Panels (2)
   • Side Panel Covers (2)
   • Trauma Pad
   • Placards/Badge
   • Accessory Platform
   • Handgun Magazine Pouch
   • Carry Bag
   Colors: Operational
   Camouflage Pattern Scorpion
   W2 (OCP)`},
   {ID:`BASP-DHS-SCALABLEPKG`,Desc:`Scalable Package
   All Sizes, To include:
   • Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   • Concealable Conversion Kit
   • Front Ballistic Panel
   • Back Ballistic Panel
   • Front Panel Cover
   • Back Panel Cover
   • Side Ballistic Panels (2)
   • Side Panel Covers (2)
   • Trauma Pad
   • Placards/Badge
   • Accessory Platform
   • Handgun Magazine Pouch
   • Carry Bag
   Colors: Black, Coyote (498),
   Ranger Green, Blue (LAPD
   Navy), CBP Specific Color`},
   {ID:`BASP-DHS-REFRESHKIT-OCP` ,Desc:`REFRESH KIT (SCALABLE) 
   All Sizes; To include:
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Panel Covers (2) 
   •Placards/Badge 
   •Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-REFRESHKIT`,Desc: `REFRESH KIT (SCALABLE) 
   All Sizes; To include: 
   •Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) 
   •Concealable Conversion Kit 
   •Front Panel Cover 
   •Back Panel Cover 
   •Side Panel Covers (2) 
   •Placards/Badge•Accessory Platform 
   •Handgun Magazine Pouch 
   •Carry Bag
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-TRADITIONALPANELS`,Desc:	`Traditional Ballistic Panels (Set of Front and Back)
   All Sizes`},
   {ID:`BASP-DHS-TRADITIONALOUTER-OCP`,Desc:	`Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   All Sizes
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-TRADITIONALOUTER`,Desc:`Traditional Outer Carrier (including Cummerbund and Independent Removeable MOLLE Platform)
   All Sizes
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-SIDEPLATEPCH-OCP`	,Desc:`Side Plate Pouch (Set of 2)
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-SIDEPLATEPCH`	,Desc:`Side Plate Pouch (Set of 2)
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-SIDEPANELCOVERS-OCP`	,Desc:`Side Panel Covers (set of 2)
   All Sizes
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-SIDEPANELCOVERS`	,Desc:`Side Panel Covers (set of 2)
   All Sizes
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-SIDEBAL`,Desc:	`Side Ballistic Panels (Set of 2)
   All Sizes`},
   {ID:`BASP-DHS-SCALABLEFRONTPANEL`,Desc:	`Scalable Front Ballistic Panel (Single)
   All Sizes`},
   {ID:`BASP-DHS-SCALABLEBACKPANEL`,Desc:	`Scalable Back Ballistic Panel (Single)
   All Sizes`},
   {ID:`BASP-DHS-PLACARDS`,Desc:`Placards (Set of Front and Back)`},
   {ID:`BASP-DHS-MAGPCH-OCP`,Desc:	`Handgun Magazine Pouch Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-MAGPCH`,Desc:	`Handgun Magazine Pouch Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-INDREMOVABLE-OCP`,Desc:	`Independent Removeable MOLLE Platform Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-INDREMOVABLE`,Desc:	`Independent Removeable MOLLE Platform Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-HANDCUFFPCH-OCP`,Desc:	`Handcuff Pouch Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-HANDCUFFPCH`,Desc:	`Handcuff Pouch Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-FRONTPANELCOVER-OCP`,Desc:	`Front Panel Cover All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-FRONTPANELCOVER`,Desc:	`Front Panel Cover All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-FITKIT`,Desc:	`Fit Kit`},
   {ID:`BASP-DHS-CUMMERBUND-OCP`,Desc:	`Cummerbund  All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-CUMMERBUND`	,Desc:`Cummerbund All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-CONCEALABLECONKIT-OCP`,Desc:	`Concealable Conversion Kit All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-CONCEALABLECONKIT`,Desc:`Concealable Conversion Kit All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-CONCEALABLECARRIER-OCP`	,Desc:`Concealable Carrier Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-CONCEALABLECARRIER`,Desc:	`Concealable Carrier Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-BOX`,Desc:	`"Pizza Box"`},
   {ID:`BASP-DHS-BASECARRIER-OCP`,Desc:`Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-BASECARRIER`,Desc:`Base Carrier (including Cummerbund and Independent Removeable MOLLE Platform) All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-BADGE-PVC` ,Desc:`Badge (PVC)`},
   {ID:`BASP-DHS-BADGE-EM`	,Desc:`Badge (Embroidered)`},
   {ID:`BASP-DHS-BACKPANELCOVER-OCP`,Desc:	`Back Panel Cover All Sizes Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-BACKPANELCOVER`,Desc:	`Back Panel Cover All Sizes Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`},
   {ID:`BASP-DHS-ACCPL-OCP`,Desc:	`Accessory Platform
   Colors: Operational Camouflage Pattern Scorpion W2 (OCP)`},
   {ID:`BASP-DHS-ACCPL`,Desc:	`Accessory Platform
   Colors: Black, Coyote (498), Ranger Green, Blue (LAPD Navy), CBP Specific Color`
  
  
  }]

   return DHS_SKUs_ARRAY

}
export default DHS_SKUs_ARRAY