import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Stack, Divider, Snackbar, Paper,Card,CardActionArea,CardMedia,CardContent} from '@mui/material';
import QrCode from 'react-qr-code';
import axios from 'axios';
import ApplicationBar from '../AppBar/ApplicationBar';
import LoadingDialog from '../Loading';
import { blueGrey, orange } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UserAuth from '../ProtectedRoute/userAuth';
import AxiosFunction from '../../axiosCustomInstance';

const GenerateQR = () => {
  const [url, setUrl] = useState(''); // State for storing URL input
  const [dataURL, setDataURL] = useState(''); // State for storing generated QR code data URL
  const [dialogMessage, setDialogMessage] = useState(''); // State for dialog message content
  const [successDialog, setSuccessDialog] = useState(false); // State for success dialog visibility
  const [errorDialog, setErrorDialog] = useState(false); // State for error dialog visibility
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [existingQRs, setExistingQRs] = useState([]); // State for storing existing QR codes
  
  const { UserName, UserEmail } = UserAuth(); // Destructuring user authentication data
  const PD = new Date(); // Creating a new Date object
  const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD); // Formatting today's date
  const APICustomInstance = AxiosFunction(); // Creating an Axios instance for API calls
  
  // State for storing QR code data with initial values
  const [qrCode, setQrCode] = useState({
    QR_CODE_URL: '', // QR code URL
    Created_By_Name: UserName, // Name of the user who created the QR code
    Created_By_Email: UserEmail, // Email of the user who created the QR code
    Version_No: 0, // Version number of the QR code
    Updating_Comments: 'NA', // Comments for updating the QR code
    Created_Date: today, // Date when the QR code was created
    Updated_Date: today, // Date when the QR code was last updated
    Updated_By_Name: UserName, // Name of the user who last updated the QR code
    Updated_By_Email: UserEmail, // Email of the user who last updated the QR code
  });
  

  useEffect(() => {
    // Fetch existing QR codes from the database
    setLoading(true)
    const fetchExistingQRs = async () => {
      try {
        const response = await APICustomInstance.get('/ProductInstructionsUpload/GetAllQRCodes');
        setExistingQRs(response?.data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching existing QR codes:', error.message);
        setErrorDialog(true)
        setDialogMessage('Error fetching existing QR codes:')
        setLoading(false)
        
      }
    };

    fetchExistingQRs();
  }, [dialogMessage]);

  const isValidURL = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const handleGenerateQR = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true); // Set loading state to true

    // Update QR code state with the entered URL
    setQrCode({ ...qrCode, QR_CODE_URL: url });

    try {
        // Check if the entered URL is valid
        if (isValidURL(url)) {
            // Send a POST request to the API to generate QR code
            const response = await APICustomInstance.post('/ProductInstructionsUpload/QRCodeGenerator', qrCode);
            // Set the generated QR code data URL in state
            setDataURL(response.data.dataURL);
            // Display success dialog
            setSuccessDialog(true);
            setErrorDialog(false);
            setDialogMessage('QR Code generated successfully!');
            setLoading(false); // Set loading state to false
        } else {
            // Display error dialog for invalid URL
            setErrorDialog(true);
            setSuccessDialog(false);
            setDialogMessage('Invalid URL. Please enter a valid URL and try again.');
            setLoading(false); // Set loading state to false
        }
    } catch (error) {
        // Display error dialog for API request failure
        setErrorDialog(true);
        setSuccessDialog(false);
        setDialogMessage(error?.response.data); // Set error message from API response
        setLoading(false); // Set loading state to false
    } finally {
        setLoading(false); // Ensure loading state is set to false after try/catch block execution
    }
};

const handleCloseDialogs = () => {
  // Close both success and error dialogs
  setSuccessDialog(false);
  setErrorDialog(false);
  // Clear dialog message
  setDialogMessage('');
};


  return (
    <>
    <ApplicationBar />
    <LoadingDialog open={loading} />
    <Stack direction="column" justifyContent="center" sx={{ marginTop: '80px', padding: '16px' }}>
      <Typography sx={{ fontSize: '25px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}> QR Code Generator</Typography>
      <Stack direction='row' justifyContent='center'>
        <TextField
          label="URL"
          variant="outlined"
          sx={{ width: "400px" }}
          size='small'
          value={url}
          onChange={(e) => { setUrl(e.target.value); setQrCode({ ...qrCode, QR_CODE_URL: e.target.value }) }}
          style={{ marginBottom: '20px' }}
        />
      </Stack>
      <Stack direction='row' justifyContent='center'>
        <Snackbar open={successDialog || errorDialog} autoHideDuration={6000} onClose={handleCloseDialogs} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleCloseDialogs} severity={successDialog ? 'success' : 'error'} sx={{ width: '100%' }}>
            {dialogMessage}
          </Alert>
        </Snackbar>
      </Stack>
      <Stack direction='row' justifyContent='center'>
        <Button variant="contained" color="primary" type="submit" sx={{ width: "200px" }} onClick={handleGenerateQR}>
          Generate QR Code
        </Button>
      </Stack>
      <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: '100%', height: '1.5px', my: '20px' }} orientation="horizontal" />
      {existingQRs.length > 0 && (
        <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
          {existingQRs.map((existingQR) => (
            <Card key={existingQR.QR_CODE_URL} sx={{ maxWidth: 300, margin: '10px' }}>
              <CardMedia/>
              <CardContent>
              <QrCode value={existingQR.QR_CODE_URL} size={84} />
                <Typography gutterBottom variant="h6">
                  QR Code
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {existingQR.QR_CODE_URL}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
      {dataURL && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">Generated QR Code:</Typography>
          <img src={dataURL} alt="QR Code" style={{ maxWidth: '100%', marginTop: '10px' }} />
        </div>
      )}
    </Stack>
  </>
  );
};

export default GenerateQR;
