import {React} from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        alignItems: 'center',
        fontStyle: 'bold',
        fontSize:9,
    },
    Delivery_Order_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Order_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Invoice_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Total_Boxes: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },

});

const Packing_Slip_Contract_Info_Row = ({ invoice }) => {
   
    return(
            <>
     <View style={styles.row}>
        <Text style={styles.Delivery_Order_Number}>{invoice.Delivery_Order_Number}</Text>
        <Text style={styles.Order_Number}>{invoice.Order_Number}</Text>
        <Text style={styles.Invoice_Number}>{invoice.Invoice_Number}</Text>
        <Text style={styles.Total_Boxes}>{invoice.Total_Boxes}</Text>
    </View>
     </>

    )
};

export default Packing_Slip_Contract_Info_Row;