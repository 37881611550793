import {React} from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { style } from '@mui/system';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: theme.palette.primary.main,
        color:"white",
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        fontWeight:"extrabold",
        flexGrow: 1,
    },
    Delivery_Order_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Order_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Invoice_Number: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
    Total_Boxes: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:9
    },
});

const Packing_Slip_Contract_Info_Header = () => (
    <>
    <View style={styles.container}>
        <Text style={styles.Delivery_Order_Number}>Delivery Order Number</Text>
        <Text style={styles.Order_Number}>Order Number</Text>
        <Text style={styles.Invoice_Number}>Invoice Number</Text>
        <Text style={styles.Total_Boxes}>Total Boxes</Text>
    </View>
     </>
);

export default Packing_Slip_Contract_Info_Header;