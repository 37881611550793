import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Login_Registration/authSlice';
import  jwtDecode  from 'jwt-decode';

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  // Check if the token is a non-empty string before decoding
  const decoded = typeof token === 'string' && token !== '' ? jwtDecode(token) : null;

  // Check if decoded exists and has a valid userInfo property
  const { UserName, UserEmail } = decoded?.userInfo || {};

  return UserEmail && token ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
