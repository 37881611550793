import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { style } from '@mui/system';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[100],
        },
    },
  });



const borderColor = theme.palette.primary.main
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        alignItems: 'center',
        fontStyle: 'bold',
        paddingRight: 8,
        paddingLeft: 8,
    },
    description: {
        width: '40%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderLeftColor: borderColor,
        borderLefttWidth: 1,
        borderRightWidth: 1,
        fontSize:8.5,
        borderBottomWidth: 1,
        
    },
    qty: {
        width: '7%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5,
        borderBottomWidth: 1,
    },
    UM: {
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5,
        borderBottomWidth: 1,
    },
    rate: {
        width: '13%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize:8.5,
        borderBottomWidth: 1,
    },
    SKU:{
        width: '15%',
        borderLeftColor: borderColor,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        textAlign: 'center',
        fontSize:8.5,
        borderBottomWidth: 1,

    },
    SNo:{
        width: '5%',
        borderRightColor: borderColor,
        borderLeftColor: borderColor,
        borderLeftWidth: 1,
        textAlign: 'center',
        fontSize:8.5,
        borderBottomWidth: 1,
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        fontSize:8.5,
        borderRightWidth: 1,
        borderBottomWidth: 1,
    },
});

const InvoiceTableRow = ({ invoice }) => {
    const rows = invoice.items?.map((item,index) =>
    <>
    <View style={styles.SNo} wrap={false}><Text>{(index + 1).toLocaleString()}</Text></View>
    <View style={styles.SKU} wrap={false}><Text>{item.SKU_ID}</Text></View>
    <View style={styles.description} wrap={false}><Text>{String(item.SKU_Description)}</Text></View>
    <View style={styles.qty} wrap={false}><Text>{item.QTY}</Text></View>
    <View style={styles.UM} wrap={false}><Text>{item.Unit_Of_Measure}</Text></View>
    <View style={styles.rate} wrap={false}><Text>{'$ ' + Number(item.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text></View>
    <View style={styles.amount} wrap={false}><Text>{'$ ' + Number(item.QTY * item.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text></View>
</>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow;