import React, { Fragment } from 'react';
import { Text, View, StyleSheet,Image,Font } from '@react-pdf/renderer';
import { textAlign } from '@mui/system';
import {
    blue,
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });
// Register the Roboto font
Font.register({
    family: 'Roboto',
    fonts: [
      { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf' },
      { src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' }
    ]
  });
  
const styles = StyleSheet.create({
    boldLabel: {
        fontWeight: 'bold',
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        fontSize: 8.5,
       
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        fontSize: 8.5,
    },
    headerRow:{
        flexDirection: 'row',
        backgroundColor: theme.palette.primary.main,
        fontWeight:"bold",
        color:'white',
        fontSize: 8.5,
        marginTop:"10px",
    },
    KOsHeader: {
        fontSize: 8.5,
        padding:'3px',
        textAlign:'left',
        fontWeight:'bold',
        width: '35%',
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:blueGrey[900]
    },
    COsHeader: {
        fontSize: 8.5,
        padding:'3px',
        textAlign:'left',
        fontWeight:'bold',
        width: '35%',
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:blueGrey[900]

    },
    QuoteInfoHeader: {
        fontSize: 8.5,
        padding:'3px',
        textAlign:'left',
        fontWeight:'bold',
        width: '20%',
        borderLeftWidth:1,
        borderRightWidth:1,
        borderColor:blueGrey[900]

    },
    invoiceDate: {
        fontSize: 8.5,
        fontStyle: 'bold',
        textAlign:'right',
    },
    label: {
        width: 60,
        fontSize: 8.5,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
    },
    Total_Con:{
        flexDirection: 'row',
        borderLeftWidth:1,
        borderRightWidth:1,
        borderBottomWidth:1,
        borderColor:theme.palette.primary.main,

    },
    KosHeaderContainer: {
        justifyContent: 'flex-start',
        borderColor:theme.palette.primary.main,
        fontSize: 8.5,
        borderRightWidth:1,
        borderBottomWidth:1,
        padding:'3px',
        width: '35%',

    },
    Date_Inv_Con:{
        flexDirection: 'column',
        borderColor:theme.palette.primary.main,
        borderBottomWidth:1,
        borderLeftWidth:1,
        width: '20%',
        borderColor:theme.palette.primary.main,
        padding:'3px',
    },
    QuotedTo: {
        borderColor:theme.palette.primary.main,
        fontSize: 8.5,
        padding:'3px',
        // borderRightWidth:1,
        // borderLeftWidth:1,
        // borderBottomWidth:1,
        width: '35%',
        
    },
    Quote_label: {
        width: 70,
        fontSize: 8.5,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
    },
    KOsLabels:
    {
        width:'70px',
        fontSize: 8.5,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
    },
    KOsLabelsFlow:
    {
        flexDirection:'row',
        fontFamily: 'Roboto',
    },
    Gap1:{
        borderTopWidth:0,
        borderBottomWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        width: '5%',
        backgroundColor:'#fff'

    },
    Gap2:{
        borderTopWidth:0,
        borderBottomWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        width: '5%',
        backgroundColor:'#fff'

    },
    EmailColor:
    {
     color:blue[900],
     textDecoration: 'underline'
    }
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={styles.headerRow}>
            {/* <Text style={styles.KOsHeader}>Customer Details</Text>
            <View style={styles.Gap1}>
            </View> */}
            <Text style={styles.COsHeader}>Bill To</Text>
            {/* <View style={styles.Gap1}>
            </View>
            <Text style={styles.QuoteInfoHeader}>Quote Info</Text> */}
        </View>
        <View style={styles.Total_Con} >
{/* 
    <View style={styles.Gap2}>
    </View> */}
    <View style={styles.QuotedTo}>
    {invoice?.BillTo_Name!=='' && (<Text>{invoice.BillTo_Name}</Text>)}
    {invoice?.BillTo_Address!=='' &&(<Text>{invoice.BillTo_Address}</Text>)}
    {invoice?.BillTo_Email!=='' && (<Text style={styles.EmailColor}>{invoice.BillTo_Email}</Text>)}
    {invoice?.BillTo_Phone!=='' && (<Text>{String(invoice.BillTo_Phone)}</Text>)}
        
     
    </View>
     <View style={styles.Gap2}>
    </View>
    </View>
    </Fragment>
);

export default InvoiceNo;