import {Box,Button,TextField,Paper, Stack,Typography,IconButton, Avatar, Grid, Tooltip,Divider} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { DatePicker } from "@mui/x-date-pickers";
import PdfDocument from "../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import userAuth from "../ProtectedRoute/userAuth"
import { orange,indigo,green,red} from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { color } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from "../../axiosCustomInstance"
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { set } from 'date-fns';
import Slate from '../Images/Slate.png'
const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
 

  function SKUsRow(props) {
      const PD= new Date()
      const { row} = props;
      const [open, setOpen] = React.useState(false);
      const[clicked,setClicked]=useState(false)

      return (
        <React.Fragment>
          <TableRow>
            <TableCell sx={{border:1,borderColor:'text.primary',color:orange[900]}} align="center" scope="row">{row.Part_No}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Item_Description}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Threat_Level}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Size}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Shape}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Style}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Thickness}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.color}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Viewport}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Curvature}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Model}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.WholeSale}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Agency}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.MSRP}</TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
   
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }
      
      TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
      };     
const SKUsRetrieve=()=>
{
    const [skuSubType, setSKUSubType]=useState(['Concealable Vests','Concealable Carriers / Options','Outer Tactical Vests','Tactical Carriers / Options','Plate Carrier Vests','Plate Carriers / Options',
    'Accessories','Accessory Carriers','K-9 Vests','K-9 Carriers','Tactical Equipment Pouches','Tactical Flotation Accessories','ID Placards','Bomb Blankets'])
    const [skuSearchParams,setSKUSearchParams]=useState({sku:'Vest',skuSub:'Concealable Vests',year:'2021-2022'})
    // Part_No:'',Item_Description:'',Threat_Level:'',Thickness:'',color:'',Size:'',Shape:'',Style:'',Curvature:'',Viewport:'',Model:'',WholeSale:'',Agency:'',MSRP:''
    const [dis,setDis]=useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [GetClick,SetGetClick]=useState(false)
    const [totalData,setTotalData]=useState([{}]) 
    const [comboSearch,setComboSearch]=useState(false)
    const [partSearch,setPartSearch]=useState(false)
    const [partNumber,setPartNumber]=useState('')
    const AxiosAPIInstance=AxiosFunction()
    const [errorDialog,setErrorDialog]=useState(false)
    const [errMssge,setErrMsge]=useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSKUTypeChange=(e)=>
    {
    
     SetGetClick(false)
     if(e.target.value==='Vest')
     {
        setSKUSubType(['Concealable Vests','Concealable Carriers / Options','Outer Tactical Vests','Tactical Carriers / Options','Plate Carrier Vests','Plate Carriers / Options',
         'Accessories','Accessory Carriers','K-9 Vests','K-9 Carriers','Tactical Equipment Pouches','Tactical Flotation Accessories','ID Placards','Bomb Blankets'])
         setSKUSearchParams({...skuSearchParams,sku:'Vest',skuSub:'Concealable Vests'})
     }
     else if(e.target.value==='Hard Armor')
     {
        setSKUSubType(['Hard Armor Plates','Hard Armor Helmets','Helmet Components','Hard Armor Shields','Shield Accessories','Riot Shields'])
        setSKUSearchParams({...skuSearchParams,sku:'Hard Armor',skuSub:'Hard Armor Plates'})
     }
     else
     {
        setSKUSubType(['Concealable Armor Inserts','Outer Tactical Vest Inserts','Plate Carrier Inserts','Accessory Inserts','K-9 Armor Inserts','Soft Armor Panel Inserts'])
        setSKUSearchParams({...skuSearchParams,sku:'Insert',skuSub:'Concealable Armor Inserts'})
     }
    }
    
    
const handleSKUSubTypeChange=()=>{
      SetGetClick(false)
    }
    
    
const handleClose=()=>{
      setErrorDialog(false)
      setErrMsge('')
      SetGetClick(false)
      setDis(false)
    }

const handleSkuSubmit=async(e)=>{
  try{
      e.preventDefault()
        SetGetClick(true)
        setDis(true)
        setTotalData([{}])
        
        try
        {
        if(partSearch===true)
        {
          if(partNumber!=='')
          {
          await AxiosAPIInstance.get('SKUsSearch/PartNo',{params:{Part_No:partNumber}}).then(async(response)=>{
          setDis(false)
          setTotalData([{...response?.data}])
           }).catch(async(err)=>{
            setErrorDialog(true)
            setErrMsge(err?.response?.data)
            setDis(false)
           })
          }
          else
          {
          setErrorDialog(true)
          setErrMsge("Part Number is Required")
          setDis(false)

          }
        }
        else if(comboSearch===true)
        {
          if(skuSearchParams.sku==='Vest')
          {
          if(skuSearchParams.skuSub==='Concealable Vests'||skuSearchParams.skuSub==='Outer Tactical Vests'
          ||skuSearchParams.skuSub==='Plate Carrier Vests'||skuSearchParams.skuSub==='Accessories'||skuSearchParams.skuSub==='K-9 Vests'
          ||skuSearchParams.skuSub==='Tactical Equipment Pouches'||skuSearchParams.skuSub==='Tactical Flotation Accessories'||skuSearchParams.skuSub==='ID Placards'||
          skuSearchParams.skuSub==='Bomb Blankets')
          {
            await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'Vest',sku_sub:skuSearchParams.skuSub,sku_sub_sub:skuSearchParams.skuSub}}).then(
              async(response)=>{
                setTotalData(response?.data)
                setDis(false)
              }
            ).catch(async(err)=>{
              setErrorDialog(true)
              setErrMsge(err?.response?.data)
              setDis(false)
            })
          }
          else
          {
            await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'Vest',sku_sub:'N/A',sku_sub_sub:skuSearchParams.skuSub}}).then(
              async(response)=>{
                setTotalData(response?.data)
                setDis(false)
              }
            ).catch(async(err)=>{
              setErrorDialog(true)
              setErrMsge(err?.response?.data)
              setDis(false)
            })
          }
          }
          else if(skuSearchParams.sku==='Hard Armor')
          { 
            if(skuSearchParams.skuSub==='Hard Armor Plates'||skuSearchParams.skuSub==='Hard Armor Helmets'
            ||skuSearchParams.skuSub==='Hard Armor Shields'||skuSearchParams.skuSub==='Riot Shields')
            {
              await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'Hard Armor',sku_sub:skuSearchParams.skuSub,sku_sub_sub:skuSearchParams.skuSub}}).then(
                async(response)=>{
                  setTotalData(response?.data)
                  setDis(false)
                }
              ).catch(async(err)=>{
                setErrorDialog(true)
                setErrMsge(err?.response?.data)
                setDis(false)
              })
            }
            else
            {
              await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'Hard Armor',sku_sub:'N/A',sku_sub_sub:skuSearchParams.skuSub}}).then(
                async(response)=>{
                  setTotalData(response?.data)
                  setDis(false)
                }
              ).catch(async(err)=>{
                setErrorDialog(true)
                setErrMsge(err?.response?.data)
                setDis(false)
              })
            }
          }
          else
          { setSKUSubType(['Concealable Armor Inserts','Outer Tactical Vest Inserts','Plate Carrier Inserts','Accessory Inserts','K-9 Armor Inserts','Soft Armor Panel Inserts'])
            if(skuSearchParams.skuSub==='Concealable Armor Inserts'||skuSearchParams.skuSub==='Outer Tactical Vest Inserts'
            ||skuSearchParams.skuSub==='Plate Carrier Inserts'||skuSearchParams.skuSub==='Accessory Inserts'||skuSearchParams.skuSub==='K-9 Armor Inserts'
            ||skuSearchParams.skuSub==='Soft Armor Panel Inserts')
            {
              await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'Insert',sku_sub:skuSearchParams.skuSub,sku_sub_sub:skuSearchParams.skuSub}}).then(
                async(response)=>{
                  setTotalData(response?.data)
                  setDis(false)
                }
              ).catch(async(err)=>{
                setErrorDialog(true)
                setErrMsge(err?.response?.data)
                setDis(false)
              })
            }
            else
            {
              await AxiosAPIInstance.get('SKUsSearch/ComboSearch',{params:{sku:'InsertHard Armor',sku_sub:'N/A',sku_sub_sub:skuSearchParams.skuSub}}).then(
                async(response)=>{
                  setTotalData(response?.data)
                  setDis(false)
                }
              ).catch(async(err)=>{
                setErrorDialog(true)
                setErrMsge(err?.response?.data)
                setDis(false)
              })
            }
          }
        }
        else
        {
          setErrorDialog(true)
          setErrMsge("Please select any of the select filters")
          setDis(false)
        }
      }
      catch
      {
        setErrorDialog(true)
        setErrMsge("Something Went Wrong!")
        setDis(false)
      }
    }
    catch
    {
      setErrorDialog(true)
      setErrMsge("Something Went Wrong")
    }
    }
     
    return (
        <>
        {<ApplicationBar></ApplicationBar>}
        {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {errMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/SKUs">OK</Button>
        </DialogActions>
      </Dialog>)}
        
        <Stack component='form' autoComplete='off' justifyContent="center" sx={{marginTop:"65px"}}>
       <Stack direction='row' justifyContent="center">
      <img src={Slate} alt='Slate Image' height="50px" width="150px"></img>
      </Stack>
        <Stack direction='row' justifyContent="center" spacing={10} sx={{marginTop:"10px",}}>
          <Stack  justifyContent="center" direction='column'  spacing={1}>
          <FormGroup  style={{
            transform: "scale(1)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={partSearch} onChange={(e)=>{
          SetGetClick(false)
          setTotalData([{}])
          setPartSearch(e.target.checked)
          if(e.target.checked===true)
          {
           setComboSearch(false)
          }
          else
          {
            setPartNumber('')
          }

        }}/>} label="Part Number Search" />
         </FormGroup>
         <TextField size="small"  required disabled={!partSearch}  variant="outlined"  label="Part Number" value={partNumber} onChange={(e)=>{setPartNumber(e.target.value);SetGetClick(false)}}></TextField>
          </Stack>
         
      
        <Stack direction='column' spacing={1} sx={{marginTop:"10px"}} justifyContent="center" >
        <FormGroup  style={{
            transform: "scale(1)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={comboSearch} onChange={(e)=>{
         SetGetClick(false)
         setTotalData([{}])
         setComboSearch(e.target.checked)
          if(e.target.checked===true)
          {
            setPartSearch(false)
            setPartNumber('')
          }
          

        }}/>} label="Combo Search" />
         </FormGroup>
        <Stack direction='row' sx={{marginTop:"40px",}} spacing={5}> 
        <FormControl size="small" disabled={!comboSearch}>
        <InputLabel id="SKU_Type">Select Year</InputLabel>
        <Select size="small"  
         variant='standard'
          labelId="SKU_Type"
          id="SKU_Type-id"
          value={skuSearchParams.year}
          label="SKU Type"
          onChange={(e)=>{ 
          setSKUSearchParams({...skuSearchParams,year:e.target.value});
          handleSKUTypeChange(e);}}

          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"2021-2022"}>2021-2022</MenuItem>
        </Select>
      </FormControl>
        <FormControl size="small" sx={{marginTop:"30px"}} disabled={!comboSearch}>
        <InputLabel id="SKU_Type">SKU Type</InputLabel>
        <Select size="small"
          labelId="SKU_Type"
          id="SKU_Type-id"
          value={skuSearchParams.sku}
          variant='standard'
          label="SKU Type"
          onChange={(e)=>{ 
          setSKUSearchParams({...skuSearchParams,sku:e.target.value});
          handleSKUTypeChange(e);}}

          sx={{width:"150px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"Vest"}>Vest</MenuItem>
          <MenuItem size="small" value={"Hard Armor"}>Hard Armor</MenuItem>
          <MenuItem size="small" value={"Insert"}>Insert</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{width:"250px"}} disabled={!comboSearch}>
                <InputLabel id="skuSubType-id">
                   Select {skuSearchParams.sku} Options
                </InputLabel>
                <Select
                    labelId="skuSubType-id"
                    
                    value={skuSearchParams.skuSub}
                    variant='standard'
                    size='small'
              
                    onChange={(e) => {
                        setSKUSearchParams({...skuSearchParams,skuSub:e.target.value})
                        handleSKUSubTypeChange(e)
                    }}>
                  {skuSubType?.map((row,index)=>{return (<MenuItem value={skuSubType[index]}
                    >{skuSubType[index]}</MenuItem>)})}
                </Select>
            </FormControl>
            
      </Stack>
      </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center" ><Button sx={{width:'250px',marginTop:"20px",marginBottom:"20px"}} variant="contained" color='success' disabled={dis} onClick={(e)=>{handleSkuSubmit(e)}} >Get</Button></Stack> 
      <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:"20px"}} orientation='horizontal'></Divider>
    </Stack>
    {GetClick ===true && (partSearch!==false || comboSearch!==false) &&
        (<TableContainer >
        <Table margin="2" aria-label="POs_DateRange">
          <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
            <TableRow>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Part No</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Item Description</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Threat Level</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Size</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Shape</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Style</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Thickness</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Color</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Viewport</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Curvature</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Model</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">WholeSale($)</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Agency</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">MSRP($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <SKUsRow key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
        </>
    );
}

export default SKUsRetrieve