import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[100],
        },
    },
});
const borderColor = 'black';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        fontSize: 9,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor: blueGrey[100],
    },
    total: {
        width: '15%',
        textAlign: 'center',
        borderRightWidth: 1,
        backgroundColor: blueGrey[100],
    },
    Realtotal: {
        width: '15%',
        textAlign: 'center',
        borderRightWidth: 1,
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
    },
    RealtotalLabel: {
        width: '85%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor: theme.palette.secondary.main,
        color: 'black',
    },
});

const InvoiceTableFooter = ({ invoice }) => {
    const total = invoice.items?.reduce((acc, item) => {
        return acc + parseFloat(item.QTY) * parseFloat(item.Customer_Unit_Cost);
    }, 0.00);

    return (
        <>
            {/* Optional additional cost rows */}
            <View style={styles.row}>
                <Text style={styles.RealtotalLabel}>TOTAL</Text>
                <Text style={styles.Realtotal}>{total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
            </View>
        </>
    );
};

export default InvoiceTableFooter;
