import * as React from 'react';
import {useEffect,useState} from "react"
import {Box,Button,TextField,Paper, Stack,Typography,Link,Divider,Chip} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import Login_Im from "../Images/Aspetto_Login.png"
import LogBack from "../Images/Login.png"
import Login_Bck from "../Images/Login.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance}  from '../../axiosGlobalInstance';
import moment from "moment";
import { deepOrange, red,blue,cyan,blueGrey, indigo } from '@mui/material/colors'
import { Twitter, Facebook, Instagram, LinkedIn, Fullscreen } from '@mui/icons-material';
import Logo from "../Images/logo.png";
import { bgcolor, color, styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const validUserName = new RegExp('^([A-z]{2,3})([0-9]{5,20})$');
const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!^&~*@#$%]).{5,15}$');

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const Footer = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '12px',
  color: 'gray',
  borderTop: '0px solid #ccc',
  borderRadius:"5px"
}));

const SocialIcons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));


const UserRegistration=()=>
{
    const PD= new Date()
    const [conpass,setConPass]=useState("");
    const [matchPwd, setMatchPwd] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [userNameValid,setUserNameValid]=useState(false)
    const [passwordValid, setPasswordValid] = useState(false);
    const [phoneerrMsg, setPhoneErrMsg] = useState('');
    const [matchError,setMatchError]=useState("")
    const [dis,setDis]=useState(false)
    const [count,setCount]=useState(0)
    const[userCreatedAlert,setUserCreatedAlert]=useState("");
    const[userAbortedAlert,setUserAbortedAlert]=useState("");
    const [successDialog,setSuccessDialog]=useState(false)
    const [errorDialog,setErrorDialog]=useState(false)

    
    const [NewUser,setNewUser]=useState({
        User_FirstName :"",
        User_LastName : "",
        User_email :"",
        User_phone_number : "",
        Created_Date : String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()),
        UserName:"",
        DOB:moment().subtract(18, "years").startOf('day').toString(),
        UserPassword :"",
        isAdmin:false,
        User_Role:""||'USER-SALES'})
    const handleClose = (e) => {
          setErrorDialog(false)
          setSuccessDialog(false)
          };
    const handleClickShowPassword2 = () => {
            setConPass(!conpass);
          };
    const handleMouseDownPassword = (event) => {
            event.preventDefault();
          };
  const handleUserName=(e)=>{
        setUserNameValid(validUserName.test(e.target.value))
        setNewUser({...NewUser,UserName:e.target.value})
        if(userNameValid===false)
        {
           setDis(true)
        }
        else
        {
          setDis(false)
          setUserCreatedAlert('')
          setUserAbortedAlert('')
        }

         }
const handlePassword=(e)=>{
  setPasswordValid(validPassword.test(e.target.value))
  setNewUser({...NewUser,UserPassword:e.target.value})
  if(e.target.value===matchPwd)
  {
   if(passwordValid===true)
   {
     setValidMatch(true)
     setMatchError('')
     setDis(false)

   }
  else
  {
   setDis(true)
   setMatchError('')
   setValidMatch(true)
  }

  }
  else 
  {
   setValidMatch(false)
   setMatchError('Password Mismatch')
   setDis(true)
  }
}

const handleConfirmPassword=(e)=>{
          setMatchPwd(e.target.value)
            if(e.target.value===NewUser.UserPassword)
             {
              if(passwordValid===true)
              {
                setValidMatch(true)
                setMatchError('')
                setDis(false)
          
              }
             else
             {
              setDis(true)
              setMatchError('')
              setValidMatch(true)
             }
          
             }
             else 
             {
              setValidMatch(false)
              setMatchError('Password Mismatch')
              setDis(true)
             }
}

const handleUserEmail=(e)=>{
  setNewUser({...NewUser,User_email:String(e.target.value).trim().toLowerCase()})
            setUserCreatedAlert('')
            setUserAbortedAlert('')
            setDis(false)
  
}
const handleUserPhone=(e)=>{
  setNewUser({...NewUser,User_phone_number:e.target.value})
  setUserCreatedAlert('')
  setUserAbortedAlert('')
  setDis(false)
  
}

const handleSubmit=async(e)=>{
        setDis(true)
        e.preventDefault()
        await AxiosGlobalAPIInstance.post("/UserAuth/register",
        {...NewUser},
        ).then(
            (response)=>{
               setUserCreatedAlert(response?.data)
               setSuccessDialog(true)
               setUserAbortedAlert('')
               setNewUser({...NewUser,
                User_FirstName :"",
                User_LastName : "",
                User_email :"",
                User_phone_number : "",
                Created_Date : String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()),
                UserName:"",
                DOB:moment().subtract(18, "years").startOf('day').toString(),
                UserPassword :""})
                setDis(false)
                setConPass("")
                setMatchPwd("")
                setValidMatch(false)
              }).
          catch((err)=>{
            if(err?.response.status===422)
            {
              setUserAbortedAlert(err.response?.data)
              setErrorDialog(true)
              setUserCreatedAlert('')
            }
           else if(err?.response.status===400)
           {
            setUserAbortedAlert("Something Went Wrong")
            setErrorDialog(true)
            setUserCreatedAlert('')
           }
          else
          {
            setUserAbortedAlert("Bad Request")
            setErrorDialog(true)
            setUserCreatedAlert('')
          }
          }).finally(()=>{
                      setDis(false)
                            })
          }
    return (
      <>
      <ThemeProvider theme={theme}>
        <Footer>
      <Stack sx={{ 
                   backgroundImage: `url(${Login_Bck})`,
                   backgroundColor:blueGrey[280],
                   backgroundSize: 'cover',
                  //  backgroundPosition: 'center',
                   backgroundRepeat: 'no-repeat',
                   height: "81vh",
                   width: '100%',
                   alignItems: 'center',
               }}
        >
        <Paper component="form" onSubmit={handleSubmit} autoComplete='off' elevation={0} sx={{margin:'auto',borderRadius:"16px",}}>
            {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {userCreatedAlert && <Alert  severity='success' variant='filled'>SUCCESS</Alert>} 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {userCreatedAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)}} href="/">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {userAbortedAlert && <Alert  variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {userAbortedAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/UserRegistartion">OK</Button>
        </DialogActions>
      </Dialog>)}
      
        <Stack direction='column' justifyContent="center" spacing={1} sx={{padding:4,border:1,borderTopLeftRadius:15,borderTopRightRadius:15}}>
          <Stack direction='row' justifyContent="center">
          <Box component="img" justifyContent="center" src={Login_Im} sx={{height:"70px",width:"200px"}}>
              </Box>
          </Stack>
              <Chip label=" ASPETTO PORTAL - USER REGISTRATION" sx={{marginTop:"10px",fontSize:"15px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)'}}></Chip>
            <Stack direction='row' spacing={3} justifyContent='center'> 
            <TextField 
            type="text"  
            value={NewUser.User_FirstName} 
            onChange={(e)=>{setNewUser({...NewUser,User_FirstName:e.target.value})
            setUserCreatedAlert('')
          }} 
          variant='standard'  
             size="small"  
             required 
             id="firstName" 
             autoComplete='off'
             label="First Name" 
             sx={{width:"230px",}} 
             InputProps={{startAdornment:(<InputAdornment position='start'>
            <PersonIcon />
            </InputAdornment>),}}>
            </TextField>
            <TextField 
            variant='standard'  
            type="text"  
            value={NewUser.User_LastName} 
            onChange={(e)=>{setNewUser({...NewUser,User_LastName:e.target.value})
            setUserCreatedAlert('') }} 
            size="small" 
            required 
            id="Last Name" 
            autoComplete='off'
            label="Last Name" 
            sx={{width:"230px"}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
            </InputAdornment>),}}>
            </TextField>
            </Stack>
            <Stack direction='row' spacing={3} justifyContent='center'>
            <TextField 
            variant='standard'  
            value={NewUser.User_email} 
            onChange={(e)=>{handleUserEmail(e)
            }} 
            size="small"
            required id="Email" 
            label="Email ID" 
            autoComplete='off'
            sx={{width:"230px"}} 
            type="email"
            InputProps={{startAdornment:(<InputAdornment position='start'>
            <EmailIcon />
            </InputAdornment>),}}>
            </TextField>
            <TextField 
            variant='standard'  
            type="text"  
            value={NewUser.User_phone_number} 
            onChange={(e)=>{handleUserPhone(e)}}  
            size="small"  
            required 
            id="Phone" 
            autoComplete='off'
            label="Phone Number" 
            sx={{width:"230px",}} 
            InputProps={{startAdornment:(<InputAdornment 
            position='start'>
            <ContactPhoneIcon />
            </InputAdornment>),}}>
            </TextField>
            </Stack>
            <Stack direction='row' spacing={3} justifyContent='center'>
             <DatePicker label="Date Of Birth" sx={{width:"230px"}} value={NewUser.DOB} minDate={moment().subtract(500, "years").startOf('day').toString()}
              maxDate={moment().subtract(18, "years").startOf('day').toString()} renderInput={(params)=><TextField variant='standard' size="small" required sx={{width:"230px"}} {...params}/>} onChange={(value)=>setNewUser({...NewUser,DOB:value})}>
        </DatePicker>
            <TextField 
            variant='standard'  
            type="text"  
            value={NewUser.UserName} 
            onChange={(e)=>{
              handleUserName(e)
            }}  
            size="small"  
            required 
            id="UserName" 
            label={"Username "}
            autoComplete='off'
            sx={{width:"230px",}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
                <AccountCircleIcon></AccountCircleIcon>
            </InputAdornment>),}}>
            </TextField>
            </Stack>
            <Stack direction='row' justifyContent='center'>
            {NewUser.UserName!==''&& (userNameValid ? <Alert severity='success'sx={{height:"35px",fontSize:"10px"}} >{"Username is Valid"}</Alert>:
             <Alert sx={{width:"450px",fontSize:"10px"}} severity='error'>{`
             The string should initiate with 2-3 letters from the alphabet [A-z], succeeded by numbers from the range [0-9].
              The total number of characters must fall within the range of 7 to 20. For instance, it could resemble AS35226 or ASP3522.`}</Alert>)}
              </Stack> 
            <Stack direction='row' spacing={3} justifyContent='center'>
    
            <TextField 
            variant='standard'  
            type="password"
            value={NewUser.UserPassword} 
            onChange={(e)=>{handlePassword(e)}} 
            size="small" 
            required 
            id="Password" 
            label="Password" 
            autoComplete='off'
            sx={{width:"230px"}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
       <PasswordIcon></PasswordIcon>
        </InputAdornment>),}}>
            </TextField>
     
  
            <TextField 
            variant='standard'  
            type={conpass?"text":"password" } 
            value={matchPwd} 
            onChange={(e)=>{
              handleConfirmPassword(e)
             }}
            size="small" 
            required 
            id="matchPwd" 
            label="Confirm Password" 
            autoComplete='off'
            sx={{width:"230px"}} 
            InputProps={{endAdornment:(<InputAdornment position='end'>
            <IconButton onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword}>{conpass?<VisibilityIcon></VisibilityIcon>:<VisibilityOffIcon></VisibilityOffIcon>}</IconButton>
        </InputAdornment>),}}>
            </TextField>
  
           </Stack>
            <Stack direction='row' spacing={3} justifyContent='center'>
            {NewUser.UserPassword!==''&& (passwordValid ? <Alert severity='success' sx={{height:"35px",fontSize:"10px"}}>{"Password is Valid"}</Alert>:
             <Alert sx={{fontSize:"10px",width:"300px",}} severity='error'>{`The string must include at least one lowercase letter (a-z), one uppercase letter (A-Z), one digit (0-9),
              and one special character from the set (!^&~*@#$%). The total length of the string should be between 5 to 15 characters`}</Alert>)}
               {NewUser.UserPassword!=='' && matchPwd!==''&& (validMatch ?<Alert sx={{height:"35px",fontSize:"10px"}} severity='success'>Passwords matched</Alert>:<Alert sx={{width:"170px",height:"35px",fontSize:"12px"}} severity="error">{matchError}</Alert>)}
          </Stack>
            <Stack  direction='row' spacing={3} justifyContent='center'>
             <Button variant='contained' type="submit" disabled={dis} color="warning" sx={{width:"230px",height:"30px"}}  size='small'>Sign Up</Button>
             <Stack direction='column' spacing={1} justifyContent='center'>
             <Stack  direction='row' spacing={0} justifyContent='center'>
        <Typography type="body"  sx={{padding:"0px", fontSize:"12px",fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Already an User?</Typography>
        <Link href="/" underline="always" sx={{paddingLeft:"5px",fontSize:"12px",paddingTop:'2px',color:'green'}}><strong>Sign In</strong></Link>
        </Stack>
        <Stack  direction='row' spacing={0} justifyContent='center'>
        <Typography type="body"  sx={{padding:"0px", fontSize:"12px", fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Forgot Password?</Typography>
        <Link href="pass-reset" underline="always" sx={{paddingLeft:"5px",fontSize:"12px",paddingTop:'2px',color:red[600]}}><strong>Reset Your Password</strong></Link>
        </Stack>
        </Stack>
        </Stack>
        </Stack>
     
        <Typography variant='body2' sx={{backgroundColor:blueGrey[900],color:'whitesmoke',borderBottomLeftRadius:"14px",borderBottomRightRadius:"14px",padding:"10px",textAlign:'center'}}>
          This Webpage is designed exclusively for Aspetto Inc. Employees.</Typography>
    
    </Paper>
    </Stack>
    <Stack direction="row" spacing={4} sx={{ bgcolor: blueGrey[900], color: "white", padding: 1, fontSize: "11px", height: "100px" }}>
            <Stack direction="column" spacing={0.1} sx={{ margin:'50px', }}>
              <IconButton size='large' edge='start' color='inherit' >
                <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
              </IconButton>
              <p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
              <p>Inc. 5000 America's Fastest Growing Private Companies</p>
              <p>ISO certified ISO 9001:2015</p>
            </Stack>
            <Stack></Stack>
            <Stack direction="column" spacing={0.1} sx={{ marginLeft: "20px",padding:'20px'  }} justifyContent="right">

              <p>Let's talk:</p>
              <p>Phone: 540-547-8487</p>
              <p>Fax: 540-300-2669</p>
              <Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}></Stack>
            <Stack direction="column" spacing={0.1} sx={{ alignItems: 'center', }}>
              <p></p>
              <p>SOCIAL MEDIA</p>
              <Stack direction="row" spacing={.5} justifyContent="center" >
                <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
                  <Twitter color="white" sx={{ backgroundColor: "white", color: cyan[400] }} />
                </IconButton>
                <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <Facebook color="white" sx={{ backgroundColor: "white", color: blue[800] }} />
                </IconButton>
                <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
                  <Instagram color="white" sx={{ backgroundColor: "white", color: red[800] }} />
                </IconButton>
                <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <LinkedIn sx={{ backgroundColor: "white", color: blueGrey[600] }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
      
      </Footer>
      </ThemeProvider>
      </>
    )

}
export default UserRegistration