import React, { useState, useEffect } from "react";
import { Box, Typography, Stack, IconButton, Avatar, Tooltip } from '@mui/material';
import { useNavigate, useSearchParams } from "react-router-dom";
import jwtDecode from 'jwt-decode'
import { green, blueGrey, red, blue, grey, indigo } from '@mui/material/colors'
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ExpiryTimeTicker = () => {
  const [expTime, setExpTime] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const DHSPageLoad = async () => {
     
      const token = String(searchParams.get('Temp')).trim();
      const parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
          return null;
        }
      };
      const decodedJWT = parseJwt(token);
      setExpTime(decodedJWT.exp);
    };

    DHSPageLoad();
  }, []); // Run only on component mount

  useEffect(() => {
    //  a function to update the timer
    const updateTimer = () => {
      if (expTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = expTime - currentTime;

        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(timeRemaining / (60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
        const seconds = timeRemaining % 60;

        // Format the time
        const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

        setExpTimeTicker(formattedTime);
      }
    };

    // Update the timer every second
    const intervalId = setInterval(updateTimer, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [expTime]); // Run when `expTime` changes

  const [expTimeTicker, setExpTimeTicker] = useState(null);
  const isLinkExpired = expTime !== null && expTime <= Math.floor(Date.now() / 1000);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <Tooltip title="Link Expires In" placement="bottom">
        <Avatar
          variant='rounded'
          sx={{
            width: "350px",
            fontSize: "16px",
            height: "40px",
            color: "white",
            bgcolor: isLinkExpired ? red[600] : red[600],
          }}
        >
          <AccessTimeIcon sx={{ width: "30px" }} />
          {isLinkExpired ? "Link Expired" : `Link Expires In: ${expTimeTicker}`}
        </Avatar>
      </Tooltip>
    </Stack>
  );
};

export default ExpiryTimeTicker;
