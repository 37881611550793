import React, { useState } from 'react';
import { Container, Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem, Stack, Divider, Grid, Button, Card, CardContent, TextField, CardActionArea, CardActions, Slider } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
  Line,
  ReferenceLine,
  RadialBarChart,
  RadialBar
} from 'recharts';
import ApplicationBar from '../../AppBar/ApplicationBar';
import UserAuth from "../../ProtectedRoute/userAuth";
import { orange, green, red, blueGrey, blue, indigo } from '@mui/material/colors';
import LoadingDialog from '../../Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import AxiosFunction from '../../../axiosCustomInstance';
import { Email, Phone } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import { DatePicker } from "@mui/x-date-pickers";
import PdfDocument from "../../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import IconButton from '@mui/material/IconButton';
import { Avatar } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MergeIcon from '@mui/icons-material/Merge';
import HistoryIcon from '@mui/icons-material/History';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, createSearchParams } from 'react-router-dom'; // Import Link, createSearchParams, 
import { fontSize } from '@mui/system';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Person,Home } from '@mui/icons-material'

const RFQsSearchViewTwo = () => {
  const [chartData, setChartData] = useState([]);
  const [chartDataByDollarValue, setChartDataByDollarValue] = useState([]);
  const [chartDataByProfitMargin, setChartDataByProfitMargin] = useState([]);
  const { UserName, UserEmail } = UserAuth();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedContractVehicle, setSelectedContractVehicle] = useState('All');
  const [dialogMssge, setDialogMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dis, setDis] = useState(false);
  const AxiosAPIInstance = AxiosFunction();
  let [usersData, setUsersData] = useState([{ User_email: "" }]);
  const [salesHeadError, setSalesHeadError] = useState(false);
  const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState("");
  const [barHeights, setBarHeights] = useState([]);
  const [averageValue, setAverageValue] = useState(0);
  const [pieChartData, setPieChartData] = useState([
    { name: 'PENDING', value: 1000, dollarValue: 0 },
    { name: 'WON', value: 2000, dollarValue: 0 },
    { name: 'LOST', value: 500, dollarValue: 0 },
  ]);

  const [pieChartDataProfitMargin, setPieChartDataProfitMargin] = useState([
    { name: 'PENDING', value: 1000, dollarValue: 0 },
    { name: 'WON', value: 2000, dollarValue: 0 },
    { name: 'LOST', value: 500, dollarValue: 0 },
  ]);
  const [totalQuotes, setTotalQuotes] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [averageDealSize, setAverageDealSize] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalProfitPercent, setTotalProfitPercent] = useState(0);
  const [wonQuotes, setWonQuotes] = useState([]);
  const [lostQuotes, setLostQuotes] = useState([]);
  const [pendingQuotes, setPendingQuotes] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [clicked, setClicked] = useState(false);

  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
  }).format(PD);

  const [Form_Value, Set_Form_Value] = useState([{
    Opportunity_Name: '', RFQ_ID: '', Invoice_No: '', Contract_Vehicle: '', KO_name: '', KO_email: '',
    KO_phone: '', KO_address: '', Published_date: null, Due_date: null, Brief: '', Organization_Name: '',
    Customer_Name: '', Customer_Email: '', Customer_Phone: '', Customer_Address: '', Shipping_Method: '', Sales_Head_Name: '', Sales_Head_Email: String(UserEmail).toLowerCase() || "sales@aspettoinc.com", Sales_Head_Ph: "",
    Shipping_Terms: 'FOB Origin', Estimated_Delivery: '', Payment_Terms: 'NET30', Notes: '', Shipping_Cost: Number(parseFloat(0).toFixed(2)),
    Tax: Number(parseFloat(0).toFixed(2)), Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
    Created_Date: today,
    Last_Updated_Date: today, RFQ_Status: 'PENDING',
    Updated_By: 'TBD', Version_No: parseInt(0), Updating_Comments: 'RFQ Created under PENDING Status'
  }]);

  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
  const [sortField, setSortField] = useState('Created_Date'); // State for sorting field
  const [dateRange, setDateRange] = useState([null, null]); // State for date range filter
  const [marginRange, setMarginRange] = useState([0, 100]); // State for margin range filter

  React.useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true);
      setClicked(false)
      try {
        const result = await AxiosAPIInstance.get("/UserAuth/users");
        setLoading(false);
        const users = [...result?.data];
        Set_Form_Value({ ...Form_Value, Sales_Head_Email: String(UserEmail).toLowerCase() });
        setUsersData(users);
        setSalesHeadError(false);
        setSalesHeadErrorMsge("");
      } catch (error) {
        setLoading(false);
        setSalesHeadError(true);
        setSalesHeadErrorMsge(error?.response.data);
      }
    };

    fetchUsersData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      setDis(true);
      setLoading(true);

      try {
        const response = await AxiosAPIInstance.get('/GetYourPerformance', {
          params: {
            Sales_Head_Email: String(Form_Value.Sales_Head_Email).toLowerCase(),
            Contract_Vehicle: selectedContractVehicle,
            Year: selectedYear
          },
        });

        const fetchedData = response.data;

        const allMonths = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            PENDING_DOLLAR: 0,
            WON_DOLLAR: 0,
            LOST_DOLLAR: 0,
          };
        });

        const updatedChartData = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex(item => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += 1;
              acc[monthIndex].PENDING_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += 1;
              acc[monthIndex].WON_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += 1;
              acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonths);

        updatedChartData.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartData(updatedChartData);

        const allMonthsByDollarValue = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
          };
        });

        const updatedChartDataByDollarValue = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex(item => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonthsByDollarValue);

        updatedChartDataByDollarValue.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByDollarValue(updatedChartDataByDollarValue);

        const allMonthsByProfitMargin = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            height: 0,
          };
        });

        const updatedMonthsByProfitMargin = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex((item) => item.name === monthYear);

          if (monthIndex !== -1) {
            const height = parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin);

            acc[monthIndex].PENDING += entry.RFQ_Status === 'PENDING' ? height : 0;
            acc[monthIndex].WON += entry.RFQ_Status === 'WON' ? height : 0;
            acc[monthIndex].LOST += entry.RFQ_Status === 'LOST' ? height : 0;
            acc[monthIndex].height += height;
          }

          return acc;
        }, allMonthsByProfitMargin);

        updatedMonthsByProfitMargin.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByProfitMargin(updatedMonthsByProfitMargin);
        setBarHeights(updatedMonthsByProfitMargin.map((entry) => entry.height));

        const calculateAverageHeight = () => {
          const averageHeight = updatedMonthsByProfitMargin.reduce((acc, entry) => acc + entry.height, 0) / updatedMonthsByProfitMargin.length;
          return averageHeight;
        };

        setAverageValue(calculateAverageHeight());

        const pieData = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedChartDataByDollarValue.forEach(entry => {
          pieData[0].value += entry.PENDING;
          pieData[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieData[1].value += entry.WON;
          pieData[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieData[2].value += entry.LOST;
          pieData[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartData(pieData);

        const pieDataProfitMargin = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedMonthsByProfitMargin.forEach(entry => {
          pieDataProfitMargin[0].value += entry.PENDING;
          pieDataProfitMargin[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieDataProfitMargin[1].value += entry.WON;
          pieDataProfitMargin[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieDataProfitMargin[2].value += entry.LOST;
          pieDataProfitMargin[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartDataProfitMargin(pieDataProfitMargin);

        const totalQuotes = fetchedData.length;
        const allQuotes=fetchedData;
        const totalWonQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'WON').length;
        const totalPendingQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'PENDING').length;
        const totalLostQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'LOST').length;


        const totalRevenue = fetchedData.filter(entry => entry.RFQ_Status === 'WON').reduce((acc, entry) => acc + parseFloat(entry.Actual_Total_Cost_After_Margin), 0);
        const averageDealSize = totalWonQuotes > 0 ? totalRevenue / totalWonQuotes : 0;
        const conversionRate = totalQuotes > 0 ? (totalWonQuotes / totalQuotes) * 100 : 0;

        const totalProfit = fetchedData.reduce((acc, entry) => acc + (parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin)), 0);
        const totalProfitPercent = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;

        setTotalQuotes(totalQuotes);
 
        setConversionRate(conversionRate);
        setAverageDealSize(averageDealSize);
        setTotalRevenue(totalRevenue);
        setTotalProfit(totalProfit);
        setTotalProfitPercent(totalProfitPercent);

        const wonQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'WON');
        setWonQuotes(wonQuotes);
        const pendingQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'PENDING');
        setPendingQuotes(pendingQuotes);
        const lostQuotes = fetchedData.filter(entry => entry.RFQ_Status === 'LOST');
        setLostQuotes(lostQuotes);

        setAllQuotes(allQuotes)

      } catch (error) {
        setErrorDialog(true);
        setSuccessDialog(false);
        setDialogMssge(error?.response?.data);
      } finally {
        setDis(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedContractVehicle, Form_Value?.Sales_Head_Email]);

  const COLORS = [orange[600], green[600], red[600]];

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    if (value === 0) return null;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  const handleClose = async () => {
    setDis(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setDialogMssge('');
    setClicked(false)
  }

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setClicked(false)
  };

  const handleContractVehicleChange = (event) => {
    setSelectedContractVehicle(event.target.value);
    setClicked(false)
  };

  const formatCurrency = (value) => `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

  const calculateAverageProfitMarginPerStatus = (status) => {
    const filteredData = chartDataByProfitMargin.filter(entry => entry[status] > 0);
    const totalProfitMargin = filteredData.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginDollar = filteredData.length > 0 ? totalProfitMargin / filteredData.length : 0;
    const totalDollarValue = chartDataByDollarValue.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginPercent = totalDollarValue > 0 ? (totalProfitMargin / totalDollarValue) * 100 : 0;

    return { averageProfitMarginDollar, averageProfitMarginPercent };
  };

  const pendingProfitMargin = calculateAverageProfitMarginPerStatus('PENDING');
  const wonProfitMargin = calculateAverageProfitMarginPerStatus('WON');
  const lostProfitMargin = calculateAverageProfitMarginPerStatus('LOST');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredQuotes = allQuotes.filter((quote) =>
    quote.Opportunity_Name.toLowerCase().includes(searchTerm) ||
    quote.Customer_Email.toLowerCase().includes(searchTerm) ||
    quote.Customer_Phone.includes(searchTerm) ||
    quote.Contract_Vehicle.toLowerCase().includes(searchTerm) ||
    quote.Invoice_No.toLowerCase().includes(searchTerm)||
    quote.RFQ_Status.toLowerCase().includes(searchTerm)||
    quote.Customer_Name.toLowerCase().includes(searchTerm)||
    quote.KO_name.toLowerCase().includes(searchTerm)||
    quote.Organization_Name.toLowerCase().includes(searchTerm)

  );

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
  };

  const handleMarginRangeChange = (event, newRange) => {
    setMarginRange(newRange);
  };

  const sortedQuotes = [...filteredQuotes].sort((a, b) => {
    if (sortField === 'Created_Date') {
      const dateA = new Date(a.Created_Date);
      const dateB = new Date(b.Created_Date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (sortField === 'Revenue') {
      const revenueA = parseFloat(a.Actual_Total_Cost_After_Margin);
      const revenueB = parseFloat(b.Actual_Total_Cost_After_Margin);
      return sortOrder === 'asc' ? revenueA - revenueB : revenueB - revenueA;
    }
    return 0;
  });

  const filteredAndSortedQuotes = sortedQuotes.filter((quote) => {
    const quoteDate = new Date(quote.Created_Date);
    const margin = ((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin)) * 100;

    return (
      (dateRange[0] === null || quoteDate >= dateRange[0]) &&
      (dateRange[1] === null || quoteDate <= dateRange[1]) &&
      margin >= marginRange[0] &&
      margin <= marginRange[1]
    );
  });

  var File_Opportunity_Name = ''
  const [InvoiceData, setInvoiceData] = useState({
    invoice_no: "",
    Contract_Vehicle: "",
    KO_name: "",
    KO_email: "",
    KO_phone: "",
    KO_address: "",
    Print_Date: String(today),
    Valid_For: "30 Days",
    trans_date: "",
    due_date: "",
    Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax: 0,
    Shipping_Cost: 0,
    Total_Cost: 0,
    Shipping_Details: {
      Organization_Name: "",
      KO_name: "",
      Sales_Head_Info: {},
      Customer_Name: "",
      Customer_Email: "",
      Customer_Phone: "",
      Customer_Address: "",
      Shipping_Method: "",
      Shipping_Terms: "",
      Estimated_Delivery: "",
      Payment_Terms: "",
      Notes: ""
    }
  })

  const navigate = useNavigate();
  // Function to navigate to the UpdateRFQ page with parameters
  const RFQID_Search = (quote) => {
    navigate({
      pathname: "/Dashboard/UpdateRFQ",
      search: createSearchParams({
        RID: quote?.RFQ_ID,
        RStatus: quote?.RFQ_Status,
        VNo: 'LifeCycle',
      }).toString()
    });
  };

  const HandlePDFClick = async (quote) => {
    // capitalizing KOs, COs and customers names
    const arr = String(quote.Sales_Head_Name).toLowerCase().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const KO_arr = String(quote.KO_name).toLowerCase().split(" ");
    for (var i = 0; i < KO_arr.length; i++) {
      KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
    }

    const CO_arr = String(quote.Customer_Name).toLowerCase().split(" ");
    for (var i = 0; i < CO_arr.length; i++) {
      CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
    }

    const updatedInvoiceData = {
      ...InvoiceData,
      invoice_num: String(quote.Invoice_No),
      Contract_Vehicle: quote.Contract_Vehicle,
      KO_name: KO_arr.join(" "),
      KO_email: quote.KO_email,
      KO_phone: quote.KO_phone,
      KO_address: quote.KO_address,
      Print_Date: String(today),
      trans_date: String(quote.Last_Updated_Date).split('T')[0],
      due_date: quote.Due_date,
      Valid_For: quote.Valid_For,
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: quote.Purchase_Orders,
      Tax: quote.Tax,
      Shipping_Cost: quote.Shipping_Cost,
      Total_Cost: quote.Actual_Total_Cost_After_Margin,
      Other_Cost_1: quote.Other_Cost_1, Other_Cost_2: quote.Other_Cost_2, Other_Cost_3: quote.Other_Cost_3, Other_Cost_Name1: quote.Other_Cost_Name1, Other_Cost_Name2: quote.Other_Cost_Name2, Other_Cost_Name3: quote.Other_Cost_Name3,
      Shipping_Details: {
        Organization_Name: quote.Organization_Name,
        KO_name: quote.KO_name,
        Sales_Head_Info: { Name: arr.join(" "), Email: quote.Sales_Head_Email, Phone: quote.Sales_Head_Ph },
        Customer_Name: CO_arr.join(" "),
        Customer_Email: quote.Customer_Email,
        Customer_Phone: quote.Customer_Phone,
        Customer_Address: quote.Customer_Address,
        Shipping_Method: quote.Shipping_Method,
        Shipping_Terms: quote.Shipping_Terms,
        Estimated_Delivery: quote.Estimated_Delivery,
        Payment_Terms: quote.Payment_Terms,
        Notes: quote.Notes
      }
    }
    const OP_arr = String(quote.Opportunity_Name).split(".");
    for (var i = 0; i < OP_arr.length; i++) {
      OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
    }
    File_Opportunity_Name = OP_arr.join(" ")

    setInvoiceData(updatedInvoiceData);  // Set state

    setTimeout(async () => {  // Use a timeout to ensure state is updated before generating PDF
      const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${File_Opportunity_Name} ${String(quote.Invoice_No).split('.')[0]}${String(quote.Invoice_No).split('.')[1]}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);  // Delay the PDF generation to allow state update
  }


  return (
    <>
      <ApplicationBar />
      {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            e.preventDefault();
            handleClose();
            setDis(false)
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      <LoadingDialog open={loading} />
      <Container >
        <Stack direction="column" justifyContent="center" sx={{ marginTop: '80px', width: '100%' }} spacing={2}>
          <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
            <Typography component="p" sx={{ borderColor: 'divider', fontSize: '25px', fontWeight: 'bold', padding: 1 }}>
              Request For Quotes (RFQs)- {selectedYear}
            </Typography>
          </Stack>
          <Box display="flex" justifyContent="center" mt={2} mb={4}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <FormControl size="small" sx={{ width: '250px' }}>
                <InputLabel id="Select-Year">Select Year</InputLabel>
                <Select value={selectedYear} onChange={handleYearChange} variant="standard">
                  {[...Array(5)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ width: '250px' }}>
                <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
                <Select
                  size="small"
                  labelId="Contract-Vehicle"
                  id="Contract-Vehicle-id"
                  value={selectedContractVehicle}
                  variant="standard"
                  label="Contract Vehicle"
                  onChange={(e) => handleContractVehicleChange(e)}
                  sx={{ width: '250px' }}
                >
                  <MenuItem size="small" value="All">All</MenuItem>
                  <MenuItem size="small" value="Federal">Federal</MenuItem>
                  <MenuItem size="small" value="Military">Military</MenuItem>
                  <MenuItem size="small" value="Tactical">Tactical</MenuItem>
                  <MenuItem size="small" value="GSA">GSA</MenuItem>
                  <MenuItem size="small" value="FBOP">FBOP</MenuItem>
                  <MenuItem size="small" value="DHS-CBP">DHS-CBP</MenuItem>
                  <MenuItem size="small" value="SAM/Open Market">SAM/Open Market</MenuItem>
                  <MenuItem size="small" value="Unison">Unison</MenuItem>
                  <MenuItem size="small" value="Direct Inquiry">Direct Inquiry</MenuItem>
                  <MenuItem size="small" value="Commercial">Commercial</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
                <Select
                  sx={{ width: '300px' }}
                  variant="standard"
                  label="Sales Head Email ID"
                  labelId="Sales-Head-id"
                  value={String(Form_Value.Sales_Head_Email).toLowerCase()}
                  size="small"
                  onChange={(e) => Set_Form_Value({ ...Form_Value, Sales_Head_Email: e.target.value })}
                >
                  {usersData?.map((row, index) => (
                    <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>
                      {String(row?.User_email).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
          {/* Key Metrics */}
          <Grid container spacing={2}>
          
            <Divider sx={{ bgcolor: 'orange', width: '100%', height: '1.5px', marginTop: '20px' }} orientation="horizontal" />
            
            <Grid item xs={12}> 
              
              <Stack direction="row" spacing={3} alignItems="center">
                  <TextField
                  helperText="Search By Customer Email/Quote Number/Quote Status/Opportunity Name/OrganiZation Name/Contract Vehicle/Phone Number/KO & Customer Names"
                  
                  label='Search'
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ flex: 1 }}></TextField>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center" sx={{marginTop:'30px'}}>
                
              <FormControl variant="outlined"  >
                  <InputLabel>Sort By</InputLabel>
                  <Select value={sortField} onChange={handleSortChange} label="Sort By" sx={{width:'250px'}}>
                    <MenuItem value="Created_Date">Created Date</MenuItem>
                    <MenuItem value="Revenue">Revenue</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel>Order</InputLabel>
                  <Select value={sortOrder} onChange={handleSortOrderChange} label="Order" sx={{width:'250px'}}>
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </FormControl>
                <Stack direction="column" spacing={1}>
           <Typography gutterBottom>Filter By Date Range</Typography>
           <Stack direction="row" spacing={2} alignItems="center" sx={{marginTop:'10px'}}>
                <DatePicker
                  value={dateRange[0]}
                  onChange={(newValue) => handleDateRangeChange([newValue, dateRange[1]])}
                  label="Start Date"
                  renderInput={(params) => <TextField {...params} sx={{ marginRight: 2 }} />}
                />
                <DatePicker
                  value={dateRange[1]}
                  onChange={(newValue) => handleDateRangeChange([dateRange[0], newValue])}
                  label="End Date"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
           </Stack>
           </Stack>

          
              <Box mt={2}>
                <Typography gutterBottom>Filter by Margin Percentage Range</Typography>
                <Slider
                  value={marginRange}
                  onChange={handleMarginRangeChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  step={1}
                  marks
                />
              </Box>
              
              <Grid container spacing={2} mt={2}>
  {filteredAndSortedQuotes.map((quote, index) => (
    <Grid item xs={12} key={index}>
      <Card sx={{ border: 1, borderColor: blueGrey[800], padding: 2 }}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h6" component="div" gutterBottom sx={{ color: blueGrey[900], fontWeight: 'bold' }}>
              {quote.Opportunity_Name}  {quote?.RFQ_Status === 'WON' ? (
              <Button color='success' variant='contained' size='small'>WON</Button>
            ) : quote.RFQ_Status === 'LOST' ? (
              <Button color='error' variant='contained' size='small'>LOST</Button>
            ) : (
              <Button color='warning' variant='contained' size='small'>PENDING</Button>
            )}
            </Typography>
           
            <Typography variant="body2" sx={{ marginTop: "20px" }}></Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper elevation={2} sx={{ padding: 2, border: 1, borderColor: blueGrey[800] }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>Quote Information</Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Quote #: </strong> {quote?.Invoice_No}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Cost Before Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_Before_Margin)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Cost After Margin: </strong> {formatCurrency(quote.Actual_Total_Cost_After_Margin)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Profit Margin: </strong>{' '}
                    {((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin) * 100).toFixed(2)}
                    %
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Contract Vehicle: </strong> {quote.Contract_Vehicle}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={2} sx={{ padding: 2, border: 1, borderColor: blueGrey[800] }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>Customer Information</Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Person sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Name: </strong> {quote.Customer_Name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Email sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Email: </strong> {quote.Customer_Email}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Phone sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Phone: </strong> {quote.Customer_Phone}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Home sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Address: </strong> {quote.Customer_Address}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={2} sx={{ padding: 2, border: 1, borderColor: blueGrey[800] }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>KO Information</Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Person sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Name: </strong> {quote.KO_name}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Email sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Email: </strong> {quote.KO_email}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Phone sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Phone: </strong> {quote.KO_phone}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Home sx={{ color: blue[700] }} />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      <strong>Address: </strong> {quote.KO_address}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>

            <Box display="flex" alignItems="center" mt={1}>
              <CalendarTodayIcon sx={{ color: blue[700] }} />
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px', fontWeight: 'bold' }} ml={1}>
                Created Date:
              </Typography>
              <Typography variant="body2" color="text.secondary" ml={1}>
                {String(quote.Created_Date).split('T')[0]}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <CalendarTodayIcon sx={{ color: blue[700] }} />
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px', fontWeight: 'bold' }} ml={1}>
                Updated Date:
              </Typography>
              <Typography variant="body2" color="text.secondary" ml={1}>
                {String(quote.Last_Updated_Date).split('T')[0]}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton
            name="View Quote"
            variant="contained"
            sx={{
              color: 'white',
              padding: '10px',
              borderRadius: 1,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: indigo[100],
                transform: 'scale(1.1)',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              RFQID_Search(quote);
            }}
          >
            <Avatar
              sx={{
                backgroundColor: indigo[800],
                width: '25px',
                height: '25px',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: indigo[800],
                  transform: 'scale(1.1)',
                },
              }}
              variant="rounded"
            >
              <VisibilityIcon sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
            </Avatar>
          </IconButton>
          <IconButton
            variant="text"
            sx={{
              color: 'white',
              padding: '10px',
              borderRadius: 1,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: red[100],
                transform: 'scale(1.1)',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              HandlePDFClick(quote);
            }}
          >
            <Avatar
              sx={{
                backgroundColor: red[800],
                width: '25px',
                height: '25px',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: red[800],
                  transform: 'scale(1.1)',
                },
              }}
              variant="rounded"
            >
              <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
            </Avatar>
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  ))}
</Grid>



            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default RFQsSearchViewTwo;




            {/* Won Quotes Grid */}
              // sx={{ backgroundColor: index % 2 === 0 ? '#e3f2fd' : '#fce4ec' }}
            {/* <Grid item xs={12}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h6" gutterBottom>
                  Won Quotes
                </Typography>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  autoHeight
                  disableSelectionOnClick
                />
              </Paper>
            </Grid> */}
{/* <Grid item xs={12}>
  <Typography variant="h6" gutterBottom>
    Won Quotes
  </Typography>
  <Grid container spacing={2}>
    {wonQuotes.map((quote, index) => (
       <Grid item xs={12} sm={6} md={4} key={index}>
       <Card >
         <CardContent>
           <Typography variant="h6" component="div" gutterBottom>
             {quote.Opportunity_Name}
           </Typography>
           <Typography variant="body2" color="text.secondary">
             <strong>Cost Before Margin:</strong> {formatCurrency(quote.Actual_Total_Cost_Before_Margin)}
           </Typography>
           <Typography variant="body2" color="text.secondary">
             <strong>Cost After Margin:</strong> {formatCurrency(quote.Actual_Total_Cost_After_Margin)}
           </Typography>
           <Typography variant="body2" color="text.secondary">
             <strong>Profit Margin:</strong> {((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin) * 100).toFixed(2)}%
           </Typography>
           <Typography variant="body2" color="text.secondary">
             <strong>Contract Vehicle:</strong> {quote.Contract_Vehicle}
           </Typography>
           <Box display="flex" alignItems="center" mt={1}>
             <Email color="primary" />
             <Typography variant="body2" color="text.secondary" ml={1}>
               {quote.Customer_Email}
             </Typography>
           </Box>
           <Box display="flex" alignItems="center" mt={1}>
             <Phone color="primary" />
             <Typography variant="body2" color="text.secondary" ml={1}>
               {quote.Customer_Phone}
             </Typography>
           </Box>
         </CardContent>
       </Card>
     </Grid>
    ))}
     const columns = [
    { field: 'Opportunity_Name', headerName: 'Opportunity Name', flex: 1 },
    { field: 'Actual_Total_Cost_Before_Margin', headerName: 'Cost Before Margin ($)', flex: 1 },
    { field: 'Actual_Total_Cost_After_Margin', headerName: 'Cost After Margin ($)', flex: 1 },
    { field: 'Profit_Margin_Percent', headerName: 'Profit Margin (%)', flex: 1 },
    { field: 'Contract_Vehicle', headerName: 'Contract Vehicle', flex: 1 },
    { field: 'Customer_Email', headerName: 'Customer Email', flex: 1 },
    { field: 'Customer_Phone', headerName: 'Customer Phone', flex: 1 },
    ];
    
    const rows = wonQuotes.map((quote, index) => ({
    id: index + 1,
    Opportunity_Name: quote.Opportunity_Name,
    Actual_Total_Cost_Before_Margin: formatCurrency(quote.Actual_Total_Cost_Before_Margin),
    Actual_Total_Cost_After_Margin: formatCurrency(quote.Actual_Total_Cost_After_Margin),
    Profit_Margin_Percent: parseFloat((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin) * 100).toFixed(2),
    Contract_Vehicle: quote.Contract_Vehicle,
    Customer_Email: quote.Customer_Email,
    Customer_Phone: quote.Customer_Phone,
    }));
  </Grid>
</Grid> */}