// Importing necessary dependencies
import React, { useState } from 'react';
import { Button, Input, Paper, Typography } from '@mui/material';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance';
import jwtDecode from 'jwt-decode'; // Importing jwtDecode library
import { useNavigate, useSearchParams } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import LoadingDialog from '../Loading';
import { indigo, blueGrey } from '@mui/material/colors';
import Barcode from 'react-barcode';
import { selectCurrentToken, selectCurrentUser } from '../Login_Registration/authSlice';
import { useSelector } from 'react-redux'; // Importing useSelector hook


const ExcelUploaderInternalSales = () => {
// State for storing the selected file
const [file, setFile] = useState(null);
// State for storing the generated barcode value
const [barcodeValue, setBarcodeValue] = useState('');

// Function to handle file change event
const handleFileChange = (e) => {
  // Set the selected file
  setFile(e.target.files[0]);
  // Generate barcode value
  setBarcodeValue(`DHSCBP${Date.now()}`);
};

// Extracting search parameters
const [searchParams] = useSearchParams();
// Selecting the current token from Redux store
const token = useSelector(selectCurrentToken);
// Decoding the JWT token to get user information
const decoded = jwtDecode(token);
// Extracting user name and email from decoded token
const { UserName, UserEmail } = decoded.userInfo;

// State for managing disabling/enabling form fields
const [dis, setDis] = useState(false);
// Navigation hook
const navigate = useNavigate();
// State for displaying messages
const [dHSMssge, setDHSMssge] = useState('');
// State for success dialog
const [successDialog, setSuccessDialog] = useState(false);
// State for error dialog
const [errorDialog, setErrorDialog] = useState(false);
// State for controlling dialog visibility
const [open, setOpen] = useState(false);
// State for managing loading state
const [loading, setLoading] = useState(false);
// State for managing status code
const [statusCode, setStatusCode] = useState(false);

// Function to handle closing dialog
const handleClose = (e) => {
  setOpen(false);
  setErrorDialog(false);
  setSuccessDialog(false);
  setErrorDialog(false);
};


// Function to handle file upload
const handleUpload = async () => {
  // Check if a file is selected
  if (file !== null) {
    // Set loading state to true
    setLoading(true);
    try {
      // Construct user details object
      const UserDetails = {
        Customer_Name: UserName,
        Customer_Email: UserEmail,
        Excel_Sheet_Upload: true,
        Temp_Token: token,
        Barcode_Value: barcodeValue, // Include barcode value in the request
      };

      // Create a new FormData object
      const formData = new FormData();
      // Append the selected file to the FormData object
      formData.append('file', file);

      // Append additional user details to the FormData object
      Object.keys(UserDetails).forEach((key) => {
        formData.append(key, UserDetails[key]);
      });

      // Send a POST request to upload the file
      await AxiosGlobalAPIInstance.post('DHSCustomer/upload/InternalSales', formData)
        .then(async (result) => {
          // On successful upload
          setLoading(false);
          setErrorDialog(false);
          setSuccessDialog(true);
          setDHSMssge(result?.data);
          setFile(null); // Reset the selected file
        })
        .catch((error) => {
          // If there is an error in the upload process
          if (error?.response?.status === 403) {
            setStatusCode(true);
          }

          setLoading(false);
          setSuccessDialog(false);
          setErrorDialog(true);
          setDHSMssge(error.response?.data);
          setDis(false);
        });
    } catch (error) {
      // If there is an error
      setLoading(false);
      setErrorDialog(true);
      setSuccessDialog(false);
      setDHSMssge("Cannot submit the request");
    }
  } else {
    // If no file is selected
    setLoading(false);
    setErrorDialog(true);
    setSuccessDialog(false);
    setDHSMssge("Please Select File to Upload");
  }
}

  return (
    <>
       <LoadingDialog open={loading} />
          {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          }>OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h5" gutterBottom>
       File Upload
      </Typography>
      <Input type="file" accept=".xlsx, .xls" required onChange={handleFileChange} style={{ marginBottom: '20px',width:"500px"}} />
    {barcodeValue && <Barcode value={barcodeValue} />}
      <Button
        variant="contained"
        sx={{ width: '200px', height: '30px', bgcolor: indigo[800] }}
        color="primary"
        onClick={handleUpload}
      >
        Upload
      </Button>
    </Paper>
    </>
  );
};

export default ExcelUploaderInternalSales;
