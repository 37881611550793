import {
  Box,
  Button,
  TextField,
  Stack,
  Chip,
  IconButton,
  Tooltip,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState } from 'react';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { DatePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { blueGrey, green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ApplicationBar from '../AppBar/ApplicationBar';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ModeEdit from '@mui/icons-material/ModeEdit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSearchParams } from 'react-router-dom';
import { selectCurrentUser } from '../Login_Registration/authSlice';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from '../../axiosCustomInstance';
import { useSelector } from 'react-redux';
import moment from 'moment';
import LoadingDialog from '../Loading';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ProfilePage = () => {
  // Create a theme with custom primary and secondary colors
  const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });

  // Fetch current user data from Redux store
  const User = useSelector(selectCurrentUser);

  // Define state variables for managing form values, error and success messages, loading state, and image cropping
  const [editClicked, setEditClicked] = useState(false);
  const [Form_Value, Set_Form_Value] = useState({
    FirstName: '',
    LastName: '',
    UserName: '',
    DOB: moment().subtract(18, 'years').startOf('day').toString(),
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    State: '',
    Country: '',
    Zip: '',
    phone_number: '',
    email: '',
    Created_Date: '',
    User_Profile_Picture: '',
  });
  const [errorMsge, setErrorMsge] = useState('');
  const [successMsge, setSuccessMsge] = useState('');
  const [errorSuccessDialog, setErrorSuccessDialog] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const AxiosAPIInstance = AxiosFunction();
  const [imageUploadFormData, setImageUploadFormData] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [src, setSrc] = useState(null);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const Email = User?.UserEmail;
  const [filename, setfilename] = useState('');

  // Fetch user's profile image from the server
  const fetchProfileImage = async () => {
    try {
      const response = await AxiosAPIInstance.get('UserAuth/FetchProfileImage', {
        params: { UserEmail: User.UserEmail },
        responseType: 'blob',
      });

      const contentType = response.headers['content-type'];
      if (contentType && contentType.startsWith('image/')) {
        const blob = response.data;
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result;
          setImageUrl(imageUrl);
        };
        reader.readAsDataURL(blob);
      } else {
        console.error('Invalid image response');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch user data and profile image when the component mounts
  React.useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      await AxiosAPIInstance.get('UserAuth/profile', {
        params: { UserEmail: User?.UserEmail },
      })
        .then((response) => {
          setLoading(false);
          Set_Form_Value({
            ...Form_Value,
            FirstName: response?.data['User_FirstName'],
            LastName: response?.data['User_LastName'],
            email: response?.data['User_email'],
            phone_number: response?.data['User_phone_number'],
            UserName: response?.data['UserName'],
            DOB: response?.data['User_DOB'],
            AddressLine1: response?.data['AddressLine1'],
            AddressLine2: response?.data['AddressLine2'],
            City: response?.data['City'],
            State: response?.data['State'],
            Country: response?.data['Country'],
            Zip: response?.data['Zip'],
            Profile_Picture_Path: response?.data['Profile_Picture_Path'],
            User_Profile_Picture: response?.data['User_Profile_Picture'],
          });
          setfilename(response?.data['User_Profile_Picture']);
        })
        .catch((error) => {
          setLoading(false);
          setErrorSuccessDialog(true);
          setErrorMsge(error?.response.data);
          setErrorStatus(true);
        });
    };
    fetchUserData();
    fetchProfileImage();
  }, [errorSuccessDialog]);

  // Handle closing of error dialog
  const handleErrorClose = (e) => {
    e.preventDefault();
    setErrorStatus(false);
    setErrorSuccessDialog(false);
    setEditClicked(true);
    setDisableSaveButton(false);
    setErrorMsge('');
    setSuccessMsge('');
  };

  // Handle closing of success dialog
  const handleSuccessClose = (e) => {
    e.preventDefault();
    setErrorStatus(false);
    setErrorSuccessDialog(false);
    setDisableSaveButton(true);
    setErrorMsge('');
    setSuccessMsge('');
  };

  // Enable edit mode for the form
  const handeEdit = (e) => {
    e.preventDefault();
    setEditClicked(true);
    setDisableSaveButton(false);
  };

  // Handle file change for profile image upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSrc(reader.result);
        setCropDialogOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // Placeholder function for image loaded
  const handleImageLoaded = (image) => {
    // Do nothing
  };

  // Handle completion of image cropping
  const handleCropComplete = (crop) => {
    makeCroppedImage(crop);
  };

  // Handle change in crop selection
  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  // Create the cropped image from the selected crop area
  const makeCroppedImage = async (crop) => {
    if (src && crop.width && crop.height) {
      const image = new Image();
      image.src = src;
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const fileUrl = URL.createObjectURL(blob);
            setCroppedImageUrl(fileUrl);
            setCroppedImageBlob(blob);
            resolve(fileUrl);
          }
        }, 'image/jpeg');
      });
    }
  };

  // Handle profile image upload to the server
  const handleProfileImage = async () => {
    if (!croppedImageBlob) {
      setErrorStatus(true);
      setEditClicked(true);
      setErrorMsge('Please select and crop an image file');
      setErrorSuccessDialog(true);
    } else {
      try {
        const Image_Name_Array = String(User?.UserName).split(' ');
        setLoading(true);
        const formData = new FormData();
        formData.append('UserName', Image_Name_Array[0] + Image_Name_Array[1]);
        formData.append('file', croppedImageBlob);
        formData.append('UserEmail', User?.UserEmail);

        await AxiosAPIInstance.post('UserAuth/ProfileImageUpload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(async (response) => {
            setSuccessMsge(response?.data);
            setErrorSuccessDialog(true);
            setEditClicked(false);
            fetchProfileImage();
          })
          .catch((error) => {
            setErrorStatus(true);
            setEditClicked(true);
            setErrorMsge(error?.response?.data);
            setErrorSuccessDialog(true);
          });
      } catch (err) {
        setErrorStatus(true);
        setEditClicked(true);
        setErrorMsge(err?.response?.data);
        setErrorSuccessDialog(true);
      }
    }
  };

  // Save the updated profile information
  const handeSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDisableSaveButton(true);
    if (
      Form_Value.FirstName !== '' &&
      Form_Value.LastName !== '' &&
      Form_Value.email !== '' &&
      Form_Value.phone_number !== ''
    ) {
      await AxiosAPIInstance.patch('UserAuth/save', Form_Value)
        .then(async (response) => {
          setLoading(false);
          setSuccessMsge(response?.data);
          setErrorSuccessDialog(true);
          setEditClicked(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorStatus(true);
          setEditClicked(true);
          setErrorMsge(error?.response.data);
          setErrorSuccessDialog(true);
        });
    } else {
      setLoading(false);
      if (Form_Value.FirstName === '') setErrorMsge('First Name is required');
      else if (Form_Value.LastName === '') setErrorMsge('Last Name is required');
      else if (Form_Value.email === '') setErrorMsge('Email is required');
      else if (Form_Value.phone_number === '')
        setErrorMsge('Phone Number is required');
      setErrorStatus(true);
      setErrorSuccessDialog(true);
    }
  };

  // Handle closing of the crop dialog
  const handleCropDialogClose = () => {
    setCropDialogOpen(false);
  };

  // Handle saving of the cropped image
  const handleCropDialogSave = () => {
    setCropDialogOpen(false);
    handleProfileImage();
  };

  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {errorSuccessDialog === true && (
        <Dialog
          open={errorSuccessDialog}
          onClose={errorStatus ? handleErrorClose : handleSuccessClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ width: '400px' }}>
            {!errorStatus ? (
              <Alert variant="filled" severity="success">
                success
              </Alert>
            ) : (
              <Alert variant="filled" severity="error">
                error
              </Alert>
            )}
          </DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            {!errorStatus ? successMsge : errorMsge}
          </DialogContent>
          <DialogActions>
            <Button
              name="OK"
              onClick={(e) => {
                errorStatus ? handleErrorClose(e) : handleSuccessClose(e);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Main container with padding */}
      <Grid container sx={{ marginTop: '50px', padding: '0 20px' }} spacing={1}>
        {/* Profile image section */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '50%', height: 'auto', overflow: 'hidden',marginTop:'50px' }}>
            <img
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
              src={imageUrl}
              alt="Profile Image"
            />
          </Box>
        </Grid>

        {/* Profile form section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: '20px' }}>
            <Stack direction="column" spacing={2}>
              {/* Personal Information */}
              <Chip
                label="Personal Information"
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  fontFamily: 'Verdana (sans-serif)',
                  width: '100%',
                }}
              ></Chip>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="FirstName"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    value={Form_Value.FirstName}
                    onChange={(e) => {
                      Set_Form_Value({ ...Form_Value, FirstName: e.target.value });
                    }}
                    InputProps={{ disabled: !editClicked, required: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="LastName"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Last Name"
                    value={Form_Value.LastName}
                    onChange={(e) => {
                      Set_Form_Value({ ...Form_Value, LastName: e.target.value });
                    }}
                    InputProps={{ disabled: !editClicked, required: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    name="DOB"
                    label="Date Of Birth"
                    value={Form_Value.DOB}
                    minDate={moment().subtract(500, 'years').startOf('day').toString()}
                    maxDate={moment().subtract(18, 'years').startOf('day').toString()}
                    renderInput={(params) => <TextField size="small" required fullWidth {...params} />}
                    InputProps={{ disabled: !editClicked, required: true }}
                    onChange={(value) => Set_Form_Value({ ...Form_Value, DOB: value })}
                  ></DatePicker>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phone_number"
                    size="small"
                    fullWidth
                    label="Phone Number"
                    value={Form_Value.phone_number}
                    onChange={(e) => {
                      Set_Form_Value({ ...Form_Value, phone_number: e.target.value });
                    }}
                    InputProps={{
                      disabled: !editClicked,
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactPhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    type="email"
                    size="small"
                    fullWidth
                    label="Email"
                    value={User?.UserEmail}
                    disabled
                    InputProps={{
                      required: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <Tooltip title="This Field is locked">
                          <LockIcon />
                        </Tooltip>
                      ),
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="file"
                    name="image"
                    size="small"
                    fullWidth
                    onChange={handleFileChange}
                    helperText="Profile Image Upload"
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Street Address"
                    value={Form_Value.AddressLine1}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, AddressLine1: e.target.value })}
                    InputProps={{
                      disabled: !editClicked,
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactMailIcon />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Apartment, suite, etc."
                    value={Form_Value.AddressLine2}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, AddressLine2: e.target.value })}
                    InputProps={{
                      disabled: !editClicked,
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="City"
                    value={Form_Value.City}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, City: e.target.value })}
                    InputProps={{ disabled: !editClicked }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="State/Province"
                    value={Form_Value.State}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, State: e.target.value })}
                    InputProps={{ disabled: !editClicked }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Country"
                    value={Form_Value.Country}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, Country: e.target.value })}
                    InputProps={{ disabled: !editClicked }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Zip/Postal Code"
                    value={Form_Value.Zip}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, Zip: e.target.value })}
                    InputProps={{ disabled: !editClicked }}
                  ></TextField>
                </Grid>
              </Grid>

              {/* Login Credentials */}
              <Chip
                label="Login Credentials"
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  fontFamily: 'Verdana (sans-serif)',
                  width: '100%',
                }}
              ></Chip>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="UserName"
                    value={Form_Value.UserName}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, UserName: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <Tooltip title="This Field is locked">
                          <LockIcon />
                        </Tooltip>
                      ),
                    }}
                    disabled
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="password"
                    size="small"
                    fullWidth
                    label="Password"
                    value={Form_Value.Password}
                    required={true}
                    onChange={(e) => Set_Form_Value({ ...Form_Value, Password: e.target.value })}
                    disabled
                  ></TextField>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                <IconButton>
                  <ModeEdit />
                </IconButton>
              </Grid> */}
              </Grid>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  color="warning"
                  size="medium"
                  onClick={(e) => {
                    handeEdit(e);
                  }}
                  startIcon={<ModeEdit />}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="medium"
                  onClick={(e) => {
                    handeSave(e);
                  }}
                  disabled={disableSaveButton}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog for cropping the profile image */}
      <Dialog open={cropDialogOpen} onClose={handleCropDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Adjust and Crop Your Image</DialogTitle>
        <DialogContent>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={handleImageLoaded}
              onComplete={handleCropComplete}
              onChange={handleCropChange}
            />
          )}
          {croppedImageUrl && (
            <img
              alt="Crop"
              style={{ maxWidth: '100%', marginTop: '10px' }}
              src={croppedImageUrl}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCropDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCropDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePage;
