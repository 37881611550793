import {React} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import Packing_Slip_Contract_Info_Header_One from './Packing_Slip_Contract_Info_Header_One';
import Packing_Slip_Contract_Info_Row_One from './Packing_Slip_Contract_Info_Row_One';
import Packing_Slip_Contract_Info_Header_Two from './Packing_Slip_Contract_Info_Header_Two';
import Packing_Slip_Contract_Info_Row_Two from './Packing_Slip_Contract_Info_Row_Two';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderColor: 'black',
        padding:0,
        borderBottomWidth:1,
        borderRightWidth:1,
        borderLeftWidth:1
    },
});

const Packing_Slip_Contract_Info_Table = ({ invoice }) => (
    <View style={styles.tableContainer}>
        <Packing_Slip_Contract_Info_Header_One />
        <Packing_Slip_Contract_Info_Row_One invoice={invoice} />
        <Packing_Slip_Contract_Info_Header_Two />
        <Packing_Slip_Contract_Info_Row_Two invoice={invoice} />
    </View>
);

export default Packing_Slip_Contract_Info_Table;