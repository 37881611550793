import {React} from 'react';
import { Text, View, StyleSheet,Image} from '@react-pdf/renderer';
import logo from "../Images/Aspetto_Login.png";
const styles = StyleSheet.create({
    label:{
        marginTop: 24,
        flexDirection:'column'
    },
    titleContainer: {
        marginTop: 20,
    },
    reportTitle: {
        color: 'black',
        fontSize: 10,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'extrabold'
    },
    Quote_Title:{
        color: 'black',
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'bold',
    },
    logo: {
        width: 150,
        height: 50,
        marginLeft: 'auto',
        marginRight: "auto",
        alignContent:"center"
    },

    Image_con:{
        flexDirection:'row'
    },
    Address:{
        width: 190,
        height: 40,
    }
});

const InvoiceTitle = ({ invoice }) => {

    return(
    <>
     <View style={styles.Image_con}>
    <Image style={styles.logo} src={logo} />
     </View>
    </>
);
}

export default InvoiceTitle;