import React, { useState, useRef, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Link,
  Stack,
  InputAdornment,
  Paper,Divider,Box,Chip
} from '@mui/material';
import Alert from '@mui/material/Alert';
import {useLocation,useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance} from '../../../axiosGlobalInstance'
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Login_Bck from "../../Images/Login.png"
import Login_Im from "../../Images/Aspetto_Login.png";
import { Twitter, Facebook, Instagram, LinkedIn, Fullscreen } from '@mui/icons-material';
import { bgcolor, color, styled } from '@mui/system';
import { deepOrange, red,blue,cyan,blueGrey } from '@mui/material/colors'
import Logo from "../../Images/logo.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingDialog from '../../Loading'
import EmailIcon from '@mui/icons-material/Email';



const RetrieveUserNameEmail  = () => {

  const [errMsge,setErrMsge]=useState("");
  const [errorDialog,setErrorDialog]=useState(false)
  const [successMsge,setSuccessMsge]=useState("");
  const [successDialog,setSuccessDialog]=useState(false)
  const [email,setEmail]=useState("")
  const[dis,setDis]=useState(true)
  const navigate=useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
  

  const Footer = styled('footer')(({ theme }) => ({
    marginTop: 'auto',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    fontSize: '12px',
    color: 'gray',
    borderTop: '0px solid #ccc',
    borderRadius:"5px"
  }));
  
  const SocialIcons = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    '& > button': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));
  



  const {token}  = useParams()
  const [verificationStatus, setVerificationStatus] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault()
        setDis(false)
      try {
        await AxiosGlobalAPIInstance.get(`UserAuth/retrieveUserName/Email`,{params:{User_email:String(email).toLowerCase().trim()}}).then(async(response)=>{
          setVerificationStatus(response?.data);
          setSuccessDialog(true)
          setSuccessMsge(response?.data)
          setErrorDialog(false)
          setErrMsge("")
          setDis(true)
      }).catch((error)=>{
        setVerificationStatus(error?.response.data);
        setErrorDialog(true)
        setErrMsge(error?.response.data)
        setSuccessDialog(false)
        setSuccessMsge("")
        setDis(true)
      })
      } catch (error) {
        setVerificationStatus('Email verification failed.');
        setErrorDialog(true)
        setErrMsge('Email verification failed.')
        setSuccessDialog(false)
        setSuccessMsge("")
        setDis(true)
      }
    };



const handleClose = (e) => {
  if(successDialog===true)
  {
    navigate("/",{ replace: true })
  }
  
    e.preventDefault();
    setErrorDialog(false)
    setSuccessMsge("")
    setSuccessDialog(false)
    setErrMsge("")
    setDis(true)
 
  }


  return (
    <>
   {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        <Alert variant='filled' severity='error'>error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle  id="alert-dialog-title">
        <Alert variant='filled' severity='success'>success</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {successMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <Stack direction="column" sx={{ backgroundImage: `url(${Login_Bck})`,
      backgroundSize:'cover',
        height: "81vh",
        justifyContent:"center",
        ":fullscreen":true,alignItems:'center'}}>
<Paper component='form' autoComplete='off' onSubmit={handleSubmit} justifyContent="center" spacing={3} sx={{backgroundColor:'white',width:"550px",borderRadius:"16px",":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
<Stack  direction='row' justifyContent="center" spacing={2}>
<Stack justifyContent="center" direction="column">
  <Box component="img" src={Login_Im} sx={{height:"75px",width:"220px",marginLeft:"40px"}}>
  </Box>
  <Chip label="RETRIEVE USERNAME" sx={{marginTop:"10px",marginLeft:"40px",fontSize:"18px",fontWeight:'bold',color:blueGrey[700],fontFamily:'Verdana (sans-serif)'}}></Chip>
</Stack>
<Divider type=""></Divider>
<Stack direction='column' spacing={3} justifyContent="center">
<Stack></Stack>
<Stack></Stack>
<TextField type="text"  
value={email} 
onChange={(e)=>{setEmail(e.target.value)
}}
variant='standard'  
size="small"  
required 
id="Email" 
label="Your Workplace Email" 
InputProps={{startAdornment:(<InputAdornment position='start'>
<EmailIcon></EmailIcon>
</InputAdornment>)}}
></TextField>
<Button type="submit" variant="contained" color="warning" size='small' disabled={!dis} >Submit </Button>
<Button type="submit" variant="contained" size='small' sx={{backgroundColor:blueGrey[500]}} disabled={!dis}  onClick={(e)=>{e.preventDefault();navigate("/",{replace:true})}}>Go Back to Login Page </Button>
<Stack></Stack>
<Stack></Stack>
</Stack>
<Stack></Stack>
<Stack></Stack>
</Stack>
<Stack direction="row" justifyContent="center" sx={{borderBottomLeftRadius:"16px",borderBottomRightRadius:"16px",backgroundColor:blueGrey[900]}}>
<Typography variant='body2' sx={{backgroundColor:blueGrey[900],color:'whitesmoke',borderBottomLeftRadius:"16px",borderBottomRightRadius:"16px",padding:"10px",alignContent:"center"}} justifyContent="center">     This webpage is designed exclusively for Aspetto Inc. employees.</Typography>     
</Stack>

</Paper>
 </Stack>
 <Stack direction="row" spacing={4} sx={{ bgcolor: blueGrey[900], color: "white", padding: 1, fontSize: "12px", height: "100px" }}>
            <Stack direction="column" spacing={0.1} sx={{ margin:'50px', }}>
              <IconButton size='large' edge='start' color='inherit' >
                <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
              </IconButton>
              <p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
              <p>Inc. 5000 America's Fastest Growing Private Companies</p>
              <p>ISO certified ISO 9001:2015</p>
            </Stack>
            <Stack></Stack>
            <Stack direction="column" spacing={0.1} sx={{ marginLeft: "20px",padding:'20px'  }} justifyContent="right">

              <p>Let's talk:</p>
              <p>Phone: 540-547-8487</p>
              <p>Fax: 540-300-2669</p>
              <Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}></Stack>
            <Stack direction="column" spacing={0.1} sx={{ alignItems: 'center', }}>
              <p></p>
              <p>SOCIAL MEDIA</p>
              <Stack direction="row" spacing={.5} justifyContent="center" >
                <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
                  <Twitter color="white" sx={{ backgroundColor: "white", color: cyan[400] }} />
                </IconButton>
                <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <Facebook color="white" sx={{ backgroundColor: "white", color: blue[800] }} />
                </IconButton>
                <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
                  <Instagram color="white" sx={{ backgroundColor: "white", color: red[800] }} />
                </IconButton>
                <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <LinkedIn sx={{ backgroundColor: "white", color: blueGrey[600] }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
          </>
  );
};

export default RetrieveUserNameEmail ;
