import * as React from 'react';
import {useEffect,useState} from "react"
import {Box,Button,TextField,Paper, Stack,Typography,Link,Divider,Chip} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import Login_Im from "../../Images/Aspetto_Login.png"
import Login_Bck from "../../Images/Login.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance}  from '../../../axiosGlobalInstance';
import { useParams } from 'react-router-dom'
import {useNavigate } from 'react-router-dom';
import { Twitter, Facebook, Instagram, LinkedIn, Fullscreen } from '@mui/icons-material';
import { deepOrange, red,blue,cyan,blueGrey, indigo } from '@mui/material/colors'
import Logo from "../../Images/logo.png";

const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!^&~*@#$%]).{5,15}$');

const ResetUserPasswordPage = () => {
  const PD = new Date();
  const [conpass, setConPass] = useState("");
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [matchError, setMatchError] = useState("");
  const [dis, setDis] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errMsge, setErrMsge] = useState("");
  const [successMsge, setSuccessMsge] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('');
  const [NewUser, setNewUser] = useState({ UserPassword: "" });

  const handleClickShowPassword2 = () => {
    setConPass(!conpass);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePassword = (e) => {
    setPasswordValid(validPassword.test(e.target.value));
    setNewUser({ ...NewUser, UserPassword: e.target.value });
    if (e.target.value === matchPwd) {
      if (passwordValid === true) {
        setValidMatch(true);
        setMatchError('');
        setDis(false);
      } else {
        setDis(true);
        setMatchError('');
        setValidMatch(true);
      }
    } else {
      setValidMatch(false);
      setMatchError('Password Mismatch');
      setDis(true);
    }
  };

  const handleConfirmPassword = (e) => {
    setMatchPwd(e.target.value);
    if (e.target.value === NewUser.UserPassword) {
      if (passwordValid === true) {
        setValidMatch(true);
        setMatchError('');
        setDis(false);
      } else {
        setDis(true);
        setMatchError('');
        setValidMatch(true);
      }
    } else {
      setValidMatch(false);
      setMatchError('Password Mismatch');
      setDis(true);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setErrorDialog(false);
    setSuccessMsge("");
    setSuccessDialog(false);
    setErrMsge("");
    navigate("/", { replace: true });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDis(true);
    try {
      await AxiosGlobalAPIInstance.get(`/UserAuth/pass-reset`, { params: { token: token, New_Password: NewUser.UserPassword } }).then(async (response) => {
        setVerificationStatus(response?.data);
        setSuccessDialog(true);
        setSuccessMsge(response?.data);
        setErrorDialog(false);
        setErrMsge("");
        setDis(false);
      }).catch((error) => {
        setVerificationStatus(error?.response.data);
        if (error?.status === 403) {
          setErrorDialog(true);
          setErrMsge(error?.response.data);
        } else {
          setErrorDialog(true);
          setErrMsge(error?.response.data);
          setSuccessDialog(false);
          setSuccessMsge("");
        }
        setDis(false);
      })
    } catch (error) {
      setVerificationStatus('Email verification failed.');
      setErrorDialog(true);
      setErrMsge('Email verification failed.');
      setSuccessDialog(false);
      setSuccessMsge("");
      setDis(false);
    }
  };

  return (
    <>
      {errorDialog === true && (<Dialog
        open={errorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Alert variant='filled' severity='error'>error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      {successDialog === true && (<Dialog
        open={successDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Alert variant='filled' severity='success'>success</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
      <Stack direction="column" sx={{
        backgroundImage: `url(${Login_Bck})`,
        backgroundSize: 'cover',
        height: "81vh",
        justifyContent: "center",
        alignItems: 'center'
      }} justifyContent='center'>
        <Paper component="form" autoComplete='off' elevation={2} sx={{
          border:1 ,
          borderRadius: "16px",
          ":hover": {
            boxShadow: "10px 10px 20px #ccc"
          }
          
        }} justifyContent='center'>
          <Stack direction='column' spacing={2} sx={{ padding: 5,}} justifyContent='center'>
            <Box component="img" src={Login_Im} sx={{ height: "75px", width: "220px",marginLeft:"70px" }} >
            </Box>
            <Chip label="PASSWORD RESET" sx={{ marginTop: "10px", fontSize: "18px", fontWeight: 'bold', color: blueGrey[700], fontFamily: 'Verdana (sans-serif)', margin: "auto" }}></Chip>
            <Stack direction='row' spacing={3} justifyContent="center">
              <TextField
                variant='standard'
                type="password"
                value={NewUser.UserPassword}
                onChange={handlePassword}
                size="small"
                required
                id="Password"
                label="New Password"
                autoComplete='off'
                sx={{ width: "230px" }}
                InputProps={{
                  startAdornment: (<InputAdornment position='start'>
                    <PasswordIcon></PasswordIcon>
                  </InputAdornment>)
                }}>
              </TextField>
              <TextField
                variant='standard'
                type={conpass ? "text" : "password"}
                value={matchPwd}
                onChange={handleConfirmPassword}
                size="small"
                required
                id="matchPwd"
                label="Confirm New Password"
                autoComplete='off'
                sx={{ width: "230px" }}
                InputProps={{
                  endAdornment: (<InputAdornment position='end'>
                    <IconButton onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword}>{conpass ? <VisibilityIcon></VisibilityIcon> : <VisibilityOffIcon></VisibilityOffIcon>}</IconButton>
                  </InputAdornment>)
                }}>
              </TextField>
              {NewUser.UserPassword !== '' && matchPwd !== '' && (validMatch ? <Alert sx={{ width: "200px", height: "35px", fontSize: "12px" }} severity='success'>Passwords matched</Alert> : <Alert sx={{ width: "170px", height: "35px", fontSize: "12px" }} severity="error">{matchError}</Alert>)}
            </Stack>
            <Stack direction='row' spacing={3} justifyContent="center">
              {NewUser.UserPassword !== '' && (passwordValid ? <Alert severity='success' sx={{ height: "35px", fontSize: "12px" }}>{"Password is Valid"}</Alert> :
                <Alert sx={{ width: "400px", height: "70px", fontSize: "12px" }} severity='error'>{"Should contain atleast one (a-z), one (A-Z), one(0-9) and one special character from (!^&~*@#$%) and total length should be between 5 to 15 "}</Alert>)}
            </Stack>
            <Button type="submit" variant="contained" sx={{ margin: "auto" }} color="warning" size='small' onClick={handleSubmit} disabled={dis}>Reset Password </Button>
          </Stack>
        </Paper>
      </Stack>
      <Stack direction="row" spacing={4} sx={{ bgcolor: blueGrey[900], color: "white", padding: 1, fontSize: "12px", height: "100px" }}>
        <Stack direction="column" spacing={0.1} sx={{ margin: '50px', }}>
          <IconButton size='large' edge='start' color='inherit' >
            <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
          </IconButton>
          <p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
          <p>Inc. 5000 America's Fastest Growing Private Companies</p>
          <p>ISO certified ISO 9001:2015</p>
        </Stack>
        <Stack></Stack>
        <Stack direction="column" spacing={0.1} sx={{ marginLeft: "20px", padding: '20px' }} justifyContent="right">
          <p>Let's talk:</p>
          <p>Phone: 540-547-8487</p>
          <p>Fax: 540-300-2669</p>
          <Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
        </Stack>
        <Stack sx={{ flexGrow: 1 }}></Stack>
        <Stack direction="column" spacing={0.1} sx={{ alignItems: 'center', }}>
          <p></p>
          <p>SOCIAL MEDIA</p>
          <Stack direction="row" spacing={.5} justifyContent="center" >
            <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
              <Twitter color="white" sx={{ backgroundColor: "white", color: cyan[400] }} />
            </IconButton>
            <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
              <Facebook color="white" sx={{ backgroundColor: "white", color: blue[800] }} />
            </IconButton>
            <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
              <Instagram color="white" sx={{ backgroundColor: "white", color: red[800] }} />
            </IconButton>
            <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
              <LinkedIn sx={{ backgroundColor: "white", color: blueGrey[600] }} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
};


export default ResetUserPasswordPage;