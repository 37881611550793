import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText,
  ListItemAvatar, Avatar, Typography, Box, IconButton, Tooltip, Alert, Link, TextField
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { blue, blueGrey, orange } from '@mui/material/colors';
import { MentionsInput, Mention } from 'react-mentions';
import moment from 'moment-timezone';
import Axios from 'axios';
import UserAuth from '../ProtectedRoute/userAuth';
import AxiosFunction from '../../axiosCustomInstance';

// Function to generate a random light hexadecimal color code
const getRandomColor = () => {
  const letters = '89ABCDEF'; // Using only the higher range to get lighter colors
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};

const useStyles = {
  mentionsInput: {
    width: '100%',
    '&multiLine': {
      input: {
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
        padding: '10px',
        minWidth: '300px',
        minHeight: '100px',
      },
      '&highlighter': {
        padding: '10px',
        border: '1px solid transparent',
      },
      '&input': {
        padding: '10px',
        border: '1px solid silver',
      },
    },
  },
  mentionsSuggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: '14px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
  mention: {
    color: blue[700],
    cursor: 'pointer',
  },
};

const ConversationDialog = ({ open, onClose, conversationId, title }) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editingCommentText, setEditingCommentText] = useState('');
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [editingReplyText, setEditingReplyText] = useState('');
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [replyingReplyId, setReplyingReplyId] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyDialogContent, setHistoryDialogContent] = useState([]);
  const { UserName, UserEmail } = UserAuth();
  const AxiosAPIInstance = AxiosFunction();
  const [userColors, setUserColors] = useState({});

  useEffect(() => {
    if (conversationId) {
      fetchOrCreateConversation(conversationId, title);
    }
    fetchUsers();
  }, [conversationId, title]);

  const fetchOrCreateConversation = async (conversationId, title) => {
    try {
      const response = await AxiosAPIInstance.post('/Conversations/conversation', { conversationId, title });
      const sortedComments = (response.data.comments || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setComments(sortedComments);
    } catch (error) {
      console.error('Failed to fetch or create conversation:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await AxiosAPIInstance.get("/UserAuth/users/comments"); // Update this with the actual API endpoint
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const handleNewCommentChange = (event, newValue, newPlainTextValue) => {
    setNewComment(newPlainTextValue);
  };

  const handleSendComment = async (e) => {
    e.preventDefault();
    if (newComment.trim() === '') return;

    try {
      const response = await AxiosAPIInstance.post('/Conversations/conversation/comment', {
        conversationId,
        userEmail: UserEmail,
        userName: UserName,
        text: newComment.trim(),
      });

      setComments((prevComments) => [...prevComments, response.data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleSendReply = async (commentId, parentId = null) => {
    if (replyText.trim() === '') return;

    try {
      const response = await AxiosAPIInstance.post('/Conversations/conversation/comment/reply', {
        commentId,
        parentId,
        userEmail: UserEmail,
        userName: UserName,
        text: replyText.trim(),
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === commentId ? { ...comment, replies: [...(comment.replies || []), response.data] } : comment
        ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );
      setReplyText('');
      setReplyingCommentId(null);
      setReplyingReplyId(null);
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  const handleEditComment = (commentId, text) => {
    setEditingCommentId(commentId);
    setEditingCommentText(text);
  };

  const handleEditCommentChange = (event) => {
    setEditingCommentText(event.target.value);
  };

  const handleSaveEditComment = async () => {
    try {
      const response = await AxiosAPIInstance.patch('/Conversations/conversation/comment/edit', {
        commentId: editingCommentId,
        newText: editingCommentText,
        userEmail: UserEmail,
        userName: UserName,
      });

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === response.data.id ? response.data : comment
        ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );

      setEditingCommentId(null);
      setEditingCommentText('');
    } catch (error) {
      console.error('Error editing comment:', error);
    }
  };

  const handleEditReply = (replyId, text) => {
    setEditingReplyId(replyId);
    setEditingReplyText(text);
  };

  const handleEditReplyChange = (event) => {
    setEditingReplyText(event.target.value);
  };

  const handleSaveEditReply = async () => {
    try {
      const response = await AxiosAPIInstance.patch('/Conversations/conversation/comment/edit', {
        commentId: editingReplyId,
        newText: editingReplyText,
        userEmail: UserEmail,
        userName: UserName,
      });

      setComments((prevComments) =>
        prevComments.map((comment) => {
          const updatedReplies = comment.replies?.map((reply) =>
            reply.id === response.data.id ? response.data : reply
          );
          return comment.replies ? { ...comment, replies: updatedReplies } : comment;
        }).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      );

      setEditingReplyId(null);
      setEditingReplyText('');
    } catch (error) {
      console.error('Error editing reply:', error);
    }
  };

  const handleClose = () => {
    setEditingCommentId(null);
    setEditingCommentText('');
    setReplyingCommentId(null);
    setReplyingReplyId(null);
    setReplyText('');
    onClose();
  };

  const handleOpenHistoryDialog = (editHistory) => {
    setHistoryDialogContent(editHistory);
    setHistoryDialogOpen(true);
  };

  const handleCloseHistoryDialog = () => {
    setHistoryDialogOpen(false);
    setHistoryDialogContent([]);
  };

  const groupCommentsByDate = (comments) => {
    return comments.reduce((groups, comment) => {
      const date = moment(comment.createdAt).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(comment);
      return groups;
    }, {});
  };

  const assignColorToUser = (userEmail) => {
    if (!userColors[userEmail]) {
      setUserColors(prevColors => ({ ...prevColors, [userEmail]: getRandomColor() }));
    }
    return userColors[userEmail];
  };

  const renderReplies = (replies, parentCommentId) => {
    if (!replies || !replies.length) return null;
    return replies.map((reply) => (
      <ListItem key={reply.id} alignItems="flex-start" sx={{ ml: 4 }}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: assignColorToUser(reply.userEmail), color: blueGrey[900], border: 0.5 }}>
            {reply.userEmail && String(reply.userEmail).toUpperCase().split('.')[0][0] + String(reply.userEmail).toUpperCase().split('.')[1][0]}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }} component="span">
                {reply.userName
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ')}
              </Typography>
              <Typography component="span"> </Typography>
              <Typography variant="body2" component="span">
                {moment(reply.createdAt).tz(moment.tz.guess()).format('hh:mm A')}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography sx={{ fontSize: '13px' }} color="textPrimary" component="p">
                {reply.id === editingReplyId ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      value={editingReplyText}
                      onChange={handleEditReplyChange}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <Tooltip title="Save">
                      <IconButton size="small" onClick={handleSaveEditReply}>
                        <Typography variant="caption" color="textSecondary">
                          Save
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  parseAndRenderMentions(reply.text)
                )}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                {reply.id === editingReplyId ? null : reply.userEmail === UserEmail && (
                  <Link
                    component="button"
                    variant="caption"
                    color="primary"
                    onClick={() => handleEditReply(reply.id, reply.text)}
                  >
                    Edit
                  </Link>
                )}
                {reply.editHistory && reply.editHistory.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <Link
                      component="button"
                      variant="caption"
                      color="primary"
                      onClick={() => handleOpenHistoryDialog(reply.editHistory)}
                    >
                      Edited
                    </Link>
                  </Box>
                )}
                <Link
                  component="button"
                  variant="caption"
                  color="primary"
                  onClick={() => setReplyingReplyId(reply.id)}
                  sx={{ ml: 1 }}
                >
                  Reply
                </Link>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  {Array.isArray(reply.reactions) && reply.reactions.map((reaction, index) => (
                    <Tooltip
                      key={index}
                      title={reaction.users.map(user => user.name).join(', ')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                        <Typography variant="body2" component="span">
                          {reaction.emoji}
                        </Typography>
                        <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                          {reaction.users.length}
                        </Typography>
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
              {replyingReplyId === reply.id && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
                  <MentionsInput
                    value={replyText}
                    onChange={(e, newValue, newPlainTextValue) => setReplyText(newPlainTextValue)}
                    style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
                  >
                    <Mention
                      trigger="@"
                      data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName }))}
                      renderSuggestion={(suggestion, search, highlightedDisplay) => (
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{suggestion.display}</Typography>
                              Email: {suggestion.id}<br />
                              Role: {suggestion.role}
                            </React.Fragment>
                          }
                          arrow
                          placement="top"
                        >
                          <Box sx={{ display: 'inline-block' }}>
                            {highlightedDisplay}
                          </Box>
                        </Tooltip>
                      )}
                      markup="@{{__type__||__id__||__display__}}"
                      displayTransform={(id, display) => (
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{display}</Typography>
                              Email: {id}<br />
                              Role: {/* Replace this with role */}
                            </React.Fragment>
                          }
                          arrow
                          placement="top"
                        >
                          <Box sx={{ display: 'inline-block', color: blue[700], cursor: 'pointer' }}>
                            {`@${display}`}
                          </Box>
                        </Tooltip>
                      )}
                    />
                  </MentionsInput>
                  <IconButton onClick={() => handleSendReply(parentCommentId, reply.id)} sx={{ ml: 1 }}>
                    <SendIcon color="warning" />
                  </IconButton>
                </Box>
              )}
              {renderReplies(reply.replies, parentCommentId)}
            </>
          }
        />
      </ListItem>
    ));
  };

  const parseAndRenderMentions = (text) => {
    return text.split(' ').map(word => {
      if (word.startsWith('@')) {
        const mentionedUser = users.find(user => `@${user.User_FirstName} ${user.User_LastName}` === word);
        if (mentionedUser) {
          return (
            <Tooltip
              key={mentionedUser.User_email}
              title={
                <React.Fragment>
                  <Typography color="inherit">{mentionedUser.User_FirstName} {mentionedUser.User_LastName}</Typography>
                  Email: {mentionedUser.User_email}<br />
                  Role: {/* Replace this with role */}
                </React.Fragment>
              }
              arrow
              placement="top"
            >
              <Box component="span" sx={{ color: blue[700], cursor: 'pointer' }}>
                {word}{' '}
              </Box>
            </Tooltip>
          );
        }
      }
      return `${word} `;
    });
  };

  const groupedComments = groupCommentsByDate(comments);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="filled">
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontWeight: 'bold', fontFamily: 'arial' }}>
            Comments :{title}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.keys(groupedComments).map((date) => (
              <React.Fragment key={date}>
                <Typography align="center" sx={{ mt: 2, mb: 2, bgcolor: blueGrey[100], padding: 1, borderRadius: 1, fontsize: '14px' }}>
                  {moment(date).format('dddd, MMMM Do YYYY')}
                </Typography>
                {groupedComments[date].map((comment) => (
                  <ListItem key={comment.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: assignColorToUser(comment.userEmail), color: blueGrey[900], border: 0.5 }}>
                        {comment.userEmail && String(comment.userEmail).toUpperCase().split('.')[0][0] + String(comment.userEmail).toUpperCase().split('.')[1][0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }} component="span">
                            {comment.userName
                              .split(' ')
                              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                              .join(' ')}
                          </Typography>
                          <Typography component="span"> </Typography>
                          <Typography variant="body2" component="span">
                            {moment(comment.createdAt).tz(moment.tz.guess()).format('hh:mm A')}
                          </Typography>
                        </>
                      }
                      secondary={
                        <>
                          <Typography sx={{ fontSize: '13px' }} color="textPrimary" component="p">
                            {comment.id === editingCommentId ? (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                  value={editingCommentText}
                                  onChange={handleEditCommentChange}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                                <Tooltip title="Save">
                                  <IconButton size="small" onClick={handleSaveEditComment}>
                                    <Typography variant="caption" color="textSecondary">
                                      Save
                                    </Typography>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : (
                              parseAndRenderMentions(comment.text)
                            )}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            {comment.id === editingCommentId ? null : comment.userEmail === UserEmail && (
                              <Link
                                component="button"
                                variant="caption"
                                color="primary"
                                onClick={() => handleEditComment(comment.id, comment.text)}
                              >
                                Edit
                              </Link>
                            )}
                            {comment.editHistory && comment.editHistory.length > 0 && (
                              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                <Link
                                  component="button"
                                  variant="caption"
                                  color="primary"
                                  onClick={() => handleOpenHistoryDialog(comment.editHistory)}
                                >
                                  Edited
                                </Link>
                              </Box>
                            )}
                            <Link
                              component="button"
                              variant="caption"
                              color="primary"
                              onClick={() => setReplyingCommentId(comment.id)}
                              sx={{ ml: 1 }}
                            >
                              Reply
                            </Link>
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                              {Array.isArray(comment.reactions) && comment.reactions.map((reaction, index) => (
                                <Tooltip
                                  key={index}
                                  title={reaction.users.map(user => user.name).join(', ')}
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                    <Typography variant="body2" component="span">
                                      {reaction.emoji}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                                      {reaction.users.length}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              ))}
                            </Box>
                          </Box>
                          {replyingCommentId === comment.id && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
                              <MentionsInput
                                value={replyText}
                                onChange={(e, newValue, newPlainTextValue) => setReplyText(newPlainTextValue)}
                                style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
                              >
                                <Mention
                                  trigger="@"
                                  data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName }))}
                                  renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">{suggestion.display}</Typography>
                                          Email: {suggestion.id}<br />
                                          Role: {suggestion.role}
                                        </React.Fragment>
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <Box sx={{ display: 'inline-block' }}>
                                        {highlightedDisplay}
                                      </Box>
                                    </Tooltip>
                                  )}
                                  markup="@{{__type__||__id__||__display__}}"
                                  displayTransform={(id, display) => (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">{display}</Typography>
                                          Email: {id}<br />
                                          Role: {/* Replace this with role */}
                                        </React.Fragment>
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <Box sx={{ display: 'inline-block', color: blue[700], cursor: 'pointer' }}>
                                        {`@${display}`}
                                      </Box>
                                    </Tooltip>
                                  )}
                                />
                              </MentionsInput>
                              <IconButton onClick={() => handleSendReply(comment.id)} sx={{ ml: 1 }}>
                                <SendIcon color="warning" />
                              </IconButton>
                            </Box>
                          )}
                          {renderReplies(comment.replies, comment.id)}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
            <MentionsInput
              value={newComment}
              onChange={handleNewCommentChange}
              style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
            >
              <Mention
                trigger="@"
                data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName, role:user.User_Role, active: user.IsActive}))}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{suggestion.display}</Typography>
                        Email: {suggestion.id}<br />
                        Role: {suggestion.role}<br />
                        Active:{suggestion.active}
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                  >
                    <Box sx={{ display: 'inline-block' }}>
                      {highlightedDisplay}
                    </Box>
                  </Tooltip>
                )}
              />
            </MentionsInput>
            <IconButton onClick={handleSendComment} sx={{ ml: 1 }}>
              <SendIcon color="warning" />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={historyDialogOpen} onClose={handleCloseHistoryDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontWeight: 'bold', fontFamily: 'arial' }}>
            Edit History
          </Alert>
        </DialogTitle>
        <DialogContent>
          <List>
            {historyDialogContent.map((history, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Edited at ${moment(history.editedAt).tz(moment.tz.guess()).format('YYYY-MM-DD hh:mm A')}`}
                  secondary={history.text}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistoryDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConversationDialog;
