import React from "react";

function FBOP_SKUs_ARRAY()
{
  const FBOP_SKUs_ARRAY=[{ID:"4-101-001",Desc:"VEST, T-SHIRT STYLE, MALE SPIKE 3, COMPLETE PACKAGE"},
  {ID:"4-101-002",Desc:"VEST, T-SHIRT STYLE, FEMALE SPIKE 3, COMPLETE PACKAGE"},
  {ID:"4-101-003",Desc:"VEST, TRADITIONAL STYLE, MALE SPIKE 3, COMPLETE PACKAGE"},
  {ID:"4-101-004",Desc:"VEST, TRADITIONAL STYLE,FEMALE, SPIKE 3, COMPLETE PACKAGE"},
  {ID:"4-101-009",Desc:"ARMOR CARRY BAG"},
  {ID:"4-101-005",Desc:"EXTRA MALE T-SHIRT CARRIER"},
  {ID:"4-101-006",Desc:"EXTRA FEMALE T-SHIRT CARRIER"},
  {ID:"4-101-007",Desc:"EXTRA MALE TRADITIONAL CARRIER"},
  {ID:"4-101-008",Desc:"EXTRA FEMALE TRADITIONAL CARRIER"},
  {ID:"4-101-010",Desc:`OVERSIZE CHARGE FOR ANY VEST WITH WAIST CIRCUMFRENCE GREATER THAN 44" (INCHES) STANDARD SIZES 2X AND ABOVE`},]

   return FBOP_SKUs_ARRAY

}
export default FBOP_SKUs_ARRAY