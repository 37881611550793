import {Box,Button,TextField,Paper, Stack,Typography,IconButton, Avatar, Grid, Tooltip,Divider} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import { useState} from "react";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey,orange} from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance"
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import DHS from '../Images/cbpseal.png'
import LoadingDialog from '../Loading'
const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
 

  function SKUsRow(props) {
      const PD= new Date()
      const { row} = props;
      const [open, setOpen] = React.useState(false);
      const[clicked,setClicked]=useState(false)

      return (
        <React.Fragment>
          <TableRow>
            <TableCell sx={{border:1,borderColor:'text.primary',color:orange[900],fontWeight:'bold',padding:1}} align="center" scope="row">{row.Part_No}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}} align="center" scope="row"><b>{row.SKU_Sub_Type}</b></TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.Item_Description}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year1}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year2}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year3}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year4}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year5}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year6}</TableCell>
            <TableCell sx={{border:1,borderColor:'text.primary',padding:1}}align="center">{row.UnitPrice_Year7}</TableCell>

          </TableRow>
        </React.Fragment>
      );
    }
   
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }
      
      TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
      };     
const SKUsDHSRetrieve=()=>
{

    const [skuSearchParamsPackages,setSKUSearchParamsPackages]=useState({sku:'Scalable Package'})
    const [skuSearchParamsIndividual,setSKUSearchParamsIndividual]=useState({sku:'Panels'})
    const [dis,setDis]=useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [GetClick,SetGetClick]=useState(false)
    const [totalData,setTotalData]=useState([{}]) 
    const [individualSearch,setIndividualSearch]=useState(false)
    const [partSearch,setPartSearch]=useState(false)
    const [partNumber,setPartNumber]=useState('')
    const AxiosAPIInstance=AxiosFunction()
    const [errorDialog,setErrorDialog]=useState(false)
    const [errMssge,setErrMsge]=useState('')
    const [packageSearch,setPackageSearch]=useState(false)
    const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSKUTypeChange=(e)=>
    {
     SetGetClick(false)
     if(e.target.value==='Panels')
     {
         setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Panels'})
     }
     else if(e.target.value==='Carriers')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Carriers'})
     }
     else if(e.target.value==='Kits')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Kits'})
     }
     else if(e.target.value==='Panel Covers')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Panel Covers'})
     }
     else if(e.target.value==='Platforms')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Platforms'})
     }
     else if(e.target.value==='Pouches')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Pouches'})
     }
     else if(e.target.value==='Placards')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Placards'})
     }
     else if(e.target.value==='Badges')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Badges'})
     }
     else if(e.target.value==='Pizza Box')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Pizza Box'})
     }
     else if(e.target.value==='Cummerbund')
     {
        setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:'Cummerbund'})
     }

    }
    
const handleClose=()=>{
      setErrorDialog(false)
      setErrMsge('')
      SetGetClick(false)
      setDis(false)
    }

const handleSkuSubmit=async(e)=>{
  
  try{
      e.preventDefault()
       setLoading(true)
        setDis(true)
        setTotalData([{}])
        
        if(partSearch===true)
        {
          if(partNumber!=='')
          {
          await AxiosAPIInstance.get('/DHSPricing/PartNo',{params:{Part_No:partNumber}}).then(async(response)=>{
          setDis(false)
          setLoading(false)
          SetGetClick(true)
          setTotalData([{...response?.data}])
           }).catch(async(err)=>{
            setLoading(false)
            SetGetClick(false)
            setErrorDialog(true)
            setErrMsge(err?.response?.data)
            setDis(false)
           })
          }
          else
          {
          setLoading(false)
          SetGetClick(false)
          setErrorDialog(true)
          setErrMsge("Part Number is Required")
          setDis(false)

          }
        }
        else if(individualSearch===true)
        {
  
            await AxiosAPIInstance.get('/DHSPricing/Individual',{params:{sku:skuSearchParamsIndividual.sku}}).then(
              async(response)=>{
                setLoading(false)
                SetGetClick(true)
                setTotalData(response?.data)
                setDis(false)
              }
            ).catch(async(err)=>{
              setLoading(false)
              SetGetClick(false)
              setErrorDialog(true)
              setErrMsge(err?.response?.data)
              setDis(false)
            })
    
        }
        else if(packageSearch===true)
        {
  
            await AxiosAPIInstance.get('/DHSPricing/Packages',{params:{sku:skuSearchParamsPackages.sku}}).then(
              async(response)=>{
                setLoading(false)
                SetGetClick(true)
                setTotalData(response?.data)
                setDis(false)
              }
            ).catch(async(err)=>{
              setLoading(false)
              SetGetClick(false)
              setErrorDialog(true)
              setErrMsge(err?.response?.data)
              setDis(false)
            })
    
        }
        else
        {
          await AxiosAPIInstance.get('/DHSPricing/All').then(
            async(response)=>{
              setLoading(false)
              SetGetClick(true)
              setTotalData(response?.data)
              setDis(false)
            }
          ).catch(async(err)=>{
            setLoading(false)
            SetGetClick(false)
            setErrorDialog(true)
            setErrMsge(err?.response?.data)
            setDis(false)
          })
        }
      }
    catch
    {
      setLoading(false)
      SetGetClick(false)
      setErrorDialog(true)
      setErrMsge("Something Went Wrong")
    }
    }
     
    return (
        <>
        <LoadingDialog open={loading} />
        {<ApplicationBar></ApplicationBar>}
        {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {errMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/SKUs">OK</Button>
        </DialogActions>
      </Dialog>)}
      
        <Stack component='form' autoComplete='off'  sx={{marginTop:"65px"}} direction='column' justifyContent='center'>
        <Stack direction='row' justifyContent='center'>
        {/* <img src={DHS} alt='Slate Image' height="80px" width="80px"></img> */}
        <Typography variant='h5' sx={{justifyContent:'center',fontWeight:'bold'}}>
        DHS-CBP(Body Armor Pricing Schedule)
        </Typography>
        </Stack>
        <Stack direction='row' spacing={5} justifyContent='center'>
          <Stack direction='column' justifyContent='center' spacing={1}>
          <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(1)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={partSearch} onChange={(e)=>{
          SetGetClick(false)
          setTotalData([{}])
          setPartSearch(e.target.checked)
          if(e.target.checked===true)
          {
           setIndividualSearch(false)
           setPackageSearch(false)
          }
          else
          {
            setPartNumber('')
          }

        }}/>} label="Part Number Search" />
         </FormGroup>
         <TextField size="small"  required disabled={!partSearch}  variant="outlined" sx={{width:"250px"}} label="Part Number" value={partNumber} onChange={(e)=>{setPartNumber(e.target.value);SetGetClick(false)}}></TextField>
          </Stack>
          <Stack direction='column' spacing={2} justifyContent='center'>
          <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(1)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={packageSearch} onChange={(e)=>{
          SetGetClick(false)
          setTotalData([{}])
          setPackageSearch(e.target.checked)
          if(e.target.checked===true)
          {
           setIndividualSearch(false)
           setPartNumber('')
           setPartSearch(false)
          }

        }}/>} label="Full Packages" />
         </FormGroup>
          <FormControl size="small" sx={{width:"250px"}} disabled={!packageSearch}>
        <Select size="small"
          labelId="Package_Type"
          id="Package_Type-id"
          value={skuSearchParamsPackages.sku}
          variant='standard'
          label="Package_Type"
          onChange={(e)=>{ 
          setSKUSearchParamsPackages({...skuSearchParamsPackages,sku:e.target.value});
          handleSKUTypeChange(e);}}

          sx={{width:"250px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"Scalable Package"}>Scalable Package</MenuItem>
          <MenuItem size="small" value={"Scalable Package(OCP)"}>Scalable Package(OCP)</MenuItem>
          <MenuItem size="small" value={"Traditional Package"}>Traditional Package</MenuItem>
          <MenuItem size="small" value={"Traditional Package(OCP)"}>Traditional Package(OCP)</MenuItem>
        </Select>
      </FormControl>
        </Stack>
{/* -------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Stack direction='column' spacing={1}  justifyContent='center'>
        <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(1)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={individualSearch} onChange={(e)=>{
         SetGetClick(false)
         setTotalData([{}])
         setIndividualSearch(e.target.checked)
          if(e.target.checked===true)
          {
            setPartSearch(false)
            setPartNumber('')
            setPackageSearch(false)
          }
          

        }}/>} label="Individual Search" />
         </FormGroup>
        <Stack direction='row' spacing={1} justifyContent='center'> 
        <FormControl size="small" sx={{width:"250px"}} disabled={!individualSearch}>
        <InputLabel id="SKU_Type">SKU Type</InputLabel>
        <Select size="small"
          labelId="SKU_Type"
          id="SKU_Type-id"
          value={skuSearchParamsIndividual.sku}
          variant='standard'
          label="SKU Type"
          onChange={(e)=>{ 
          setSKUSearchParamsIndividual({...skuSearchParamsIndividual,sku:e.target.value});
          handleSKUTypeChange(e);}}

          sx={{width:"250px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"Panels"}>Panels</MenuItem>
          <MenuItem size="small" value={"Carriers"}>Carriers</MenuItem>
          <MenuItem size="small" value={"Kits"}> Kits</MenuItem>
          <MenuItem size="small" value={"Panel Covers"}> Panel Covers</MenuItem>
          <MenuItem size="small" value={"Platforms"}> Platforms</MenuItem>
          <MenuItem size="small" value={"Pouches"}> Pouches</MenuItem>
          <MenuItem size="small" value={"Placards"}> Placards</MenuItem>
          <MenuItem size="small" value={"Badges"}> Badges</MenuItem>
          <MenuItem size="small" value={"Pizza Box"}> Pizza Box</MenuItem>
          <MenuItem size="small" value={"Cummerbund"}> Cummerbund</MenuItem>
          <MenuItem size="small" value={"Pads"}> Pads</MenuItem>
        </Select>
      </FormControl>
            
      </Stack>
      </Stack>
      </Stack>
      <Stack justifyContent='center' direction='row'><Button sx={{width:'250px',marginTop:"10px",marginBottom:"10px"}} variant="contained" color='success' disabled={dis} onClick={(e)=>{handleSkuSubmit(e)}} >Get</Button></Stack> 
      <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:"20px"}} orientation='horizontal'></Divider>
    </Stack>
    {GetClick ===true && 
        (<TableContainer >
        <Table margin="0" aria-label="POs_DateRange">
          <TableHead sx={{backgroundColor:theme.palette.primary.main}}>
            <TableRow>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',padding:1}} align="center">Part No</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',padding:1}} align="center">SKU Name</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',padding:1}} align="center">Item Description</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',backgroundColor:orange[300],color:'black',padding:1}} align="center" colSpan={7}>Unit Price
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 1</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 2</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 3</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 4</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 5</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 6</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)',width:'12%',color:'black',padding:1}}  align="center">Year 7</TableCell>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <SKUsRow key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
        </>
    );
}

export default SKUsDHSRetrieve