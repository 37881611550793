import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useState} from "react";
import {Button, Stack,TextField,InputAdornment,MenuItem, CircularProgress,Divider} from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey,red,orange } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate} from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar"
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SalesRow from "./DHSTableRowHelper";
import AxiosFunction from "../../axiosCustomInstance"
import LoadingDialog from "../Loading";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function DHSReqsSearch() {

// State variable to track whether the search button is clicked or not
const [GetClick, SetGetClick] = useState(false);

// State variable to store total data fetched from the API
const [totalData, setTotalData] = useState([{}]);

// State variable to store DHS (Department of Health and Social Care) number
const [getDHSNum, setDHSNum] = useState("");

// State variable to manage error state and message related to order type
const [orderTypeError, setOrderTypeError] = useState(false);
const [orderTypeErrorMsge, setOrderTypeErrorMsge] = useState("");

// State variable to track the checked status of DHS number checkbox
const [getDHSNumChecked, setGetRMANumChecked] = useState(false);

// State variable to store the field checked for search
const [fieldchecked, setFieldChecked] = useState("");

// State variable to manage the checked status of the status field
const [statusFieldChecked, setStatusFieldChecked] = useState(false);

// State variable to store the order type selected in the dropdown
const [ordertype, setOrderType] = useState("Traditional System Order");

// State variable to manage the disabled state of certain elements
const [dis, setDis] = useState(false);

// State variable to manage error state and message related to DHS number
const [dHSNumError, setDHSNumError] = useState(false);
const [dHSNumErrorMsge, setDHSNumErrorMsge] = useState("");

// State variable to manage error state and message related to search filters
const [searchFilterError, setSearchFilterError] = useState(false);
const [searchFilterErrorMsge, setSearchFilterErrorMsge] = useState("");

// Navigation hook from react-router-dom
const navigate = useNavigate();

// State variables for pagination
const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(5);

// Axios instance for making API requests
const AxiosAPIInstance = AxiosFunction();

// State variable to manage loading state
const [loading, setLoading] = useState(false);


//-----------------------------------------------------------------------------------------------------------------

// Avoid a layout jump when reaching the last page with empty rows.
const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

// Function to handle closing of dialog and resetting error states and messages
const handleClose = (e) => {
    // Resetting error states and messages related to DHS number, order type, and search filters
    setDHSNumError(false);
    setDHSNumErrorMsge("");
    setDis(false);
    setOrderTypeError(false);
    setOrderTypeErrorMsge("");
    setSearchFilterError(false);
    setSearchFilterErrorMsge("");
};

// useEffect hook to reset total data and search button click state when the order type changes
useEffect(() => {
    // Resetting total data and search button click state
    setTotalData([{}]);
    SetGetClick(false);
}, [ordertype]);


// Function to handle changes in the DHS number checkbox
const handleDHSNumChange = (e) => {
    // Update state based on checkbox status
    setGetRMANumChecked(e.target.checked);
    // Reset search button click state
    SetGetClick(false);
    if (e.target.checked === true) {
        // If checkbox is checked, set the search field to "DHSID" and reset other fields
        setFieldChecked("DHSID");
        setStatusFieldChecked(false);
        setOrderType("");
    } else {
        // If checkbox is unchecked, reset search field and DHS number input value
        setFieldChecked("");
        setDHSNum("");
    }
}

// Function to handle changes in the status checkbox
const handleStatusChange = (e) => {
    // Update state based on checkbox status
    setStatusFieldChecked(e.target.checked);
    // Reset search button click state
    SetGetClick(false);
    if (e.target.checked === true) {
        // If checkbox is checked, set the search field to "Status" and reset other fields
        setFieldChecked("Status");
        setGetRMANumChecked(false);
        setDHSNum("");
    } else {
        // If checkbox is unchecked, reset search field, order type, and DHS number input value
        setFieldChecked("");
        setOrderType("");
        setDHSNum("");
    }
}

const Get_Data_Click = async (e) => {
  e.preventDefault();
  setDis(true)
  setLoading(true)
  if (fieldchecked !== '') {
      // Checking if the DHS number checkbox is checked
      if (getDHSNumChecked === true) {
          // Checking if the DHS number input is not empty
          if (getDHSNum !== '') {
              // Making a GET request to fetch data based on DHS number
              await AxiosAPIInstance.get("/DHSCustomer/DHSReqsSearch/getbyDHSReqID", {
                      params: {
                          REQ_ID: getDHSNum
                      }
                  })
                  .then((response) => {
                      // Handling the response when data is successfully fetched
                      setLoading(false);
                      // Checking if data is returned from the API
                      if (response?.data.length > 0) {
                          // Setting total data with the fetched data
                          setTotalData(response?.data);
                          // Enabling search button
                          SetGetClick(true);
                          // Resetting error states related to DHS number
                          setDis(false);
                      } else {
                          // Handling when no records are found
                          SetGetClick(false);
                          // Setting error state and message for DHS number
                          setDHSNumError(true);
                          setDHSNumErrorMsge("No Records Found");
                      }
                  })
                  .catch((err) => {
                      // Handling errors from the API
                      setLoading(false);
                      // Setting error state and message for DHS number
                      setDHSNumError(true);
                      setDHSNumErrorMsge(err.response?.data);
                      // Resetting total data and search button click state
                      setTotalData([{}]);
                      setDis(false);
                  });
          } else {
              // Handling when DHS number input is empty
              setLoading(false);
              // Setting error state and message for DHS number
              setDHSNumError(true);
              setDHSNumErrorMsge("DHS Number is Required");
              // Resetting total data and search button click state
              setTotalData([{}]);
              setDis(false);
          }
      }
      // Checking if the status field checkbox is checked
      else if (statusFieldChecked === true) {
          // Making a GET request to fetch data based on order type
          await AxiosAPIInstance.get("/DHSCustomer/DHSReqsSearch/getbyDHSOrderType", {
                  params: {
                      Order_Type: ordertype
                  }
              })
              .then((response) => {
                  // Handling the response when data is successfully fetched
                  setLoading(false);
                  // Checking if data is returned from the API
                  if (response?.data.length > 0) {
                      // Setting total data with the fetched data
                      setTotalData(response?.data);
                      // Enabling search button
                      SetGetClick(true);
                      // Resetting error states related to order type
                      setDis(false);
                  } else {
                      // Handling when no records are found
                      SetGetClick(false);
                      // Setting error state and message for order type
                      setOrderTypeError(true);
                      setOrderTypeErrorMsge("No Records Found");
                  }
              })
              .catch((err) => {
                  // Handling errors from the API
                  setLoading(false);
                  SetGetClick(false);
                  // Setting error state and message for order type
                  setOrderTypeError(true);
                  setOrderTypeErrorMsge(err.response?.data);
                  // Resetting total data and search button click state
                  setTotalData([{}]);
                  setDis(false);
              });
      }

      setDis(false)
      setLoading(false)
  }
  // If neither DHS number checkbox nor status checkbox is checked
  else {
      // Making a GET request to fetch all DHS records
      await AxiosAPIInstance.get("/DHSCustomer/DHSReqsSearch/getALLDHSs")
          .then((response) => {
              // Handling the response when data is successfully fetched
              setLoading(false);
              // Checking if data is returned from the API
              if (response?.data.length > 0) {
                  // Setting total data with the fetched data
                  setTotalData(response?.data);
                  // Enabling search button
                  SetGetClick(true);
                  // Resetting error states related to order type
                  setDis(false);
              } else {
                  // Handling when no records are found
                  SetGetClick(false);
                  // Setting error state and message for order type
                  setOrderTypeError(true);
                  setOrderTypeErrorMsge("No Records Found");
              }
          })
          .catch((err) => {
              // Handling errors from the API
              setLoading(false);
              SetGetClick(false);
              // Setting error state and message for order type
              setOrderTypeError(true);
              setOrderTypeErrorMsge(err.response?.data);
              // Resetting total data and search button click state
              setTotalData([{}]);
              setDis(false);
          });
      // Resetting loading and disabling states
      setDis(false);
      setLoading(false);
  }
}
  return (
    <>
    {<ApplicationBar></ApplicationBar>}
    <LoadingDialog open={loading} />
    {dHSNumError===true&&(<Dialog
      open={dHSNumError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert  variant='filled' severity={dHSNumErrorMsge==="No Records Found"?"warning":"error"}>{dHSNumErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {dHSNumErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {orderTypeError===true&&(<Dialog
      open={orderTypeError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert  variant='filled' severity={dHSNumErrorMsge==="No Records Found"?"warning":"error"}>{orderTypeErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {orderTypeErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}

    {searchFilterError===true&&(<Dialog
      open={searchFilterError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' severity="error">ERROR</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
      {searchFilterErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    <Stack direction='column' spacing={0} >
      <Stack direction='row' spacing={0} justifyContent="center" sx={{marginTop:"70px"}}>
      <Typography  sx={{fontSize:"25px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)'}}>DHS-CBP Customer Requests</Typography>
      </Stack>
        <Stack sx={{marginTop:'10px'}} direction='row' justifyContent="center" >
        <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="DHSNumSearch"  checked={getDHSNumChecked} onChange={(e)=>{handleDHSNumChange(e)}}/>} label="DHS ID" />
       </FormGroup>
        <TextField required  size="small" disabled={!getDHSNumChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
         
            </InputAdornment>
          ),}} value={getDHSNum} onChange={(e)=>{setDHSNum(e.target.value);SetGetClick(false)}}></TextField>
          <Stack sx={{width:"100px"}}></Stack>
          <FormGroup>
      <FormControlLabel sx={{color:'red'}} size="small" control={<Checkbox size="small" name="Order Type"  checked={statusFieldChecked} onChange={(e)=>{handleStatusChange(e)}}/>} label="Order Type" />
       </FormGroup>
          <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Order_Type">Order Type</InputLabel>
        <Select size="small"
          labelId="Order_Type"
          id="Order_Type-id"
          value={ordertype}
          label="Order Type"
          onChange={(e)=>{            
            setOrderType(e.target.value)
          }}
          disabled={!statusFieldChecked}>
          <MenuItem size="small" name="SSO" value={"Scalable System Order"}>Scalable System Order</MenuItem>
          <MenuItem size="small" name="TSO" value={"Traditional System Order"}>Traditional System Order</MenuItem>
        </Select>
      </FormControl>
          </Stack>
        <Stack direction="row" justifyContent="center" sx={{marginTop:"10px",marginBottom:"10px"}}>
          <Button sx={{width:'200px'}} variant="contained" color='success' disabled={dis} onClick={(e)=>Get_Data_Click(e)}>Get</Button>
          </Stack>
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          <br></br>
        <Stack></Stack>
        {GetClick ===true &&(getDHSNumChecked===true||statusFieldChecked===true||totalData.length>0)&& 
        (<TableContainer >
        <Table margin="1" aria-label="POs_DateRange">
          <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
            <TableRow>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">DHS Order Details</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Customer Details</TableCell>
              {/* <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Order Type</TableCell> */}
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Size Info</TableCell>
              {/* <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Status & Actions</TableCell> */}
            </TableRow>

          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <SalesRow key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
      </Stack>
      </>
    );
}
  export default DHSReqsSearch