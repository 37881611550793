import * as React from 'react';
import { useState } from "react";
import { Box, Button, TextField, Paper, Stack, Typography, Link, Divider, Chip, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { blueGrey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance';
import { selectCurrentToken } from './authSlice';
import { useSelector } from 'react-redux';
import LoadingDialog from '../Loading';
import { Twitter, Facebook, Instagram, LinkedIn, Fullscreen } from '@mui/icons-material';
import Logo from "../Images/logo.png";
import Login_Im from "../Images/Aspetto_Login.png";
import Login_Bck from "../Images/Login.png";
import { bgcolor, color, styled } from '@mui/system';
import { deepOrange, red, blue, cyan } from '@mui/material/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const Footer = styled('footer')(({ theme }) => ({
  marginTop: 'auto',
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  fontSize: '12px',
  color: 'gray',
  borderTop: '0px solid #ccc',
  borderRadius: "5px"
}));

const SocialIcons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function Login_Form() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState({ password: "", showPassword: false });
  const [err, setErr] = useState("");
  const [errMsge, setErrMsge] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialogDecision, setSuccessDialogDecision] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const [dis, setDis] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setErrorDialog(false);
    setErrMsge("");
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setPass({ ...pass, showPassword: !pass.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    setDis(true);
    setLoading(true);
    e.preventDefault();
    const UserPassword = pass.password;
    const UserName = user;

    await AxiosGlobalAPIInstance.post('/UserAuth', { UserName, UserPassword }).then(async (response) => {
      const { accessToken } = response?.data;
      dispatch(setCredentials(accessToken));
      navigate('/Dashboard', { replace: false });
      setDis(false);
      setLoading(false);
    }).catch((error) => {
      if (error?.response.status === 404) {
        setLoading(false);
        setErrorDialog(true);
        setErrMsge("Page Not Found");
      } else if (error?.response.status === 401) {
        setLoading(false);
        setErr(error?.response.data);
        setErrorDialog(false);
        setErrMsge("");
      } else {
        setLoading(false);
        setErr("Bad Request");
        setErrorDialog(false);
        setErrMsge("");
      }
      setLoading(false);
      setDis(false);
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Footer>
          <LoadingDialog open={loading} />
          {errorDialog === true && (
            <Dialog
              open={errorDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle sx={{ width: "250px" }} id="alert-dialog-title">
                <Alert variant='filled' severity='error'>error</Alert>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {errMsge}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button name="OK" onClick={(e) => { handleClose(e) }}>OK</Button>
              </DialogActions>
            </Dialog>
          )}
          <Stack sx={{
            backgroundImage: `url(${Login_Bck})`,
            backgroundColor:blueGrey[280],
            backgroundSize: 'cover',
            // backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: "81vh",
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Paper component="form" onSubmit={(e) => handleSubmit(e)} autoComplete='off' elevation={2} sx={{
              borderRadius: "15px",
              ":hover": {
                boxShadow: "10px 10px 20px #ccc"
              },
            }}>
        
        <Stack direction='row' justifyContent="center" spacing={4} sx={{padding:2,border:1,borderTopLeftRadius:15,borderTopRightRadius:15}}>
          <Stack></Stack>
                <Stack direction="column" justifyContent="center" spacing={1}>
                  <Box component="img" src={Login_Im} sx={{ height: "75px", width: "220px", margin:'auto' }} >
                  </Box>
                  <Chip label="EMPLOYEE LOGIN" sx={{  fontSize: "18px",  fontWeight: 'bold', color: blueGrey[700], fontFamily: 'Verdana (sans-serif)', }}></Chip>
                </Stack>
                <Stack direction='column' spacing={2.5} sx={{ border: "0.5px", color: blueGrey[900] }} justifyContent="center">
                  <Stack sx={{ fontSize: "12px",}} justifyContent="center">{err && <Alert severity='error'>{err}</Alert>}</Stack>
                  <p></p>
                  <TextField type="text"
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value)
                      setErr('')
                    }}
                    variant='outlined'
                    size="small"
                    required
                    id="UserName"
                    label="UserName"
                    InputProps={{
                      startAdornment: (<InputAdornment position='start'>
                        <AccountCircleIcon></AccountCircleIcon>
                      </InputAdornment>),
                    }}>
                  </TextField>
                  <TextField
                    type={pass.showPassword ? "text" : "password"}
                    value={pass.password}
                    onChange={(e) => {
                      setPass({ ...pass, password: e.target.value })
                      setErr('')
                    }}
                    variant='outlined'
                    size="small"
                    required
                    id="Password"
                    label="Password"
                    InputProps={{
                      endAdornment: (<InputAdornment position='end'>
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{pass.showPassword ? <VisibilityIcon></VisibilityIcon> : <VisibilityOffIcon></VisibilityOffIcon>}</IconButton>
                      </InputAdornment>),
                    }}>
                  </TextField>
                  <Button variant='contained' type="submit" color="warning" disabled={dis} size='small'>Login</Button>
                  <Stack direction='column' spacing={.5} justifyContent="center">
                    <Stack direction='row' spacing={0} justifyContent="center">
                      <Typography type="body" sx={{ padding: "0px", fontSize: "12px", fontFamily: 'Verdana (sans-serif)' }}>Need an Account?</Typography>
                      <Link href="/UserRegistartion" underline="always" sx={{ paddingLeft: "5px", fontSize: "12px", paddingTop: '2px', color: "green" }}><b>Sign Up</b></Link>
                    </Stack>
                    <Stack direction='row' spacing={0} justifyContent="center">
                      <Typography type="body" sx={{ padding: "0px", fontSize: "12px", fontFamily: 'Verdana (sans-serif)', }}>Forgot Password?</Typography>
                      <Link href="pass-reset" underline="always" sx={{ paddingLeft: "5px", fontSize: "12px", paddingTop: '2px', color: blue[700] }}><b>Reset</b></Link>
                    </Stack>
                    <Stack direction='row' spacing={0} justifyContent="center">
                      <Typography type="body" sx={{ padding: "0px", fontSize: "12px", fontFamily: 'Verdana (sans-serif)', }}>Forgot UserName?</Typography>
                      <Link href="RetrieveUserNameEmail" underline="always" sx={{ paddingLeft: "5px", fontSize: "12px", paddingTop: '2px', color: blue[700] }}><b>Retrieve</b></Link>
                    </Stack>
                    <Stack direction='row' spacing={0} justifyContent="center">
                      <Typography type="body" sx={{ padding: "0px", fontSize: "12px", fontFamily: 'Verdana (sans-serif)' }}>DHS-CBP Customers?</Typography>
                      <Link href="DHS-CBP" underline="always" sx={{ paddingLeft: "5px", fontSize: "12px", paddingTop: '2px', color: 'red' }}><b>Click Here</b></Link>
              
                    </Stack>
                  </Stack>
                </Stack>
                <Stack></Stack>
              </Stack>
              <Typography variant='body2' sx={{ backgroundColor: blueGrey[900], color: 'whitesmoke', borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", padding: "10px" }}>
                This Webpage is designed exclusively for Aspetto Inc. Employees.</Typography>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={4} sx={{ bgcolor: blueGrey[900], color: "white", padding: 1, fontSize: "11px", height: "100px" }}>
            <Stack direction="column" spacing={0.1} sx={{ margin:'50px', }}>
              <IconButton size='large' edge='start' color='inherit' >
                <img src={Logo} alt="ASPETTO" height="25px" width="100px"></img>
              </IconButton>
              <p>&copy; 2023 Aspetto Inc. All rights reserved.</p>
              <p>Inc. 5000 America's Fastest Growing Private Companies</p>
              <p>ISO certified ISO 9001:2015</p>
            </Stack>
            <Stack></Stack>
            <Stack direction="column" spacing={0.1} sx={{ marginLeft: "20px",padding:'20px'  }} justifyContent="right">

              <p>Let's talk:</p>
              <p>Phone: 540-547-8487</p>
              <p>Fax: 540-300-2669</p>
              <Link sx={{ color: blue[500] }} underline='true' href="https://www.aspetto.com">https://www.aspetto.com</Link>
            </Stack>
            <Stack sx={{ flexGrow: 1 }}></Stack>
            <Stack direction="column" spacing={0.1} sx={{ alignItems: 'center',margin:'auto' }}>
              <p></p>
              <p>SOCIAL MEDIA</p>
              <Stack direction="row" spacing={.5} justifyContent="center" >
                <IconButton color="white" href="https://twitter.com/i/flow/login?redirect_after_login=%2Faspettoinc" target="_blank" rel="noopener noreferrer">
                  <Twitter color="white" sx={{ backgroundColor: "white", color: cyan[400] }} />
                </IconButton>
                <IconButton color="white" href="https://www.facebook.com/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <Facebook color="white" sx={{ backgroundColor: "white", color: blue[800] }} />
                </IconButton>
                <IconButton color="white" href="https://www.instagram.com/aspetto/" target="_blank" rel="noopener noreferrer">
                  <Instagram color="white" sx={{ backgroundColor: "white", color: red[800] }} />
                </IconButton>
                <IconButton color="primary" href="https://www.linkedin.com/company/aspettoinc" target="_blank" rel="noopener noreferrer">
                  <LinkedIn sx={{ backgroundColor: "white", color: blueGrey[600] }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Footer>
      </ThemeProvider>
    </>
  )
}

export default Login_Form;
