import React, { useState, useEffect } from "react";
import { Stack, TextField, Button, MenuItem, FormControl, InputLabel, Select, Typography, Box, IconButton, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination, TableSortLabel, Alert, InputAdornment } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { blueGrey, yellow, orange } from '@mui/material/colors';
import { createTheme, useTheme } from '@mui/material/styles';
import { SaveAlt, FirstPage as FirstPageIcon, LastPage as LastPageIcon, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import ApplicationBar from "../AppBar/ApplicationBar";
import { useNavigate } from "react-router-dom";
import MasterTableHelper from '../LifeCycle/MasterTableHelper';
import AxiosFunction from "../../axiosCustomInstance";
import LoadingDialog from '../Loading';
import excel from '../Images/excel.png';
import UserAuth from "../ProtectedRoute/userAuth";
import useSorting from "../HelperFunctions/sortingUtils";
import PropTypes from 'prop-types';

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function GSA_Life_Cycle() {
  const { UserEmail } = UserAuth(); // User authentication hook to get user's email
  const [GetClick, SetGetClick] = useState(false); // State to track if the Get Data button is clicked
  const [totalData, setTotalData] = useState([]); // State to store the fetched data
  const [DateValue, SetDateValue] = useState({ From_Date: new Date(new Date().getFullYear(), 0, 1), To_Date: new Date() });
  const [open1, setOpen1] = useState(false); // State to control the visibility of a modal
  const [stus, setStus] = useState("ALL"); // State to store the status filter
  const [dis, setDis] = useState(false); // State to control the disabled state of some elements
  const [errors, setErrors] = useState({}); // State to store errors
  const [formData, setFormData] = useState({
    RFQID: "", // Input value for RFQID filter
    CustomerName: "", // Input value for Customer Name filter
    SalesHeadEmail: "", // Input value for Sales Head Email filter
    InvoiceNo: "", // Input value for Invoice Number filter
  });
  const { orderBy, order, handleRequestSort, stableSort, getComparator } = useSorting(); // Custom hook for sorting data
  const navigate = useNavigate(); // Hook to handle navigation
  const [page, setPage] = useState(0); // State to store current page in pagination
  const [rowsPerPage, setRowsPerPage] = useState(5); // State to store number of rows per page in pagination
  const [usersData, setUsersData] = useState([{ User_email: "" }]); // State to store users data fetched from API
  const AxiosAPIInstance = AxiosFunction(); // Axios instance for API calls
  const [loading, setLoading] = useState(false); // State to track loading status
  const today = new Date().toLocaleDateString(); // Get today's date in locale string format

  // Calculate the number of empty rows for pagination
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

  // Function to handle page change in pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change in pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Effect to fetch users data on component mount
  useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true); // Set loading to true before API call
      setTotalData([]); // Reset total data
      try {
        const result = await AxiosAPIInstance.get("/UserAuth/users"); // Fetch users data from API
        setLoading(false); // Set loading to false after API call
        setUsersData(result?.data); // Set fetched users data
      } catch (error) {
        setLoading(false); // Set loading to false if API call fails
        setErrors(prev => ({ ...prev, SalesHead: error?.response.data })); // Set error state
      }
    };
    fetchUsersData(); // Call the function to fetch users data
  }, []);

  // Effect to fetch initial data based on logged-in user's email
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true); // Set loading to true before API call
      try {
        setFormData({ ...formData, SalesHeadEmail: String(UserEmail).toLowerCase().trim() }); // Set SalesHeadEmail in form data
        const response = await AxiosAPIInstance.get("/GSALifeCycle/search", {
          params: { Sales_Head_Email: String(UserEmail).toLowerCase().trim(), Status: stus }
        }); // Fetch initial data from API based on logged-in user's email and status
        setLoading(false); // Set loading to false after API call
        if (response?.data.length > 0) {
          SetGetClick(true); // Set GetClick to true if data is found
          setTotalData(response?.data); // Set fetched data to totalData state
          setDis(false); // Set disabled state to false
        } else {
          SetGetClick(false); // Set GetClick to false if no data is found
          setErrors(prev => ({ ...prev, SalesHead: "No Records Found" })); // Set error state
        }
      } catch (err) {
        setLoading(false); // Set loading to false if API call fails
        SetGetClick(false); // Set GetClick to false
        setErrors(prev => ({ ...prev, SalesHead: err.response?.data })); // Set error state
        setDis(false); // Set disabled state to false
      }
    };
    fetchInitialData(); // Call the function to fetch initial data
  }, []);

  // Function to handle closing of a modal or dialog
  const handleClose = () => {
    setOpen1(false); // Close the modal
    setErrors({}); // Reset errors
    setTotalData([]); // Reset total data
  };

  // Function to generate Excel file from the data
  const handleGenerateExcel = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading to true before generating Excel
    try {
      if (totalData?.length > 0) {
        const formattedData = totalData?.map((row) => ({
          Date: String(row.Created_Date).split('T')[0],
          Customer: row.Customer_Name,
          Price: row.Customer_Cost,
          Cost: row.Aspetto_Cost,
          ProfitMargin: row.Profit_Margin,
          Percent: row.Percent,
          ContractVehicle: row.Contract_Vehicle,
          Division: row.Division,
          Status: row.Status,
          PurchaseOrder: row.Purchase_Order,
          InvoiceNumber: row.Invoice_Number,
          PaymentDate: String(row.Payment_Date).split('T')[0],
          InvoiceDate: String(row.Invoice_Date).split('T')[0],
          TeamMember: row.Sales_Head_Email,
          ExpectedDelivery: String(row.Expected_Delivery).split('T')[0],
          ActualDelivery: String(row.Actual_Delivery).split('T')[0],
          LastUpdatedDate: String(row.Last_Updated_Date).split('T')[0],
          UpdatedBy: row.Updated_By,
          History: row.History,
        })); // Format data for Excel file

        const headers = [
          "Date", "Customer", "Price($)", "Cost($)", "Profit Margin($)", "Percent(%)",
          "Contract Vehicle", "Division", "Status", "Purchase Order", "Invoice Number",
          "Payment Date", "Invoice Date", "Team Member", "Expected Delivery", "Actual Delivery",
          "Last Updated Date", "Updated By", "History",
        ]; // Define headers for Excel file

        const wb = XLSX.utils.book_new(); // Create a new workbook
        const ws = XLSX.utils.json_to_sheet(formattedData); // Convert formatted data to worksheet
        headers.forEach((header, index) => {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
          ws[cellAddress].s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } }
          };
        }); // Style headers
        XLSX.utils.book_append_sheet(wb, ws, 'GSA'); // Append worksheet to workbook
        const excelFileName = `GSA-${today}-STATUS-${stus}.xlsx`; // Define Excel file name
        XLSX.writeFile(wb, excelFileName); // Write workbook to file

        setLoading(false); // Set loading to false after generating Excel
      } else {
        setLoading(false); // Set loading to false if no data
        setErrors(prev => ({ ...prev, Date: "No Records Found" })); // Set error state
      }
    } catch (err) {
      setLoading(false); // Set loading to false if error
      setErrors(prev => ({ ...prev, General: "Something went wrong" })); // Set error state
    }
  };

  // Function to handle input change and update form data
  const handleInputChange = (field) => (e) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value })); // Update form data
    SetGetClick(false); // Reset GetClick state
  };

  // Function to fetch data based on the form inputs
  const Get_Data_Click = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading to true before fetching data
    setDis(true); // Set disabled state to true
    setTotalData([]); // Reset total data

    try {
      const params = {
        Status: stus,
        ...formData.RFQID && { RFQ_ID: formData.RFQID },
        ...formData.CustomerName && { Customer_Name: formData.CustomerName },
        ...formData.SalesHeadEmail && { Sales_Head_Email: formData.SalesHeadEmail },
        ...formData.InvoiceNo && { Invoice_No: formData.InvoiceNo },
        From: DateValue.From_Date,
        To: DateValue.To_Date,
      }; // Define query parameters based on form data

      const response = await AxiosAPIInstance.get("/GSALifeCycle/search", { params });
      setLoading(false); // Set loading to false after fetching data

      if (response?.data.length > 0) {
        setTotalData(response?.data); // Set fetched data to totalData state
        setDis(false); // Set disabled state to false
        SetGetClick(true); // Set GetClick state to true
      } else {
        setDis(true); // Set disabled state to true
        SetGetClick(false); // Set GetClick state to false
        setErrors(prev => ({ ...prev, Date: "No Records Found" })); // Set error state
      }
    } catch (error) {
      setLoading(false); // Set loading to false if error
      SetGetClick(false); // Set GetClick state to false
      setErrors(prev => ({ ...prev, General: error.response?.data  })); // Set error state
      setDis(false); // Set disabled state to false
    }
  };

  // Function to refresh row data based on the form inputs
  const refreshRowData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const params = {
        Status: stus,
        ...formData.RFQID && { RFQ_ID: formData.RFQID },
        ...formData.CustomerName && { Customer_Name: formData.CustomerName },
        ...formData.SalesHeadEmail && { Sales_Head_Email: formData.SalesHeadEmail },
        ...formData.InvoiceNo && { Invoice_No: formData.InvoiceNo },
        From: DateValue.From_Date,
        To: DateValue.To_Date,
      }; // Define query parameters based on form data

      const response = await AxiosAPIInstance.get("/GSALifeCycle/search", { params });
      setLoading(false); // Set loading to false after fetching data
      if (response?.data.length > 0) {
        setTotalData(response?.data); // Set fetched data to totalData state
        setDis(false); // Set disabled state to false
        SetGetClick(true); // Set GetClick state to true
      } else {
        SetGetClick(false); // Set GetClick state to false
        setErrors(prev => ({ ...prev, Date: "No Records Found" })); // Set error state
      }
    } catch (err) {
      setLoading(false); // Set loading to false if error
      SetGetClick(false); // Set GetClick state to false
      setErrors(prev => ({ ...prev, General: "Something went wrong" })); // Set error state
      setDis(false); // Set disabled state to false
    }
  };

  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {open1 && (
        <Dialog open={open1} onClose={handleClose}>
          <DialogTitle>Are you sure you want to exit this page?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              All the changes will be lost if you decide to exit this page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => { e.preventDefault(); handleClose(); navigate("/Dashboard") }}>Yes</Button>
            <Button onClick={(e) => { e.preventDefault(); handleClose() }} autoFocus>No</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(errors).map((errorKey) => (
        <Dialog key={errorKey} open={!!errors[errorKey]} onClose={handleClose}>
          <DialogTitle>
            <Alert severity={errors[errorKey] === "No Records Found" ? "warning" : "error"}>
              {errors[errorKey] === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{errors[errorKey]}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      ))}
      <Stack direction="column" spacing={1.5} sx={{ marginTop: "70px" }} justifyContent="center">
        <Stack sx={{ marginTop: "60px" }} direction="row" justifyContent="center">
          <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>
            Sales Life Cycle (GSA)
          </Typography>
        </Stack>
        <Stack sx={{ marginTop: '10px' }} direction="row" justifyContent="center" spacing={2}>
          <Stack direction="column" spacing={0} justifyContent="center">
            <TextField
              size="small"
              label="Quote Number"
              value={formData.InvoiceNo}
              onChange={handleInputChange("InvoiceNo")}
            />
          </Stack>
          <Stack direction="column" spacing={0} justifyContent="center">
            <TextField
              size="small"
              label="Customer Name"
              value={formData.CustomerName}
              onChange={handleInputChange("CustomerName")}
            />
          </Stack>
          <Stack direction="column" spacing={0} justifyContent="center">
            <FormControl sx={{minWidth:'150px'}}>
              <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
              <Select
                label="Sales Head Email ID"
                labelId="Sales-Head-id"
                value={formData.SalesHeadEmail.toLowerCase()}
                size="small"
                onChange={handleInputChange("SalesHeadEmail")}>
                <MenuItem value='all'>All</MenuItem>
                {usersData.map((row, index) => (
                  <MenuItem key={index} value={row.User_email.toLowerCase()}>
                    {row.User_email.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="column" spacing={0} justifyContent="center">
            <Stack direction="row" spacing={1}>
              <DatePicker
                size="small"
                label="From"
                maxDate={DateValue.To_Date}
                required
                value={DateValue.From_Date}
                onChange={(value) => { SetDateValue({ ...DateValue, From_Date: value }); SetGetClick(false); }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
              <DatePicker
                label="To"
                minDate={DateValue.From_Date}
                required
                value={DateValue.To_Date}
                onChange={(value) => { SetDateValue({ ...DateValue, To_Date: value }); SetGetClick(false); }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
          <FormControl size="small" sx={{ width: "170px" }}>
            <InputLabel id="Quote_Status">Status</InputLabel>
            <Select
              size="small"
              labelId="Quote_Status"
              sx={{ width: "170px" }}
              id="Quote_Status-id"
              value={stus}
              label="Quote Status"
              onChange={(e) => {
                setStus(e.target.value);
                SetGetClick(false);
                setPage(0);
                setTotalData([]);
              }}
            >
              <MenuItem value={"ALL"}>ALL</MenuItem>
              <MenuItem value={"QUOTE GENERATED"}>QUOTE GENERATED</MenuItem>
              <MenuItem value={"ORDERED"}>ORDERED</MenuItem>
              <MenuItem value={"SHIPPED"}>SHIPPED</MenuItem>
              <MenuItem value={"INVOICED"}>INVOICED</MenuItem>
              <MenuItem value={"ORDER COMPLETE"}>ORDER COMPLETE</MenuItem>
            </Select>
          </FormControl>

          <Button sx={{ width: '200px', height: '32px' }} size="small" variant="contained" color="success" disabled={dis} onClick={Get_Data_Click}>
            Get
          </Button>
          <Button
            disabled={!GetClick}
            variant="contained"
            sx={{ backgroundColor: yellow[900], fontSize: "10px", ":hover": { backgroundColor: yellow[800] }, height: '35px' }}
            startIcon={<SaveAlt />}
            onClick={handleGenerateExcel}
            size="small"
          >
            Download
            <img src={excel} alt="ASPETTO" height="35px" width="35px" />
          </Button>
        </Stack>
        <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation="horizontal" />
        <br />
        {GetClick && totalData.length > 0 && (
          <TableContainer>
            <Table aria-label="POs_DateRange">
              <TableHead sx={{ backgroundColor: theme.palette.primary.main, padding: 0 }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold', padding: 1, color: "white", backgroundColor: orange[600],
                      fontFamily: 'Verdana (sans-serif)', cursor: 'pointer', "&:hover": { backgroundColor: orange[300], color: orange[800] }, width: '20%'
                    }}
                    align="center"
                  >
                    <TableSortLabel
                      active
                      direction={orderBy === 'Created_Date' ? order : 'asc'}
                      onClick={() => handleRequestSort('Created_Date')}
                    >
                      <b>Sort by Created Date</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "white", fontFamily: 'Verdana (sans-serif)', width: '20%', padding: 1 }} align="center">KO & Customer</TableCell>
                  <TableCell sx={{ color: "white", fontFamily: 'Verdana (sans-serif)', width: '20%', padding: 1 }} align="center">Payment/Invoice Info</TableCell>
                  <TableCell sx={{ color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1, width: '5%' }} align="center">Cost</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', width: '50%', padding: 1 }} align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stableSort(totalData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : totalData
                ).map((row, index) => (
                  <MasterTableHelper key={index} row={row} refreshRowData={refreshRowData} usersData={usersData} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={totalData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
}

export default GSA_Life_Cycle;
