import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Tooltip,
  IconButton,
  useMediaQuery,
  Stack,
  InputAdornment,
  TableSortLabel,
  Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AxiosFunction from '../../axiosCustomInstance';
import LoadingDialog from '../Loading';
import UserAuth from '../ProtectedRoute/userAuth';
import ApplicationBar from '../AppBar/ApplicationBar';
import {
  orange, blueGrey, blue, yellow, green, red
} from '@mui/material/colors';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { createTheme, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin, selectUserRole } from "../Login_Registration/authSlice";
// Custom theme for consistent colors
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

// Utility function to perform stable sorting
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]); // Pair elements with their indices
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]); // Sort by comparator function
    if (order !== 0) return order;
    return a[1] - b[1]; // Maintain original order for equal elements
  });
  return stabilizedThis.map((el) => el[0]);
}

// Utility function to get the sorting comparator based on order and orderBy
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Comparator function for descending order sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Main component for Admin Page
const AdminPage = () => {
  let [usersData, setUsersData] = useState([]); // State to store fetched users data
  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user for actions
  const [selectedRole, setSelectedRole] = useState(''); // State to store the selected role for assignment
  const [deactivationReason, setDeactivationReason] = useState(''); // State to store the reason for deactivation or deletion
  const [searchEmail, setSearchEmail] = useState(''); // State to store the search email input
  const [searchRole, setSearchRole] = useState(''); // State to store the search role input
  const [searchStatus, setSearchStatus] = useState(''); // State to store the search status input
  const AxiosAPIInstance = AxiosFunction(); // Custom Axios instance for API calls
  const [loading, setLoading] = useState(false); // State to control the loading dialog visibility
  const [dialogOpen, setDialogOpen] = useState(false); // State to control the action dialog visibility
  const [actionType, setActionType] = useState(''); // State to store the type of action (assignRoles, deactivateAccount, deleteAccount)
  const [orderBy, setOrderBy] = useState('User_email'); // State to store the column by which the data is sorted
  const [order, setOrder] = useState('asc'); // State to store the sort order (asc or desc)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Media query to detect mobile screens
  const [roles, setRoles] = useState([]); // State to store roles fetched from the server
  const [currentUserRoles, setCurrentUserRoles] = useState([]); // State to store the roles currently assigned to the selected user
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);
  // Fetch roles when the component mounts
  useEffect(() => {
    fetchRoles();
  }, []);
  
  // Function to fetch roles from the server
  const fetchRoles = async () => {
    try {
      const response = await AxiosAPIInstance.get('/Admin/roles'); // Fetch roles
      setRoles(response.data); // Store roles in state
    } catch (error) {
      console.error('Error fetching roles:', error); // Log error to console
    }
  };

  // Fetch users data when the component mounts
  useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true); // Show loading dialog
      await AxiosAPIInstance.get("/UserAuth/users/admin").then((result) => {
        setLoading(false); // Hide loading dialog
        const users = [...result?.data]; // Store fetched users data
        setUsersData(users);
      }).catch((error) => {
        setLoading(false); // Hide loading dialog on error
        console.error("Error fetching users:", error); // Log error to console
      });
    }
    fetchUsersData(); // Fetch users data
  }, [dialogOpen]);

  // Function to handle action dialog opening
  const handleAction = (type) => {
    setActionType(type); // Set the action type
    setDialogOpen(true); // Open dialog for the selected action

    if (type === 'assignRoles') {
      fetchCurrentUserRoles(); // Fetch current user roles when assigning roles
    }
  };

  // Function to handle dialog closing
  const handleDialogClose = () => {
    setDialogOpen(false); // Close the dialog
  };

  // Function to fetch current roles of the selected user
  const fetchCurrentUserRoles = async () => {
    setLoading(true)
    try {
      const response = await AxiosAPIInstance.get(`/api/users/${selectedUser?.UserName}/roles`);
      setCurrentUserRoles(response.data.map(role => role.RoleName.toLowerCase())); // Store current roles
      setLoading(false)
    } catch (error) {
      console.error('Error fetching user roles:', error); // Log error to console
    }
  };

  // Function to handle sorting request
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'; // Determine the sorting order
    setOrder(isAsc ? 'desc' : 'asc'); // Toggle sort order
    setOrderBy(property); // Set the column to be sorted
  };

  // Filtered users data based on search criteria
  const filteredUsersData = usersData.filter((user) => {
    const emailMatch = user.User_email?.toLowerCase().includes(searchEmail.toLowerCase()) ?? false;
    const roleMatch = user.User_Role?.toLowerCase().includes(searchRole.toLowerCase()) ?? false;
    const statusMatch = searchStatus ? (user.IsActive ? 'active' : 'inactive').includes(searchStatus.toLowerCase()) : true;
    return emailMatch && roleMatch && statusMatch; // Return only users matching the search criteria
  });

  // Function to handle role assignment action
  const handleAssignRoles = (user) => {
    AxiosAPIInstance.post('/Admin/assignRoles', {
      UserName: user?.UserName,
      User_Role: selectedRole,
    })
      .then(response => {
        setLoading(false)
        console.log('Roles assigned successfully:', response.data); // Log success message
        setDialogOpen(false); // Close dialog on success
      })
      .catch(error => {
        setLoading(false)
        console.error('Error assigning roles:', error); // Log error to console
      });
  };

  // Function to handle account deactivation action
  const handleDeactivateAccount = (user) => {
    AxiosAPIInstance.post('/Admin/deactivateAccount', {
      UserName: user?.UserName,
      reason: deactivationReason,
    })
      .then(response => {
        console.log('Account deactivated successfully:', response.data); // Log success message
        setLoading(false)
        setDialogOpen(false); // Close dialog on success
      })
      .catch(error => {
        setLoading(false)
        console.error('Error deactivating account:', error); // Log error to console
      });
  };

  // Function to handle account deletion action
  const handleDeleteAccount = (user) => {

    AxiosAPIInstance.post('/Admin/deleteAccount', {
      UserName: user?.UserName,
      reason: deactivationReason,
    })
      .then(response => {
        setLoading(false)
        console.log('Account deleted successfully:', response.data); // Log success message
        setDialogOpen(false); // Close dialog on success
      })
      .catch(error => {
        setLoading(false)
        console.error('Error deleting account:', error); // Log error to console
      });
  };

  return (
    <>
      {/* Application Bar */}
      <ApplicationBar />

      {/* Loading dialog shown during data fetching */}
      <LoadingDialog open={loading} />

      {/* Main content stack */}
      <Stack sx={{ padding: '20px', marginTop: '50px' }} justifyContent='center' spacing={2} direction='column'>
        <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{ alignContent: "center", fontSize: "25px", fontWeight: "bold", padding: 1 }}>
            Portal Admin(Users)
          </Typography>
        </Stack>

        {/* Divider with consistent color */}
        <Stack justifyContent="center" direction="row"  >
          <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal' />
        </Stack>

        {/* Search Filters */}
        <Stack direction="row" spacing={2}>
          {/* Search by Email */}
          <TextField size='small'
            label="Search by Email"
            variant="outlined"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* Search by User Role */}
          <FormControl variant="outlined" fullWidth size='small'>
            <InputLabel>User Role</InputLabel>
            <Select
              value={searchRole}
              onChange={(e) => setSearchRole(e.target.value)}
              label="User Role"
            >
              <MenuItem value="">All Roles</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.RoleName.toLowerCase()}>
                  {role.RoleName.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Search by Status */}
          <FormControl variant="outlined" fullWidth size='small'>
            <InputLabel>Status</InputLabel>
            <Select
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Statuses</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {/* Table container for displaying user data */}
        <TableContainer component={Paper} sx={{ width: '100%', marginTop: '120px' }}>
          <Table>
            {/* Table Head with custom styles */}
            <TableHead sx={{ backgroundColor: blueGrey[800], color: 'white' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1 }} align="center">
                  <TableSortLabel
                    active={orderBy === 'User_FirstName'}
                    direction={orderBy === 'User_FirstName' ? order : 'asc'}
                    onClick={() => handleRequestSort('User_FirstName')}
                  >
                    Full Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1 }} align="center">
                  <TableSortLabel
                    active={orderBy === 'User_email'}
                    direction={orderBy === 'User_email' ? order : 'asc'}
                    onClick={() => handleRequestSort('User_email')}
                  >
                    User Email
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 'bold',
                  color: "white",
                  fontFamily: 'Verdana (sans-serif)',
                  padding: 1,
                  cursor: 'pointer',
                  backgroundColor: orange[600],
                  "&:hover": {
                    backgroundColor: orange[300],
                    color: blueGrey[800],
                  },
                }} align="center">
                  <TableSortLabel
                    active={orderBy === 'User_Role'}
                    direction={orderBy === 'User_Role' ? order : 'asc'}
                    onClick={() => handleRequestSort('User_Role')}
                  >
                    User Role
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1 }} align="center">
                  <TableSortLabel
                    active={orderBy === 'IsActive'}
                    direction={orderBy === 'IsActive' ? order : 'asc'}
                    onClick={() => handleRequestSort('IsActive')}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{
                  fontWeight: 'bold',
                  color: "white",
                  fontFamily: 'Verdana (sans-serif)',
                  cursor: 'pointer',
                  padding: 1,
                  backgroundColor: orange[600],
                  "&:hover": {
                    backgroundColor: orange[300],
                    color: blueGrey[800],
                  },
                }} align="center">
                  <TableSortLabel
                    active={orderBy === 'Created_Date'}
                    direction={orderBy === 'Created_Date' ? order : 'asc'}
                    onClick={() => handleRequestSort('Created_Date')}
                    align="center"
                  >
                    Account Created
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1 }} align="center">UserName</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 1 }} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {stableSort(filteredUsersData, getComparator(order, orderBy)).map((user) => (
                <TableRow key={user.User_email}>
                  <TableCell align="center" sx={{ padding: 1 }}>{user.User_FirstName + " " + user.User_LastName}</TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>{user.User_email}</TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>
                    {user.User_Role === 'USER-SALES' ? <Tooltip title="User Sales"><Avatar sx={{ bgcolor: green[600], width: 24, height: 24 }}>U</Avatar></Tooltip> :
                      user.User_Role === 'USER-ADMIN' ? <Tooltip title="User Admin"><Avatar sx={{ bgcolor: orange[600], width: 24, height: 24 }}>A</Avatar></Tooltip> :
                      user.User_Role === 'USER-FINANCE' ? <Tooltip title="User Finance"><Avatar sx={{ bgcolor: blue[600], width: 24, height: 24 }}>F</Avatar></Tooltip> :
                      user.User_Role === 'USER-SUPER-ADMIN' ? <Tooltip title="User Super Admin"><Avatar sx={{ bgcolor: red[600], width: 34, height: 34 }}>SA</Avatar></Tooltip> :
                        <Tooltip title="Role Not Defined"><Avatar sx={{ bgcolor: blue[600], width: 24, height: 24 }}>N</Avatar></Tooltip>}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>
                    {user.IsActive ?
                      <Tooltip title="Active"><span><CheckCircleIcon sx={{ color: green[600] }} /> Active</span></Tooltip> :
                      user.IsDeleted ?
                        <><Tooltip title="Deleted"><CancelIcon sx={{ color: red[600] }} /> Deleted</Tooltip><br></br>{user?.Reason_For_Deletion}</> :
                        <> <Tooltip title="Deactivated"><ErrorIcon sx={{ color: yellow[800] }} />Deactivated</Tooltip><br></br>{user?.InActive_Reason}</>}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>{String(user?.Created_Date).split('T')[0]}</TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>{user.UserName}</TableCell>
                  <TableCell align="center" sx={{ padding: 1 }}>
                    {/* Assign Roles Button */}
                    <Tooltip title="Assign Roles">
                      <IconButton variant="text" sx={{
                        color: 'white',
                        padding: isMobile ? '8px' : '10px',
                        borderRadius: 1,
                        transition: 'transform 0.2s ease-in-out',
                        "&:hover": {
                          backgroundColor: blue[200],
                          transform: 'scale(1.1)',
                        },
                      }} onClick={() => { 
                        setSelectedUser(user); 
                        handleAction('assignRoles'); }}>
                        <Avatar sx={{
                          bgcolor: theme.palette.primary.main,
                          width: "25px",
                          height: "25px",
                          transition: 'transform 0.2s ease-in-out',
                          "&:hover": {
                            backgroundColor: blueGrey[600],
                            transform: 'scale(1.1)',
                          },
                        }} variant='rounded'>
                          <AssignmentIcon sx={{ color: blue[700], backgroundColor: "white", width: "25px", height: "25px" }} />
                        </Avatar>
                      </IconButton>
                    </Tooltip>

                    {/* Deactivate Account Button */}
                    <Tooltip title="Deactivate Account">
                      <IconButton variant="text" sx={{
                        color: 'white',
                        padding: isMobile ? '8px' : '10px',
                        borderRadius: 1,
                        transition: 'transform 0.2s ease-in-out',
                        "&:hover": {
                          backgroundColor: yellow[100],
                          transform: 'scale(1.1)',
                        },
                      }} onClick={() => { setSelectedUser(user); handleAction('deactivateAccount'); }}>
                        <Avatar sx={{
                          bgcolor: yellow[700],
                          width: "25px",
                          height: "25px",
                          transition: 'transform 0.2s ease-in-out',
                          "&:hover": {
                            backgroundColor: yellow[700],
                            transform: 'scale(1.1)',
                          },
                        }} variant='rounded'>
                          <CloseIcon sx={{ backgroundColor: yellow[700], color: "white", width: "25px", height: "25px" }} />
                        </Avatar>
                      </IconButton>
                    </Tooltip>

                    {/* Delete Account Button */}
                    {userRole==='USER-SUPER-ADMIN'&&(<Tooltip title="Delete Account">
                      <IconButton variant="text" sx={{
                        color: 'white',
                        padding: isMobile ? '8px' : '10px',
                        borderRadius: 1,
                        transition: 'transform 0.2s ease-in-out',
                        "&:hover": {
                          backgroundColor: red[200],
                          transform: 'scale(1.1)',
                        },
                      }} onClick={() => { setSelectedUser(user); handleAction('deleteAccount'); }}>
                        <Avatar sx={{
                          bgcolor: theme.palette.primary.main,
                          width: "25px",
                          height: "25px",
                          transition: 'transform 0.2s ease-in-out',
                          "&:hover": {
                            backgroundColor: blueGrey[600],
                            transform: 'scale(1.1)',
                          },
                        }} variant='rounded'>
                          <DeleteForeverIcon sx={{ color: red[700], backgroundColor: "white", width: "25px", height: "25px" }} />
                        </Avatar>
                      </IconButton>
                    </Tooltip>)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* Action Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle sx={{fontSize:'15px',fontWeight:'bold', bgcolor: actionType === 'assignRoles' ? blue[700] : actionType === 'deactivateAccount' ? yellow[700] : red[700] }}>
          {actionType === 'assignRoles' ? 'Assign Roles' : actionType === 'deactivateAccount' ? 'Deactivate Account' : 'Delete Account'}
        </DialogTitle>
        <DialogContent>
          {actionType === 'assignRoles' && (
            <>
              <br />
              <br />
              <FormControl variant="outlined" fullWidth size='small'>
                <InputLabel>User Role</InputLabel>
                <Select
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  label="User Role"
                >
                  <MenuItem value="">All Roles</MenuItem>
                  {roles
                    .filter(role => !currentUserRoles.includes(role.RoleName.toLowerCase()))  // Filter out already assigned roles
                    .map(filteredRole => (
                      <MenuItem key={filteredRole.id} value={filteredRole.RoleName.toLowerCase()}>
                        {filteredRole.RoleName.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          )}

          {(actionType === 'deactivateAccount' || actionType === 'deleteAccount') && (
            <>
              <br />
              <br />
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel>Reason</InputLabel>
                <Select
                  variant='standard'
                  value={deactivationReason}
                  onChange={(e) => setDeactivationReason(e.target.value)}
                >
                  <MenuItem value="Employment Terminated">Employment Terminated</MenuItem>
                  <MenuItem value="Employee Resigned">Employee Resigned</MenuItem>
                  <MenuItem value="Inactive Account">Inactive Account</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
             onClick={() =>
              actionType === 'assignRoles' ? handleAssignRoles(selectedUser) :
                actionType === 'deactivateAccount' ? handleDeactivateAccount(selectedUser) :
                actionType === 'deleteAccount' ? handleDeleteAccount(selectedUser):undefined
            }
            color={actionType === 'deleteAccount' ? 'error' : actionType === 'deactivateAccount' ? 'secondary' : 'primary'}
          >
            {actionType === 'assignRoles' ? 'Assign Roles' : actionType === 'deactivateAccount' ? 'Deactivate Account' : 'Delete Account'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminPage;
