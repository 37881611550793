import {
  Box,
  Button,
  TextField,
  Paper,
  Stack,
  Typography,
  IconButton,
  Avatar,
  Grid,
  Tooltip,
  useScrollTrigger,
  Divider
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {
  useState
} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import {
  PDFDownloadLink,
  PDFViewer
} from '@react-pdf/renderer';
import {
  DatePicker
} from "@mui/x-date-pickers";
import PdfDocument from "./PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import BusinessIcon from '@mui/icons-material/Business';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  blueGrey
} from '@mui/material/colors'
import {
  createTheme
} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  useNavigate
} from 'react-router-dom';
import {
  Alert
} from '@mui/material';
import {
  orange,
  indigo,
  green,
  red,blue
} from '@mui/material/colors';
import {
  useSearchParams
} from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {
  color
} from '@mui/system';
import LoadingDialog from '../Loading'
import DHS_SKUs_ARRAY from '../SKUs/DHS_SKUs_ARRAY';
import { AxiosGlobalAPIInstance } from '../../axiosGlobalInstance';


const theme = createTheme({
  palette: {
      primary: {
          main: blueGrey[900],
      },
      secondary: {
          main: blueGrey[300],
      },
  },
});


//---------------------------------------------------------------------------------
function DHSCustomerQuoteGenerator({UserDetails}) {

  //--------------------------------------------------------------------------------------------------------------------
// State variable to track if a button is clicked
const [clicked, setClicked] = useState(false);

// State variable to store the file name
const [fileName, setFileName] = useState("");

// State variable to control the disabled state of components
const [dis, setDis] = useState(false);

// Hook to navigate between routes
const navigate = useNavigate();

// State variable to hold RFQ message
const [rfqMssge, setRFQMssge] = useState('');

// State variables to control visibility of success and error dialogs
const [successDialog, setSuccessDialog] = useState(false);
const [errorDialog, setErrorDialog] = useState(false);

// Extracted user details
const UserName = String(String(UserDetails).split("E-N")[1]).split("T-T")[0];
const UserEmail = String(UserDetails).split("E-N")[0];

// Extract search parameters using useSearchParams hook
const [searchParams] = useSearchParams();

// State variable to indicate loading state
const [loading, setLoading] = useState(false);

// State variable to track if DHS-CBP dialog is clicked
const [dhsCBPDialogClicked, setDHSCBPDialogClicked] = useState(false);

// Array of DHS SKUs
var DHSSKUsARRAY = DHS_SKUs_ARRAY();

// State variable to store pricing year
const [pricingYear, setPricingYear] = useState('');

// State variables to track checkbox states for KO's information
const [KOsNameChecked, setKOsNameChecked] = useState(false);
const [KOsEmailChecked, setKOsEmailChecked] = useState(false);
const [KOsPhoneChecked, setKOsPhoneChecked] = useState(false);
const [KOsAddressChecked, setKOsAddressChecked] = useState(false);

//----------------------------------------------------------------------------------------------------------------------------
const PD = new Date()
const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium'
}).format(PD)

//--------------------------------------------------------------------------------------------------------------------
// State variable to store an array of DHS SKUs
var [DHSSKUIDs, setDHSSKUIDs] = useState(DHSSKUsARRAY);

// State variable to store the search input
const [searchInput, setSearchInput] = useState('');

// State variable to store the filtered DHS SKUs based on search input
const [filteredDHSSKUIDs, setFilteredDHSSKUIDs] = useState([]);

// Effect hook to filter DHS SKUs based on search input
React.useEffect(() => {
  // Filter DHS SKUs based on search input
  const filteredSKUs = DHSSKUIDs.filter((SKU) => {
    const searchTerm = searchInput.toLowerCase();
    return (
      SKU.ID.toLowerCase().includes(searchTerm) ||
      SKU.Desc.toLowerCase().includes(searchTerm)
    );
  });
  // Update filtered DHS SKUs
  setFilteredDHSSKUIDs(filteredSKUs);
}, [searchInput, DHSSKUIDs]);

// Function to handle search input change
const handleSearchSKU = (input) => {
  setSearchInput(input);
};

//---------------------------------------------------------------------------------------------------------------------
// Initial state for form values
const [Form_Value, Set_Form_Value] = useState({
  // General Information
  Opportunity_Name: 'DHS-CBP',
  RFQ_ID: 'NA',
  Invoice_No: '',
  Contract_Vehicle: 'DHS-CBP',
  KO_name: UserName,
  KO_email: UserEmail,
  KO_phone: '',
  KO_address: '',
  Published_date: String(today),
  Due_date: String(today),
  Brief: '',
  Organization_Name: '',
  Notes_For_PO: 'NA',

  // Customer Information
  Customer_Name: '',
  Customer_Email: '',
  Customer_Phone: '',
  Customer_Address: '',

  // Sales Information
  Shipping_Method: 'N/A',
  Sales_Head_Name: String('DHS-CBP').toLowerCase(),
  Valid_For: '30 Days',
  Sales_Head_Email: 'DHS_orders@aspettoinc.com',
  Sales_Head_Ph: 'NA',
  Shipping_Terms: 'FOB Origin',
  Estimated_Delivery: '45 Days ARO',
  Payment_Terms: 'NET30',
  Notes: 'N/A',
  Shipping_Cost: parseFloat(0).toFixed(2),
  Tax: parseFloat(0).toFixed(2),
  Other_Cost_1: parseFloat(0).toFixed(2),
  Other_Cost_2: parseFloat(0).toFixed(2),
  Other_Cost_3: parseFloat(0).toFixed(2),
  Other_Cost_Name1: 'NA',
  Other_Cost_Name2: 'NA',
  Other_Cost_Name3: 'NA',

  // Date Information
  Created_Date: String(today),
  Last_Updated_Date: String(today),

  // Status and Comments
  RFQ_Status: 'PENDING',
  Updated_By: String(UserEmail).toLowerCase(),
  Version_No: parseInt(0),
  Updating_Comments: 'RFQ Created under PENDING Status',

  // Additional Information
  Working_Sales_Head: 'DHS_orders@aspettoinc.com',
  Tranfer_History: '',

  // Token
  Temp_Token: String(UserDetails).split("T-T")[1]
});


const [New_SKU,Set_New_SKU]=useState([{Vendor_Partner_name:'',
SKU_ID:'',
SKU_Description:'',
QTY:parseInt(0),
Aspetto_Unit_Cost:parseFloat(0).toFixed(2),
Unit_Of_Measure:'ea',
RFQRFQID:'',
Margin_Percent:parseFloat(0).toFixed(2),
Customer_Unit_Cost:parseFloat(0).toFixed(2),
Total_SKU_Cost:parseFloat(0).toFixed(2),
RFQ_Created_Date:Form_Value.Created_Date,
RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
SKU_Created_Date:String(today),
SKU_Last_Updated_Date:String(today),
SKU_Created_By:String(UserEmail).toLowerCase(),
SKU_Updated_By:String(UserEmail).toLowerCase(),
Version_No:Form_Value.Version_No}])

const [TotalCost,SetTotalCost]=useState({
  Actual_Total_Cost_Before_Margin:parseFloat(0.00).toFixed(2),
  Actual_Total_Cost_After_Margin:parseFloat(0.00 ).toFixed(2),
  Discount_Applied:parseFloat(0).toFixed(2),
  Final_Total_Cost_After_Dicscount:parseFloat(0).toFixed(2),
  })
  const today1 = new Date();

  const dateRanges = [
    { start: new Date('06/09/23'), end: new Date('06/08/24'), year: 'Year1' },
    { start: new Date('06/09/24'), end: new Date('06/08/25'), year: 'Year2' },
    { start: new Date('06/09/25'), end: new Date('06/08/26'), year: 'Year3' },
    { start: new Date('06/09/26'), end: new Date('06/08/27'), year: 'Year4' },
    { start: new Date('06/09/27'), end: new Date('06/08/28'), year: 'Year5' },
    { start: new Date('06/09/28'), end: new Date('06/08/29'), year: 'Year6' },
    { start: new Date('06/09/29'), end: new Date('06/08/30'), year: 'Year7' },
  ];

React.useEffect(()=>{
 
},[Form_Value?.RFQ_ID])


{/*-----------------------------------------------------------------*/}
const handleClose = (e) => {
  setErrorDialog(false)
  setSuccessDialog(false)
  setLoading(false)
  setDis(false)
  setDHSCBPDialogClicked(false)
}


{/*-----------------------------------------------------------------*/}
const handleDHSSKUs = async (e) => {
  setClicked(false)

  function isEqual(obj1, obj2) {
      return obj1.ID === obj2.ID && obj1.Desc === obj2.Desc;
  }
  //-----------------------Code For Adjusting the SKUs if the Contract Vehicle is DHS------------------------------------
  function subtractArrays(arr1, arr2) {
      const result = [];

      arr1.forEach(obj1 => {
          const matchingObj = arr2.find(obj2 => isEqual(obj1, obj2));

          if (!matchingObj) {
              result.push(obj1);
          }
      });

      return result;
  }

  //-------------------------------------------Setting Pricing Year for DHS----------------------------------------------------------------------
  var array3 = DHSSKUsARRAY
  var array4 = [{}]
  New_SKU.map((s, i) => {

      array4.push({
          ID: s.SKU_ID,
          Desc: s.SKU_Description
      })
  })

  const subtractedDHSArray = subtractArrays(array3, array4);
  setDHSSKUIDs(subtractedDHSArray)
  setDHSCBPDialogClicked(true);

}

//-------------------------------------------------------------------------
const HandleAddSKU=()=>{
  setClicked(false)
  Set_New_SKU([...New_SKU,{
  Vendor_Partner_name:'',
  SKU_ID:'',
  SKU_Description:'',
  QTY:parseInt(0),
  Aspetto_Unit_Cost:parseFloat(0).toFixed(2),
  Unit_Of_Measure:'ea',
  RFQRFQID:Form_Value.RFQ_ID,
  Margin_Percent:parseFloat(0).toFixed(2),
  Customer_Unit_Cost:parseFloat(0).toFixed(2),
  Total_SKU_Cost:parseFloat(0).toFixed(2),
  RFQ_Created_Date:Form_Value.Created_Date,
  RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
  SKU_Created_Date:String(today),
  SKU_Last_Updated_Date:String(today),
  SKU_Created_By:String(UserEmail).toLowerCase(),
  SKU_Updated_By:String(UserEmail).toLowerCase(),
  Version_No:parseInt(Form_Value.Version_No)
}])
  }
{/*-----------------------------------------------------------------*/}
const HandleRemoveSKU=(index)=>{
  setClicked(false)
  const skusl=[...New_SKU];
    skusl.splice(index,1)
    Set_New_SKU(skusl)
   if (Form_Value.Contract_Vehicle==="DHS-CBP")
    {
      setDHSSKUIDs([...DHSSKUIDs,{ID:New_SKU[index].SKU_ID,Desc:New_SKU[index].SKU_Description}])
    }

    let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
    let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
    skusl.map((sku, index)=>{
      cal=cal + (parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost===""?parseFloat(0.00):sku.Aspetto_Unit_Cost));
      cal3= cal3+ parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Customer_Unit_Cost===""?parseFloat(0.00):sku.Customer_Unit_Cost);
    skusl[index]['RFQRFQID']=Form_Value.RFQ_ID
    skusl[index]['Sales_Head_Email']=String(Form_Value.Sales_Head_Email).toLowerCase();
    skusl[index]['Opportunity_Name']=Form_Value.Opportunity_Name;
    })
    cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))
    cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3 ).toFixed(2),});


  }
//-------------------------------------------------------
const HandleSKUChange=(e,index)=>{
  setClicked(false)
  const {name,value}=e.target;
  const skusl=[...New_SKU];
  skusl[index][name]=value


  if(name==="Customer_Unit_Cost")
  {
    skusl[index]['Margin_Percent']=parseFloat((parseFloat(parseFloat(e.target.value)-skusl[index]['Aspetto_Unit_Cost'])/parseFloat(e.target.value))*100).toFixed(2);
    skusl[index]['Total_SKU_Cost']=parseFloat(parseInt(skusl[index]['QTY'])*parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(2);
  }
  else
  {
  
  skusl[index]['Customer_Unit_Cost']=parseFloat(skusl[index]['Aspetto_Unit_Cost']/(1-skusl[index]['Margin_Percent']/100)).toFixed(2);
  skusl[index]['Total_SKU_Cost']=parseFloat(parseInt(skusl[index]['QTY'])*parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(2);
  }
  skusl[index]['SKU_Last_Updated_Date']=String(today);
  skusl[index]['SKU_Updated_By']=String(UserEmail).toLowerCase();

  Set_New_SKU(skusl)
  let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
  let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
  skusl.map((sku, index)=>{
  cal=cal + (parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost===""?parseFloat(0.00):sku.Aspetto_Unit_Cost));
  cal3= cal3+ parseInt(sku.QTY===""?parseFloat(0):sku.QTY)*parseFloat(sku.Customer_Unit_Cost===""?parseFloat(0.00):sku.Customer_Unit_Cost);
  })
  cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))
  cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Other_Cost_1)+parseFloat(Form_Value.Other_Cost_2)+parseFloat(Form_Value.Other_Cost_3))


  SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
}



//------------------------------------------------------------------------------
  const [InvoiceData, setInvoiceData] = useState({
      invoice_no: '',
      Contract_Vehicle: '',
      KO_name: "",
      KO_email: "",
      KO_phone: "",
      KO_address: "",
      Print_Date:String(today),
      Valid_For:"30 Days",
      trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
      due_date: "",
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: [{}],
      Tax: 0,
      Shipping_Cost: 0,
      Other_Cost_1:0,Other_Cost_2:0,Other_Cost_3:0,Other_Cost_Name1:"",Other_Cost_Name2:"",Other_Cost_Name3:"",
      Total_Cost: 0,
      Shipping_Details: {
          Organization_Name: "",
          KO_name: "",
          Sales_Head_Info: {},
          Customer_Name: "",
          Customer_Email: "",
          Customer_Phone: "",
          Customer_Address: "",
          Shipping_Method: "",
          Shipping_Terms: "",
          Estimated_Delivery: "",
          Payment_Terms: "",
          Notes: ""
      }
  })

  const CreateQuote=async(e)=>{
    e.preventDefault()
    setLoading(true)
    setDis(true)
    //Updating RFQ Last Updated Data
    Set_Form_Value({
        ...Form_Value,
        Last_Updated_Date: String(today)
    })
    const Form_Table = {
        ...Form_Value,
        ...TotalCost
    }
    var No_Of_Null_Values = 0;


var No_Of_Null_Values = 0;
New_SKU.map(async (row, index) => {
    if (row.SKU_ID === '' || row.SKU_Description === '' || row.Vendor_Partner_name === '') {
        No_Of_Null_Values = No_Of_Null_Values + 1;
    }
})
/*--------------------------------Updating the database---------------*/
if (No_Of_Null_Values > 0 || Form_Value.Other_Cost_1==="" || Form_Value.Other_Cost_2==="" || Form_Value.Other_Cost_3===""||Form_Value.Shipping_Cost===""
||Form_Value.Tax==="") {
    setRFQMssge('All the fields from the order details section are required')
    setErrorDialog(true)
} else {

  //--------------------------------------------------------------------------------
    await AxiosGlobalAPIInstance.post('/DHSCustomer/CreateRFQ', [Form_Table, New_SKU]).then(
            async (response) => {
              setLoading(false)
              setClicked(true);
                //------------------Setting PDF Data-------------------------

                const invoice_num = response?.data
                setInvoiceData({
                  ...InvoiceData,
                  invoice_num: invoice_num,
                  Contract_Vehicle: Form_Value.Contract_Vehicle,
                  KO_name: Form_Value.KO_name,
                  KO_email: Form_Value.KO_email,
                  KO_phone: Form_Value.KO_phone,
                  KO_address: Form_Value.KO_address,
                  Print_Date:String(today),
                  trans_date: String(Form_Value.Last_Updated_Date).split('T')[0],
                  due_date: Form_Value.Due_date,
                  Valid_For:Form_Value.Valid_For,
                  Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
                  items: [...New_SKU],
                  Tax: Form_Value.Tax,
                  Shipping_Cost: Form_Value.Shipping_Cost,
                  Total_Cost: TotalCost.Actual_Total_Cost_After_Margin,
                  Other_Cost_1:Form_Value.Other_Cost_1,
                  Other_Cost_2:Form_Value.Other_Cost_2,
                  Other_Cost_3:Form_Value.Other_Cost_3,
                  Other_Cost_Name1:Form_Value.Other_Cost_Name1,
                  Other_Cost_Name2:Form_Value.Other_Cost_Name2,
                  Other_Cost_Name3:Form_Value.Other_Cost_Name3,
                  Shipping_Details: {
                      Organization_Name: Form_Value.Organization_Name,
                      KO_name: Form_Value.KO_name,
                      Sales_Head_Info: {
                          Name: Form_Value.Sales_Head_Name,
                          Email: Form_Value.Sales_Head_Email,
                          Phone: "540-547-8487"
                      },
                      Customer_Name: Form_Value.Customer_Name,
                      Customer_Email: Form_Value.Customer_Email,
                      Customer_Phone: Form_Value.Customer_Phone,
                      Customer_Address: Form_Value.Customer_Address,
                      Shipping_Method: Form_Value.Shipping_Method,
                      Shipping_Terms: Form_Value.Shipping_Terms,
                      Estimated_Delivery: Form_Value.Estimated_Delivery,
                      Payment_Terms: Form_Value.Payment_Terms,
                      Notes: Form_Value.Notes
                  }
              })


                setFileName("DHS" + String(invoice_num).split('.')[0]+String(invoice_num).split('.')[1])
                setClicked(true)
                setDis(false)
                setRFQMssge('A new quote with QUOTE NUMBER :' +invoice_num + ' created sucessfully')
                setSuccessDialog(true)
                //---------------------------------------------------------------------------------------
                Set_Form_Value({
                    ...Form_Value,
                    Opportunity_Name: '',
                    RFQ_ID: '',
                    Contract_Vehicle: 'GSA',
                    KO_name: UserName,
                    KO_email:UserEmail,
                    KO_phone: '',
                    KO_address: '',
                    Published_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    Due_date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    Brief: 'N/A',
                    Organization_Name: '',
                    Notes_For_PO:"NA",
                    Customer_Name: '',
                    Customer_Email: '',
                    Customer_Phone: '',
                    Customer_Address: '',
                    Valid_For:'30 Days',
                    Shipping_Method: 'N/A',
                    Sales_Head_Name: '',
                    Sales_Head_Ph: "540-547-8487",
                    Sales_Head_Email:'DHS_orders@aspettoinc.com',
                    Shipping_Terms: 'FOB Origin',
                    Estimated_Delivery: 'N/A',
                    Payment_Terms: 'NET30',
                    Other_Cost_1:Number(parseFloat(0).toFixed(2)),Other_Cost_2:Number(parseFloat(0).toFixed(2)),Other_Cost_3:Number(parseFloat(0).toFixed(2)),Other_Cost_Name1:"NA",Other_Cost_Name2:"NA",Other_Cost_Name3:"NA",
                    Notes: 'N/A',
                    Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    RFQ_Status: 'PENDING',
                    Shipping_Cost: parseFloat(0.00).toFixed(2),
                    Tax: parseFloat(0.00).toFixed(2),
                    Updated_By: 'TBD',
                    Version_No: parseInt(0),
                    Updating_Comments: 'RFQ Created under PENDING Status',
                    Temp_Token:String(UserDetails).split("T-T")[1]
                })
                SetTotalCost({
                    ...TotalCost,
                    Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2),
                    Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
                    Discount_Applied: parseFloat(0).toFixed(2),
                    Final_Total_Cost_After_Dicscount: parseFloat(0).toFixed(2)
                })
                //-----------------------------------------------------------------------------------------
                Set_New_SKU([{
                    Vendor_Partner_name: '',
                    SKU_ID: '',
                    SKU_Description: '',
                    QTY: parseInt(0),
                    Aspetto_Unit_Cost: parseFloat(0.00).toFixed(2),
                    Unit_Of_Measure: 'ea',
                    RFQRFQID: '',
                    Margin_Percent: parseFloat(0.00).toFixed(2),
                    Customer_Unit_Cost: parseFloat(0.00).toFixed(2),
                    Total_SKU_Cost: parseFloat(0.00).toFixed(2),
                    RFQ_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    RFQ_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    SKU_Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    SKU_Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
                    SKU_Created_By: 'TBD',
                    SKU_Updated_By: 'TBD',
                }])

            })
        .catch((err) => {
          setLoading(false)
            setRFQMssge(err.response?.data)
            setErrorDialog(true)
            setDis(false)
        })
 
}
}
return(
  <>
   <LoadingDialog open={loading} />
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfqMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
          {rfqMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          e.preventDefault();
          handleClose();
          setDHSSKUIDs(DHSSKUsARRAY)
          setDis(false)
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {rfqMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>
          {rfqMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {dhsCBPDialogClicked === true && (
  <Dialog
    open={dhsCBPDialogClicked}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <Alert variant='filled' sx={{ bgcolor: indigo[700] }}>DHS-CBP SKUs</Alert>
    </DialogTitle>
    <DialogContent>
      <TextField
        size="small"
        label="Search SKU"
        variant="standard"
        sx={{ width: "100%", marginBottom: 2 }}
        onChange={(e) => handleSearchSKU(e.target.value)}
      />
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Display SKUs based on search results */}
        {filteredDHSSKUIDs.map((SKU, index) => {
          return (
            <FormGroup>
             <Stack direction="row">
          <FormControlLabel size="small" control={<IconButton  variant="contained"  onClick={async(e,)=>
          {
            e.preventDefault()
            setDis(true)
            var DHS_Pricing_Year=String(pricingYear).split('(')[0]
            await AxiosGlobalAPIInstance.get("/DHSCustomer/DHSCustomerExternal/PartNo/PricingYear",{params:{Part_No:SKU.ID}}).then(
             async(result)=>{
               setDis(false)
               var CustomerCost=''
                 if(DHS_Pricing_Year==='06/09/23-06/08/24')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year1).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/24-06/08/25')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year2).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/25-06/08/26')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year3).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/26-06/08/27')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year4).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/27-06/08/28')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year5).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/28-06/08/29')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year6).replace("$",'').replace(",",'').replace(' ','')
                 }
                 else if(DHS_Pricing_Year==='06/09/28-06/08/30')
                 {
                  CustomerCost=String(result?.data.UnitPrice_Year7).replace("$",'').replace(",",'').replace(' ','')
                 }

                 Set_New_SKU([...New_SKU,{
                   Vendor_Partner_name:result?.data.Part_No,
                   SKU_ID:result?.data.Part_No,
                   SKU_Description:SKU.Desc,
                   QTY:parseInt(0),
                   Aspetto_Unit_Cost:parseFloat(CustomerCost).toFixed(2),
                   Unit_Of_Measure:'ea',
                   RFQRFQID:Form_Value.RFQ_ID,
                   Margin_Percent:parseFloat(0.00).toFixed(2),
                   Customer_Unit_Cost:parseFloat(CustomerCost).toFixed(2),
                   Total_SKU_Cost:parseFloat(0).toFixed(2),
                   RFQ_Created_Date:Form_Value.Created_Date,
                   RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
                   SKU_Created_Date:String(today),  
                   SKU_Last_Updated_Date:String(today),
                   SKU_Created_By:String(UserEmail).toLowerCase(),
                   SKU_Updated_By:String(UserEmail).toLowerCase(),
                   Version_No:parseInt(Form_Value.Version_No)
               }])
        const skus=[...DHSSKUIDs]
         skus.splice(index,1)
         setDHSSKUIDs(skus)
             }
            ).catch((error)=>{
             setRFQMssge(error?.response?.data)
             setErrorDialog(true)
             setDis(false)
            }
              
            )

          }}><Avatar variant='rounded' sx={{bgcolor:[indigo[800]],marginLeft:"10px",width:"30px",height:"30px",}}>
            <Tooltip title={`Add SKU# ${SKU.ID}`}><AddBoxIcon/></Tooltip></Avatar></IconButton>  } label={<span style={{ whiteSpace: 'pre-wrap' }}><b>{SKU.ID} :</b><br/>
 {SKU.Desc} 
</span>} />
           </Stack>
           <Divider sx={{color:red[900],bgcolor:red[900]}}></Divider>
            </FormGroup>
          );
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        setSearchInput("")
        handleClose();
      }}>OK</Button>
    </DialogActions>
  </Dialog>
)}
  
     
 <Grid container>
<Paper name="RFQ_Form" component="form" sx={{padding:"20px"}} autoComplete='off' elevation={3}>
    <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={1}>
        <Stack direction="row"  spacing={2}>
        <Stack direction="row" spacing={1}>
          </Stack>
        </Stack>
        <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{alignContent:"center",fontSize:"18px", fontWeight:"bold",padding:1}}>Quote Requested By:</Typography>
          </Stack>
          <Stack justifyContent="center" direction="row">
          <Divider sx={{color:blueGrey[900],bgcolor:blue[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
        <Stack required direction="row" spacing={2} justifyContent="center">
        <TextField variant="standard" size="small"  sx={{width:"250px"}} label="Your Full Name" value={Form_Value.KO_name}  onChange={
          (e)=>
          { 
            Set_Form_Value({...Form_Value,KO_name:e.target.value});
            setKOsNameChecked(false)
          }} InputProps={{
            readOnly:true,
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small"  variant="standard" type="email" sx={{width:"250px"}} label="Official Email" value={Form_Value.KO_email} 
        onChange={(e)=>
          { 
            Set_Form_Value({...Form_Value,KO_email:e.target.value})
            setKOsEmailChecked(false)

          }
          } InputProps={{
            readOnly:true,
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField size="small"  variant="standard" sx={{width:"250px"}} label="Phone" value={Form_Value.KO_phone} 
        onChange={(e)=>
          {  
            
            Set_Form_Value({...Form_Value,KO_phone:e.target.value})
            setKOsPhoneChecked(false)
          }
          } InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small"  variant="standard" multiline={true} rowsMax={10} sx={{width:"250px"}} label="Address" value={Form_Value.KO_address} 
        onChange={(e)=>
          {  
            
            Set_Form_Value({...Form_Value,KO_address:e.target.value})
            setKOsAddressChecked(false)
          }
        } InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField size="small" variant="standard"   sx={{width:"250px"}} label="Organization/Agency's Name" value={Form_Value.Organization_Name} onChange={(e)=>{ Set_Form_Value({...Form_Value,Organization_Name:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}></TextField>
        
        </Stack>

        <Stack direction='column' spacing={1} justifyContent="center">
        <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{alignContent:"center",fontSize:"18px", fontWeight:"bold",padding:1}}>Ship To:</Typography>
          </Stack>
          <Stack justifyContent="center" direction="row">
          <Divider sx={{color:blueGrey[900],bgcolor:blue[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
          <Stack></Stack>
        <Stack direction='row' spacing={2} justifyContent="center">
          <TextField size="small" variant="standard" sx={{width:"250px"}} label="Name"  value={Form_Value.Customer_Name} disabled={KOsNameChecked} onChange={(e)=>
          {  
            Set_Form_Value({...Form_Value,Customer_Name:e.target.value})
           
          }} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" variant="standard"  type="email" sx={{width:"250px"}} label="Email" value={Form_Value.Customer_Email} disabled={KOsEmailChecked} onChange={(e)=>{
          
           Set_Form_Value({...Form_Value,Customer_Email:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
       
        <TextField size="small" variant="standard" sx={{width:"250px"}} label="Phone" disabled={KOsPhoneChecked} value={Form_Value.Customer_Phone} 
        onChange={(e)=>{
       
         Set_Form_Value({...Form_Value,Customer_Phone:e.target.value})
                      }
        } InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
             
         <TextField size="small" variant="standard"  multiline={true} rowsMax={10} sx={{width:"250px"}} disabled={KOsAddressChecked} label="Address" value={Form_Value.Customer_Address} 
         onChange={(e)=>{
  
          Set_Form_Value({...Form_Value,Customer_Address:e.target.value})}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
          </TextField>
          <Stack sx={{width:"250px"}}></Stack>
          </Stack>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent="center">
        <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(0.9)",
        }}>
        <FormControlLabel  sx={{color:'red'}} size='small' control={<Checkbox size="small" sx={{width:'30px'}}  checked={KOsNameChecked} onChange={(e)=>{
     
          setKOsNameChecked(e.target.checked)
          if(e.target.checked===true)
          {
            Set_Form_Value({...Form_Value,Customer_Name:Form_Value.KO_name})
          }
          else Set_Form_Value({...Form_Value,Customer_Name:''})

        }}/>} label="Copy Your Name" />
         </FormGroup>

         <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(0.9)",
        }}>
        <FormControlLabel  size="small" sx={{color:'red'}} control={<Checkbox size="small" sx={{width:'30px'}} checked={KOsEmailChecked}  onChange={(e)=>{
          
    
          setKOsEmailChecked(e.target.checked);
          if(e.target.checked===true)
          {
            Set_Form_Value({...Form_Value,Customer_Email:Form_Value.KO_email})
          }
          else Set_Form_Value({...Form_Value,Customer_Email:''})
        
        }}/>} label="Copy Your Email" />
         </FormGroup>
        
         <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(0.9)",
        }}>
        <FormControlLabel  sx={{color:'red'}} control={<Checkbox size="small" checked={KOsPhoneChecked} sx={{width:'30px'}} onChange={(e)=>{ 
  
          setKOsPhoneChecked(e.target.checked);
          if(e.target.checked===true)
          {
            Set_Form_Value({...Form_Value,Customer_Phone:Form_Value.KO_phone})
          }
          else Set_Form_Value({...Form_Value,Customer_Phone:''})
        }}/>} label="Copy Your Phone" />
         </FormGroup>

         <FormGroup sx={{width:"250px"}} style={{
            transform: "scale(0.9)",
        }}>
        <FormControlLabel sx={{color:'red'}} control={<Checkbox size="small" sx={{width:'30px'}} checked={KOsAddressChecked} onChange={(e)=>{
   
          setKOsAddressChecked(e.target.checked);
          if(e.target.checked===true)
          {
            Set_Form_Value({...Form_Value,Customer_Address:Form_Value.KO_address})
          }
          else Set_Form_Value({...Form_Value,Customer_Address:''})
        
        }}/>} label="Copy Your Address" />
         </FormGroup>
         <Stack sx={{width:"250px"}}></Stack>
        </Stack>
     

     
  {/*--------------------------ORDER DETAILS-----------------------------------------------*/}
  <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{alignContent:"center",fontSize:"18px", fontWeight:"bold",padding:1}}>SKUs:</Typography>
          </Stack>
          <Stack justifyContent="center" direction="row">
          <Divider sx={{color:blueGrey[900],bgcolor:blue[800],width:"100%",height:"1.5px"}} orientation='horizontal'></Divider>
          </Stack>
        <Stack direction="column" spacing={2} >
        <Stack sx={{width:"250px"}}></Stack>
<Stack direction='row' spacing={3} justifyContent='center'>
 <FormControl size="small" sx={{width:"250px"}}>
 <InputLabel id="Pricing-Year">Select Pricing Year</InputLabel>
 <Select size="small"
   required
   labelId="Pricing-Year"
   id="Pricing-Year-id"
   value={pricingYear}
   variant="standard"
   label="Pricing-Year"
   onChange={(e)=>
    { 
      Set_New_SKU([{Vendor_Partner_name:'',
      SKU_ID:'',
      SKU_Description:'',
      QTY:parseInt(0),
      Aspetto_Unit_Cost:parseFloat(0).toFixed(2),
      Unit_Of_Measure:'ea',
      RFQRFQID:'',
      Margin_Percent:parseFloat(0).toFixed(2),
      Customer_Unit_Cost:parseFloat(0).toFixed(2),
      Total_SKU_Cost:parseFloat(0).toFixed(2),
      RFQ_Created_Date:Form_Value.Created_Date,
      RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
      SKU_Created_Date:String(today),
      SKU_Last_Updated_Date:String(today),
      SKU_Created_By:Form_Value.Sales_Head_Email,
      SKU_Updated_By:Form_Value.Sales_Head_Email,
      Version_No:Form_Value.Version_No}])
      setDHSSKUIDs(DHSSKUsARRAY)
      setPricingYear(e.target.value)
    }}
   sx={{width:"250px"}}>
   <MenuItem size="small" name="Yr1" value={"06/09/23-06/08/24(Year1)"}>06/09/23-06/08/24(Year1)</MenuItem>
   <MenuItem size="small" name="Yr2" value={"06/09/24-06/08/25(Year2)"}>06/09/24-06/08/25(Year2)</MenuItem>
   <MenuItem size="small" name="Yr3" value={"06/09/25-06/08/26(Year3)"}>06/09/25-06/08/26(Year3)</MenuItem>
   <MenuItem size="small" name="Yr4" value={"06/09/26-06/08/27(Year4)"}>06/09/26-06/08/27(Year4)</MenuItem>
   <MenuItem size="small" name="Yr5" value={"06/09/27-06/08/28(Year5)"}>06/09/27-06/08/28(Year5)</MenuItem>
   <MenuItem size="small" name="Yr6" value={"06/09/28-06/08/29(Year6)"}>06/09/28-06/08/29(Year6)</MenuItem>
   <MenuItem size="small" name="Yr7" value={"06/09/28-06/08/30(Year7)"}>06/09/28-06/08/30(Year7)</MenuItem>
 </Select>
</FormControl>
<Button  variant="contained" onClick={(e)=>{ setSearchInput("");handleDHSSKUs(e)}} sx={{height:"35px",bgcolor:[indigo[500]],width:"200px"}} disabled={pricingYear===''?true:false}>ADD ITEMS</Button>
</Stack>
      {New_SKU?.map((New_Sku,index)=>{return(
      <Stack key={index} direction="column" spacing={1} justifyContent='center'>
      <Stack direction="row" spacing={2} justifyContent='center'>
        <TextField size="small" variant="standard"   name="SKU_ID" sx={{width:"300px"}} label="SKU ID" value={New_Sku.SKU_ID} onChange={(e)=>HandleSKUChange(e,index)}></TextField>
        <TextField size="small" variant="standard"  sx={{width:"400px"}} name="SKU_Description" label="SKU Description"  multiline={true} rowsMax={20} value={New_Sku.SKU_Description} onChange={(e)=>HandleSKUChange(e,index)}  InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" variant="standard" name="QTY"   step="0.00" sx={{width:"100px"}} label="QTY" value={New_Sku.QTY} onChange={(e)=>HandleSKUChange(e,index)} inputProps={{min:"0",inputMode:"numeric"}} ></TextField>
        <TextField size="small" variant="standard" name="Unit_Of_Measure"  sx={{width:"70px"}} label="UM" value={New_Sku.Unit_Of_Measure} onChange={(e)=>HandleSKUChange(e,index) }></TextField>
         <TextField size="small" variant="standard" name="Customer_Unit_Cost"   sx={{width:"200px"}} label="Unit Cost" value={New_Sku.Customer_Unit_Cost==="NaN"||""?parseFloat(0.00):New_Sku.Customer_Unit_Cost} onChange={(e)=>HandleSKUChange(e,index)} inputProps={{min:"0",step:"any",inputMode:"numeric"}} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          )}}></TextField>
            <TextField size="small" variant="standard" name="Total_SKU_Cost"   sx={{width:"200px"}}  label="Total Unit Cost" value={New_Sku.Total_SKU_Cost==="NaN"||""?parseFloat(0.00):New_Sku.Total_SKU_Cost} inputProps={{min:"0",step:"any",inputMode:"numeric"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          )}}></TextField>
        </Stack>
        <Stack direction="row" spacing={4}>   
        {New_SKU.length >1 && (<IconButton name="Cancel" variant='contained' color='warning'  onClick={()=>HandleRemoveSKU(index)}> 
         <Avatar variant="rounded" sx={{ backgroundColor:[red[700]] ,":hover":{boxShadow:"10px 10px 20px #ccc"}}}><Tooltip title="Remove SKU"><DisabledByDefaultIcon/></Tooltip></Avatar></IconButton>)} 
        {New_SKU.length-1===index && (<IconButton  variant="contained"  color="success" onClick={()=>HandleAddSKU(index)}><Avatar variant='rounded' sx={{bgcolor:[green[800]]}}><Tooltip title="Add SKU"><AddBoxIcon/></Tooltip></Avatar></IconButton>)} </Stack>
        </Stack>)})}
   
          {/*------------------------------------------------------------Other Costs--------------------------------------------- */}
          {/* ------------------------------------------------------------------------------------------------------------------ */}

     <Stack direction="row" spacing={2}>
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"240px"}}></Stack><Stack sx={{width:"330px"}}></Stack>
        <TextField size="small" variant="standard"  sx={{width:"300px"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Total Cost " value={TotalCost.Actual_Total_Cost_After_Margin==="NaN"?parseFloat(0.00):Number(TotalCost.Actual_Total_Cost_After_Margin).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ></TextField>
        </Stack>
          
      </Stack>
        <Stack direction="row" spacing={2}>
        <Stack sx={{width:"300px"}}></Stack><Stack sx={{width:"520px"}}></Stack>
        <Stack sx={{width:"200px"}}>
          { 
         clicked===true &&
            (<PDFDownloadLink component="button" color="secondary" sx={{fontSize:"15px"}}
            document={<PdfDocument invoicedata={InvoiceData}/>}
            fileName={fileName}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : "Download Your Quote"
            }
          </PDFDownloadLink>)
        }
        </Stack>
        <Button   variant="contained" onClick={(e)=>{CreateQuote(e)}} sx={{bgcolor:[indigo[500]],width:"200px"}}> Generate Quote</Button>
  
        </Stack>
        <Stack>
        <Typography variant='body2'><strong>Note:</strong>This page serves as an estimate for the total number of items, and generating a quote here automatically notifies Aspetto's DHS Sales team. 
          To proceed with the quote and place an order, it is essential to contact the team directly at {' '}
  <a href="mailto:DHS_orders@aspettoinc.com" style={{ color: 'blue' }}><strong>DHS_orders@aspettoinc.com</strong></a> or Phone: <strong>540-547-8487</strong>.</Typography>
        </Stack>
      </Stack>
   
        </Stack>

</Paper>
</Grid>
</>
)
}
export default DHSCustomerQuoteGenerator