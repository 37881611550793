// Importing necessary modules from React and Material-UI
import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { Avatar, Button, TableHead, Tooltip, Divider, Stack } from '@mui/material'
import { blueGrey, deepOrange, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import { orange, indigo, yellow, green, blue, teal } from '@mui/material/colors';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import FileDownloadButton from './DHSExcelFileDownloadInternal'; // Assuming this is a custom component
import LoadingDialog from '../Loading'; // Assuming this is a custom Loading component

const steps = [
  'RMA Created',
  'Investigation',
  'Resolution',
  'Follow Up',
  'Closed',
];
const steps2 = [
  'RMA Re-Opened',
  'Investigation',
  'Resolution',
  'Follow Up',
  'Closed',
  ,
];

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const color1=blueGrey[500]
const color2=blueGrey[50]

function DHSRow(props) {
// Get the current date
const PD = new Date();

// Format the current date in a medium format (e.g., Jan 1, 2024)
const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD);

// Destructure the props object to extract the 'row' property
const { row } = props;

// State variables and their setters for handling dialog boxes and loading state
const [dHSMssge, setDHSMssge] = useState(''); // State for dynamic message
const [successDialog, setSuccessDialog] = useState(false); // State for success dialog
const [errorDialog, setErrorDialog] = useState(false); // State for error dialog
const [open, setOpen] = useState(false); // State for general dialog
const [loading, setLoading] = useState(false); // State for loading indicator
const [dis, setDis] = useState(false); // State for disabling controls

// Function to handle closing of dialogs
const handleClose = (e) => {
  setOpen(false); // Close general dialog
  setErrorDialog(false); // Close error dialog
  setSuccessDialog(false); // Close success dialog
};


    return (
      <React.Fragment>
         <LoadingDialog open={loading} />
          {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          } >OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {dHSMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {dHSMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}}>OK</Button>
        </DialogActions>
      </Dialog>)}

        <TableRow>
 
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="left">{row.REQ_ID} <br></br>  <b>Order Type:<br/></b><b style={{color:'red'}}>{row.Order_Type==="Traditional System Order" ?row.Order_Type+ "("+String(row.Armor_Gender)+")" :row.Order_Type}</b><br/>
          <Divider sx={{color:red[900],bgcolor:red[900],height:"2px",}}></Divider>
          <b>Contract Number:</b>{row.Contract_Number}<br/>
          <b>Delivery Order Number:</b>{row.Delivery_Order_Number}<br/>
          <b>CLIN:</b>{row.CLIN}<br/>
          <b>Order Number:</b>{row.Order_Number}<br/>
   
         <Divider sx={{color:red[900],bgcolor:red[900],height:"2px",}}></Divider>

          <b>Created Date:</b>{row.Created_Date}<br/>
          <b>Updated Date:</b>{row.Updated_Date}<br/>
          <b>Created By:</b>{row.Created_By}<br/>

          </TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>User ID:</b>{row.User_ID}<br/><b>Location:</b>{row.Location}<br/><b>Armor Gender:</b>{row.Armor_Gender}<br/><b>Customer Name:</b>{row.Customer_Name}<br></br>
          <b>Email:</b>{row.Customer_Email}
          

          
          
          </Box></TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="Left"> {row.Order_Type==='Traditional System Order' && (<>
      <Table sx={{border:"1px",borderColor:'black'}}>
          <TableRow >
            <TableCell sx={{backgroundColor:'orange'}}>
            <b>Weight(lbs):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Weight_lbs}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Height(ft-in):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Height_ft_in}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Back(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Back_in}
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{backgroundColor:'orange'}}>
            <b>Chest(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Chest_in}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Waist(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Waist_in}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Front Stand(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Front_Stand_in}
              </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{backgroundColor:'orange'}}>
            <b>Front Sit(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Front_Sit_in}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Front Stand(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Front_Stand_in}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Color:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Color}
              </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{backgroundColor:'orange'}}>
            <b>Placard:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Placard}
              </TableCell>
              <TableCell sx={{backgroundColor:'orange'}}>
            <b>Refresh Kit:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:orange[100]}}>
            {row.Refresh_Kit?"Yes":"No"}
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
          </TableRow>
          {row.Armor_Gender==="Female" &&(<>
        <TableRow>
            <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Bra Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Bra_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Cup Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Cup_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Tip Apex to Tip Apex(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Tip_Apex_to_Tip_Apex_in}
              </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Tip Apex to Belt(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Tip_Apex_to_Belt_in}
              </TableCell>
              <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Tip Apex to Clavicle_Shoulder(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Tip_Apex_to_Clavicle_Shoulder_in}
              </TableCell>
              <TableCell sx={{backgroundColor:blue[600]}}>
            <b>Tip Apex to Side(inches):</b>
            </TableCell>
            <TableCell sx={{backgroundColor:blue[100]}}>
            {row.Tip_Apex_to_Side_in}
              </TableCell>
          </TableRow>
 </>)}

      </Table>
  </>)}
  
  
  {row.Order_Type==='Scalable System Order' && (<>
  
         <TableRow>
            <TableCell sx={{backgroundColor:green[600]}}>
            <b>Side Panel Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Side_Panel_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:green[600]}}>
            <b>Cumberbund Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Cumberbund_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:green[600]}}>
            <b>Front Panel Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Front_Panel_Size}
              </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{backgroundColor:green[600]}}>
            <b>Back Panel Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Back_Panel_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:green[600]}}>
            <b>Base Carrier Size:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Base_Carrier_Size}
              </TableCell>
              <TableCell sx={{backgroundColor:green[600]}}>
            <b>Refresh Kit:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Refresh_Kit?"Yes":"No"}
              </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{backgroundColor:green[600]}}>
            <b>Placard:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Placard}
              </TableCell>
              <TableCell sx={{backgroundColor:green[600]}}>
            <b>Color:</b>
            </TableCell>
            <TableCell sx={{backgroundColor:green[100]}}>
            {row.Color}
              </TableCell>
          </TableRow>
          </>)}
          {row.Excel_Sheet_Upload===true && (<>
            <FileDownloadButton filename={row.File_Name} />
          </>)}          
          </TableCell>
          


        </TableRow>
            
      </React.Fragment>
    );
  }
  
  export default DHSRow