import {React} from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { style } from '@mui/system';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        fontWeight:"extrabold",
        flexGrow: 1,
    },
    Contract_Number: {
        width: '25%',
        borderRightColor: borderColor,
        fontSize:9
    },
    Shipping_Method: {
        width: '25%',
        borderRightColor: borderColor,

        fontSize:9
    },
    Tracking_Number: {
        width: '25%',
        borderRightColor: borderColor,

        fontSize:9
    },
    Ship_Date: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:9
    },
});

const Packing_Slip_Contract_Info_Header = () => (
    <>
    <View style={styles.container}>
        <Text style={styles.Contract_Number}>Contract Number</Text>
        <Text style={styles.Shipping_Method}>Shipping Method</Text>
        <Text style={styles.Tracking_Number}>Tracking Number</Text>
        <Text style={styles.Ship_Date}>Ship Date</Text>
    </View>

     </>
);

export default Packing_Slip_Contract_Info_Header;