import { useState } from "react";
import * as React from 'react';
import { Box, Button, Stack, Typography, IconButton, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import RFQForm from '../Images/RFQ-Form.png';
import RFI from '../Images/RFI.png';
import POs from '../Images/POs.png';
import SKUs from '../Images/SKUs.png';
import RMA from '../Images/RMA.png';
import My_Performance from '../Images/My_Performance.png';
import Revenue_Goals from '../Images/REVENUE_GOALS.png';
import { blueGrey, orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import { lighten } from '@mui/system';
import DHS_CBP_HOME from '../Images/DHS-CBP_HOME.png';
import SALES from '../Images/SALES.png';
import { selectIsAdmin, selectUserRole } from "../Login_Registration/authSlice";
import { useSelector } from "react-redux";
import LoadingDialog from "../Loading";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: orange[500],
    },
  },
  typography: {
    fontSize: 12,
    button: {
      textTransform: 'none',
    },
  },
});

const cardDimensions = {
  height: '350px',
  width: '300px',
};

function HomePage() {
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);

  const cardItems = [
    { image: RFQForm, title: "REQUEST FOR A QUOTE", description: "Explore this tab for: Initiating a New RFQ, Modifying an existing RFQ, Retrieving all available Quotes.", link: ["/Dashboard/CreateRFQ", "/Dashboard/RFQsSearch"], 
      linkNames: ['Create', 'Retrieve'], linkColors: [orange[500], blueGrey[500]], userRole: 'USER-SALES' },
    { image: RFI, title: "REQUEST FOR INFORMATION", description: "Utilize this tab for: Initiating a New RFI/Sources Sought, Modifying an existing RFI/Sources Sought, Retrieving all RFIs/Sources Sought.", link: ["/Dashboard/CreateRFI", "/Dashboard/RFIsSearch"], 
      linkNames: ['Create', 'Retrieve'], linkColors: [orange[500], blueGrey[500]], userRole: 'USER-SALES' },
    { image: RMA, title: "WARRANTY/REPAIRS", description: "Opt for this tab to: Generate a New RETURN MERCHANDISE AUTHORIZATION request, Update and track existing RMAs.", link: ["/Dashboard/NewRMA", "/Dashboard/RMAsSearch"], 
      linkNames: ['Create', 'Retrieve'], linkColors: [orange[500], blueGrey[500]], userRole: 'USER-SALES' },
    { image: SALES, title: "SALES LIFE CYCLE", description: "Navigate to this tab to: View/Update the Sales Life Cycle for various contract types. Modify Invoice numbers, Payment details, and other contract-related information.", link: ["/Dashboard/LifeCycle/MASTER", "/Dashboard/LifeCycle/FBOP", "/Dashboard/LifeCycle/GSA", "/Dashboard/LifeCycle/DHS-CBP"], 
      linkNames: ['MASTER', 'FBOP', 'GSA', 'DHS-CBP'], linkColors: [orange[500], blueGrey[500], orange[500], blueGrey[500]], userRole: 'USER-SALES' },
    { image: POs, title: "PURCHASE ORDERS", description: "Access this tab for: Retrieving all Purchase Orders (POs), Gathering information on Margins for each Quote.", link: ["/Dashboard/POsSearch"], 
      linkNames: ['Retrieve'], linkColors: [blueGrey[500]], userRole: 'USER-REVENUE' },
    { image: My_Performance, title: "MY PERFORMANCE METRICS", description: "Track and analyze your sales performance. Access this tab for: retrieving data on your sales performance, gathering insights on key metrics, and making data-driven decisions.", 
      link: ["/Dashboard/MyPerformanceDashboard"], linkNames: ['View'], linkColors: [blueGrey[500]], userRole: 'USER-SALES' },
    { image: DHS_CBP_HOME, title: "DHS-CBP", description: "Set and review your sales goals. Use this tab to establish targets, assess your sales performance data, gain insights into crucial metrics, and inform your decision-making with data-driven analysis.", 
      link: ["/Dashboard/DHSCBPCustomerInfoRequestForm/InternalSales","/Dashboard/DHSReqsSearch"], linkNames: ['Upload', 'Retrieve'], linkColors: [orange[500], blueGrey[500]], userRole: 'USER-SALES' },
    { image: Revenue_Goals, title: "REVENUE GOALS", description: "Set and review your sales goals. Use this tab to establish targets, assess your sales performance data, gain insights into crucial metrics, and inform your decision-making with data-driven analysis.", 
      link: ["/Dashboard/RevenueGoals/Set","/Dashboard/PageUnderDev"], linkNames: ['Set Goals', 'View Goals'], linkColors: [orange[500], blueGrey[500]], userRole: 'USER-SALES' },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <ApplicationBar />
        <Grid 
          container 
          spacing={5} 
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: { xs: '10px', md: '50px' }, marginBottom: '20px', paddingLeft: '10px', paddingRight: '10px' }}
        >
          {cardItems.map((item, index) => (
            <Grid 
              key={index} 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              lg={3} 
              xl={2} 
              sx={{ display: 'flex', justifyContent: 'center', padding: '5px' }}
            >
              <Card sx={{ ...cardDimensions, ":hover": { boxShadow: "10px 10px 20px #ccc" } }}>
                <CardMedia component="img" height="100" image={item.image} alt="card image" sx={{ margin: 'auto' }} />
                <CardContent>
                  <Typography gutterBottom variant="body1" component="div" sx={{ textAlign: 'center', fontSize: '14px' }}>
                    <b>{item.title}</b>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', fontSize: '12px' }}>
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1 }}>
                  <Grid container spacing={1}>
                    {Array.isArray(item.link) && item.link.map((link, linkIndex) => (
                      <Grid key={linkIndex} item xs={12} sm={6}>
                        <Button
                          sx={{
                            width: "100%",
                            backgroundColor: item.linkColors[linkIndex],
                            fontSize: '10px',
                            ":hover": {
                              backgroundColor: lighten(item.linkColors[linkIndex], 0.2),
                              boxShadow: "10px 10px 20px #ccc",
                            },
                          }}
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(link);
                          }}
                        >
                          {item.linkNames[linkIndex]}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default HomePage;
