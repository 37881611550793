import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import { Button, Container, Typography, Paper, Input,Stack,Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import LoadingDialog from '../Loading';
import { indigo, blueGrey, red,cyan,blue,orange } from '@mui/material/colors';
import ApplicationBar from '../AppBar/ApplicationBar';
import AxiosFunction from '../../axiosCustomInstance';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFDataExtractor() {
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfData, setPdfData] = useState('');
  const [dis, setDis] = useState(false);
  const [fileMssge, setFileMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const AxiosAPIInstance=AxiosFunction()

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPdfFile(file);
    }
  };

  const handleClose = (e) => {
    setFileMssge('')
    setErrorDialog(false);
    setSuccessDialog(false);
    setErrorDialog(false);

  };

  const [extractedData, setExtractedData] = useState({});

  const extractData = async () => {
    if (!pdfFile) return;
  
    try {
      setLoading(true);
      const pdfDataArrayBuffer = await pdfFile.arrayBuffer();
      const pdfDataUint8Array = new Uint8Array(pdfDataArrayBuffer);
      const pdf = await pdfjs.getDocument({ data: pdfDataUint8Array }).promise;
  
      let extractedDataObject = {}; // Object to store extracted data
  
      // Loop through each page of the PDF
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join('\n');
  
        // Extracting specific fields
        const fields = {
          'DATE OF ORDER': /DATE OF ORDER\s*([\w\s]+\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2}\s\d{4})\s*/,
          'ORDER NUMBER': /ORDER NUMBER\s*([\w\s-]+)\s/,
          'REQUISITIONING OFFICE': /REQUISITIONING OFFICE\s*([^\n]+)/,
          'ISSUING OFFICE': /ISSUING OFFICE\s*([^\n]+)/,
          'DELIVERY TO FOB POINT ON OR BEFORE (date)': /DELIVERY TO FOB POINT ON OR BEFORE \(date\)\s*([\w\s]+)\s/,
          'DISCOUNT TERMS': /DISCOUNT TERMS\s*([\w\s]+)\s/,
          'E-MAIL': /E-MAIL\s*([\w@.]+)\s/,
          'PHONE': /PHONE\s*([\d-]+)\s/,
          'GRAND TOTAL': /GRAND TOTAL:\s*\$?([\d.]+)\s/ // Regex for GRAND TOTAL
        };
  
        Object.entries(fields).forEach(([fieldName, regex]) => {
          const match = pageText.match(regex);
          if (match && match[1]) {
            // Add extracted field name and value to the object
            extractedDataObject[fieldName] = match[1].trim();
          }
        });
  
        // Extracting SHIPPING INSTRUCTIONS
        const shippingInstructionsRegex = /SHIP TO:(.*?)PO:/s;
        const shippingInstructionsMatch = pageText.match(shippingInstructionsRegex);
        if (shippingInstructionsMatch && shippingInstructionsMatch[1]) {
          const shippingInstructions = shippingInstructionsMatch[1].trim();
          // Add extracted shipping instructions to the object
          extractedDataObject['CUSTOMER CONTACT INFORMATION'] = shippingInstructions;
        }
      }
  
      setExtractedData(extractedDataObject);
      setFileMssge('Data extraction successful');
      setSuccessDialog(true);
      setLoading(false);
    } catch (error) {
      console.error('Error extracting data from PDF:', error);
      setLoading(false);
      setFileMssge('Error extracting data from PDF. Please try again.');
      setErrorDialog(true);
    }
  };
  

  return (
    <>
    <ApplicationBar/>
    <Container maxWidth="md" sx={{ marginTop: 10 }}>
          <LoadingDialog open={loading} />
      {successDialog === true && (
        <Dialog
          open={successDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {fileMssge && <Alert variant="filled" severity="success">SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {fileMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorDialog === true && (
        <Dialog
          open={errorDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {fileMssge && <Alert variant="filled" severity="error">ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {fileMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e) => {
              handleClose();
              e.preventDefault();
              setDis(false);
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
      <Stack justifyContent="center" direction="row">
          <Typography component="p" sx={{alignContent:"center",fontSize:"25px", fontWeight:"bold",padding:1}}> PDF Data Extractor</Typography>
          </Stack>
          <Stack justifyContent="center" direction="row">
          <Divider sx={{color:blueGrey[900],bgcolor:orange[800],width:"100%",height:"1.5px",marginBottom:"30px"}} orientation='horizontal'></Divider>
          </Stack>
          <Stack direction='row' spacing={3} justifyContent='center'>
          <Input type="file" onChange={onFileChange} sx={{ marginBottom: 2 }} />
        <Button variant="contained" onClick={extractData} sx={{ marginBottom: 2 }}>
          Extract Data
        </Button>
          </Stack>
{/* 
        <div>
          {pdfFile && (
            <Document file={pdfFile}>
              <Page pageNumber={1} />
            </Document>
          )}
        </div> */}
      </Paper>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h6" gutterBottom>
          Extracted Data:
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="extracted data table">
            <TableHead>
              <TableRow>
                <TableCell>Field Name</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(extractedData).map(([fieldName, value]) => (
                <TableRow key={fieldName}>
                  <TableCell component="th" scope="row">
                    {fieldName}
                  </TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
    </>
  );
}

export default PDFDataExtractor;
